import React, { Component, Fragment } from "react";

import {
	Avatar,
	ListItem,
	ListItemText,
	Typography,
	Button,
	ListItemAvatar,
} from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import { checkUrl } from "../../utils/helpers";

class SelectLawyerModalLawyerItem extends Component {
	state = {
		showBio: false,
	};

	render() {
		const { lawyer, selectedLawyer, selectLawyer } = this.props;
		const { showBio } = this.state;
		let isValidUrl = checkUrl(lawyer.image);
		return (
			<ListItem key={lawyer.uid} alignItems="flex-start">
				<ListItemAvatar>
					<Avatar
						src={
							isValidUrl
								? lawyer.image
								: process.env.REACT_APP_DRUPAL_HOST_URL +
								  lawyer.image
						}
					/>
				</ListItemAvatar>
				<ListItemText
					primary={lawyer.name}
					secondary={
						<Fragment>
							<Typography
								component="span"
								style={{ color: "#555555", fontSize: 16 }}
								gutterBottom
							>
								{showBio
									? lawyer.description.text
									: lawyer.description.trim}
							</Typography>
							{!!lawyer.description.trim && (
								<Typography
									color="primary"
									style={{
										display: "block",
										marginBottom: 10,
										color: "#310095",
										fontSize: 16,
									}}
									component="span"
									onClick={() => {
										this.setState({
											showBio: !showBio,
										});
									}}
								>
									{showBio && "Collapse bio"}
									{!showBio && (
										<Fragment>
											<AddCircleOutline
												style={{
													fontSize: 16,
													marginRight: 5,
												}}
											/>
											Read Bio
										</Fragment>
									)}
								</Typography>
							)}

							<Button
								variant="outlined"
								color="primary"
								onClick={() => {
									selectLawyer(lawyer.uid);
								}}
								style={{
									borderColor: "#28008F",
									color: "#220C89",
									padding: "4px 12px",
									fontSize: "13px",
								}}
							>
								{selectedLawyer === lawyer.uid
									? "selected"
									: "select"}
							</Button>
						</Fragment>
					}
				/>
			</ListItem>
		);
	}
}

export default SelectLawyerModalLawyerItem;
