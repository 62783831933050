import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { fetchTransactionsList, acceptDisbTransaction, cancelDisbTransaction, rejectDisbTransaction } from '../redux/actions/transactionActions';
import { Loading, Processing, CancelationModal } from '../Components/Layouts';
import { Paper, Table, TableBody, TableRow, TableCell, Toolbar, Typography, IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import TransactionItem from '../Components/Transaction/TransactionItem';
import TransactionsStickyHeader from '../Components/Transaction/StickyHeader';

const styles = theme => ({
    tableWrapper: {
        overflowX: 'auto',
    },
    tableHeader: {
        fontSize: 12,
        fontWeight: 700,
    },
    toolbarHeader: {
        borderBottom: '1px solid #f9f9f9'
    },
    title: {
        flex: '0 0 auto',
    },
    spacer: {
        flexGrow: 1,
    },
    actions: {
        display: 'flex',
    },
    chipItem: {
        height: 'auto',
        borderRadius: 6,
        fontSize: 11,
    },
    tableCell: {
        padding: '4px 15px',
        fontSize: 11,
    }
});

function convert_seconds_to_hours(totalSeconds) {
    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);

    return `${hours}h ${minutes}m`;
}

class TransactionsPage extends Component {

    state = {
        cancelationModalOpen: {
            open: false,
            title: '',
            uuid: '',
        },
        rejectionModalOpen: {
            open: false,
            title: '',
            uuid: '',
        },
    };

    componentDidMount() {
        var data = new FormData();

        var userData = JSON.parse(localStorage.getItem("userData"));
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);

        this.props.fetchTransactionsList(this.props.auth.case_uuid, data);
    };

    /*
        handleScrollRight = (wrapper_id, content_id) => {
            let wrapper = document.getElementById(wrapper_id);
            let table = document.getElementById(content_id);
            document.getElementById(wrapper_id).scrollLeft += (table.offsetWidth - wrapper.offsetWidth);
        };

        handleScrollLeft = (wrapper_id, content_id) => {
            let wrapper = document.getElementById(wrapper_id);
            let table = document.getElementById(content_id);
            document.getElementById(wrapper_id).scrollLeft += (wrapper.offsetWidth - table.offsetWidth);
        };
    */

    handleAcceptDisbTransaction = (transaction_uuid) => {
        let userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('uuid', transaction_uuid);

        this.props.acceptDisbTransaction(transaction_uuid, data);
    };

    handleCancelationModalOpen = (data) => {
        this.setState({
            [data.type]: {
                open: true,
                title: data.title,
                uuid: data.uuid,
            },
        });
    };

    handleCancelationModalClose = (type) => {
        this.setState({
            [type]: {
                open: false,
                title: '',
                uuid: '',
            },
        })
    };

    handleCancelDisbTransaction = (cancel_data) => {
        let userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('uuid', cancel_data.uuid);
        data.append('comment', cancel_data.comment);
        this.handleCancelationModalClose('cancelationModalOpen');

        //data.append('reason', cancel_data.reason);
        this.props.cancelDisbTransaction(cancel_data.uuid, data)
    };

    handleRejectDisbTransaction = (cancel_data) => {
        let userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('uuid', cancel_data.uuid);
        data.append('comment', cancel_data.comment);
        this.handleCancelationModalClose('rejectionModalOpen');

        //data.append('reason', cancel_data.reason);
        this.props.rejectDisbTransaction(cancel_data.uuid, data)
    };

    render() {

        let totalDuration = 0;
        let totalAmount = 0;
        let disbTotalDuration = 0;
        let disbTotalAmount = 0;

        const { transactionList, classes, rejectionReasons, isLawyer } = this.props,
            { cancelationModalOpen, rejectionModalOpen } = this.state;

        if (!transactionList.loaded) {
            return <Loading />
        }
        console.log(isLawyer);

        const transactionRejectionReasons = Object.entries(rejectionReasons)
            .filter(([group, reason]) => reason.name.toLowerCase() === "transaction")
            .map(([group, reason]) => {
                return reason;
            })[0];

        const disbTransactions = transactionList.transactions.filter(tr => tr.category === "Disbursement");
        const transactions = transactionList.transactions.filter(tr => tr.category !== "Disbursement");

        return (
            <Fragment>
                <TransactionsStickyHeader
                    //onMenuSelect={this.handleMenuSelect}
                    activeMenu={1}
                />

                <div className="page--wrapper">
                    {transactionList.proccessing &&
                        <Processing />
                    }

                    <Paper style={{ marginBottom: 10 }}>
                        <Toolbar className={classes.toolbarHeader}>
                            <div className={classes.title}>
                                <Typography className={classes.tableHeader} color="primary">
                                    APPOINTMENTS & TASKS
                            </Typography>
                            </div>
                            <div className={classes.spacer} />
                            {/*
                            <div className={classes.actions}>
                                <IconButton onClick={() => this.handleScrollLeft('transactions-scroll-wrapper', 'transactions-table')} color="primary">
                                    <ChevronLeft />
                                </IconButton>

                                <IconButton onClick={() => this.handleScrollRight('transactions-scroll-wrapper', 'transactions-table')} color="primary">
                                    <ChevronRight />
                                </IconButton>
                            </div>
                            */}
                        </Toolbar>
                        <div className={classes.tableWrapper} id="transactions-scroll-wrapper">
                            <Table id="transactions-table">
                                <TableBody>
                                    {
                                        transactions.map(tr => {
                                            if (tr.category !== "Disbursement" && tr.category !== "Task") {
                                                totalDuration = totalDuration + Number(tr.duration);
                                            }

                                            totalAmount = totalAmount + Number(tr.amount) + Number(tr.tax_amount);
                                            return <TransactionItem transaction={tr} key={tr.nid} />
                                        })
                                    }
                                    <TableRow>
                                        {/* <TableCell colSpan={2} className={classes.tableCell} /> */}
                                        <TableCell className={classes.tableCell}>
                                            <strong>RUNNING TOTALS</strong>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <strong>{`$ ${totalAmount.toFixed(2)}`}</strong>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <strong>{convert_seconds_to_hours(totalDuration)}</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    </Paper>

                    <Paper style={{ marginBottom: 10 }}>
                        <Toolbar className={classes.toolbarHeader}>
                            <div className={classes.title}>
                                <Typography className={classes.tableHeader} color="primary">
                                    {isLawyer ? 'REIMBURSABLE EXPENSES' : 'EXPENSES'}
                                </Typography>
                            </div>
                            <div className={classes.spacer} />
                            {/*
                            <div className={classes.actions}>
                                <IconButton color="primary" onClick={() => this.handleScrollLeft('disb-transactions-scroll-wrapper', 'disb-transactions-table')}>
                                    <ChevronLeft />
                                </IconButton>

                                <IconButton color="primary" onClick={() => this.handleScrollRight('disb-transactions-scroll-wrapper', 'disb-transactions-table')}>
                                    <ChevronRight />
                                </IconButton>
                            </div>
                            */}
                        </Toolbar>
                        <div className={classes.tableWrapper} id="disb-transactions-scroll-wrapper">
                            <Table id="disb-transactions-table">
                                <TableBody>
                                    {
                                        disbTransactions.map(tr => {
                                            disbTotalDuration = disbTotalDuration + Number(tr.duration);
                                            disbTotalAmount = disbTotalAmount + Number(tr.amount) + Number(tr.tax_amount);
                                            return <TransactionItem
                                                key={tr.nid}
                                                transaction={tr}
                                                acceptTransaction={this.handleAcceptDisbTransaction}
                                                openCancelationModal={this.handleCancelationModalOpen}
                                            />
                                        })
                                    }
                                    <TableRow>
                                        <TableCell colSpan={1} className={classes.tableCell} />
                                        <TableCell className={classes.tableCell}>
                                            <strong>RUNNING TOTALS</strong>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <strong>{`$ ${disbTotalAmount.toFixed(2)}`}</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    </Paper>

                    {cancelationModalOpen.open &&
                        <CancelationModal
                            open={cancelationModalOpen.open}
                            handleClose={() => this.handleCancelationModalClose('cancelationModalOpen')}
                            onSubmit={this.handleCancelDisbTransaction}
                            title={cancelationModalOpen.title}
                            rejectionReasons={transactionRejectionReasons}
                            data={{
                                comment: '',
                                uuid: cancelationModalOpen.uuid,
                                reason: '',
                            }}
                        />
                    }

                    {rejectionModalOpen.open &&
                        <CancelationModal
                            open={rejectionModalOpen.open}
                            handleClose={() => this.handleCancelationModalClose('rejectionModalOpen')}
                            onSubmit={this.handleRejectDisbTransaction}
                            title={rejectionModalOpen.title}
                            rejectionReasons={transactionRejectionReasons}
                            data={{
                                comment: '',
                                uuid: rejectionModalOpen.uuid,
                                reason: '',
                            }}
                        />
                    }
                </div>
            </Fragment>
        )
    }
}

TransactionsPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        transactionList: state.transaction,
        rejectionReasons: state.utility.rejectionReasons,
        auth: state.auth,
        isLawyer: state.headerReducer.user.is_lawyer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchTransactionsList: (case_uuid, data) => dispatch(fetchTransactionsList(case_uuid, data)),
        acceptDisbTransaction: (transaction_uuid, data) => dispatch(acceptDisbTransaction(transaction_uuid, data)),
        cancelDisbTransaction: (transaction_uuid, data) => dispatch(cancelDisbTransaction(transaction_uuid, data)),
        rejectDisbTransaction: (transaction_uuid, data) => dispatch(rejectDisbTransaction(transaction_uuid, data)),
    }
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(TransactionsPage);