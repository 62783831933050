import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Divider } from '@material-ui/core';
import Sticky from 'react-stickynode';

import './StickySubHeader.css';

const styles = theme => ({
    subHeader: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '80%',
        margin: '0 auto',
        padding: '10px 0'
    },
    title: {
        cursor: 'pointer',
    },
    grow: {
        flexGrow: 1,
    },
    verDivider: {
        width: 2,
        height: 16,
        margin: '4px 8px',
    },
    horDivider: {
        marginLeft: 36,
        marginRight: 36,
    }
});


class StickySubHeader extends Component {
    state = {
        showDivider: true,
    }

    handleStateChange = (status) => {
        this.setState({
            showDivider: !status.status,
        })
    }

    getStyle = (index) => {
        if (this.props.active === index) {
            return {
                color: '#220D89',
                fontWeight: 700,
            }
        }
    }

    render() {

        const { classes, title1, title2, changeMode } = this.props;

        return (
            <Sticky enabled={true} top={56} innerZ={1299} bottomBoundary={1200} onStateChange={this.handleStateChange}>
                <div className={classes.subHeader}>
                    <Typography inline={true} className={classes.title} style={this.getStyle(1)} onClick={() => changeMode(1)}>
                        {title1}
                    </Typography>
                    <Divider className={classes.verDivider} />
                    <Typography inline={true} className={classes.title} style={this.getStyle(2)} onClick={() => changeMode(2)}>
                        {title2}
                    </Typography>
                </div>
                {this.state.showDivider &&
                    <Divider variant="fullWidth" className={classes.horDivider} />
                }
            </Sticky>
        )
    }
}

StickySubHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StickySubHeader);

