import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
    Dialog,
    IconButton,
    Typography,
    Button,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit,
    },
}))(MuiDialogActions);

const AppointmentCreateExitConfirm = ({ classes, open, handleClose, handleQuit }) => {
    return (
        <Dialog
            onClose={handleClose}
            fullWidth={true}
            open={open}
            classes={{
                paper: classes.modalPaper
            }}
        >
            <DialogTitle onClose={handleClose}>
                Confirm Exit
            </DialogTitle>
            <DialogContent>
                <Typography>
                    Are you sure you want to quit booking an appointment?
                    Changes you've made may be lost.
                </Typography>

                <Typography>
                    Press QUIT BOOKING to quit or CONTINUE BOOKING to stay on the current page.
                </Typography>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleQuit} color="default" variant="contained">
                    QUIT BOOKING
                </Button>
                <Button onClick={handleClose} color="primary" variant="contained">
                    CONTINUE BOOKING
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default withStyles(theme => ({
    modalPaper: {
        margin: 0,
    },
}))(AppointmentCreateExitConfirm);