import {
    DASHBOARD_FETCH_REQUIRED_ATTENTIONS,
    DASHBOARD_CANCEL_ATTENTION,
    DASHBOARD_ACCEPT_ATTENTION,
    DASHBOARD_SHOW_SNACKBAR,
    DASHBOARD_HIDE_SNACKBAR,
    DASHBOARD_SHOW_PROCESSING,
} from "../actions/actionTypes";

const initState = {
    loaded: false,
    processing: false,
    attentions: [],
    notificationSnackbar: {
        open: false,
        variant: "",
        message: "",
    },
};

const dashboardReducer = (state = initState, action) => {
    switch (action.type) {
        case DASHBOARD_FETCH_REQUIRED_ATTENTIONS:
            return {
                ...state,
                loaded: true,
                attentions: action.payload,
            };
        case DASHBOARD_CANCEL_ATTENTION:
            let newAttentions = state.attentions.filter(
                (attention) => attention.uuid !== action.payload
            );
            return {
                ...state,
                processing: false,
                attentions: newAttentions,
            };
        case DASHBOARD_ACCEPT_ATTENTION:
            let accpetedAttention = state.attentions.find(
                (attention) => attention.uuid === action.payload.attention_uuid
            );
            accpetedAttention.new_actions = action.payload.actions;
            accpetedAttention.status = action.payload.status.toLowerCase();
            return {
                ...state,
                processing: false,
            };
        case DASHBOARD_SHOW_SNACKBAR:
            return {
                ...state,
                processing: false,
                notificationSnackbar: {
                    open: true,
                    variant: action.payload.variant,
                    message: action.payload.message,
                },
            };
        case DASHBOARD_HIDE_SNACKBAR:
            return {
                ...state,
                processing: false,
                notificationSnackbar: initState.notificationSnackbar,
            };
        case DASHBOARD_SHOW_PROCESSING:
            return {
                ...state,
                processing: true,
            };
        default:
            return state;
    }
};

export default dashboardReducer;
