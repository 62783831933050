import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Paper, Typography } from '@material-ui/core';
import moment from 'moment';
import './MessageItem.css'

const styles = theme => ({
    paperMe: {
        backgroundColor: '#dae2fa',
        width: 'calc(100% - 30px)',
        margin: '0 auto',
        borderBottomRightRadius: 0,
    },
    paperContact: {
        width: 'calc(100% - 30px)',
        margin: '0 auto',
        borderBottomLeftRadius: 0,
    },
    messageHeader: {
        fontSize: 10,
        color: '#999',
        fontWeight: 700,
    }
});

class MessageItem extends Component {

    render() {

        const { message, classes, timezone } = this.props;
        return (
            <Paper className={message.is_my ? classes.paperMe : classes.paperContact} elevation={0}>
                <div className={message.is_my ? "my--message" : "income--message"}>
                    <div style={{ display: 'flex', justifyContent: "space-between" }}>
                        <Typography className={classes.messageHeader}>
                            {message.is_my ? 'Me' : message.name}
                        </Typography>
                        <Typography className={classes.messageHeader}>
                            {message.time ? moment.unix(message.time).tz(timezone).format('h:mm a') : moment().format('h:mm a')}
                        </Typography>
                    </div>
                    <Typography dangerouslySetInnerHTML={{ __html: message.message }}>
                    </Typography>
                </div>
            </Paper>
        )
    }
}

MessageItem.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MessageItem);