import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
    Paper,
    FormControl,
    TextField,
    Typography,
    Button,
    FormControlLabel,
    Checkbox,
    FormHelperText,
} from "@material-ui/core";
import {
    PageHading,
    Processing,
    CalendarNavbar,
    Loading,
} from "../Components/Layouts";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import moment from "moment-timezone";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { createTask } from "../redux/actions/taskActions";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import "../styles/calendar.css";
import { TASKS_RESET_REDIRECT } from "../redux/actions/actionTypes";

const styles = (theme) => ({
    pageContainer: {
        minHeight: "calc(100vh - 128px)",
    },
    formContainer: {
        // display: 'flex',
        // justifyContent: 'space-between',
        marginTop: 25,
    },
    textField: {
        fontSize: 12,
        // marginLeft: theme.spacing.unit,
        // marginRight: theme.spacing.unit,
    },
    Paper: {
        paddingRight: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 2,
        borderRadius: 0,
    },
    formLabel: {
        textTransform: "uppercase",
        color: "#999999",
        fontSize: 10,
        fontWeight: 700,
    },
    formGroup: {
        marginBottom: 25,
    },
    resize: {
        fontSize: 13,
    },
});

class TaskCreatePage extends Component {
    state = {
        loading: true,
        processing: true,
        dateError: false,
        isRedirect: false,
        taskData: {
            startDate: null,
            taskName: "",
            taskAmount: "",
            taskDescription: "",
            isBillable: false,
            billableDissabled: false,
        },
        CaseData: {},
    };

    componentDidMount() {
        this.getCase(this.props.auth.case_uuid);
    }

    getCase(case_uuid) {
        let data = new FormData();

        let userData = JSON.parse(localStorage.getItem("userData"));

        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);

        fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/${case_uuid}`, {
            method: "POST",
            body: data,
        })
            .then((res) => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error("failed");
                }

                return res.json();
            })
            .then((resData) => {
                if (resData.success) {
                    this.setState({
                        CaseData: resData.data,
                        loading: false,
                        taskData: {
                            ...this.state.taskData,
                            isBillable:
                                resData.data.hasOwnProperty(
                                    "field_case_billing_type"
                                ) &&
                                ["Hourly"].includes(
                                    resData.data.field_case_billing_type.name
                                ) &&
                                //&& (!!Object.keys(resData.data.client.field_stripe_account).length)
                                !!Object.keys(
                                    resData.data.lawyer.field_stripe_account
                                ).length,
                            billableDissabled: !(
                                resData.data.hasOwnProperty(
                                    "field_case_billing_type"
                                ) &&
                                ["Hourly"].includes(
                                    resData.data.field_case_billing_type.name
                                ) &&
                                //&& (!!Object.keys(resData.data.client.field_stripe_account).length)
                                !!Object.keys(
                                    resData.data.lawyer.field_stripe_account
                                ).length
                            ),
                        },
                    });
                }
            })
            .catch((err) => {
                //console.log(err);
                this.setState({
                    error: true,
                });
            });
    }

    handleCheckboxChange = (name) => ({ target: { checked } }) => {
        this.setState({
            taskData: {
                ...this.state.taskData,
                [name]: checked,
            },
        });
    };

    handleInputChange = (name) => ({ target: { value } }) => {
        this.setState({
            taskData: {
                ...this.state.taskData,
                [name]: value,
            },
        });
    };

    handleDayClick = (day, { selected }) => {
        this.setState({
            dateError: false,
            taskData: {
                ...this.state.taskData,
                startDate: selected ? undefined : day,
            },
        });
    };

    filterDate = (data) => {
        const filterDate = moment(data);
        const dateNow = moment();
        const matchDate = filterDate.isSameOrAfter(dateNow, "day");
        return !matchDate;
    };

    handleSubmit = () => {
        const { taskData } = this.state;

        if (taskData.startDate) {
            var userData = JSON.parse(localStorage.getItem("userData"));
            var data = new FormData();
            data.append("qase_api_token", userData.token);
            data.append("sid", userData.sid);
            data.append("title", taskData.taskName);
            data.append("body", taskData.taskDescription);
            data.append("billable", taskData.isBillable ? 1 : 0);
            data.append(
                "amount",
                taskData.isBillable ? taskData.taskAmount : 0
            );
            data.append(
                "due_date",
                moment(taskData.startDate).format("YYYY-MM-DD")
            );
            data.append("case_uuid", this.props.auth.case_uuid);
            this.props.createTask(data);
        } else {
            this.setState({
                dateError: true,
            });
        }
    };

    render() {
        if (this.state.loading) {
            return <Loading />;
        }
        const { classes, processing, isRedirect } = this.props,
            { taskData, dateError, CaseData } = this.state;
        if (isRedirect) {
            this.props.resetRedirect();
            return <Redirect to={`/tasks/${this.props.auth.case_uuid}`} />;
        }

        return (
            <div
                className="page--wrapper--no-margin"
                style={{ paddingBottom: 72 }}
            >
                <Paper className={classes.Paper}>
                    <div className={classes.pageContainer}>
                        {processing && <Processing />}
                        <PageHading title={"New Task"} />

                        <ValidatorForm
                            ref="form"
                            onSubmit={this.handleSubmit}
                            className={classes.formContainer}
                        >
                            <Fragment>
                                <div className={classes.formGroup}>
                                    <DayPicker
                                        showOutsideDays
                                        selectedDays={taskData.startDate}
                                        navbarElement={<CalendarNavbar />}
                                        fromMonth={moment().toDate()}
                                        onDayClick={this.handleDayClick}
                                        disabledDays={this.filterDate}
                                    />
                                    {dateError && (
                                        <Typography
                                            style={{ color: "#f44336" }}
                                        >
                                            please select date
                                        </Typography>
                                    )}
                                </div>

                                <div className={classes.formGroup}>
                                    <Typography className={classes.formLabel}>
                                        TASK DETAILS
                                    </Typography>

                                    <FormControl fullWidth margin="normal">
                                        <TextValidator
                                            variant="outlined"
                                            placeholder="Task Name*"
                                            label="Task Name*"
                                            value={taskData.taskName}
                                            onChange={this.handleInputChange(
                                                "taskName"
                                            )}
                                            validators={["required"]}
                                            errorMessages={[
                                                "Task name is required",
                                            ]}
                                            InputProps={{
                                                classes: {
                                                    input: classes.resize,
                                                },
                                            }}
                                            fullWidth
                                        />
                                    </FormControl>

                                    <FormControl fullWidth margin="normal">
                                        <TextValidator
                                            variant="outlined"
                                            multiline
                                            rows={4}
                                            placeholder="Task Description"
                                            label="Task Description*"
                                            value={taskData.taskDescription}
                                            onChange={this.handleInputChange(
                                                "taskDescription"
                                            )}
                                            validators={["required"]}
                                            errorMessages={[
                                                "Description is required",
                                            ]}
                                            fullWidth
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        taskData.isBillable
                                                    }
                                                    onChange={this.handleCheckboxChange(
                                                        "isBillable"
                                                    )}
                                                    value="isBillable"
                                                    color="primary"
                                                    disabled={
                                                        taskData.billableDissabled
                                                    }
                                                />
                                            }
                                            label="Billable Task"
                                        />
                                        {CaseData.hasOwnProperty(
                                            "field_case_billing_type"
                                        ) &&
                                            [
                                                "Contingency",
                                                "Pro Bono",
                                            ].includes(
                                                CaseData.field_case_billing_type
                                                    .name
                                            ) && (
                                                <FormHelperText
                                                    style={{ color: "red" }}
                                                >
                                                    Billing is disabled for{" "}
                                                    {
                                                        CaseData
                                                            .field_case_billing_type
                                                            .name
                                                    }
                                                </FormHelperText>
                                            )}
                                        {/* {
                                            (CaseData.hasOwnProperty('field_case_billing_type') && ['Hourly'].includes(CaseData.field_case_billing_type.name)) &&
                                            ((!Object.keys(CaseData.client.field_stripe_account).length)) &&
                                            <FormHelperText style={{ color: 'red' }}> client has not registered the needed financial information to enable
                                              billing.</FormHelperText>
                                        } */}
                                        {CaseData.hasOwnProperty(
                                            "field_case_billing_type"
                                        ) &&
                                            ["Hourly"].includes(
                                                CaseData.field_case_billing_type
                                                    .name
                                            ) &&
                                            !Object.keys(
                                                CaseData.lawyer
                                                    .field_stripe_account
                                            ).length && (
                                                <FormHelperText
                                                    style={{ color: "red" }}
                                                >
                                                    {" "}
                                                    lawyer has not registered
                                                    the needed financial
                                                    information to enable
                                                    billing.
                                                </FormHelperText>
                                            )}
                                    </FormControl>

                                    {taskData.isBillable && (
                                        <FormControl fullWidth margin="normal">
                                            <TextValidator
                                                variant="outlined"
                                                placeholder="Amount*"
                                                label="Amount*"
                                                value={taskData.taskAmount}
                                                onChange={this.handleInputChange(
                                                    "taskAmount"
                                                )}
                                                validators={[
                                                    "required",
                                                    "isNumber",
                                                ]}
                                                errorMessages={[
                                                    "Task amount is required",
                                                    "Enter numbers only, do not include special characters.",
                                                ]}
                                                InputProps={{
                                                    classes: {
                                                        input: classes.resize,
                                                    },
                                                }}
                                                fullWidth
                                            />
                                        </FormControl>
                                    )}
                                </div>
                            </Fragment>

                            <Button
                                variant="contained"
                                fullWidth
                                size="large"
                                color="primary"
                                style={{ borderRadius: 0, marginBottom: 25 }}
                                type="submit"
                            >
                                Submit Request
                            </Button>
                        </ValidatorForm>
                    </div>
                </Paper>
            </div>
        );
    }
}

const mapSateToProps = (state) => {
    return {
        isRedirect: state.task.isRedirect,
        processing: state.task.processing,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createTask: (data) => {
            dispatch(createTask(data));
        },
        resetRedirect: () => {
            dispatch({ type: TASKS_RESET_REDIRECT });
        },
    };
};

TaskCreatePage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
    connect(mapSateToProps, mapDispatchToProps)
)(TaskCreatePage);
