import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
// import logger from 'redux-logger';
import rootReducer from './reducers';
import { QaseApi } from '../Services/QaseApi';

//export default createStore(rootReducer, applyMiddleware(reduxThunk.withExtraArgument(QaseApi)));

export function configureStore() {
    const qaseApi = new QaseApi();

    const composeEnhancers = (
        (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ?
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                name: 'QASE', actionsBlacklist: ['REDUX_STORAGE_SAVE']
            }) : compose
    );

    return createStore(
        rootReducer,
        composeEnhancers(
            applyMiddleware(reduxThunk.withExtraArgument(qaseApi))
        )
    )
}

