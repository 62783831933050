import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { FormControlLabel, Paper, Switch } from '@material-ui/core';

import Actions from './Actions';

const styles = theme => ({
    Paper: {
        padding: 20,
        marginBottom: 10,
    },
    formGroup: {
        marginBottom: 15,
    },
    editBtn: {
        padding: 5,
    },
    colorSwitchBase: {
        color: '#999999',
        '&$colorChecked': {
            color: '#00cc99',
            '& + $colorBar': {
                backgroundColor: 'rgba(0, 204, 153, 0.647058823529412)',
            },
        },
    },
    colorBar: {},
    colorChecked: {},
});

class NotificationInfo extends Component {


    state = {
        checkedB: (this.props.user.pm_email_notify === "1" ? true : false),
        checkedSMS: (this.props.user.field_sms_notify === "1" ? true : false),
        checkedMsg: (this.props.user.field_case_message_notify === "1" ? true : false),
        editMode: false,
        submited: false,
    };

    componentDidUpdate(prevProps, prevState){
        if(
            (prevProps.user.pm_email_notify !== this.props.user.pm_email_notify) ||
            (prevProps.user.field_sms_notify !== this.props.user.field_sms_notify) ||
            (prevProps.user.field_case_message_notify !== this.props.user.field_case_message_notify)
        ){
            this.setState({
                checkedB: (this.props.user.pm_email_notify === "1" ? true : false),
                checkedSMS: (this.props.user.field_sms_notify === "1" ? true : false),
                checkedMsg: (this.props.user.field_case_message_notify === "1" ? true : false),
            })
        } 
    }

    changeEditMode = () => {
        this.setState({
            editMode: !this.state.editMode,
        })
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            submited: true,
        });

        const { checkedB, checkedSMS, checkedMsg } = this.state;

        var data = new FormData();

        var sessionUserData = JSON.parse(localStorage.getItem("userData"));

        data.append('qase_api_token', sessionUserData.token);
        data.append('sid', sessionUserData.sid);
        data.append('pm_email_notify', (checkedB ? 1 : 0));
        data.append('field_sms_notify', (checkedSMS ? 1 : 0));
        data.append('field_case_message_notify', (checkedMsg ? 1 : 0));
        fetch(`${process.env.REACT_APP_API_HOST_URL}/user/notification/update`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('failed');
                }
                return res.json();
            })
            .then(resData => {
                //console.log(resData);
                if (resData.success) {
                    this.setState({
                        editMode: false,
                        submited: false,
                    });
                }
            })
            .catch(err => {
                //console.log(err);
            });
    }

    render() {
        const { classes } = this.props,
            { editMode, submited } = this.state;
        return (
            <Paper className={classes.Paper}>
                <form onSubmit={this.handleSubmit}>
                    <Actions
                        editMode={editMode}
                        submited={submited}
                        changeEditMode={this.changeEditMode}
                        title="NOTIFICATIONS" />

                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.state.checkedB}
                                onChange={this.handleChange('checkedB')}
                                value="0"
                                name="pm_email_notify"
                                classes={{
                                    switchBase: classes.colorSwitchBase,
                                    checked: classes.colorChecked,
                                    bar: classes.colorBar,
                                }}
                                disabled={!editMode}
                            />
                        }
                        label="Email Notifications"
                    />

                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.state.checkedSMS}
                                onChange={this.handleChange('checkedSMS')}
                                value="1"
                                name="field_sms_notify"
                                classes={{
                                    switchBase: classes.colorSwitchBase,
                                    checked: classes.colorChecked,
                                    bar: classes.colorBar,
                                }}
                                disabled={!editMode}
                            />
                        }
                        label="SMS Notifications"
                    />

                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.state.checkedMsg}
                                onChange={this.handleChange('checkedMsg')}
                                value="2"
                                name="field_case_message_notify"
                                classes={{
                                    switchBase: classes.colorSwitchBase,
                                    checked: classes.colorChecked,
                                    bar: classes.colorBar,
                                }}
                                disabled={!editMode}
                            />
                        }
                        label="Case Message Notifications"
                    />
                </form>

            </Paper>
        )
    }
}

NotificationInfo.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotificationInfo);