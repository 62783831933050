import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import Sticky from 'react-stickynode';

const styles = theme => ({
    subHeader: {
        backgroundColor: '#00cc99',
        paddingTop: 5,
        paddingBottom: 5,
    },
    navLink: {
        color: '#FFFFFF',
        fontSize: 13,
        fontWeight: 700,
    },
    backIcon: {
        position: 'absolute',
        padding: 5,
        top: 0,
        color: '#FFFFFF',
        left: 15
    }
});

class BackToConference extends Component {
    render() {
        if (this.props.location.pathname.includes('conference')) {
            return null;
        }

        const { classes } = this.props;
        return (
            <Sticky enabled={true} top={56} innerZ={1299} bottomBoundary={1200} onStateChange={this.handleStateChange}>
                <div className={classes.subHeader}>
                    <IconButton className={classes.backIcon}
                        onClick={() => this.props.history.push(`/conference/${this.props.conference_uuid}`)}
                    >
                        <ArrowBack fontSize="small" />
                    </IconButton>
                    <Typography align="center">
                        <NavLink className={classes.navLink} to={`/conference/${this.props.conference_uuid}`}>Tap to RETURN TO CALL</NavLink>
                    </Typography>
                </div>
            </Sticky>
        )
    }
};

BackToConference.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        conference_uuid: state.utility.conference_uuid,
    }
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps)
)(BackToConference);