import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
	Typography,
	ListItem,
	ListItemIcon,
	ListItemText,
	Chip,
} from "@material-ui/core";
import {
	Home as HomeIcon,
	Folder as FolderIcon,
	CreditCard as CreditCradIcon,
} from "@material-ui/icons";
import { AppointmentIcon, MessagesIcon, NotesIcon } from "./Icons";
import { Link as NavLink, withRouter } from "react-router-dom";
import Link from "@material-ui/core/Link";

const styles = (theme) => ({
	itemIcon: {
		color: "#220D89",
	},
	itemNavLink: {
		color: "#220D89",
		fontSize: 13,
		textDecoration: "none",
		"&:hover": {
			textDecoration: "none",
			fontWeight: 700,
		},
	},
	itemSecondaryHeader: {
		color: "#333333",
		fontSize: 10,
		fontWeight: 700,
	},
	qaseChip: {
		height: "15px",
		borderColor: "#cccccc",
		color: "#cccccc",
		borderRadius: "3px",
		marginRight: 2,
	},
	qaseChipLabel: {
		color: "#999",
		fontSize: "9px",
		fontWeight: 700,
		padding: 4,
		textTransform: "uppercase",
	},
	fromNowLable: {
		color: "#999",
		fontSize: "9px",
		padding: 4,
	},
});

class ActivityItem extends Component {
	removeTagsFromHtmlString(html) {
		var regex = /(<([^>]+)>)/gi;
		var result = html.replace(regex, "");
		return result;
	}

	render() {
		const { classes, activity } = this.props;

		const activityIcons = {
			Appointment: <AppointmentIcon className={classes.itemIcon} />,
			Case: <HomeIcon className={classes.itemIcon} />,
			File: <FolderIcon className={classes.itemIcon} />,
			Message: <MessagesIcon className={classes.itemIcon} />,
			Note: <NotesIcon className={classes.itemIcon} />,
			Task: <AppointmentIcon className={classes.itemIcon} />,
			Transaction: <CreditCradIcon className={classes.itemIcon} />,
		};

		var redirectUrl = "/dashboard";
		if (activity.element) {
			if (activity.type == "Message") {
				redirectUrl = "/messages";
			} else {
				redirectUrl = `/${activity.element.type.toLowerCase()}/${
					activity.uuid
				}/${activity.mid}`;
			}
		}

		return (
			<ListItem alignItems="flex-start">
				<ListItemIcon>
					{/* <AppointmentIcon className={classes.itemIcon} /> */}
					{activityIcons[activity.type]}
				</ListItemIcon>

				<ListItemText
					disableTypography={true}
					primary={
						<Link
							//component={NavLink}
							className={classes.itemNavLink}
							href="#"
							onClick={(e) => {
								e.preventDefault();
								if (activity.type == "Message") {
									console.log("Message");
									this.props.setCaseUuid(activity.uuid);
								}
								this.props.history.push(redirectUrl);
							}}
						>
							{this.removeTagsFromHtmlString(activity.title)}
						</Link>
					}
					secondary={
						<Fragment>
							<Typography
								component="span"
								className={classes.itemSecondaryHeader}
							>
								Case: {activity.case_name}
							</Typography>
							<div
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<Chip
									label={activity.type}
									className={classes.qaseChip}
									classes={{
										label: classes.qaseChipLabel,
									}}
									variant="outlined"
								/>
								<span className={classes.fromNowLable}>
									{activity.time_interval}
								</span>
							</div>
						</Fragment>
					}
					classes={
						{
							//primary: classes.itemNavLink,
						}
					}
				/>
			</ListItem>
		);
	}
}

ActivityItem.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(ActivityItem));
