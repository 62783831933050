import { MESSAGES_FETCH_BEGINING_DATA, MESSAGES_ADD_NEW_MESSAGE } from "../actions/actionTypes";

const initSate = {
    messages: [],
    loaded: false,
    timezone: '',
    defaultMessage: {
        is_my: false,
        name: 'QASE BOT',
        time: null,
        message: 'Messages that appear here are between you and your lawyer. A lawyer-client relationship is only formed when you and a lawyer agree to work together. Until then, please omit sensitive information from your messages.',
    }
};

const messageReducer = (state = initSate, action) => {
    switch (action.type) {
        case MESSAGES_FETCH_BEGINING_DATA:
            return {
                ...state,
                loaded: true,
                messages: action.payload.messages,
                timezone: action.payload.timezone,
            };
        case MESSAGES_ADD_NEW_MESSAGE:
            state.messages.push(action.payload);
            return {
                ...state
            };
        default:
            return state;
    }
}

export default messageReducer;