import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import {
	Paper,
	Typography,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableFooter,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from "@material-ui/core";

import { loadStripe } from "@stripe/stripe-js";
import {
	Elements,
	ElementsConsumer,
	CardNumberElement,
} from "@stripe/react-stripe-js";

import {
	PageHading,
	Loading,
	Processing,
	NotificationSnackbar,
	CancelationModal,
} from "../Components/Layouts";

import compose from "recompose/compose";
import { connect } from "react-redux";
import { fetchHeaderData } from "../redux/actions/headerActions";
import { LOGOUT } from "../redux/actions/actionTypes";
import { currency } from "../utils/helpers";
import StripeSplitForm from "../Components/Layouts/StripeSplitForm";
import { ReportProblem } from "@material-ui/icons";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const styles = (theme) => ({
	Paper: {
		overflow: "hidden",
		padding: "8px 20px",
	},
	detailGroup: {
		marginBottom: 15,
	},
	caption: {
		fontSize: 10,
		color: "#A0AAB4",
	},
	tableCell: {
		border: "none",
	},
	margin: {
		marginRight: theme.spacing.unit,
	},
});

class TaskDetailPage extends Component {
	state = {
		task: {},
		loading: true,
		processing: false,
		notificationSnackbar: {
			open: false,
			variant: "",
			message: "",
		},
		cancelationModalOpen: false,
		rejectionModalOpen: false,
		acceptBtnColor: "default",
		acceptedModalOpen: false,
	};

	stripeContextRef = React.createRef();

	componentDidMount() {
		const {
			match: {
				params: { id, pmid },
			},
		} = this.props;
		this.getTask(id, pmid);
	}

	getTask(uid, pmid) {
		var data = new FormData();

		var userData = JSON.parse(localStorage.getItem("userData"));

		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("uuid", uid);
		if (pmid) {
			data.append("pmid", pmid);
		}

		fetch(`${process.env.REACT_APP_API_HOST_URL}/task`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				//console.log(res);
				if (res.status !== 200 && res.status !== 201) {
					throw new Error("failed");
				}

				return res.json();
			})
			.then((resData) => {
				//console.log(resData);
				if (resData.data.success) {
					this.setState(
						{
							task: resData.data.result,
							loading: false,
						},
						() => {
							if (pmid) {
								this.props.fetchHeaderData(data);
							}
						}
					);
				}

				if (
					!resData.success &&
					(resData.message === "Session too old." ||
						resData.message === "No session found.")
				) {
					this.props.logout();
				}
			})
			.catch((err) => {
				//console.log(err);
			});
	}

	handleStripeElementChange = (change) => {
		//console.log("[change]", change);
		//console.log(this.stripeContextRef);
		if (change.error || change.empty) {
			this.setState({
				acceptBtnColor: "default",
			});
		} else {
			this.setState({
				acceptBtnColor: "primary",
			});
		}
	};

	getActionButtons = () => {
		const { task } = this.state;
		const { classes, isLawyer, auth } = this.props;
		const task_actions = task.task_actions;
		let actionsList =
			task.user_uuid === auth.uid
				? isLawyer
					? task_actions.provider_source_options
					: task_actions.client_source_options
				: isLawyer
				? task_actions.provider_target_options
				: task_actions.client_target_options;

		if (
			task.user_role == "client" &&
			!task.accept_billable &&
			task.status == "pending"
		) {
			return (
				<Fragment>
					<Typography>
						<ReportProblem
							style={{ fontSize: 16, marginRight: 4 }}
						/>
						Please complete billing details.
					</Typography>
					<Elements stripe={stripePromise}>
						<ElementsConsumer>
							{(context) => {
								this.stripeContextRef.current = context;
								return (
									<StripeSplitForm
										stripe={context.stripe}
										elements={context.elements}
										onChange={
											this.handleStripeElementChange
										}
									/>
								);
							}}
						</ElementsConsumer>
					</Elements>
					{actionsList.map((action) => {
						return (
							<Button
								variant="contained"
								size="small"
								color={
									action.field_end_point_function.und[0]
										.value == "task-accept"
										? this.state.acceptBtnColor
										: "primary"
								}
								className={classes.margin}
								key={action.tid}
								onClick={() =>
									this.handleActionButtonsClick(
										action.field_end_point_function.und[0]
											.value
									)
								}
							>
								{action.field_future_state_label.und[0].value}
							</Button>
						);
					})}
				</Fragment>
			);
		}
		if (actionsList) {
			return (
				<Fragment>
					{actionsList.map((action) => {
						if (
							task.billable === "0" ||
							action.field_end_point_function.und[0].value !==
								"task-invoice"
						) {
							let endpoint =
								action.field_end_point_function.und[0].value ===
								"task-invoice"
									? task.billable === "1"
										? action.field_end_point_function.und[0]
												.value
										: "task-complete"
									: action.field_end_point_function.und[0]
											.value;
							let title =
								action.field_end_point_function.und[0].value ===
								"task-invoice"
									? task.billable === "1"
										? action.field_future_state_label.und[0]
												.value
										: "Complete"
									: action.field_future_state_label.und[0]
											.value;
							return (
								<Button
									variant="contained"
									size="small"
									color="primary"
									className={classes.margin}
									key={action.tid}
									onClick={() =>
										this.handleActionButtonsClick(endpoint)
									}
								>
									{title}
								</Button>
							);
						} else {
							return null;
						}
					})}
				</Fragment>
			);
		}
	};

	handleActionButtonsClick = (type) => {
		switch (type.toLowerCase()) {
			case "task-accept":
				this.handleAcceptTask();
				break;
			case "task-cancel":
				this.handleTaskCancel();
				break;
			case "task-decline":
				this.setState({
					rejectionModalOpen: true,
				});
				break;
			case "task-complete":
				this.handleCompleteTask();
				break;
			default:
				break;
		}
	};

	/* TASK ACTIONS */
	handleAcceptTask = async () => {
		this.setState({
			processing: true,
		});

		let endpoint_url = `${process.env.REACT_APP_API_HOST_URL}/task/accept`;

		const { task } = this.state;
		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("uuid", task.uuid);

		if (
			task.user_role == "client" &&
			!task.accept_billable &&
			task.status == "pending"
		) {
			endpoint_url = `${process.env.REACT_APP_API_HOST_URL}/task/accept_with_token`;
			let stripe = this.stripeContextRef.current.stripe;
			let elements = this.stripeContextRef.current.elements;
			let card = elements.getElement(CardNumberElement);
			const { error, token } = await stripe.createToken(card);
			if (error) {
				this.handleShowSnackbar({
					variant: "error",
					message: error.message,
				});
				this.setState({
					processing: false,
				});
				return;
			}
			data.append("stripeToken", token.id);
		}

		fetch(endpoint_url, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				if (res.status !== 200 && res.status !== 201) {
					if (task.uuid) {
						throw new Error("Failed to accept task " + task.uuid);
					}
					throw new Error("General failure: " + res.status);
				}

				return res.json();
			})
			.then((resData) => {
				console.log(resData);
				if (resData.success) {
					if (resData.data.success) {
						if (resData.data.show_modal) {
							this.setState(
								{
									processing: false,
								},
								() =>
									this.handleShowSnackbar({
										variant: "warning",
										message:
											"Please complete billing details",
									})
							);
						} else {
							this.setState(
								{
									processing: false,
									task: {
										...this.state.task,
										status: resData.data.status.toLowerCase(),
										task_actions: resData.data.actions,
									},
									acceptedModalOpen:
										resData.data.billable &&
										resData.data.is_client,
								},
								() =>
									this.handleShowSnackbar({
										variant: "success",
										message: "Task accepted!",
									})
							);
						}
					} else {
						this.setState(
							{
								processing: false,
							},
							() =>
								this.handleShowSnackbar({
									variant: "error",
									message: !!resData.data.error
										? resData.data.error
										: "Task not accepted!",
								})
						);
					}
				} else {
					this.setState({
						processing: false,
					});
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState(
					{
						processing: false,
					},
					() =>
						this.handleShowSnackbar({
							variant: "error",
							message: "Task not accepted!",
						})
				);
			});
	};

	handleTaskCancel = () => {
		this.setState({
			cancelationModalOpen: true,
		});
	};

	handleCancelTaskSubmit = (cancel_data) => {
		this.setState({
			processing: true,
			cancelationModalOpen: false,
		});

		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("uuid", cancel_data.uuid);
		data.append("comment", cancel_data.comment);
		data.append("reason", cancel_data.reason);

		fetch(`${process.env.REACT_APP_API_HOST_URL}/task/cancel`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				//console.log(res);
				if (res.status !== 200 && res.status !== 201) {
					if (cancel_data.uuid) {
						throw new Error(
							"Failed to cancel task " + cancel_data.uuid
						);
					}
					throw new Error("General failure: " + res.status);
				}

				return res.json();
			})
			.then((resData) => {
				//console.log(resData);
				if (resData.success) {
					if (resData.data.success) {
						this.setState({
							processing: false,
							task: {
								...this.state.task,
								task_actions: [],
							},
						});
					} else {
						this.setState(
							{
								processing: false,
							},
							() =>
								this.handleShowSnackbar({
									variant: "error",
									message: "Task not canceled!",
								})
						);
					}
				} else {
					this.setState(
						{
							processing: false,
						},
						() =>
							this.handleShowSnackbar({
								variant: "error",
								message: "Task not canceled!",
							})
					);
				}
			})
			.catch((err) => {
				//console.log(err);
				this.setState(
					{
						processing: false,
					},
					() =>
						this.handleShowSnackbar({
							variant: "error",
							message: "Task not canceled!",
						})
				);
			});
	};

	handleTaskReject = () => {
		this.setState({
			rejectionModalOpen: true,
		});
	};

	handleRejectTaskSubmit = (reject_data) => {
		this.setState({
			processing: true,
			rejectionModalOpen: false,
		});

		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("uuid", reject_data.uuid);
		data.append("comment", reject_data.comment);
		data.append("reason", reject_data.reason);
		//console.log(reject_data);
		fetch(`${process.env.REACT_APP_API_HOST_URL}/task/reject`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				//console.log(res);
				if (res.status !== 200 && res.status !== 201) {
					if (reject_data.uuid) {
						throw new Error(
							"Failed to reject task " + reject_data.uuid
						);
					}
					throw new Error("General failure: " + res.status);
				}

				return res.json();
			})
			.then((resData) => {
				if (resData.success) {
					if (resData.data.success) {
						this.setState({
							processing: false,
							task: {
								...this.state.task,
								task_actions: [],
							},
						});
					} else {
						this.setState(
							{
								processing: false,
							},
							() =>
								this.handleShowSnackbar({
									variant: "error",
									message: "Task not rejected!",
								})
						);
					}
				} else {
					this.setState(
						{
							processing: false,
						},
						() =>
							this.handleShowSnackbar({
								variant: "error",
								message: "Task not rejected!",
							})
					);
				}
			})
			.catch((err) => {
				//console.log(err);
				this.setState(
					{
						processing: false,
					},
					() =>
						this.handleShowSnackbar({
							variant: "error",
							message: "Task not rejected!",
						})
				);
			});
	};

	handleCompleteTask = () => {
		const { task } = this.state;
		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("uuid", task.uuid);

		fetch(`${process.env.REACT_APP_API_HOST_URL}/task/complete`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				if (res.status !== 200 && res.status !== 201) {
					if (task.uuid) {
						throw new Error("Failed to accept task " + task.uuid);
					}
					throw new Error("General failure: " + res.status);
				}

				return res.json();
			})
			.then((resData) => {
				//console.log(resData);
				if (resData.success) {
					if (resData.data.success) {
						this.setState(
							{
								processing: false,
								task: {
									...this.state.task,
									task_actions: resData.data.actions,
								},
							},
							() =>
								this.handleShowSnackbar({
									variant: "success",
									message: "Task completed!",
								})
						);
					} else {
						this.setState(
							{
								processing: false,
							},
							() =>
								this.handleShowSnackbar({
									variant: "error",
									message: "Task not completed!",
								})
						);
					}
				} else {
					this.setState({
						processing: false,
					});
				}
			})
			.catch((err) => {
				//console.log(err);
				this.setState(
					{
						processing: false,
					},
					() =>
						this.handleShowSnackbar({
							variant: "error",
							message: "Task not completed!",
						})
				);
			});
	};

	handleShowSnackbar = (options) => {
		this.setState({
			notificationSnackbar: {
				open: true,
				variant: options.variant,
				message: options.message,
			},
		});
	};

	handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		this.setState({
			notificationSnackbar: {
				open: false,
				variant: "",
				message: "",
			},
		});
	};

	handleCancelationModalClose = (type) => {
		this.setState({
			[type]: false,
		});
	};

	getRejectionReasons = () => {
		const { rejectionReasons } = this.props;
		return Object.entries(rejectionReasons)
			.filter(([group, reason]) => reason.name.toLowerCase() === "task")
			.map(([group, reason]) => {
				return reason;
			});
	};

	render() {
		if (this.state.loading) {
			return <Loading />;
		}

		const { classes } = this.props;
		const {
			task,
			notificationSnackbar,
			cancelationModalOpen,
			rejectionModalOpen,
			acceptedModalOpen,
		} = this.state;
		//console.log(task);
		const actionBtns = this.getActionButtons();
		const taskRejectionReasons = this.getRejectionReasons();
		return (
			<div className="page--wrapper--no-margin">
				{this.state.processing && <Processing />}
				{notificationSnackbar.open && (
					<NotificationSnackbar
						snackbarOptions={notificationSnackbar}
						handleClose={this.handleSnackbarClose}
					/>
				)}
				<Paper className={classes.Paper}>
					<PageHading title="" history={this.props.history} />
					<div className={classes.detailGroup}>
						<Typography
							variant="caption"
							classes={{
								caption: classes.caption,
							}}
						>
							TASK:
						</Typography>
						<Typography paragraph={true}>
							{task.title_text}
						</Typography>
					</div>

					<div className={classes.detailGroup}>
						<Typography
							variant="caption"
							classes={{
								caption: classes.caption,
							}}
						>
							WHEN:
						</Typography>
						<Typography paragraph={true}>
							{task.time_for_show}
						</Typography>
					</div>

					<div className={classes.detailGroup}>
						<Typography
							variant="caption"
							classes={{
								caption: classes.caption,
							}}
						>
							Notes:
						</Typography>
						<div
							dangerouslySetInnerHTML={{
								__html: task.description,
							}}
						></div>
					</div>

					{/* {(['pending', 'accepted'].indexOf(task.status) === -1) &&
                        <Fragment>
                           
                        </Fragment>
                    } */}

					<Divider></Divider>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell
									align="left"
									padding="none"
									classes={{
										body: classes.tableCell,
									}}
								>
									<Typography>Estimated Total:</Typography>
								</TableCell>
								<TableCell
									align="right"
									padding="none"
									classes={{
										body: classes.tableCell,
									}}
								>
									<Typography>{task.base_amount}</Typography>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align="left" padding="none">
									<Typography>
										Tax ({task.tax_percentage}%):
									</Typography>
								</TableCell>
								<TableCell align="right" padding="none">
									<Typography>+ {task.tax_amount}</Typography>
								</TableCell>
							</TableRow>
						</TableBody>
						<TableFooter>
							<TableRow>
								<TableCell
									align="left"
									padding="none"
									classes={{
										body: classes.tableCell,
									}}
								>
									<Typography style={{ fontWeight: 700 }}>
										TOTAL
									</Typography>
								</TableCell>
								<TableCell
									align="right"
									padding="none"
									classes={{
										body: classes.tableCell,
									}}
								>
									<Typography style={{ fontWeight: 700 }}>
										{currency()} {task.total_amount}
									</Typography>
								</TableCell>
							</TableRow>
						</TableFooter>
					</Table>

					{actionBtns}
				</Paper>

				{cancelationModalOpen && (
					<CancelationModal
						open={cancelationModalOpen}
						handleClose={() =>
							this.handleCancelationModalClose(
								"cancelationModalOpen"
							)
						}
						onSubmit={this.handleCancelTaskSubmit}
						rejectionReasons={taskRejectionReasons[0]}
						title={task.title_text}
						data={{
							comment: "",
							uuid: task.uuid,
							reason: "",
						}}
					/>
				)}

				{rejectionModalOpen && (
					<CancelationModal
						open={rejectionModalOpen}
						handleClose={() =>
							this.handleCancelationModalClose(
								"rejectionModalOpen"
							)
						}
						onSubmit={this.handleRejectTaskSubmit}
						rejectionReasons={taskRejectionReasons[0]}
						title={task.title_text}
						data={{
							comment: "",
							uuid: task.uuid,
							reason: "",
						}}
					/>
				)}

				{acceptedModalOpen && (
					<Dialog
						open={acceptedModalOpen}
						onClose={() =>
							this.handleCancelationModalClose(
								"acceptedModalOpen"
							)
						}
					>
						<DialogTitle>
							Pre-authorization charge accepted
						</DialogTitle>
						<DialogContent>
							<DialogContentText>
								The pre-authorization amount is reserved on your
								credit card. You may see the amount being
								reflected on your credit card statement as In
								Process or Pending, but this isn’t an actual
								charge.
								<br />
								<br />
								The pre-authorization amount held on your credit
								card will not expire. When your lawyer completes
								their services and sends you an invoice, their
								fee is automatically captured from the reserved
								pre-authorization amount.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								color="primary"
								variant="contained"
								onClick={() =>
									this.handleCancelationModalClose(
										"acceptedModalOpen"
									)
								}
							>
								ok
							</Button>
						</DialogActions>
					</Dialog>
				)}
			</div>
		);
	}
}

TaskDetailPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	return {
		isLawyer: state.headerReducer.user.is_lawyer,
		auth: state.auth,
		rejectionReasons: state.utility.rejectionReasons,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchHeaderData: (data) => {
			dispatch(fetchHeaderData(data));
		},
		logout: () => dispatch({ type: LOGOUT, payload: true }),
	};
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps)
)(TaskDetailPage);
