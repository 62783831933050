import React, { Component, Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
	ListItem,
	ListItemText,
	Typography,
	ListItemSecondaryAction,
	IconButton,
	Menu,
	MenuItem,
	Button,
} from "@material-ui/core";
import {
	MoreVert as MoreVertIcon,
	PhoneCallback,
	Videocam,
} from "@material-ui/icons";
import moment from "moment-timezone";
import { withRouter } from "react-router-dom";
import { CancelationModal, QaseModal } from "../Layouts";
const styles = (theme) => ({
	listDateGroup: {
		display: "inline-flex",
		alignItems: "center",
		flexDirection: "column",
		justifyContent: "center",
	},
	listItemDay: {
		color: "#220D89",
		fontSize: "15px",
		fontStyle: "normal",
		fontWeight: "700",
		alignItems: "center",
	},

	listItemMonth: {
		color: "#220D89",
		fontSize: "15px",
		fontStyle: "normal",
		fontWeight: "700",
	},
	listItemPrimary: {
		color: "#333333",
	},
	listItemSecondary: {
		color: "#333333",
	},
	joinCallBtn: {
		backgroundColor: "#00cc99",
		"&:hover": {
			backgroundColor: "#00cc99",
		},
	},
	connectionOption: {
		fontSize: "11px",
		fontWeight: "400",
		fontStyle: "normal",
	},
	connectionOptionIcon: {
		fontSize: 16,
		marginRight: 8,
	},
	textGreen: {
		color: "#0D7511!important",
	},
});

const ITEM_HEIGHT = 48;

class AppointmentListItem extends Component {
	state = {
		anchorEl: null,
		cancelationModalOpen: false,
		rejectionModalOpen: false,
		connectionOptionModalOpen: false,
	};

	handleClick = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	handleMenuClick = (type) => {
		const {
			unattendedAppointmentComplete,
			acceptAppointment,
			completeAppointment,
			appointment,
			isLawyer,
		} = this.props;
		switch (type.toLowerCase()) {
			case "appointment-reschedule":
				this.handleRescheduleClick();
				break;
			case "appointment-cancel":
				this.setState({
					cancelationModalOpen: true,
					anchorEl: null,
				});
				break;
			case "appointment-decline":
				this.setState({
					rejectionModalOpen: true,
					anchorEl: null,
				});
				break;
			case "appointment-accept":
				if (appointment.billable && !isLawyer) {
					this.props.history.push(`/appointment/${appointment.uuid}`);
				} else {
					this.handleClose();
					acceptAppointment(appointment.uuid);
				}
				break;
			case "unattended_complete":
			case "appointment-close":
				this.handleClose();
				unattendedAppointmentComplete(appointment.uuid);
				break;
			case "appointment-complete":
				this.handleClose();
				completeAppointment(appointment.uuid);
				break;
			default:
				break;
		}
	};

	handleRescheduleClick = () => {
		const { appointment } = this.props;
		this.props.history.push(
			`/appointment/create?type=reschdule&uuid=${appointment.uuid}`
		);
	};

	getRejectionReasons = () => {
		const { rejectionReasons } = this.props;
		return Object.entries(rejectionReasons)
			.filter(
				([group, reason]) => reason.name.toLowerCase() === "appointment"
			)
			.map(([group, reason]) => {
				return reason;
			});
	};

	handleModalClose = (type) => {
		this.setState({
			[type]: false,
		});
	};

	getMenuItems = () => {
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);
		const { appointment, classes, isLawyer, auth } = this.props;
		const appointment_actions = appointment.appointment_actions;
		if (
			appointment.status === "Accepted" &&
			appointment.from <= appointment.now + 600 &&
			appointment.to > appointment.now - 3600
		) {
			// return (
			// 	<ListItemSecondaryAction>
			// 		<Button
			// 			color="primary"
			// 			variant="contained"
			// 			size="small"
			// 			className={classes.joinCallBtn}
			// 			onClick={() =>
			// 				this.props.history.push(
			// 					`/conference/${appointment.uuid}`
			// 				)
			// 			}
			// 		>
			// 			Join Call
			// 		</Button>
			// 	</ListItemSecondaryAction>
			// );
			return null;
		}

		if (appointment.status === "Closed" && isLawyer) {
			if (!appointment.billable) {
				return (
					<ListItemSecondaryAction>
						<IconButton
							aria-label="More"
							aria-haspopup="true"
							onClick={this.handleClick}
						>
							<MoreVertIcon />
						</IconButton>
						<Menu
							anchorEl={anchorEl}
							open={open}
							onClose={this.handleClose}
							PaperProps={{
								style: {
									maxHeight: ITEM_HEIGHT * 4.5,
									width: 200,
								},
							}}
						>
							<MenuItem
								onClick={() =>
									this.handleMenuClick("appointment-complete")
								}
							>
								Complete
							</MenuItem>
						</Menu>
					</ListItemSecondaryAction>
				);
			} else {
				return null;
			}
		}

		let actionsList =
			appointment.user_uuid === auth.uid
				? isLawyer
					? appointment_actions.provider_source_options
					: appointment_actions.client_source_options
				: isLawyer
				? appointment_actions.provider_target_options
				: appointment_actions.client_target_options;

		if (actionsList) {
			return (
				<ListItemSecondaryAction>
					<IconButton
						aria-label="More"
						aria-haspopup="true"
						onClick={this.handleClick}
					>
						<MoreVertIcon />
					</IconButton>
					<Menu
						anchorEl={anchorEl}
						open={open}
						onClose={this.handleClose}
						PaperProps={{
							style: {
								maxHeight: ITEM_HEIGHT * 4.5,
								width: 200,
							},
						}}
					>
						{actionsList.map((action) => {
							//console.log(action.field_end_point_function.und[0].value);
							return (
								<MenuItem
									key={action.tid}
									onClick={() =>
										this.handleMenuClick(
											action.field_end_point_function
												.und[0].value
										)
									}
								>
									{
										action.field_future_state_label.und[0]
											.value
									}
								</MenuItem>
							);
						})}
					</Menu>
				</ListItemSecondaryAction>
			);
		}

		return null;
	};

	handleCancel = (data) => {
		const { cancelAppointment } = this.props;
		this.setState(
			{
				cancelationModalOpen: false,
			},
			() => {
				cancelAppointment(data);
			}
		);
	};

	handleDecline = (data) => {
		const { rejectAppointment } = this.props;
		this.setState(
			{
				rejectionModalOpen: false,
			},
			() => {
				rejectAppointment(data);
			}
		);
	};

	goToDetail = (uuid) => {
		this.props.history.push(`/appointment/${uuid}`);
	};

	clickConnectionOption = () => {
		const { appointment } = this.props;
		if (
			appointment.status === "Accepted" &&
			appointment.from <= appointment.now + 600 &&
			appointment.to > appointment.now - 3600
		) {
			if (
				appointment.connection_option ===
				process.env.REACT_APP_CONNECTION_VIDEO_CALL
			) {
				this.props.history.push(`/conference/${appointment.uuid}`);
			} else {
				this.setState({ connectionOptionModalOpen: true });
			}
		} else {
			this.setState({ connectionOptionModalOpen: true });
		}
	};

	getConnectionOption = () => {
		const { classes, appointment } = this.props;
		let conntection_icon = "";
		switch (appointment.connection_option) {
			case process.env.REACT_APP_CONNECTION_VIDEO_CALL:
				conntection_icon = (
					<Videocam className={classes.connectionOptionIcon} />
				);
				break;
			case process.env.REACT_APP_CONNECTION_CLIENT_CALL:
				conntection_icon = (
					<PhoneCallback className={classes.connectionOptionIcon} />
				);
				break;
			default:
				conntection_icon = (
					<Videocam className={classes.connectionOptionIcon} />
				);
				break;
		}

		let isConferenceStarted =
			appointment.status === "Accepted" &&
			appointment.from <= appointment.now + 600 &&
			appointment.to > appointment.now - 3600;
		return (
			<Typography
				className={classNames(classes.connectionOption, {
					[classes.textGreen]: isConferenceStarted,
				})}
				component="span"
				onClick={this.clickConnectionOption}
			>
				{conntection_icon} {appointment.connection_option_name}
			</Typography>
		);
	};

	getConnectionOptionModal = () => {
		const { connectionOptionModalOpen } = this.state;
		const { appointment } = this.props;

		let message = "";
		switch (appointment.connection_option) {
			case process.env.REACT_APP_CONNECTION_VIDEO_CALL:
				message =
					"Please wait until the scheduled start time to join the call. Video call will be available 10 minutes prior to the start of your appointment.";
				break;
			case process.env.REACT_APP_CONNECTION_CLIENT_CALL:
				message = `The lawyer will call ${appointment.client_number}  at the appointment time.`;
				break;
			default:
				break;
		}

		return (
			<QaseModal
				open={connectionOptionModalOpen}
				handleClose={() => {
					this.handleModalClose("connectionOptionModalOpen");
				}}
			>
				{message}
			</QaseModal>
		);
	};

	render() {
		const { appointment, classes, timeZone } = this.props,
			{ cancelationModalOpen, rejectionModalOpen } = this.state;
		const menuItems = this.getMenuItems();
		const appointmentRejectionReasons = this.getRejectionReasons();
		console.log(appointment);
		return (
			<Fragment>
				<ListItem key={appointment.nid} disableGutters>
					<div className={classes.listDateGroup}>
						<span className={classes.listItemDay}>
							{moment
								.unix(appointment.from)
								.tz(timeZone)
								.format("D")}
						</span>
						<span className={classes.listItemMonth}>
							{moment
								.unix(appointment.from)
								.tz(timeZone)
								.format("MMM")}
						</span>
					</div>
					<ListItemText
						primary={
							<Typography
								variant="h6"
								style={{
									color: "#220D89",
									fontSize: "14px",
									fontWeight: "400",
									fontStyle: "normal",
								}}
							>
								<span
									onClick={() =>
										this.goToDetail(appointment.uuid)
									}
								>
									{appointment.title}
								</span>
							</Typography>
						}
						secondary={
							<Fragment>
								{appointment.time_show_spa}
								{this.getConnectionOption()}
							</Fragment>
						}
						classes={{
							secondary: classes.listItemSecondary,
						}}
					/>
					{menuItems}
				</ListItem>
				{cancelationModalOpen && (
					<CancelationModal
						open={cancelationModalOpen}
						handleClose={() =>
							this.handleModalClose("cancelationModalOpen")
						}
						onSubmit={this.handleCancel}
						title={appointment.title}
						rejectionReasons={appointmentRejectionReasons[0]}
						data={{
							comment: "",
							uuid: appointment.uuid,
							reason: "",
						}}
					/>
				)}

				{rejectionModalOpen && (
					<CancelationModal
						open={rejectionModalOpen}
						handleClose={() =>
							this.handleModalClose("rejectionModalOpen")
						}
						onSubmit={this.handleDecline}
						title={appointment.title}
						rejectionReasons={appointmentRejectionReasons[0]}
						data={{
							comment: "",
							uuid: appointment.uuid,
							reason: "",
						}}
					/>
				)}

				{this.getConnectionOptionModal()}
			</Fragment>
		);
	}
}

AppointmentListItem.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(AppointmentListItem));
