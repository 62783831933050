import React from 'react';
import { Typography } from '@material-ui/core';

export default props => {
    return (
        <div className="processing__wrapper">
            <div className="home-page__content">
                <img src={require('../../images/processing.gif')} alt="" style={{
                    maxWidth: '241px',
                    maxHeight: '206px',
                    margin: '10px auto'
                }} />
                <Typography>
                    Processing ...
                </Typography>
            </div>
        </div>
    )
}

