import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withRouter, NavLink } from "react-router-dom";
import { TableRow, TableCell, Chip, Link, Typography } from "@material-ui/core";
import {
	ButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
const styles = (theme) => ({
	chipItem: {
		height: "auto",
		borderRadius: 6,
		fontSize: 11,
	},
	tableCell: {
		padding: "4px 15px",
		fontSize: 11,
	},
	toggleBtn: {
		backgroundColor: "#220d89!important",
		borderColor: "#220d89!important",
		boxShadow: "none!important",
		"&:hover": {
			backgroundColor: "#220d89",
			borderColor: "#220d89",
		},
		"&:active": {
			backgroundColor: "#220d89",
			borderColor: "#220d89",
		},
	},
});

class TranactionItem extends Component {
	state = {
		disbActionsOpen: false,
	};

	handleActionClick = (type, action) => {
		const { acceptTransaction, transaction } = this.props;
		switch (type) {
			case "review_trans":
				switch (action.toLowerCase()) {
					case "accept":
						//console.log(transaction);
						this.props.history.push(
							`/transaction/${transaction.uuid}`
						);
						//acceptTransaction(transaction.uuid);
						break;
					case "cancel":
						this.props.openCancelationModal({
							type: "cancelationModalOpen",
							title: transaction.title,
							uuid: transaction.uuid,
						});
						break;
					case "reject":
						this.props.openCancelationModal({
							type: "rejectionModalOpen",
							title: transaction.title,
							uuid: transaction.uuid,
						});
						break;
					default:
						break;
				}
				break;
			default:
				break;
		}
	};

	getDisbTransactionBtns = (action) => {
		const { classes, transaction } = this.props;
		if (action.constructor === Object) {
			return (
				<ButtonDropdown
					isOpen={this.state.disbActionsOpen}
					toggle={this.handleDisbActionsDropdownToggle}
				>
					<DropdownToggle
						caret
						size="sm"
						color="primary"
						className={classes.toggleBtn}
					>
						{action.title}
					</DropdownToggle>
					<DropdownMenu right={true}>
						<DropdownItem
							onClick={() =>
								this.props.history.push(
									`/transaction/${transaction.uuid}`
								)
							}
						>
							View
						</DropdownItem>
						{Object.keys(action.options).map((objectKey, i) => {
							return (
								<DropdownItem
									key={i}
									onClick={() =>
										this.handleActionClick(
											action.type,
											objectKey
										)
									}
								>
									{action.options[objectKey].hasOwnProperty(
										"title"
									)
										? action.options[objectKey].title
										: action.options[objectKey]}
								</DropdownItem>
							);
						})}
					</DropdownMenu>
				</ButtonDropdown>
			);
		}
		return null;
	};

	handleDisbActionsDropdownToggle = () => {
		this.setState({
			disbActionsOpen: !this.state.disbActionsOpen,
		});
	};

	render() {
		const { classes, transaction } = this.props;
		return (
			<TableRow>
				{transaction.category !== "Disbursement" && (
					<TableCell className={classes.tableCell}>
						<Chip
							label={transaction.status}
							variant="outlined"
							className={classes.chipItem}
						></Chip>
					</TableCell>
				)}
				{transaction.category === "Disbursement" && (
					<TableCell>
						{this.getDisbTransactionBtns(transaction.actions)}
					</TableCell>
				)}

				<TableCell
					className={classes.tableCell}
					style={{ minWidth: 150 }}
				>
					<Link
						component={NavLink}
						to={`/transaction/${transaction.uuid}`}
						underline="none"
						style={{ textDecoration: "none!important" }}
					>
						{transaction.title}
					</Link>
					<Typography style={{ fontSize: 11 }}>
						{transaction.time_show_spa}
					</Typography>
				</TableCell>
				<TableCell
					className={classes.tableCell}
					style={{ minWidth: 100 }}
				>
					{`$ ${(
						Number(transaction.amount) +
						Number(transaction.tax_amount)
					).toFixed(2)}`}

					{transaction.category !== "Disbursement" &&
						transaction.category !== "Task" && (
							<Typography style={{ fontSize: 11 }}>
								{transaction.duration_show}
							</Typography>
						)}
				</TableCell>
			</TableRow>
		);
	}
}

TranactionItem.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(TranactionItem));
