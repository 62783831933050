import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Typography } from "@material-ui/core";

const styles = {
    modalPaper: {
        marginLeft: 3,
        marginRight: 3,
    },
};

class CompleteRoomModal extends Component {
    render() {
        const { open, handleClose, handleCompleteRoom, classes } = this.props;
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{
                        paper: classes.modalPaper,
                    }}
                >
                    <DialogContent>
                        <Typography gutterBottom>End call?</Typography>
                        <Typography>
                            If you end now the appointment cannot be joined
                            again
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleCompleteRoom}
                            color="primary"
                            variant="contained"
                        >
                            End
                        </Button>
                        <Button
                            onClick={handleClose}
                            color="primary"
                            autoFocus
                            variant="contained"
                        >
                            Return to appointment
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(CompleteRoomModal);
