import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper, FormControl, Typography } from '@material-ui/core';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator'
import { Alert } from 'reactstrap'
import Actions from './Actions';

const companyTypes = ["Company", "Individual"];

const currencies = [
    {
        value: 'cad',
        label: 'Canadian Dollars',
    },
    {
        value: 'usd',
        label: 'US Dollars',
    },
];

const countries = [
    {
        value: 'CA',
        label: 'Canada',
    },
    {
        value: 'US',
        label: 'United States',
    },
];

const styles = theme => ({
    Paper: {
        padding: 20,
        marginBottom: 10,
    },
});

class BankingDetail extends Component {
    state = {
        editMode: false,
        bankingData: this.getInitBankingData(),
        submited: false,
        errorMessage: false,
    }

    getInitBankingData() {
        const { user } = this.props;

        if (user.payment_info.success) {

            const source = user.payment_info.result.external_accounts.data[0]

            if (source) {
                const routing_number = source.routing_number;
                const index = routing_number.indexOf("-");
                const transit_number = routing_number.substring(0, index);
                const institution_number = routing_number.substring(index + 1);
                return {
                    bank_account_holder_name: source.account_holder_name,
                    bank_account_holder_type: source.account_holder_type,
                    bank_currency: source.currency,
                    bank_country: source.country,
                    bank_transit_number: (source.country === 'CA' ? transit_number : ''),
                    bank_institution_number: (source.country === 'CA' ? institution_number : ''),
                    bank_routing_number: (source.country === 'US' ? source.routing_number : ''),
                    bank_account_number: '',
                    personal_id_number: '',
                }
            }
        }

        return {
            bank_account_holder_name: `${user.field_first_name} ${user.field_last_name}`,
            bank_account_holder_type: 'company',
            bank_currency: 'cad',
            bank_country: 'CA',
            bank_transit_number: '',
            bank_institution_number: '',
            bank_routing_number: '',
            bank_account_number: '',
            personal_id_number: '',
        }
    }


    changeEditMode = () => {
        this.setState({
            editMode: !this.state.editMode,
        })
    }

    handleInputChange = name => ({ target: { value } }) => {
        this.setState({
            bankingData: {
                ...this.state.bankingData,
                [name]: value
            }
        })
    }

    handleSubmit = (event) => {
        this.setState({
            submited: true,
        });
        const { bankingData } = this.state;

        var data = new FormData();

        var sessionUserData = JSON.parse(localStorage.getItem("userData"));

        data.append('qase_api_token', sessionUserData.token);
        data.append('sid', sessionUserData.sid);

        Object.keys(bankingData).forEach((item) => {
            data.append(item, bankingData[item]);
        });

        fetch(`${process.env.REACT_APP_API_HOST_URL}/user/banking/update`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('failed');
                }
                return res.json();
            })
            .then(resData => {
                //console.log(resData);
                if (resData.success) {
                    this.setState({
                        editMode: false,
                        submited: false,
                        errorMessage: resData.data.success ? !resData.data.success : resData.data.error,
                    });
                }
            })
            .catch(err => {
                //console.log(err);
            });
    };

    render() {
        const { classes } = this.props,
            { editMode, bankingData, submited, errorMessage } = this.state;
        return (
            <Paper className={classes.Paper}>
                <ValidatorForm
                    ref="form"
                    onSubmit={this.handleSubmit}
                >
                    <Actions changeEditMode={this.changeEditMode} submited={submited} editMode={editMode} title="Bank Info" />

                    <Typography variant="subheading" gutterBottom>
                        Account Holder
                    </Typography>

                    <FormControl fullWidth margin="normal">
                        <TextValidator
                            fullWidth
                            label="ACCOUNT HOLDER FULL NAME"
                            value={bankingData.bank_account_holder_name}
                            name="bank_account_holder_name"
                            onChange={this.handleInputChange('bank_account_holder_name')}
                            validators={['required']}
                            errorMessages={['holder name is required']}
                            disabled={!editMode}
                        />
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <SelectValidator
                            select
                            label="ACCOUNT TYPE"
                            value={bankingData.bank_account_holder_type}
                            name="bank_account_holder_type"
                            onChange={this.handleInputChange('bank_account_holder_type')}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            disabled={!editMode}
                        >
                            {
                                companyTypes.map(type => (
                                    <option key={type} value={type.toLowerCase()}>
                                        {type}
                                    </option>
                                ))
                            }
                        </SelectValidator>
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <SelectValidator
                            select
                            label="CURRENCY"
                            value={bankingData.bank_currency}
                            name="bank_currency"
                            onChange={this.handleInputChange('bank_currency')}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            disabled={!editMode}
                        >
                            {
                                currencies.map(currency => (
                                    <option key={currency.value} value={currency.value}>
                                        {currency.label}
                                    </option>
                                ))
                            }
                        </SelectValidator>
                    </FormControl>

                    <Typography variant="subheading" gutterBottom>
                        Account Info
                    </Typography>

                    <FormControl fullWidth margin="normal">
                        <SelectValidator
                            select
                            label="CURRENCY"
                            value={bankingData.bank_country}
                            name="bank_country"
                            onChange={this.handleInputChange('bank_country')}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            disabled={!editMode}
                        >
                            {
                                countries.map(currency => (
                                    <option key={currency.value} value={currency.value}>
                                        {currency.label}
                                    </option>
                                ))
                            }
                        </SelectValidator>
                    </FormControl>

                    {
                        bankingData.bank_country === 'CA' &&
                        <Fragment>
                            <FormControl fullWidth margin="normal">
                                <TextValidator
                                    fullWidth
                                    label="TRANSIT (BRANCH) NUMBER"
                                    value={bankingData.bank_transit_number}
                                    name="bank_transit_number"
                                    onChange={this.handleInputChange('bank_transit_number')}
                                    validators={['required']}
                                    errorMessages={['transit number is required']}
                                    disabled={!editMode}
                                />
                            </FormControl>

                            <FormControl fullWidth margin="normal">
                                <TextValidator
                                    fullWidth
                                    label="INSTITUTION (BANK) NUMBER"
                                    value={bankingData.bank_institution_number}
                                    name="bank_institution_number"
                                    onChange={this.handleInputChange('bank_institution_number')}
                                    validators={['required']}
                                    errorMessages={['institution number is required']}
                                    disabled={!editMode}
                                />
                            </FormControl>
                        </Fragment>
                    }
                    {
                        bankingData.bank_country === 'US' &&
                        <FormControl fullWidth margin="normal">
                            <TextValidator
                                fullWidth
                                label="ROUTING NUMBER"
                                value={bankingData.bank_routing_number}
                                name="bank_routing_number"
                                onChange={this.handleInputChange('bank_routing_number')}
                                validators={['required']}
                                errorMessages={['routing number is required']}
                                disabled={!editMode}
                            />
                        </FormControl>
                    }

                    <FormControl fullWidth margin="normal">
                        <TextValidator
                            fullWidth
                            label="ACCOUNT NUMBER"
                            value={bankingData.bank_account_number}
                            name="bank_account_number"
                            onChange={this.handleInputChange('bank_account_number')}
                            validators={['required']}
                            errorMessages={['account number is required']}
                            disabled={!editMode}
                        />
                    </FormControl>

                    {
                        bankingData.bank_country === 'CA' &&
                        <FormControl fullWidth margin="normal">
                            <TextValidator
                                fullWidth
                                label="SIN"
                                value={bankingData.personal_id_number}
                                name="personal_id_number"
                                onChange={this.handleInputChange('personal_id_number')}
                                validators={['required']}
                                errorMessages={['transit number is required']}
                                disabled={!editMode}
                            />
                        </FormControl>
                    }

                </ValidatorForm>

                {errorMessage &&
                    <Alert color="danger">
                        {errorMessage}
                    </Alert>
                }
            </ Paper>
        )
    }
}

BankingDetail.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BankingDetail);