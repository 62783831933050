import {
	LOGIN,
	LOGOUT,
	AUTH_SET_CASE_UUID,
	LOGIN_FAILED,
	AUTH_RESET_SESSION_EXPIRED_NOTIFY,
	AUTH_SET_CARD_ASSINGNED,
	AUTH_REMOVE_CASE_POPUP,
} from "../actions/actionTypes";
const userData = JSON.parse(localStorage.getItem("userData"));
const initState = userData || {
	case_uuid: null,
	mail: null,
	name: null,
	picture: null,
	sid: null,
	token: null,
	uid: null,
	errorMessage: null,
	sessionExpired: false,
};
const authReducer = (state = initState, action) => {
	let userData = JSON.parse(localStorage.getItem("userData"));
	switch (action.type) {
		case LOGIN:
			return {
				...state,
				...action.payload,
			};
		case LOGIN_FAILED:
			return {
				...state,
				errorMessage: action.payload,
			};
		case AUTH_SET_CASE_UUID:
			userData.case_uuid = action.payload;
			localStorage.setItem("userData", JSON.stringify(userData));
			return {
				...state,
				case_uuid: action.payload,
			};

		case AUTH_SET_CARD_ASSINGNED:
			console.log(0);
			//let a = JSON.parse(localStorage.getItem("userData"));
			userData.is_client_card_exists = action.payload;
			localStorage.setItem("userData", JSON.stringify(userData));
			return {
				...state,
				is_client_card_exists: action.payload,
			};
		case AUTH_REMOVE_CASE_POPUP:
			userData.case_popup = false;
			localStorage.setItem("userData", JSON.stringify(userData));
			return {
				...state,
				case_popup: false,
			};
		case LOGOUT:
			localStorage.removeItem("userData");
			return {
				case_uuid: null,
				mail: null,
				name: null,
				picture: null,
				sid: null,
				token: null,
				uid: null,
				errorMessage: null,
				sessionExpired: action.payload ? action.payload : false,
			};
		case AUTH_RESET_SESSION_EXPIRED_NOTIFY:
			return {
				...state,
				sessionExpired: false,
			};
		default:
			return state;
	}
};

export default authReducer;
