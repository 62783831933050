import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import {
	Paper,
	Typography,
	Grid,
	FormControl,
	TextField,
	Divider,
	Button,
} from "@material-ui/core";

import {
	CardNumberElement,
	CardExpiryElement,
	CardCVCElement,
	StripeProvider,
	Elements,
	injectStripe,
} from "react-stripe-elements";

import { Alert } from "reactstrap";
import { Loading, NotificationSnackbar } from "../Layouts";
import Actions from "./Actions";

const styles = (theme) => ({
	Paper: {
		padding: 20,
		marginBottom: 10,
	},
	CardLabel: {
		color: "#999999",
		fontSize: 10,
		textTransform: "uppercase",
	},
	errorLabel: {
		color: "#c23d4b",
		fontSize: 10,
	},
	actionBtn: {
		margin: "5px 3px",
	},
});

// You can customize your Elements to give it the look and feel of your site.
const createOptions = () => {
	return {
		style: {
			base: {
				fontSize: "16px",
				color: "#424770",
				letterSpacing: "0.025em",
				"::placeholder": {
					color: "#aab7c4",
				},
			},
			invalid: {
				color: "#c23d4b",
			},
		},
	};
};

class _SplitFieldsForm extends Component {
	state = {
		editMode: false,
		submited: false,
		errorMsgs: {},
		additionalData: {
			name: "",
			address_line1: "",
		},
		cardCreateError: false,
	};

	handleChange = (element) => {
		if (element.error) {
			this.setState({
				errorMsgs: {
					...this.state.errorMsgs,
					[element.elementType]: element.error.message,
				},
			});
		} else {
			this.setState({
				errorMsgs: {
					...this.state.errorMsgs,
					[element.elementType]: false,
				},
			});
		}
	};

	changeEditMode = () => {
		this.setState({
			editMode: !this.state.editMode,
		});
	};

	handleInputChange =
		(name) =>
		({ target: { value } }) => {
			this.setState({
				additionalData: {
					...this.state.additionalData,
					[name]: value,
				},
			});
		};

	handleSubmit = (evt) => {
		evt.preventDefault();
		this.setState({
			submited: true,
		});
		if (this.props.stripe) {
			const { additionalData } = this.state;

			this.props.stripe.createToken(additionalData).then((result) => {
				if (result.token) {
					var data = new FormData();

					var sessionUserData = JSON.parse(
						localStorage.getItem("userData")
					);

					data.append("qase_api_token", sessionUserData.token);
					data.append("sid", sessionUserData.sid);
					data.append("stripeToken", result.token.id);

					fetch(
						`${process.env.REACT_APP_API_HOST_URL}/user/billing/update`,
						{
							method: "POST",
							body: data,
						}
					)
						.then((res) => {
							if (res.status !== 200 && res.status !== 201) {
								throw new Error("failed");
							}
							return res.json();
						})
						.then((resData) => {
							//console.log(resData);
							if (resData.success) {
								this.setState({
									editMode: false,
									cardCreateError: false,
									submited: false,
								});
								this.props.getUserData(2);
							}
						})
						.catch((err) => {
							//console.log(err);
						});
				} else {
					this.setState({
						cardCreateError: result.error.message,
						submited: false,
					});
				}
			});
		} else {
			this.setState({
				cardCreateError: "Stripe.js hasn't loaded yet.",
				submited: false,
			});
		}
	};

	render() {
		const { classes } = this.props,
			{
				editMode,
				submited,
				cardCreateError,
				errorMsgs: { cardNumber, cardExpiry, cardCvc },
				additionalData,
			} = this.state;

		return (
			<form onSubmit={this.handleSubmit}>
				<Actions
					changeEditMode={this.changeEditMode}
					editMode={editMode}
					title="CARD INFO"
					submited={submited}
				/>

				{/* <FormControl margin="normal" fullWidth>
                    <TextField
                        placeholder="Name"
                        fullWidth
                        value={additionalData.name}
                        onChange={this.handleInputChange('name')}
                        name="name"
                        disabled={!editMode}
                    />
                </FormControl>

                <FormControl margin="normal" fullWidth>
                    <TextField
                        placeholder="Address"
                        fullWidth
                        value={additionalData.address_line1}
                        onChange={this.handleInputChange('address_line1')}
                        name="address_line1"
                        disabled={!editMode}
                    />
                </FormControl> */}

				<FormControl margin="normal" fullWidth>
					<CardNumberElement
						disabled={!editMode}
						onReady={this.handleReady}
						onChange={this.handleChange}
						{...createOptions()}
					/>
					<label className={classes.CardLabel}>Card Number</label>
					{cardNumber && (
						<label className={classes.errorLabel}>
							{cardNumber}
						</label>
					)}
				</FormControl>
				<Grid container spacing={8}>
					<Grid item xs={6}>
						<FormControl margin="normal" fullWidth>
							<CardExpiryElement
								disabled={!editMode}
								onChange={this.handleChange}
								{...createOptions()}
							/>
							<label className={classes.CardLabel}>
								Expiration
							</label>
							{cardExpiry && (
								<label className={classes.errorLabel}>
									{cardExpiry}
								</label>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={6}>
						<FormControl margin="normal" fullWidth>
							<CardCVCElement
								onChange={this.handleChange}
								disabled={!editMode}
								{...createOptions()}
							/>
							<label className={classes.CardLabel}>CVV</label>
							{cardCvc && (
								<label className={classes.errorLabel}>
									{cardCvc}
								</label>
							)}
						</FormControl>
					</Grid>
				</Grid>
				{cardCreateError && (
					<Alert color="danger">{cardCreateError}</Alert>
				)}
			</form>
		);
	}
}

const SplitFieldsForm = injectStripe(_SplitFieldsForm);

class BillingDetail extends Component {
	state = {
		stripe: null,
		loading: true,
		notificationSnackbar: {
			open: false,
			variant: "",
			message: "",
		},
		user: this.props.user,
	};

	componentDidMount() {
		const stripeJs = document.createElement("script");
		stripeJs.id = "stripe-script";
		stripeJs.src = "https://js.stripe.com/v3/";
		stripeJs.async = true;
		stripeJs.onload = () => {
			this.setState({
				stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY),
				loading: false,
			});
		};
		document.body && document.body.appendChild(stripeJs);
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.user.payment_info.result.sources.total_count !=
			this.props.user.payment_info.result.sources.total_count
		) {
			this.setState({
				user: this.props.user,
			});
		}
	}

	handleShowSnackbar = (options) => {
		this.setState({
			notificationSnackbar: {
				open: true,
				variant: options.variant,
				message: options.message,
			},
		});
	};

	handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		this.setState({
			notificationSnackbar: {
				open: false,
				variant: "",
				message: "",
			},
		});
	};

	handleRemoveCreditCard = () => {
		let data = new FormData();

		let sessionUserData = JSON.parse(localStorage.getItem("userData"));

		data.append("qase_api_token", sessionUserData.token);
		data.append("sid", sessionUserData.sid);
		this.props.showProcessing(true);
		fetch(
			`${process.env.REACT_APP_API_HOST_URL}/user/billing/remove_card`,
			{
				method: "POST",
				body: data,
			}
		)
			.then((res) => {
				if (res.status !== 200 && res.status !== 201) {
					throw new Error("failed");
				}
				return res.json();
			})
			.then((resData) => {
				console.log(resData);
				if (resData.data.success) {
					this.props.getUserData(2);
				} else {
					this.handleShowSnackbar({
						variant: "error",
						message: resData.data.error,
					});
				}
				this.props.showProcessing(false);
			})
			.catch((err) => {
				console.log(err);
				this.props.showProcessing(false);
			});
	};

	render() {
		if (this.state.loading) {
			return <Loading />;
		}

		const { classes } = this.props;
		const { user, notificationSnackbar } = this.state;

		let payment_info = null;
		if (user.payment_info && user.payment_info.success) {
			payment_info = user.payment_info.result.sources.data.filter(
				(info) => {
					return info.id === user.payment_info.result.default_source;
				}
			)[0];
		}

		return (
			<Paper className={classes.Paper}>
				{payment_info && (
					<>
						<Grid container justify="space-between">
							<Grid item>
								<Typography>
									Name: {payment_info.name}
								</Typography>
								<Typography>
									Number: •••• {payment_info.last4}
								</Typography>
								<Typography>
									Expires: {payment_info.exp_month} /{" "}
									{payment_info.exp_year}
								</Typography>
								<Typography>
									Type: {payment_info.brand}
								</Typography>
								<Typography>
									Billing address:{" "}
									{payment_info.address_line1}
								</Typography>
								<Typography>
									Origin: {payment_info.country}
								</Typography>
							</Grid>
							<Grid item>
								<Button
									onClick={this.handleRemoveCreditCard}
									color="primary"
									variant="contained"
								>
									Remove
								</Button>
							</Grid>
						</Grid>

						{notificationSnackbar.open && (
							<NotificationSnackbar
								snackbarOptions={notificationSnackbar}
								handleClose={this.handleSnackbarClose}
							/>
						)}
						<Divider style={{ margin: "10px 0" }} />
					</>
				)}
				<StripeProvider
					apiKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
				>
					<Elements>
						<SplitFieldsForm
							classes={classes}
							getUserData={this.props.getUserData}
						/>
					</Elements>
				</StripeProvider>
			</Paper>
		);
	}
}

BillingDetail.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BillingDetail);
