import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import {
	Typography,
	Button,
	Drawer,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@material-ui/core";
import {
	KeyboardArrowDown,
	Person as PersonIcon,
	CreditCard as CreditCardIcon,
	Lock as LockIcon,
	Info as InfoIcon,
} from "@material-ui/icons";
import Sticky from "react-stickynode";
import "../Layouts/StickySubHeader.css";
const styles = (theme) => ({
	subHeader: {
		display: "flex",
		alignItems: "center",
		maxWidth: "80%",
		margin: "0 auto",
		padding: 5,
	},
	subHeaderTitle: {
		fontSize: 15,
		color: "#1E1E1E",
		fontWeight: 700,
	},
	subHeaderBtn: {
		textTransform: "capitalize",
	},
	accountMenuBackdrop: {
		top: "102px",
	},
	accountMenuPaper: {
		top: "102px",
	},
});

class UserStickyHeader extends Component {
	state = {
		top: false,
	};

	toggleDrawer = (side, open) => () => {
		this.setState({
			[side]: open,
		});
	};

	getListIcon = (index) => {
		switch (index) {
			case 0:
				return <PersonIcon />;
			case 1:
				return <CreditCardIcon />;
			case 2:
				return <LockIcon />;
			case 3:
				return <InfoIcon />;
			default:
				return null;
		}
	};

	getMenuItems = (role) => {
		if (role === "client") {
			return ["Profile", "Billing Details", "Password", "Notifications"];
		} else {
			return ["Profile", "Banking Details", "Password", "Notifications"];
		}
	};

	render() {
		const { classes, activeMenu, onMenuSelect, role } = this.props;
		const MenuItems = this.getMenuItems(role);

		const fullList = (
			<div className={classes.fullList}>
				<List>
					{MenuItems.map((text, index) => (
						<ListItem
							button
							key={text}
							onClick={() => onMenuSelect(index + 1)}
						>
							<ListItemIcon>
								{this.getListIcon(index)}
							</ListItemIcon>
							<ListItemText primary={text} />
						</ListItem>
					))}
				</List>
			</div>
		);

		return (
			<Fragment>
				<Sticky
					enabled={true}
					top={56}
					innerZ={1299}
					bottomBoundary={1200}
					onStateChange={this.handleStateChange}
				>
					<div className={classes.subHeader}>
						<Typography
							inline={true}
							className={classes.subHeaderTitle}
						>
							Account Settings:
						</Typography>
						<Button
							color="primary"
							onClick={this.toggleDrawer("top", true)}
							className={classes.subHeaderBtn}
						>
							{activeMenu
								? MenuItems[activeMenu - 1]
								: MenuItems[0]}
							<KeyboardArrowDown />
						</Button>
					</div>
				</Sticky>

				<Drawer
					anchor="top"
					open={this.state.top}
					onClose={this.toggleDrawer("top", false)}
					classes={{
						paper: classes.accountMenuPaper,
						modal: classes.accountMenuBackdrop,
					}}
					ModalProps={{
						BackdropProps: {
							style: {
								top: 102,
								bottom: 72,
							},
						},
					}}
				>
					<div
						tabIndex={0}
						role="button"
						onClick={this.toggleDrawer("top", false)}
						onKeyDown={this.toggleDrawer("top", false)}
					>
						{fullList}
					</div>
				</Drawer>
			</Fragment>
		);
	}
}

UserStickyHeader.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserStickyHeader);
