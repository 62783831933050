import React from 'react';

export default props => {
    return (
        <div className="loading__wrapper">
            <div className="home-page__content">
            <img src={require('../../images/logo.png')} alt="" style={{
                        maxWidth: '241px',
                        maxHeight: '206px',
                        margin: '0 auto'
                    }} />

            </div>
        </div>
    )
}

