import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
    Dialog,
    IconButton,
    Typography,
    Button,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit,
    },
}))(MuiDialogActions);

const FileDeleteConfirmationModal = ({ classes, open, handleClose, handleAccept, fid }) => {
    return (
        <Dialog
            onClose={handleClose}
            fullWidth={true}
            open={open}
            classes={{
                paper: classes.modalPaper
            }}
        >
            <DialogTitle onClose={handleClose}>
                Confirm File Deletion
            </DialogTitle>
            <DialogContent>
                Are you sure you want to delete this file
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default" variant="contained">
                    Cancel
                </Button>
                <Button onClick={() => handleAccept(fid)} color="primary" variant="contained">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default withStyles(theme => ({
    modalPaper: {
        margin: 0,
    },
}))(FileDeleteConfirmationModal);