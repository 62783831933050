import {
    APPOINTMENTS_CREATE_STEP_CHANGE,
    APPOINTMENTS_FETCH_BEGINING_DATA,
    APPOINTMENTS_CANCEL_APPOINTMENT,
    APPOINTMENTS_SHOW_PROCESSING,
    APPOINTMENTS_ACCEPT_APPOINTMENT,
    APPOINTMENTS_SHOW_ERROR,
    APPOINTMENTS_HIDE_ERROR,
    APPOINTMENTS_COMPLETE_UNATTENDED,
    APPOINTMENTS_COMPLETE_APPOINTMENT
} from '../actions/actionTypes';

const initState = {
    loaded: false,
    processing: false,
    createAppointmentAcitveStep: 0,
    createAppointmentSteps: ["1: Enter Details", "2: Select Day", "3: Choose time", "4: Confirm Appointment"],
    appointments: [],
    timeZone: '',
    notificationSnackbar: {
        open: false,
        variant: '',
        message: '',
    },
};

const appointmentReducer = (state = initState, action) => {
    switch (action.type) {
        case APPOINTMENTS_FETCH_BEGINING_DATA:

            return {
                ...state,
                loaded: true,
                appointments: action.payload.appointments,
                timeZone: action.payload.time_zone,
            }
        case APPOINTMENTS_CREATE_STEP_CHANGE:
            return {
                ...state,
                createAppointmentAcitveStep: action.payload,
            }
        case APPOINTMENTS_ACCEPT_APPOINTMENT:
        case APPOINTMENTS_COMPLETE_UNATTENDED:
            let accpetedAppointment = state.appointments.find(appt => appt.uuid === action.payload.appointment_uuid);
            accpetedAppointment.appointment_actions = action.payload.data.actions;
            accpetedAppointment.status = action.payload.data.status;
            return {
                ...state,
                processing: false,
            }
        case APPOINTMENTS_CANCEL_APPOINTMENT:
            if (action.payload.isLawyer) {
                let cacneledAppointment = state.appointments.find(appt => appt.uuid === action.payload.appointment_uuid);
                cacneledAppointment.appointment_actions = action.payload.data.actions;
                cacneledAppointment.status = action.payload.data.status;
                return {
                    ...state,
                    processing: false,
                }
            }
            else {
                let newAppoitments = state.appointments.filter(appt => appt.uuid !== action.payload.appointment_uuid);
                return {
                    ...state,
                    processing: false,
                    appointments: newAppoitments
                };
            }
        case APPOINTMENTS_COMPLETE_APPOINTMENT:
            let completedAppointment = state.appointments.find(appt => appt.uuid === action.payload.appointment_uuid);
            completedAppointment.appointment_actions = {};
            completedAppointment.status = action.payload.data.status;
            return {
                ...state,
                processing: false,
            };
        case APPOINTMENTS_SHOW_PROCESSING:
            return {
                ...state,
                processing: true,
            };
        case APPOINTMENTS_SHOW_ERROR:
            return {
                ...state,
                processing: false,
                notificationSnackbar: {
                    open: true,
                    variant: action.payload.variant,
                    message: action.payload.message,
                },
            };
        case APPOINTMENTS_HIDE_ERROR:
            return {
                ...state,
                processing: false,
                notificationSnackbar: initState.notificationSnackbar,
            };
        default:
            return state;
    }
}

export default appointmentReducer;