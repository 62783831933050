import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
    Dialog,
    IconButton,
    Typography,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit,
    },
}))(MuiDialogActions);

class CancelationModal extends Component {

    state = this.getInitState();

    getInitState() {
        const { data } = this.props;

        return data ? data : {
            comment: '',
            title: '',
            reason: '',
        }
    };

    componentWillReceiveProps({ data }) {
        this.setState({
            ...data,
        })
    }

    handleChange = name => ({ target: { value } }) => {
        this.setState({
            [name]: value
        })
    };

    handleSubmit = () => {
        this.props.onSubmit(this.state);

        this.getInitState();
    };

    render() {
        const { classes, open, handleClose, title, rejectionReasons } = this.props,
            { comment, reason } = this.state;

        return (
            <Dialog
                onClose={handleClose}
                fullWidth={true}
                open={open}
                classes={{
                    paper: classes.modalPaper
                }}
            >
                <DialogTitle onClose={handleClose}>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <form>
                        {rejectionReasons &&
                            <FormControl fullWidth margin="normal">
                                <InputLabel htmlFor="muscles">
                                    REASON
                                </InputLabel>
                                <Select
                                    value={reason}
                                    onChange={this.handleChange('reason')}
                                >
                                    <MenuItem value="">--Choose Reason --</MenuItem>
                                    {
                                        Object.entries(rejectionReasons.children).map(([group, reason]) => {
                                            return <MenuItem value={group} key={group}>{reason.name}</MenuItem>
                                        })
                                    }

                                </Select>
                            </FormControl>
                        }

                        <FormControl fullWidth margin="normal">
                            <TextField
                                multiline
                                rows="4"
                                label="Comment"
                                value={comment}
                                onChange={this.handleChange('comment')}
                            />
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleSubmit} color="primary" variant="contained">
                        Decline / Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        )
    };
}

export default withStyles(theme => ({
    modalPaper: {
        margin: 0,
    },
}))(CancelationModal);