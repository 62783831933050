import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { Paper, Typography, Button } from "@material-ui/core";
import {
    Loading,
    Processing,
    NotificationSnackbar,
} from "../Components/Layouts";
import {
    ConferenceRoom,
    ExtendCallModal,
    ExtendCallPreAuthModal,
} from "../Components/Appointement";
import moment from "moment-timezone";
import socketIOClient from "socket.io-client";
import compose from "recompose/compose";
import { connect } from "react-redux";
import Timer from "react-compound-timer";
import { LOGOUT } from "../redux/actions/actionTypes";

const styles = (theme) => ({
    rootPaper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    usedTimerText: {
        fontSize: 17,
        color: "#00CC99",
        fontWeight: 700,
    },
});

function iOS() {
    var iDevices = [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
    ];

    if (!!navigator.platform) {
        while (iDevices.length) {
            if (navigator.platform === iDevices.pop()) {
                return true;
            }
        }
    }

    return false;
}

class ConferencePage extends Component {
    socket = null;

    state = {
        appointment: null,
        loading: true,
        isExtendModalOpen: false,
        isPreAuthModalOpen: false,
        preAuthData: null,
        processing: false,
        notificationSnackbar: {
            open: false,
            variant: "",
            message: "",
        },
        disconnected: false,
        //extendedTime: 0,
    };

    componentDidMount() {
        const {
            match: {
                params: { uuid },
            },
        } = this.props;
        this.getAppointment(uuid);

        this.socket = socketIOClient(process.env.REACT_APP_NODE_API_URL, {
            path: "/api/conference",
            rejectUnauthorized: false,
        });

        this.socket.on("connect_to_room", () => {
            //console.log("connect");
            this.socket.emit("connect_to_room", uuid);
        });

        this.socket.on("call_extended", (data) => {
            //console.log("show extend");
            this.handleShowSnackbar({
                variant: "info",
                message: "This appointment has been extended",
            });
            // this.setState({
            //     extendedTime: data.duration,
            // })
        });
    }

    getAppointment(uuid) {
        const userData = JSON.parse(localStorage.getItem("userData"));

        let data = new FormData();
        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);
        data.append("uuid", uuid);
        data.append("conference", 1);

        fetch(`${process.env.REACT_APP_API_HOST_URL}/appointment`, {
            method: "POST",
            body: data,
        })
            .then((res) => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error("failed");
                }
                return res.json();
            })
            .then((resData) => {
                //console.log(resData)
                if (resData.success) {
                    this.setState({
                        appointment: resData.data,
                        loading: false,
                    });
                }

                if (
                    !resData.success &&
                    (resData.message === "Session too old." ||
                        resData.message === "No session found.")
                ) {
                    this.props.logout();
                }
            })
            .catch((err) => {
                //console.log(err);
            });
    }

    getPreAuthData = (duration) => {
        const { appointment } = this.state;
        let data = new FormData();

        let userData = JSON.parse(localStorage.getItem("userData"));

        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);
        data.append("uuid", appointment.uuid);
        data.append("duration", duration);

        fetch(`${process.env.REACT_APP_API_HOST_URL}/appointment/extend_call`, {
            method: "POST",
            body: data,
        })
            .then((res) => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error("failed");
                }
                return res.json();
            })
            .then((resData) => {
                //console.log(resData);
                if (resData.data.success) {
                    this.setState({
                        preAuthData: resData.data.result,
                        isPreAuthModalOpen: true,
                    });
                }
            })
            .catch((err) => {
                //console.log(err);
            });
    };

    acceptExntendCall = (duration, uuid) => {
        let data = new FormData();

        let userData = JSON.parse(localStorage.getItem("userData"));

        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);
        data.append("uuid", uuid);
        data.append("duration", duration);

        fetch(
            `${process.env.REACT_APP_API_HOST_URL}/appointment/extend_call/accept`,
            {
                method: "POST",
                body: data,
            }
        )
            .then((res) => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error("failed");
                }
                return res.json();
            })
            .then((resData) => {
                //console.log(resData);
                if (resData.success) {
                    let data = {
                        ...resData.data,
                        roomName: uuid,
                    };
                    this.socket.emit("call_extended", data);
                }
            })
            .catch((err) => {
                //console.log(err);
            })
            .then(() => {
                this.setState({
                    processing: false,
                });
            });
    };

    extendCallPreOpen = () => {
        this.setState({
            isExtendModalOpen: true,
        });
    };

    extendCallPreClose = () => {
        this.setState({
            isExtendModalOpen: false,
        });
    };

    handleExtnedCallSelectTime = (value) => {
        this.extendCallPreClose();
        this.getPreAuthData(value);
    };

    preAuthModalOpen = () => {
        this.setState({
            isPreAuthModalOpen: true,
        });
    };

    preAuthModalClose = () => {
        this.setState({
            isPreAuthModalOpen: false,
            preAuthData: null,
        });
    };

    handleAcceptPreAuth = (duration, uuid) => {
        this.preAuthModalClose();
        this.setState({
            processing: true,
        });
        this.acceptExntendCall(duration, uuid);
    };

    handleShowSnackbar = (options) => {
        this.setState({
            notificationSnackbar: {
                open: true,
                variant: options.variant,
                message: options.message,
            },
        });
    };

    handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({
            notificationSnackbar: {
                open: false,
                variant: "",
                message: "",
            },
        });
    };

    handleRoomDisconected = () => {
        this.setState({
            disconnected: true,
        });
    };

    pad2 = (number) => {
        return (number < 10 ? "0" : "") + number;
    };

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        const {
                appointment,
                isExtendModalOpen,
                isPreAuthModalOpen,
                processing,
                notificationSnackbar,
                disconnected,
            } = this.state,
            { classes } = this.props;

        if (
            appointment.status !== "Accepted" &&
            appointment.status !== "Pending"
        ) {
            this.props.history.goBack();
        }

        const unixnow = moment().unix();
        const isIos = iOS();

        if (unixnow + 600 < appointment.from) {
            return (
                <div className="page--wrapper">
                    <Paper className={classes.rootPaper} elevation={1}>
                        <Typography component="p">
                            Your conference is scheduled to begin at{" "}
                            {moment
                                .unix(appointment.from)
                                .tz(appointment.time_zone)
                                .format("h:mma MMMM D, YYYY")}
                            . The video conference room will be available 10
                            minutes prior to this time.
                        </Typography>
                        {/* {isIos && (
							<Fragment>
								<Typography component="p">
									Video call not available, please send the
									other party a message and ask them to call
									you directly.
								</Typography>
							</Fragment>
						)} */}
                    </Paper>
                </div>
            );
        }

        if (disconnected) {
            const nowUnix = moment().unix();
            const usedTime = nowUnix - appointment.from;
            return (
                <div className="page--wrapper">
                    <Paper
                        className={classes.rootPaper}
                        elevation={1}
                        style={{ textAlign: "center" }}
                    >
                        <Typography component="p" align="center" gutterBottom>
                            Your appointment has ended
                        </Typography>

                        {/* <Timer
                            initialTime={usedTime * 1000}
                            startImmediately={false}
                        >
                            <React.Fragment>
                                <Typography className={classes.usedTimerText}>
                                    <Timer.Hours
                                        formatValue={(value) => {
                                            if (value > 0) {
                                                return `${this.pad2(value)}:`;
                                            } else {
                                                return "";
                                            }
                                        }}
                                    />
                                    <Timer.Minutes
                                        formatValue={(value) =>
                                            `${this.pad2(value)}:`
                                        }
                                    />
                                    <Timer.Seconds
                                        formatValue={(value) =>
                                            this.pad2(value)
                                        }
                                    />
                                </Typography>
                            </React.Fragment>
                        </Timer>
                        <Typography
                            className={classes.usedTimerText}
                            gutterBottom
                        >
                            Used
                        </Typography> */}
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() =>
                                this.props.history.push("/dashboard")
                            }
                        >
                            Return to Case
                        </Button>
                    </Paper>
                </div>
            );
        }

        // if (isIos) {
        // 	return (
        // 		<div className="page--wrapper">
        // 			<Paper className={classes.rootPaper} elevation={1}>
        // 				<Typography component="p">
        // 					Video call not available, please send the other
        // 					party a message and ask them to call you directly.
        // 				</Typography>
        // 			</Paper>
        // 		</div>
        // 	);
        // }

        return (
            <Fragment>
                <div className="page--wrapper--conference">
                    {processing && <Processing />}
                    <ConferenceRoom
                        appointment={appointment}
                        extenCallModalOpen={this.extendCallPreOpen}
                        handleRoomDisconected={this.handleRoomDisconected}
                        //extendedTime={extendedTime}
                        socket={this.socket}
                    />

                    <ExtendCallModal
                        open={isExtendModalOpen}
                        handleClose={this.extendCallPreClose}
                        selectTime={this.handleExtnedCallSelectTime}
                    />

                    <ExtendCallPreAuthModal
                        open={isPreAuthModalOpen}
                        handleClose={this.preAuthModalClose}
                        handleAccept={this.handleAcceptPreAuth}
                        preAuth={this.state.preAuthData}
                    />

                    {notificationSnackbar.open && (
                        <NotificationSnackbar
                            snackbarOptions={notificationSnackbar}
                            handleClose={this.handleSnackbarClose}
                        />
                    )}
                </div>
            </Fragment>
        );
    }
}

ConferencePage.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch({ type: LOGOUT, payload: true }),
    };
};

export default compose(
    withStyles(styles),
    connect(null, mapDispatchToProps)
)(ConferencePage);

//export default withStyles(styles)(ConferencePage);
