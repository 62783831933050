import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import List from '@material-ui/core/List';
//import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import Avatar from '@material-ui/core/Avatar';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { LawyerBio } from '.';
import { Alert } from 'reactstrap';

const styles = {
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    grow: {
        flexGrow: 1,
    },
    errorText: {
        color: 'red',
    }
};

function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}


function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class TransferLawyersList extends Component {

    state = {
        lawyer: '',
        validationError: false,
        showBio: false,
        bio_uuid: '',
    };

    handleChange = name => ({ target: { value } }) => {
        this.setState({ [name]: value });
    };

    handleSelectLawyer = (type, value) => {
        this.setState({
            [type]: value
        })
    };

    handleCloseBio = () => {
        this.setState({
            showBio: false,
            bio_uuid: '',
        })
    };

    getLawyersList = () => {
        const { lawyers } = this.props;

        if (this.state.showBio) {
            let lawyer = lawyers.find(l => l.uid === this.state.bio_uuid);

            return (<LawyerBio
                lawyer_show={lawyer}
                handleCloseBio={this.handleCloseBio}
                handleSelectLawyer={this.handleSelectLawyer}
                is_case_transfer={true}
            />
            )
        }

        if (lawyers.length) {
            return (
                <List>
                    {lawyers.map(lawyer => {
                        //console.log(lawyer);
                        return (
                            <ListItem key={lawyer.uid}>
                                <Radio
                                    color="primary"
                                    checked={this.state.lawyer === lawyer.uid}
                                    value={lawyer.uid}
                                    onChange={this.handleChange('lawyer')}
                                    style={{
                                        paddingLeft: 0,
                                    }}
                                />
                                <ListItemAvatar>
                                    <Avatar src={validURL(lawyer.image) ? lawyer.image : process.env.REACT_APP_DRUPAL_HOST_URL + lawyer.image} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={lawyer.name}
                                    secondary={
                                        <Typography color="primary" style={{ display: 'inline' }} component="span"
                                            onClick={() => {
                                                this.setState({
                                                    showBio: true,
                                                    bio_uuid: lawyer.uid,
                                                })
                                            }}
                                        >
                                            Read Bio
                                </Typography>
                                    }
                                />
                            </ListItem>
                        )
                    })}
                </List>
            )
        }
        else {
            return (
                <Alert color="primary">
                    No available lawyers
                </Alert>
            );
        }
    };

    handleTransfer = () => {
        if (this.state.lawyer) {
            this.props.transfer(this.state);
        }
        else {
            this.setState({
                validationError: 'Please choose a lawyer',
            })
        }

    };

    handleModalClose = () => {
        const { handleClose } = this.props;
        this.setState({
            lawyer: '',
            validationError: false,
        }, () => {
            handleClose();
        })
    };

    render() {
        const { classes, open } = this.props,
            { validationError } = this.state;
        const lawyersList = this.getLawyersList();

        return (
            <Dialog
                fullScreen
                open={open}
                onClose={this.handleModalClose}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton color="inherit" onClick={this.handleModalClose} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                        <div className={classes.grow} />
                        {/* <Typography variant="h6" color="inherit" className={classes.flex}>
                            Sound
                        </Typography> */}
                        <Button color="inherit" onClick={this.handleTransfer}>
                            transfer
                        </Button>
                    </Toolbar>
                </AppBar>
                {validationError &&
                    <Typography align="center" className={classes.errorText}>{validationError}</Typography>
                }
                {lawyersList}
            </Dialog>
        )
    };
}

TransferLawyersList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TransferLawyersList);