import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
    Dialog,
    IconButton,
    Typography,
    Grid,
    Paper,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';

const extendTimes = [
    {
        value: 900,
        show: 15,
        showText: 'mins'
    },
    {
        value: 1800,
        show: 30,
        showText: 'mins'
    },
    {
        value: 3600,
        show: 1,
        showText: 'hour'
    }
];

const styles = theme => ({
    modalPaper: {
        margin: 0,
    },
    paperItem: {
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
        cursor:'pointer',
    },
    timeValue: {
        fontWeight: 700,
        fontSize: 36,
        color: '#220D89',
    },
    timeText: {
        fontWeight: 700,
        fontSize: 15,
        color: '#220D89',
        textTransform: 'uppercase',
    }
});

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit,
    },
}))(MuiDialogActions);

const ExtendCallModal = ({ classes, open, handleClose, selectTime }) => {
    return (
        <Dialog
            onClose={handleClose}
            fullWidth={true}
            open={open}
            classes={{
                paper: classes.modalPaper
            }}
        >
            <DialogTitle onClose={handleClose}>
                Extend call by:
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid
                            container
                            spacing={16}
                            justify="center"
                        >
                            {
                                extendTimes.map((time, index) => (
                                    <Grid item key={index}>
                                        <Paper className={classes.paperItem} onClick={() => { selectTime(time.value) }}>
                                            <Typography align="center" className={classes.timeValue}>{time.show}</Typography>
                                            <Typography align="center" className={classes.timeText}>{time.showText}</Typography>
                                        </Paper>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
};

export default withStyles(styles)(ExtendCallModal);