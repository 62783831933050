import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
    Dialog,
    IconButton,
    Typography,
    Button,
    Divider,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableFooter
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { currency } from '../../utils/helpers/';

const styles = theme => ({
    modalPaper: {
        margin: 0,
    },
    detailGroup: {
        marginBottom: 15,
    },
    caption: {
        fontSize: 10,
        color: '#A0AAB4'
    },
    tableCell: {
        border: 'none',
    },
    margin: {
        marginRight: theme.spacing.unit / 2,
    }
});

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit,
    },
}))(MuiDialogActions);

const ExtendCallPreAuthModal = ({ classes, open, handleClose, handleAccept, preAuth }) => {
    return (
        <Dialog
            onClose={handleClose}
            fullWidth={true}
            open={open}
            classes={{
                paper: classes.modalPaper
            }}
        >
            <DialogTitle onClose={handleClose}>
                Extend Call Pre-authorization
            </DialogTitle>
            <DialogContent>
                {preAuth && (
                    <Fragment>
                        <div className={classes.detailGroup}>
                            <Typography
                                variant="caption"
                                classes={{
                                    caption: classes.caption,
                                }}
                            >
                                MEETING:
                        </Typography>
                            <Typography paragraph={true}>
                                {preAuth.title}
                            </Typography>
                        </div>

                        <div className={classes.detailGroup}>
                            <Typography
                                variant="caption"
                                classes={{
                                    caption: classes.caption,
                                }}
                            >
                                WHEN:
                            </Typography>
                            <Typography paragraph={true}>
                                {preAuth.spa_time_show}
                            </Typography>
                        </div>

                        <div className={classes.detailGroup}>
                            <Typography
                                variant="caption"
                                classes={{
                                    caption: classes.caption,
                                }}
                            >
                                DURATION:
                            </Typography>
                            <Typography paragraph={true}>
                                {preAuth.duration}
                            </Typography>
                        </div>

                        <div className={classes.detailGroup}>
                            <Typography
                                variant="caption"
                                classes={{
                                    caption: classes.caption,
                                }}
                            >
                                NOTES:
                            </Typography>
                            <div dangerouslySetInnerHTML={{ __html: preAuth.description }} ></div>
                        </div>

                        <div className={classes.detailGroup}>
                            <Typography
                                variant="caption"
                                classes={{
                                    caption: classes.caption,
                                }}
                            >
                                BREAKDOWN:
                            </Typography>
                            <Typography>
                                Duration: {preAuth.duration}
                            </Typography>
                            <Typography paragraph={true}>
                                Rate: {preAuth.amount_hour} {currency()}
                            </Typography>
                        </div>

                        <Divider></Divider>

                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left" padding="none"
                                        classes={{
                                            body: classes.tableCell
                                        }}
                                    >
                                        <Typography>
                                            Estimated Total:
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right" padding="none"
                                        classes={{
                                            body: classes.tableCell
                                        }}
                                    >
                                        <Typography>
                                            {preAuth.base_amount}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left" padding="none">
                                        <Typography>
                                            Tax ({preAuth.tax_percentage}%):
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right" padding="none">
                                        <Typography>
                                            + {preAuth.tax_amount}
                                        </Typography>
                                    </TableCell>
                                </TableRow>

                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell align="left" padding="none"
                                        classes={{
                                            body: classes.tableCell
                                        }}
                                    >
                                        <Typography style={{ fontWeight: 700 }}>
                                            TOTAL
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right" padding="none"
                                        classes={{
                                            body: classes.tableCell
                                        }}
                                    >
                                        <Typography style={{ fontWeight: 700 }}>
                                            {currency()}  {preAuth.total_amount}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </Fragment>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" variant="contained" onClick={handleClose}>
                    DECLINE
                </Button>
                <Button color="primary" variant="contained" onClick={() => handleAccept(preAuth.duration_seconds, preAuth.uuid)}>
                    ACCEPT
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default withStyles(styles)(ExtendCallPreAuthModal);