import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper, FormControl, Button } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { PageHading, Processing, NotificationSnackbar } from '../Components/Layouts';
import AuthContext from '../Context/auth-context';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Editor } from '@tinymce/tinymce-react';

const styles = (theme) => ({
    Paper: {
        paddingRight: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 2,
        borderRadius: 0,
    },
    pageContainer: {
        height: 'calc(100vh - 128px)'
    },
    formGroup: {
        marginBottom: 25,
    },
});

class NoteCreatePage extends Component {

    state = {
        processing: false,
        note: {
            title: '',
            body: '',
        },
        notificationSnackbar: {
            open: false,
            variant: '',
            message: '',
        },
        editMode: false,
    };

    static contextType = AuthContext;

    componentDidMount() {
        const { isEdit } = this.props;
        if (isEdit) {

            const { match: { params: { note_uuid } } } = this.props;
            this.setState({
                editMode: true,
            }, () => {
                this.getNote(note_uuid);
            });
        }
    };

    getNote(uuid) {
        var data = new FormData();

        var userData = JSON.parse(localStorage.getItem("userData"));

        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('uuid', uuid);

        fetch(`${process.env.REACT_APP_API_HOST_URL}/note`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('failed');
                }
                return res.json();
            })
            .then(resData => {
                if (resData.success) {
                    this.setState({
                        note: {
                            title: resData.data.title,
                            body: resData.data.body,
                            uuid: resData.data.uuid,
                        }
                    })
                }
            })
            .catch(err => {
                //console.log(err);
            });
    }

    handleInputChange = (name) => ({ target: { value } }) => {
        this.setState({
            note: {
                ...this.state.note,
                [name]: value
            }
        });
    };

    handleEditorChange = (content, editor) => {
        //console.log(content);
        //console.log(editor);
        
        this.setState({
            note: {
                ...this.state.note,
                'body': content
            }
        });

      }
    
    handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({
            notificationSnackbar: {
                open: false,
                variant: '',
                message: '',
            }
        });
    };

    handleSubmit = () => {

        const { note, editMode } = this.state;

        this.setState({
            processing: true,
        });

        var data = new FormData();
        var userData = JSON.parse(localStorage.getItem("userData"));
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('title', note.title);
        data.append('body', note.body);

        if (editMode) {
            data.append('uuid', note.uuid);
        }

        const request_url = editMode
            ? `${process.env.REACT_APP_API_HOST_URL}/note/edit`
            : `${process.env.REACT_APP_API_HOST_URL}/cases/${this.props.auth.case_uuid}/notes/create`


        fetch(request_url, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('failed');
                }
                return res.json();
            })
            .then(resData => {
                //console.log(resData);
                if (resData.success) {
                    this.props.history.push('/notes');
                }
                else {
                    this.setState({
                        processing: false,
                        notificationSnackbar: {
                            open: true,
                            variant: 'error',
                            message: 'Note not created!',
                        }
                    });
                }

            })
            .catch(err => {
                //console.log(err);
                this.setState({
                    processing: false,
                    notificationSnackbar: {
                        open: true,
                        variant: 'error',
                        message: 'Note not created!',
                    }
                });
            });

    };

    render() {

        const { classes } = this.props,
            { note, processing, notificationSnackbar, editMode } = this.state;
        return (
            <div className="page--wrapper--no-margin" style={{ paddingBottom: 72 }}>
                <Paper className={classes.Paper}>
                    <div className={classes.pageContainer}>
                        {processing && <Processing />}
                        <PageHading title={editMode ? "Edit Note" : "New Note"} />

                        <ValidatorForm
                            ref="form"
                            onSubmit={this.handleSubmit}
                            className={classes.formContainer}
                            style={{ marginTop: 25 }}
                        >
                            <div className={classes.formGroup}>
                                <FormControl fullWidth margin="normal">
                                    <TextValidator
                                        variant="outlined"
                                        placeholder="Note Title*"
                                        label="Note Title*"
                                        value={note.title}
                                        onChange={this.handleInputChange('title')}
                                        validators={['required']}
                                        errorMessages={['note title is required']}
                                    />
                                </FormControl>

                                <FormControl fullWidth margin="normal">
                                    {/* <TextValidator
                                        variant="outlined"
                                        multiline
                                        rows={6}
                                        placeholder="Note Content"
                                        label="Note Content"
                                        value={note.body}
                                        onChange={this.handleInputChange('body')}
                                        validators={['required']}
                                        errorMessages={['note content is required']}
                                    /> */}
                                    <Editor
                                        apiKey={`${process.env.REACT_APP_TINYMC_API_KEY}`}
                                        variant="outlined"
                                        init={{ selector: 'textarea', plugins: 'link table', placeholder: 'Note Content' }}
                                        //placeholder="Note Content"
                                        rows={6}
                                        label="Note Content"
                                        value={note.body}
                                        onEditorChange={this.handleEditorChange}
                                        validators={['required']}
                                        errorMessages={['note content is required']}
                                    />
                                </FormControl>
                            </div>

                            <Button variant="contained" fullWidth size="large" color="primary" style={{ borderRadius: 0 }} type="submit">
                                {editMode ? "SAVE" : "SAVE"}
                            </Button>
                        </ValidatorForm>

                        {notificationSnackbar.open &&
                            <NotificationSnackbar
                                snackbarOptions={notificationSnackbar}
                                handleClose={this.handleSnackbarClose}
                            />
                        }
                    </div>
                </Paper>
            </div>
        )
    }
}

NoteCreatePage.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    }
};

export default compose(
    withStyles(styles),
    connect(
        mapStateToProps
    ),
)(NoteCreatePage);

