import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import {
	Paper,
	Typography,
	Toolbar,
	Chip,
	Button,
	Link,
} from "@material-ui/core";
import { Videocam, PhoneCallback } from "@material-ui/icons";
import {
	ButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Button as BootstrapButton,
} from "reactstrap";
import { NavLink, withRouter } from "react-router-dom";

import {
	Processing,
	CancelationModal,
	QaseModal,
	SelectLawyerModal,
} from "./Layouts";

const styles = (theme) => ({
	grow: {
		flexGrow: 1,
	},
	Paper: {
		marginBottom: 10,
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
	requiresSubHeading: {
		fontSize: "13px",
		fontWeight: 700,
		marginBottom: 10,
	},
	qaseChip: {
		height: "auto",
		borderColor: "#cccccc",
		color: "#cccccc",
		borderRadius: "3px",
		marginRight: 2,
	},
	qaseChipLabel: {
		color: "#999",
		fontSize: "9px",
		fontWeight: 700,
		padding: 4,
		textTransform: "uppercase",
	},
	toggleBtn: {
		backgroundColor: "#220d89!important",
		borderColor: "#220d89!important",
		boxShadow: "none!important",
		"&:hover": {
			backgroundColor: "#220d89",
			borderColor: "#220d89",
		},
		"&:active": {
			backgroundColor: "#220d89",
			borderColor: "#220d89",
		},
	},
	joinCallBtn: {
		backgroundColor: "#0D7511",
		borderColor: "#0D7511",
	},
	joinCallText: {
		color: "#0D7511",
	},
	connectionOptionIcon: {
		fontSize: 16,
		marginRight: 8,
	},
});

class RequiresAttentionItem extends Component {
	state = {
		dropdownOpen: false,
		cancleationModalOpen: false,
		rejectionModalOpen: false,
		connectionModalOpen: false,
		selectLawyerModalOpen: false,
		selectLawyerPractices: [],
		defaultSelectedArea: false,
		isLawyerSelected: false,
	};

	getHeadingColor = () => {
		const { attention } = this.props;
		return {
			color:
				attention.type === "appointment" &&
				attention.status === "accepted" &&
				attention.when <= attention.now + 600 &&
				attention.to > attention.now - 3600
					? "#220d89"
					: "#220d89",
		};
	};

	toggle = () => {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen,
		});
	};

	handleActionClick = (type, action) => {
		const { attention, user } = this.props;
		switch (type) {
			case "review_appointment":
				switch (action.toLowerCase()) {
					case "appointment-accept":
						if (attention.billable && !user.is_lawyer) {
							this.props.history.push(
								`/appointment/${attention.uuid}`
							);
						} else {
							this.handleAcceptRequiredAttention();
						}

						//this.handleAppointmentAccept();
						break;
					case "appointment-cancel":
						this.handleAttentionCancel();
						break;
					case "appointment-decline":
						this.handleAttentionReject();
						break;
					case "appointment-reschedule":
						//console.log(this.props);
						this.props.history.push(
							`/appointment/create?type=reschdule&uuid=${this.props.attention.uuid}`
						);
						break;
					case "unattended_complete":
					case "appointment-close":
						this.handleCloseRequiredAttention();
						break;
					default:
						return null;
				}
				break;
			case "review_task":
				switch (action.toLowerCase()) {
					case "task-accept":
						//console.log(attention);
						if (attention.billable && !user.is_lawyer) {
							this.props.history.push(`/task/${attention.uuid}`);
						} else {
							this.handleAcceptRequiredAttention();
						}

						break;
					case "task-cancel":
						this.handleAttentionCancel();
						break;
					case "task-decline":
						this.handleAttentionReject();
						break;
					default:
						return null;
				}
				break;
			case "review_case":
				switch (action.toLowerCase()) {
					case "case-accept":
						this.handleAcceptRequiredAttention();
						break;
					case "case-cancel":
						this.handleCancelRequiredAttention({
							uuid: attention.uuid,
						});
						break;
					case "case-decline":
						this.handleAttentionReject();
						break;
					default:
						return null;
				}
				break;
			case "review_trans":
				switch (action.toLowerCase()) {
					case "accept":
						if (attention.billable && !user.is_lawyer) {
							this.props.history.push(
								`/transaction/${attention.uuid}`
							);
						} else {
							this.handleAcceptRequiredAttention();
						}
						//this.handleAcceptRequiredAttention();
						break;
					case "cancel":
						this.handleAttentionCancel();
						break;
					case "reject":
						this.handleAttentionReject();
						break;
					default:
						return null;
				}
				break;
			default:
				return null;
		}
	};

	getSubmitRejectAction = (type) => {
		switch (type) {
			case "appointment":
				return this.handleAppointmentRejectSubmit;
			case "task":
				return this.handleTaskRejectSubmit;
			case "case":
				return this.handleCaseRejectSubmit;
			case "transaction":
				return this.handleDisbTransactionRejectSubmit;
			default:
				return null;
		}
	};

	handleAcceptRequiredAttention = () => {
		const { attention } = this.props;
		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		if (attention.type === "case") {
			data.append("case_nid", attention.uuid);
		} else {
			data.append("uuid", attention.uuid);
		}

		this.props.acceptRequiredAttention(
			attention.type,
			attention.uuid,
			data
		);
	};

	handleCancelRequiredAttention = (cancelation_data) => {
		this.setState({ cancleationModalOpen: false });
		const { attention } = this.props;
		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		if (attention.type === "case") {
			data.append("case_nid", cancelation_data.uuid);
		} else {
			data.append("uuid", cancelation_data.uuid);
			data.append("comment", cancelation_data.comment);
			data.append("reason", cancelation_data.reason);
		}

		this.props.cancelRequiredAttetion(
			attention.type,
			cancelation_data.uuid,
			data
		);
	};

	handleRejectRequiredAttention = (rejection_data) => {
		this.setState({ rejectionModalOpen: false });
		const { attention } = this.props;
		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		if (attention.type === "case") {
			data.append("case_nid", rejection_data.uuid);
			data.append("description", rejection_data.comment);
		} else {
			data.append("uuid", rejection_data.uuid);
			data.append("comment", rejection_data.comment);
		}

		data.append("reason", rejection_data.reason);

		this.props.rejectRequiredAttention(
			attention.type,
			rejection_data.uuid,
			data
		);
	};

	handleAttentionReject = () => {
		this.setState({
			rejectionModalOpen: true,
		});
	};

	handleAttentionCancel = () => {
		this.setState({
			cancleationModalOpen: true,
		});
	};

	handleCloseRequiredAttention = () => {
		const { attention } = this.props;
		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("uuid", attention.uuid);

		this.props.closeRequiredAttention(attention.type, attention.uuid, data);
	};

	handleCancelationModalClose = (type) => {
		this.setState({
			[type]: false,
		});
	};

	getRejectionReasons = (type) => {
		const { rejectionReasons } = this.props;
		return Object.entries(rejectionReasons)
			.filter(([group, reason]) => reason.name.toLowerCase() === type)
			.map(([group, reason]) => {
				return reason;
			});
	};

	getAvailablePracticeAreasForSelect = (case_nid) => {
		console.log(case_nid);
		//const { auth } = this.props;
		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("case_nid", case_nid);
		data.append("is_transfer", false);
		data.append("show_practice_areas", 1);

		fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/transfer_lawyers`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				//console.log(res);
				if (res.status !== 200 && res.status !== 201) {
					// if (auth.case_uid) {
					// 	throw new Error('Failed to accept case ' + auth.case_uid);
					// }
					throw new Error("General failure: " + res.status);
				}

				return res.json();
			})
			.then((resData) => {
				//console.log(resData);
				if (resData.success) {
					this.setState({
						selectLawyerModalOpen: true,
						defaultSelectedArea:
							resData.data.content.default_selected_area,
						selectLawyerPractices:
							resData.data.content.practice_areas,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	getCaseActionBtns = () => {
		const {
			attention: {
				nid,
				case_actions,
				originator,
				lawyer,
				referral_source,
				declined_message_url,
				isQC,
			},
			auth,
			isLawyer,
			classes,
		} = this.props;
		if (case_actions.name === "Pending" && !lawyer) {
			if (referral_source === "acba-lrs") {
				return (
					<Typography style={{ marginLeft: 15 }}>
						The referral request was not accepted, visit{" "}
						<a href={declined_message_url} target="_blank">
							{declined_message_url}
						</a>{" "}
						for more info
					</Typography>
				);
			}

			if (referral_source === "apb-lrs") {
				return (
					<Typography style={{ marginLeft: 15 }}>
						Your referral request could not be accepted. Please call
						the Lawyer Referral Service at 604-687-3221 (toll free
						1-800-663-1919) or send them a message:{" "}
						<a href="mailto:lawyerreferral@accessprobono.ca">
							lawyerreferral@accessprobono.ca
						</a>
					</Typography>
				);
			}

			if (referral_source === "vfm") {
				return (
					<Typography style={{ marginLeft: 15 }}>
						The referral request was not accepted, visit the{" "}
						<a href={declined_message_url} target="_blank">
							Virtual Family Mediation
						</a>{" "}
						website for more information
					</Typography>
				);
			}
			if (!!isQC) {
				return (
					<BootstrapButton
						size="sm"
						color="success"
						className={classes.toggleBtn}
						//onClick={() => this.getAvailablePracticeAreasForSelect(nid)}
					>
						{isLawyer ? "Declined" : "Request Declined"}
					</BootstrapButton>
				);
			} else {
				return (
					<BootstrapButton
						size="sm"
						color="success"
						className={classes.toggleBtn}
						onClick={() =>
							this.getAvailablePracticeAreasForSelect(nid)
						}
					>
						{isLawyer ? "Declined" : "Select Lawyer"}
					</BootstrapButton>
				);
			}
		}
		let btnTitle =
			originator === auth.uid
				? isLawyer
					? case_actions.provider_source_text
					: case_actions.client_source_text
				: isLawyer
				? case_actions.provider_target_text
				: case_actions.client_target_text;
		let actionsList =
			originator === auth.uid
				? isLawyer
					? case_actions.provider_source_options
					: case_actions.client_source_options
				: isLawyer
				? case_actions.provider_target_options
				: case_actions.client_target_options;
		if (actionsList) {
			return (
				<ButtonDropdown
					isOpen={this.state.dropdownOpen}
					toggle={this.toggle}
				>
					<DropdownToggle
						caret
						size="sm"
						color="primary"
						className={classes.toggleBtn}
					>
						{btnTitle}
					</DropdownToggle>
					<DropdownMenu right={true}>
						{actionsList.map((action) => {
							//console.log(action);
							return (
								<DropdownItem
									key={action.tid}
									onClick={() =>
										this.handleActionClick(
											"review_case",
											action.field_end_point_function
												.und[0].value
										)
									}
								>
									{
										action.field_future_state_label.und[0]
											.value
									}
								</DropdownItem>
							);
						})}
					</DropdownMenu>
				</ButtonDropdown>
			);
		} else {
			return (
				<BootstrapButton
					size="sm"
					color="success"
					className={classes.toggleBtn}
				>
					{btnTitle}
				</BootstrapButton>
			);
		}
	};

	getAppointmentActionBtns = () => {
		const { attention, auth, isLawyer, classes } = this.props;
		const appointment_actions = attention.new_actions;

		if (
			(attention.status === "accepted" ||
				attention.status === "pending") &&
			attention.when <= attention.now + 600 &&
			attention.to > attention.now - 3600
		) {
			return (
				<BootstrapButton
					className={classes.joinCallBtn}
					color="success"
					size="sm"
					onClick={() =>
						this.props.history.push(`/conference/${attention.uuid}`)
					}
				>
					<Videocam style={{ fontSize: 16, marginRight: 4 }} />
					Join Call
				</BootstrapButton>
			);
		}

		let btnTitle =
			attention.user_uuid === auth.uid
				? isLawyer
					? appointment_actions.provider_source_text
					: appointment_actions.client_source_text
				: isLawyer
				? appointment_actions.provider_target_text
				: appointment_actions.client_target_text;

		let actionsList =
			attention.user_uuid === auth.uid
				? isLawyer
					? appointment_actions.provider_source_options
					: appointment_actions.client_source_options
				: isLawyer
				? appointment_actions.provider_target_options
				: appointment_actions.client_target_options;

		if (actionsList) {
			return (
				<ButtonDropdown
					isOpen={this.state.dropdownOpen}
					toggle={this.toggle}
				>
					<DropdownToggle
						caret
						size="sm"
						color="primary"
						className={classes.toggleBtn}
					>
						{btnTitle}
					</DropdownToggle>
					<DropdownMenu right={true}>
						{actionsList.map((action) => {
							return (
								<DropdownItem
									key={action.tid}
									onClick={() =>
										this.handleActionClick(
											"review_appointment",
											action.field_end_point_function
												.und[0].value
										)
									}
								>
									{
										action.field_future_state_label.und[0]
											.value
									}
								</DropdownItem>
							);
						})}
					</DropdownMenu>
				</ButtonDropdown>
			);
		} else {
			return (
				<BootstrapButton
					size="sm"
					color="primary"
					className={classes.toggleBtn}
				>
					{btnTitle}
				</BootstrapButton>
			);
			//return null;
		}
	};

	getTaskActionBtns = () => {
		const { attention } = this.props,
			{ auth, isLawyer, classes } = this.props;
		const task_actions = attention.new_actions;

		let btnTitle =
			attention.user_uuid === auth.uid
				? isLawyer
					? task_actions.provider_source_text
					: task_actions.client_source_text
				: isLawyer
				? task_actions.provider_target_text
				: task_actions.client_target_text;

		let actionsList =
			attention.user_uuid === auth.uid
				? isLawyer
					? task_actions.provider_source_options
					: task_actions.client_source_options
				: isLawyer
				? task_actions.provider_target_options
				: task_actions.client_target_options;

		if (actionsList) {
			return (
				<ButtonDropdown
					isOpen={this.state.dropdownOpen}
					toggle={this.toggle}
				>
					<DropdownToggle
						caret
						size="sm"
						color="primary"
						className={classes.toggleBtn}
					>
						{btnTitle}
					</DropdownToggle>
					<DropdownMenu right={true}>
						{actionsList.map((action) => {
							return (
								<DropdownItem
									key={action.tid}
									onClick={() =>
										this.handleActionClick(
											"review_task",
											action.field_end_point_function
												.und[0].value
										)
									}
								>
									{
										action.field_future_state_label.und[0]
											.value
									}
								</DropdownItem>
							);
						})}
					</DropdownMenu>
				</ButtonDropdown>
			);
		} else {
			// return <BootstrapButton size="sm" color="success" className={classes.toggleBtn}>{btnTitle}</BootstrapButton>
			return null;
		}
	};

	getActionBtns = () => {
		const { attention } = this.props;
		const { dropdownOpen } = this.state,
			{ classes } = this.props;
		if (attention.type === "case") {
			return this.getCaseActionBtns();
		} else if (attention.type === "appointment") {
			return this.getAppointmentActionBtns();
		} else if (attention.type === "task") {
			return this.getTaskActionBtns();
		}

		if (attention.actions) {
			return attention.actions.map((action, index, actions) => {
				switch (action.type) {
					case "join_call":
						return (
							<BootstrapButton
								className={classes.joinCallBtn}
								color="success"
								size="sm"
								key={index}
								onClick={() =>
									this.props.history.push(
										`/conference/${attention.uuid}`
									)
								}
							>
								Join Call
							</BootstrapButton>
						);
					//case 'review_appointment':
					case "review_task":
					case "review_trans":
						//case 'review_case':
						return (
							<ButtonDropdown
								isOpen={dropdownOpen}
								toggle={this.toggle}
								key={index}
							>
								<DropdownToggle
									caret
									size="sm"
									color="primary"
									className={classes.toggleBtn}
								>
									{action.title}
								</DropdownToggle>
								<DropdownMenu right={true}>
									{Object.keys(action.options).map(
										(objectKey, i) => {
											return (
												<DropdownItem
													key={i}
													onClick={() =>
														this.handleActionClick(
															actions[index].type,
															objectKey
														)
													}
												>
													{action.options[
														objectKey
													].hasOwnProperty("title")
														? action.options[
																objectKey
														  ].title
														: action.options[
																objectKey
														  ]}
												</DropdownItem>
											);
										}
									)}
								</DropdownMenu>
							</ButtonDropdown>
						);
					default:
						return null;
				}
			});
		}
		return null;
	};

	clickConnectionOption = () => {
		const { attention } = this.props;
		if (
			attention.status === "accepted" &&
			attention.when <= attention.now + 600 &&
			attention.to > attention.now - 3600
		) {
			console.log("conference");
			if (
				attention.connection_option ===
				process.env.REACT_APP_CONNECTION_VIDEO_CALL
			) {
				this.props.history.push(`/conference/${attention.uuid}`);
			} else {
				this.setState({ connectionModalOpen: true });
			}
		} else {
			this.setState({ connectionModalOpen: true });
		}
	};

	getConnectionOption = () => {
		const { classes, attention } = this.props;
		const { connectionModalOpen } = this.state;
		if (
			attention.type === "appointment" &&
			!!attention["connection_option"]
		) {
			let isConferenceStarted =
				attention.status === "accepted" &&
				attention.when <= attention.now + 600 &&
				attention.to > attention.now - 3600;
			let conntection_icon = "";
			let message = "";
			switch (attention.connection_option) {
				case process.env.REACT_APP_CONNECTION_VIDEO_CALL:
					conntection_icon = (
						<Videocam className={classes.connectionOptionIcon} />
					);
					message =
						"Please wait until the scheduled start time to join the call. Video call will be available 10 minutes prior to the start of your appointment.";
					break;
				case process.env.REACT_APP_CONNECTION_CLIENT_CALL:
					conntection_icon = (
						<PhoneCallback
							className={classes.connectionOptionIcon}
						/>
					);
					message = `The lawyer will call ${attention.client_number}  at the appointment time.`;
					break;
				default:
					conntection_icon = (
						<Videocam className={classes.connectionOptionIcon} />
					);
					break;
			}
			return (
				<Fragment>
					<Typography
						onClick={this.clickConnectionOption}
						//style={{ color: "#0D7511" }}
						className={classNames({
							[classes.joinCallText]: isConferenceStarted,
						})}
					>
						{conntection_icon}
						{attention.connection_option_name}
					</Typography>

					<QaseModal
						open={connectionModalOpen}
						handleClose={() => {
							console.log("close");
							this.setState({ connectionModalOpen: false });
						}}
					>
						{message}
					</QaseModal>
				</Fragment>
			);
		}

		return null;
	};

	render() {
		const { classes, attention } = this.props,
			{
				cancleationModalOpen,
				rejectionModalOpen,
				selectLawyerModalOpen,
				isLawyerSelected,
			} = this.state;
		//console.log(attention);
		let attentionAction = this.getActionBtns();
		const rejectionReasons = this.getRejectionReasons(attention.type);
		return (
			<Fragment>
				<Paper className={classes.Paper}>
					<Typography
						variant="subheading"
						classes={{
							subheading: classes.requiresSubHeading,
						}}
					>
						<Link
							component={NavLink}
							to={`/${attention.type}/${attention.uuid}`}
							style={this.getHeadingColor()}
						>
							{attention.title_text}
						</Link>
					</Typography>
					{attention.time_for_show && (
						<Typography>{attention.time_for_show}</Typography>
					)}
					{attention.amount && (
						<Typography>Amount: {attention.amount}</Typography>
					)}
					{this.getConnectionOption()}

					<Toolbar disableGutters={true}>
						<Chip
							label={attention.type}
							className={classes.qaseChip}
							classes={{
								label: classes.qaseChipLabel,
							}}
							variant="outlined"
						/>
						{attention.type !== "case" && (
							<Chip
								label={
									attention.billable ? "$" : "Non-Billable"
								}
								className={classes.qaseChip}
								classes={{
									label: classes.qaseChipLabel,
								}}
								variant="outlined"
							/>
						)}

						<div className={classes.grow} />
						{attentionAction}
					</Toolbar>
				</Paper>
				{cancleationModalOpen && (
					<CancelationModal
						open={cancleationModalOpen}
						handleClose={() =>
							this.handleCancelationModalClose(
								"cancleationModalOpen"
							)
						}
						onSubmit={this.handleCancelRequiredAttention}
						rejectionReasons={rejectionReasons[0]}
						title={attention.title_text}
						data={{
							comment: "",
							uuid: attention.uuid,
							reason: "",
						}}
					/>
				)}

				{rejectionModalOpen && (
					<CancelationModal
						open={rejectionModalOpen}
						handleClose={() =>
							this.handleCancelationModalClose(
								"rejectionModalOpen"
							)
						}
						//onSubmit={this.getSubmitRejectAction(attention.type)}
						onSubmit={this.handleRejectRequiredAttention}
						rejectionReasons={rejectionReasons[0]}
						title={attention.title_text}
						data={{
							comment: "",
							uuid: attention.uuid,
							reason: "",
						}}
					/>
				)}

				{selectLawyerModalOpen && (
					<SelectLawyerModal
						open={selectLawyerModalOpen}
						handleClose={() =>
							this.handleCancelationModalClose(
								"selectLawyerModalOpen"
							)
						}
						title={`Select Lawyer: ${attention.title_text}`}
						practice_areas={this.state.selectLawyerPractices}
						defaultSelectedArea={this.state.defaultSelectedArea}
						case_nid={attention.nid}
					/>
				)}
			</Fragment>
		);
	}
}

RequiresAttentionItem.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(RequiresAttentionItem));
