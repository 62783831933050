import React, { Component, Fragment } from "react";

import {
	UserStickyHeader,
	ContactInfo,
	BillingDetail,
	PasswordDetail,
	NotificationInfo,
	BankingDetail,
} from "../Components/User";

import { connect } from "react-redux";

import { Loading, Processing } from "../Components/Layouts";
import { LOGOUT } from "../redux/actions/actionTypes";

class ProfilePage extends Component {
	state = {
		show: 1, //
		user: null,
		loading: true,
		processing: false,
	};

	componentDidMount() {
		this.getUserData();
	}

	showProcessing = (value) => {
		this.setState({
			processing: value,
		});
	};

	getUserData() {
		const userData = JSON.parse(localStorage.getItem("userData"));

		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);

		fetch(`${process.env.REACT_APP_API_HOST_URL}/user/all`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				if (res.status !== 200 && res.status !== 201) {
					throw new Error("failed");
				}
				return res.json();
			})
			.then((resData) => {
				// console.log(resData);
				if (resData.success) {
					this.setState({
						loading: false,
						processing: false,
						user: resData.data,
					});
				}

				if (
					!resData.success &&
					(resData.message == "Session too old." ||
						resData.message == "No session found.")
				) {
					this.props.logout();
				}
			})
			.catch((err) => {
				//console.log(err);
			});
	}

	handleMenuSelect = (index) => {
		this.setState(
			{
				show: index,
				processing: true,
			},
			() => {
				this.getUserData();
			}
		);
	};

	getUserPaymentBlock = (user) => {
		if (user.role === "client") {
			return (
				<BillingDetail
					user={user}
					getUserData={this.handleMenuSelect}
					showProcessing={this.showProcessing}
				/>
			);
		} else {
			return <BankingDetail user={user} />;
		}
	};

	render() {
		const { loading, processing, show, user } = this.state;

		if (loading) {
			return <Loading />;
		}

		return (
			<Fragment>
				<UserStickyHeader
					onMenuSelect={this.handleMenuSelect}
					activeMenu={show}
					role={user.role}
				/>
				{processing && <Processing />}
				<div className="page--wrapper">
					{show === 1 && (
						<ContactInfo
							userData={user}
							showProcessing={this.showProcessing}
						/>
					)}
					{show === 2 && this.getUserPaymentBlock(user)}
					{show === 3 && <PasswordDetail />}
					{show === 4 && <NotificationInfo user={user} />}
				</div>
			</Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		logout: () => dispatch({ type: LOGOUT, payload: true }),
	};
};

export default connect(null, mapDispatchToProps)(ProfilePage);
