export const FILES_FETCH_BEGINING_DATA = "FILES_FETCH_BEGINING_DATA";
export const FILES_UPLOAD_CASE_FILE = "FILES_UPLOAD_CASE_FILE";
export const FILES_SHOW_PROCESSING = "FILES_SHOW_PROCESSING";
export const FILES_HIDE_PROCESSING = "FILES_HIDE_PROCESSING";
export const FILES_REMOVE_FILE = "FILES_REMOVE_FILE";
export const FILES_SHARE_FILE = "FILES_SHARE_FILE";
export const FILES_SHOW_ERROR = "FILES_SHOW_ERROR";
export const FILES_HIDE_ERROR = "FILES_HIDE_ERROR";

export const TASKS_FETCH_BEGINING_DATA = "TASKS_FETCH_BEGINING_DATA";
export const TASKS_CREATE_TASK = "TASKS_CREATE_TASK";
export const TASKS_SHOW_PROCESSING = "TASKS_SHOW_PROCESSING";
export const TASKS_ACCEPT_TASK = "TASKS_ACCEPT_TASK";
export const TASKS_SHOW_ERROR = "TASKS_SHOW_ERROR";
export const TASKS_HIDE_ERROR = "TASKS_HIDE_ERROR";
export const TASKS_CANCEL_TASK = "TASKS_CANCEL_TASK";
export const TASKS_REJECT_TASK = "TASKS_REJECT_TASK";
export const TASKS_COMPLETE_TASK = "TASKS_COMPLETE_TASK";
export const TASKS_RESET_REDIRECT = "TASKS_RESET_REDIRECT";

export const UTILITY_FETCH_REJECTION_REASONS =
	"UTILITY_FETCH_REJECTION_REASONS";
export const UTILITY_JOIN_CONFERENCE = "UTILITY_JOIN_CONFERENCE";
export const UTILITY_LEAVE_CONFERENCE = "UTILITY_LEAVE_CONFERENCE";
export const UTILITY_FETCH_LEGAL_ISSUES = "UTILITY_FETCH_LEGAL_ISSUES";
export const UTILITY_FETCH_CONNECT_OPTIONS = "UTILITY_FETCH_CONNECT_OPTIONS";
export const UTILITY_FETCH_LOCALS = "UTILITY_FETCH_LOCALS";
export const UTILITY_FETCH_CASE_BILLING_TYPES =
	"UTILITY_FETCH_CASE_BILLING_TYPES";

export const MESSAGES_FETCH_BEGINING_DATA = "MESSAGES_FETCH_BEGINING_DATA";
export const MESSAGES_ADD_NEW_MESSAGE = "MESSAGES_ADD_NEW_MESSAGE";

export const APPOINTMENTS_CREATE_STEP_CHANGE =
	"APPOINTMENTS_CREATE_STEP_CHANGE";
export const APPOINTMENTS_FETCH_BEGINING_DATA =
	"APPOINTMENTS_FETCH_BEGINING_DATA";
export const APPOINTMENTS_SHOW_PROCESSING = "APPOINTMENTS_SHOW_PROCESSING";
export const APPOINTMENTS_CANCEL_APPOINTMENT =
	"APPOINTMENTS_CANCEL_APPOINTMENT";
export const APPOINTMENTS_ACCEPT_APPOINTMENT =
	"APPOINTMENTS_ACCEPT_APPOINTMENT";
export const APPOINTMENTS_SHOW_ERROR = "APPOINTMENTS_SHOW_ERROR";
export const APPOINTMENTS_HIDE_ERROR = "APPOINTMENTS_HIDE_ERROR";
export const APPOINTMENTS_COMPLETE_UNATTENDED =
	"APPOINTMENTS_COMPLETE_UNATTENDED";
export const APPOINTMENTS_COMPLETE_APPOINTMENT =
	"APPOINTMENTS_COMPLETE_APPOINTMENT";

export const TRANSACTIONS_FETCH_BEGINING_DATA =
	"TRANSACTIONS_FETCH_BEGINING_DATA";
export const TRANSACTIONS_ACCEPT_TRANSACTION =
	"TRANSACTIONS_ACCEPT_TRANSACTION";
export const TRANSACTIONS_CANCEL_TRANSACTION =
	"TRANSACTIONS_CANCEL_TRANSACTION";
export const TRANSACTIONS_REJECT_TRANSACTION =
	"TRANSACTIONS_REJECT_TRANSACTION";
export const TRANSACTIONS_SHOW_PROCESSING = "TRANSACTIONS_SHOW_PROCESSING";

export const CASE_NEW_CASE_FILL_INIT_DATA = "CASE_NEW_CASE_FILL_INIT_DATA";
export const CASE_NEW_CASE_INIT_STEPS = "CASE_NEW_CASE_INIT_STEPS";
export const CASE_NEW_CASE_FILL_DATA = "CASE_NEW_CASE_FILL_DATA";
export const CASE_NEW_CASE_FILL_USER_DATA = "CASE_NEW_CASE_FILL_USER_DATA";
export const CASE_NEW_SHOW_LAWYER_BIO = "CASE_NEW_SHOW_LAWYER_BIO";
export const CASE_NEW_HIDE_LAWYER_BIO = "CASE_NEW_HIDE_LAWYER_BIO";
export const CASE_NEW_RESET_AFTER_CREATE = "CASE_NEW_RESET_AFTER_CREATE";
export const CASE_NEW_CASE_STEP_CHANGE = "CASE_NEW_CASE_STEP_CHANGE";
export const CASE_STORE_CASE_DATA = "CASE_STORE_CASE_DATA";

export const HEADER_FETCH_BEGINING_DATA = "HEADER_FETCH_BEGINING_DATA";
export const HEADER_FETCH_HEADER_FAILURE = "HEADER_FETCH_HEADER_FAILURE";
export const HEADER_ADD_NEW_CASE = "HEADER_ADD_NEW_CASE";

export const LOGIN = "LOGIN";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";
export const AUTH_SET_CASE_UUID = "HEADET_SET_CASE_UUID";
export const AUTH_RESET_SESSION_EXPIRED_NOTIFY =
	"AUTH_RESET_SESSION_EXPIRED_NOTIFY";
export const AUTH_SET_CARD_ASSINGNED = "AUTH_SET_CARD_ASSINGNED";

export const AUTH_REMOVE_CASE_POPUP = "AUTH_REMOVE_CASE_POPUP";

export const NOTES_FETCH_BEGINING_DATA = "NOTES_FETCH_BEGINING_DATA";
export const NOTES_CREATE_NOTE = "NOTES_CREATE_NOTE";
export const NOTES_DELETE_NOTE = "NOTES_DELETE_NOTE";
export const NOTES_SHARE_NOTE = "NOTES_SHARE_NOTE";
export const NOTES_SHOW_PROCESSING = "NOTES_SHOW_PROCESSING";
export const NOTES_HIDE_PROCESSING = "NOTES_HIDE_PROCESSING";
export const NOTES_SHOW_ERROR = "NOTES_SHOW_ERROR";
export const NOTES_HIDE_ERROR = "NOTES_HIDE_ERROR";

export const DASHBOARD_FETCH_REQUIRED_ATTENTIONS =
	"DASHBOARD_FETCH_REQUIRED_ATTENTIONS";
export const DASHBOARD_CANCEL_ATTENTION = "DASHBOARD_CANCEL_ATTENTION";
export const DASHBOARD_ACCEPT_ATTENTION = "DASHBOARD_ACCEPT_ATTENTION";
export const DASHBOARD_ACCEPT_TASK = "DASHBOARD_ACCEPT_TASK";
export const DASHBOARD_SHOW_SNACKBAR = "DASHBOARD_SHOW_SNACKBAR";
export const DASHBOARD_HIDE_SNACKBAR = "DASHBOARD_HIDE_SNACKBAR";
export const DASHBOARD_SHOW_PROCESSING = "DASHBOARD_SHOW_PROCESSING";
