import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import { AccessTime, Clear } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import Sticky from 'react-stickynode';

const styles = theme => ({
    subHeader: {
        backgroundColor: '#ff5555',
        paddingTop: 5,
        paddingBottom: 5,
    },
    text: {
        color: '#FFFFFF',
        fontSize: 13,
        fontWeight: 700,
    },
    backIcon: {
        position: 'absolute',
        padding: 5,
        top: 0,
        color: '#FFFFFF',
        left: 15
    },
    closeIcon: {
        position: 'absolute',
        padding: 5,
        top: 0,
        color: '#FFFFFF',
        right: 15
    }
});

class CallEndingNotification extends Component {
    render() {
        const { classes, extendCall, closeNotify, showExtend = false } = this.props;
        return (
            <Sticky enabled={true} top={56} innerZ={1299} bottomBoundary={1200} onStateChange={this.handleStateChange}>
                <div className={classes.subHeader}>
                    <IconButton className={classes.backIcon}>
                        <AccessTime fontSize="small" />
                    </IconButton>
                    <Typography align="center" className={classes.text}>
                        Call ending in 5min.
                        {showExtend &&
                            <span style={{ textDecoration: 'underline', marginLeft: 5 }} onClick={extendCall}>EXTEND CALL</span>
                        }
                        
                    </Typography>
                    <IconButton className={classes.closeIcon} onClick={closeNotify}>
                        <Clear fontSize="small" />
                    </IconButton>
                </div>
            </Sticky>
        )
    }
};

CallEndingNotification.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CallEndingNotification);