import {
	LOGIN,
	LOGOUT,
	LOGIN_FAILED,
	CASE_STORE_CASE_DATA,
} from "./actionTypes";

export const login = (data) => {
	return async (dispatch, getState, qaseApi) => {
		try {
			const response = await qaseApi.login(data);
			localStorage.setItem("userData", JSON.stringify(response.data));
			localStorage.removeItem("newRegiser");
			dispatch({
				type: LOGIN,
				payload: response.data,
			});
			dispatch({
				type: CASE_STORE_CASE_DATA,
				payload: false,
			});
		} catch (e) {
			dispatch({
				type: LOGIN_FAILED,
				payload: e.message,
			});
			console.error(e);
		}
	};
};

export const logout = () => ({
	type: LOGOUT,
});
