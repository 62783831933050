import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
    Divider,
    Fab,
    Popper,
    Grow,
    Paper,
    MenuItem,
    MenuList,
    ListItemIcon,
    ListItemText,
    ClickAwayListener,
    Typography,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import {
    Loading,
    CalendarNavbar,
    Processing,
    NotificationSnackbar,
} from "../Components/Layouts";
import {
    DateRangeIcon,
    ListIcon,
    AppointmentIcon,
    TaskIcon,
} from "../Components/Icons";
import { AppointmentItems } from "../Components/Appointement";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import "../styles/calendar.css";
import moment from "moment-timezone";
import { connect } from "react-redux";
import compose from "recompose/compose";
import {
    fetchTasksList,
    acceptTask,
    cancelTask,
    rejectTask,
    completeTask,
} from "../redux/actions/taskActions";
import {
    fetchAppointmentsList,
    cancelAppointment,
    rejectAppointment,
    acceptAppointment,
    completeUnattendedAppointment,
    completeAppointment,
} from "../redux/actions/appointmentActions";
import TaskItem from "../Components/Task/TaskItem";
import { APPOINTMENTS_HIDE_ERROR } from "../redux/actions/actionTypes";

const styles = (theme) => ({
    apptTitle: {
        fontWeight: "700",
        fontStyle: "normal",
        fontSize: "15px",
        color: "#220D89",
        //marginLeft: '15px',
    },

    apptSubTitle: {
        color: "#999999",
        fontSize: "15px",
    },

    dividerStyle: {
        marginTop: "15px",
    },
    toolbarStyle: {
        marginTop: "10px",
        marginBottom: "10px",
    },
    bookedItem: {
        width: "16px",
        height: "16px",
        backgroundColor: "#220D89",
        float: "left",
    },

    pendingItem: {
        width: "16px",
        height: "16px",
        backgroundColor: "white",
        float: "left",
        borderColor: "#220D89",
        borderStyle: "solid",
        borderWidth: "thin",
        marginLeft: "15px",
    },

    toolbarLabel: {
        fontSize: "10px",
        fontWeight: "700",
        color: "#999999",
        marginLeft: "10px",
    },

    toolBarIcon: {
        width: "16px",
        height: "16px",
        marginLeft: "10px",
        color: "#999999",
    },

    fab: {
        position: "fixed",
        bottom: 100,
        right: theme.spacing.unit * 2,
        zIndex: 1001,
    },

    menuPaper: {
        backgroundColor: "#220d89",
        padding: theme.spacing.unit,
    },
    menuIcon: {
        color: "#fff",
    },
    menuPrimary: {
        color: "#fff",
        fontSize: 13,
    },
    menuHeader: {
        textTransform: "uppercase",
        fontSize: 10,
        color: "#CCCCCC",
    },
    verDivider: {
        width: 2,
        height: 16,
        margin: "4px 8px",
    },
});

const taskCreateAvailableStatusArray = [
    "accepted",
    "reviewed",
    "active",
    "hold",
];

class AppointmentsPage extends Component {
    state = {
        isOpen: false,
        _case: null,
    };

    componentDidMount() {
        //const { match: { params: { id } } } = this.props;
        var userData = JSON.parse(localStorage.getItem("userData"));
        var data = new FormData();
        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);
        this.getCase(this.props.auth.case_uuid);
        this.props.fetchTasksList(this.props.auth.case_uuid, data);
    }

    getCase(case_uuid) {
        let data = new FormData();

        let userData = JSON.parse(localStorage.getItem("userData"));

        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);

        fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/${case_uuid}`, {
            method: "POST",
            body: data,
        })
            .then((res) => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error("failed");
                }

                return res.json();
            })
            .then((resData) => {
                console.log(resData);
                this.setState({
                    _case: resData.data,
                });
            })
            .catch((err) => {
                //console.log(err);
                this.setState({
                    error: true,
                });
            });
    }

    handleToggle = () => {
        this.setState((state) => ({ isOpen: !state.open }));
    };

    handleClose = (event) => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }

        this.setState({ isOpen: false });
    };

    getStyle = () => {
        return {
            color: "#220D89",
            fontWeight: 700,
        };
    };

    /* TASK ACTIONS */
    handleAcceptTask = (task_uuid) => {
        var userData = JSON.parse(localStorage.getItem("userData"));
        var data = new FormData();
        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);
        data.append("uuid", task_uuid);

        this.props.acceptTask(task_uuid, data);
    };

    handleCancelTask = (cancel_data) => {
        var userData = JSON.parse(localStorage.getItem("userData"));
        var data = new FormData();
        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);
        data.append("uuid", cancel_data.uuid);
        data.append("comment", cancel_data.comment);
        data.append("reason", cancel_data.reason);
        //console.log(cancel_data);
        this.props.cancelTask(cancel_data.uuid, data);
    };

    handleRejectTask = (reject_data) => {
        var userData = JSON.parse(localStorage.getItem("userData"));
        var data = new FormData();
        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);
        data.append("uuid", reject_data.uuid);
        data.append("comment", reject_data.comment);
        data.append("reason", reject_data.reason);
        this.props.rejectTask(reject_data.uuid, data);
    };

    handleCompleteTask = (task_uuid) => {
        var userData = JSON.parse(localStorage.getItem("userData"));
        var data = new FormData();
        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);
        data.append("uuid", task_uuid);

        this.props.completeTask(task_uuid, data);
    };

    render() {
        const {
                classes,
                history,
                taskList,
                rejectionReasons,
                isLawyer,
                auth,
            } = this.props,
            { isOpen, _case } = this.state;

        if (!taskList.loaded || _case === null) {
            return <Loading />;
        }

        return (
            <Fragment>
                <div className="page--wrapper">
                    <div style={{ marginBottom: 10 }}>
                        <span
                            className={classes.apptSubTitle}
                            style={this.getStyle()}
                        >
                            Tasks
                        </span>
                    </div>

                    <div style={{ marginBottom: 90 }}>
                        {taskList.processing && <Processing />}
                        {taskList.tasks.map((task) => {
                            return (
                                <TaskItem
                                    key={task.nid}
                                    task={task}
                                    rejectionReasons={rejectionReasons}
                                    acceptTask={this.handleAcceptTask}
                                    cancelTask={this.handleCancelTask}
                                    rejectTask={this.handleRejectTask}
                                    completeTask={this.handleCompleteTask}
                                    isLawyer={isLawyer}
                                    auth={auth}
                                />
                            );
                        })}
                        {taskList.notificationSnackbar.open && (
                            <NotificationSnackbar
                                snackbarOptions={taskList.notificationSnackbar}
                                handleClose={this.props.hideTaskError}
                            />
                        )}
                    </div>

                    {taskCreateAvailableStatusArray.includes(
                        _case.status.name.toLowerCase()
                    ) &&
                        isLawyer && (
                            <Fragment>
                                <Fab
                                    className={classes.fab}
                                    color="primary"
                                    buttonRef={(node) => {
                                        this.anchorEl = node;
                                    }}
                                    aria-owns={
                                        isOpen ? "menu-list-grow" : undefined
                                    }
                                    aria-haspopup="true"
                                    onClick={this.handleToggle}
                                >
                                    <AddIcon />
                                </Fab>
                                <Popper
                                    open={isOpen}
                                    anchorEl={this.anchorEl}
                                    transition
                                    disablePortal
                                    placement="top-start"
                                >
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            id="menu-list-grow"
                                            style={{
                                                transformOrigin:
                                                    placement === "bottom"
                                                        ? "center top"
                                                        : "center bottom",
                                            }}
                                            //style={{ transformOrigin: 'center top' }}
                                        >
                                            <Paper
                                                className={classes.menuPaper}
                                            >
                                                <ClickAwayListener
                                                    onClickAway={
                                                        this.handleClose
                                                    }
                                                >
                                                    <Fragment>
                                                        <Typography
                                                            className={
                                                                classes.menuHeader
                                                            }
                                                        >
                                                            Create
                                                        </Typography>
                                                        <MenuList>
                                                            {isLawyer && (
                                                                <MenuItem
                                                                    disableGutters
                                                                    onClick={() =>
                                                                        history.push(
                                                                            "/task/create"
                                                                        )
                                                                    }
                                                                >
                                                                    <ListItemIcon
                                                                        className={
                                                                            classes.menuIcon
                                                                        }
                                                                    >
                                                                        <TaskIcon
                                                                            style={{
                                                                                fontSize: 18,
                                                                            }}
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        classes={{
                                                                            primary:
                                                                                classes.menuPrimary,
                                                                        }}
                                                                        primary="New Task"
                                                                    />
                                                                </MenuItem>
                                                            )}
                                                        </MenuList>
                                                    </Fragment>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </Fragment>
                        )}
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        taskList: state.task,
        rejectionReasons: state.utility.rejectionReasons,
        isLawyer: state.headerReducer.user.is_lawyer,
        appointmentList: state.appointment,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchTasksList: (case_uuid, data) =>
            dispatch(fetchTasksList(case_uuid, data)),
        acceptTask: (task_uuid, data) => dispatch(acceptTask(task_uuid, data)),
        cancelTask: (task_uuid, data) => dispatch(cancelTask(task_uuid, data)),
        rejectTask: (task_uuid, data) => dispatch(rejectTask(task_uuid, data)),
        completeTask: (task_uuid, data) =>
            dispatch(completeTask(task_uuid, data)),
        hideTaskError: () => dispatch({ type: "TASKS_HIDE_ERROR" }),
    };
};

AppointmentsPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(AppointmentsPage);
