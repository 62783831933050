import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography, Button } from '@material-ui/core';
import { PageHading, Loading, CancelationModal, Processing, NotificationSnackbar } from '../Components/Layouts';

import compose from 'recompose/compose';
import { connect } from "react-redux";
import { fetchHeaderData } from '../redux/actions/headerActions';
import { LOGOUT } from '../redux/actions/actionTypes';

const styles = theme => ({
    Paper: {
        overflow: 'hidden',
        padding: '8px 20px',
    },
    detailGroup: {
        marginBottom: 15,
    },
    caption: {
        fontSize: 10,
        color: '#A0AAB4'
    },
    tableCell: {
        border: 'none',
    },
    margin: {
        marginRight: theme.spacing.unit / 2,
    }
});

class TransactionDetailPage extends Component {

    state = {
        transaction: {},
        loading: true,
        processing: false,
        notificationSnackbar: {
            open: false,
            variant: '',
            message: '',
        },
        cancelationModalOpen: false,
        rejectionModalOpen: false,
    };

    componentDidMount() {
        const { match: { params: { id, pmid } } } = this.props;

        this.getTransaction(id, pmid);
    };

    getTransaction(uid, pmid) {
        var data = new FormData();

        var userData = JSON.parse(localStorage.getItem("userData"));

        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('uuid', uid);
        if (pmid) {
            data.append('pmid', pmid);
        }

        fetch(`${process.env.REACT_APP_API_HOST_URL}/transaction`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('failed');
                }

                return res.json();
            })
            .then(resData => {
                console.log("Transaction", resData.data.result);
                if (resData.data.success) {
                    this.setState({
                        transaction: resData.data.result,
                        loading: false,
                    }, () => {
                        if (pmid) {
                            this.props.fetchHeaderData(data);
                        }
                    })
                }

                if (!resData.success && (resData.message === 'Session too old.' || resData.message === 'No session found.')) {
                    this.props.logout();
                }
            })
            .catch(err => {
                //console.log(err);
            });
    };

    handleActionButtonsClick = (type) => {
        switch (type.toLowerCase()) {
            case 'accept':
                this.handleAcceptDisbTransaction();
                break;
            case 'cancel':
                this.disbTransactionCancelModalOpen('cancelationModalOpen');
                break;
            case 'reject':
                this.disbTransactionCancelModalOpen('rejectionModalOpen');
                break;
            default:
                break;
        }
    };

    getActionButtons = action => {
        const { classes } = this.props;
        //console.log(action);
        if (action.constructor === Object && Object.entries(action.options).length > 0) {
            return Object.keys(action.options).map((option, index) => {
                return (
                    <Button variant={option === 'Accept' ? 'contained' : 'outlined'} size="small" color="primary" key={index} className={classes.margin}
                        onClick={() => this.handleActionButtonsClick(option)}
                    >
                        {action.options[option]["title"]}
                    </Button>
                )
            });
        }
        return null;
    };

    handleCancelationModalClose = (type) => {
        this.setState({
            [type]: false,
        })
    };

    disbTransactionCancelModalOpen = (type) => {
        this.setState({
            [type]: true,
        });
    };

    handleCancalDisbTransaction = (cancel_data) => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('uuid', cancel_data.uuid);
        data.append('comment', cancel_data.comment);

        this.setState({
            processing: true,
            cancelationModalOpen: false,
        })

        fetch(`${process.env.REACT_APP_API_HOST_URL}/disb_transaction/cancel`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    if (cancel_data.uuid) {
                        throw new Error('Failed to cancel transaction ' + cancel_data.uuid);
                    }
                    throw new Error('General failure: ' + res.status);
                }

                return res.json();
            })
            .then(resData => {
                //console.log(resData);
                if (resData.success) {
                    if (resData.data.success) {
                        this.setState({
                            processing: false,
                            transaction: {
                                ...this.state.transaction,
                                action: [],
                            },
                        });
                    }

                }
                else {
                    this.setState({
                        processing: false,
                    },
                        () => this.handleShowSnackbar({
                            variant: 'error',
                            message: 'Transaction not canceled!',
                        }));
                }
            })
            .catch(err => {
                //console.log(err);
                this.setState({
                    processing: false,
                },
                    () => this.handleShowSnackbar({
                        variant: 'error',
                        message: 'Transaction not canceled!',
                    }));
            });
    };

    handleRejectDisbTransaction = (reject_data) => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('uuid', reject_data.uuid);
        data.append('comment', reject_data.comment);

        this.setState({
            processing: true,
            rejectionModalOpen: false,
        })

        fetch(`${process.env.REACT_APP_API_HOST_URL}/disb_transaction/reject`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    if (reject_data.uuid) {
                        throw new Error('Failed to reject transaction ' + reject_data.uuid);
                    }
                    throw new Error('General failure: ' + res.status);
                }

                return res.json();
            })
            .then(resData => {
                //console.log(resData);
                if (resData.success) {
                    if (resData.data.success) {
                        this.setState({
                            processing: false,
                            transaction: {
                                ...this.state.transaction,
                                action: [],
                            },
                        });
                    }

                }
                else {
                    this.setState({
                        processing: false,
                    },
                        () => this.handleShowSnackbar({
                            variant: 'error',
                            message: 'Transaction not rejected!',
                        }));
                }
            })
            .catch(err => {
                //console.log(err);
                this.setState({
                    processing: false,
                },
                    () => this.handleShowSnackbar({
                        variant: 'error',
                        message: 'Transaction not rejected!',
                    }));
            });
    };

    handleAcceptDisbTransaction = () => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        const { transaction } = this.state;
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('uuid', transaction.uuid);

        this.setState({
            processing: true,
            rejectionModalOpen: false,
        })

        fetch(`${process.env.REACT_APP_API_HOST_URL}/disb_transaction/accept`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    if (transaction.uuid) {
                        throw new Error('Failed to accept transaction ' + transaction.uuid);
                    }
                    throw new Error('General failure: ' + res.status);
                }

                return res.json();
            })
            .then(resData => {
                //console.log(resData);
                if (resData.success) {
                    if (resData.data.success) {
                        this.setState({
                            processing: false,
                            transaction: {
                                ...this.state.transaction,
                                action: [],
                            },
                        });
                    }

                }
                else {
                    this.setState({
                        processing: false,
                    },
                        () => this.handleShowSnackbar({
                            variant: 'error',
                            message: 'Transaction not accpeted!',
                        }));
                }
            })
            .catch(err => {
                //console.log(err);
                this.setState({
                    processing: false,
                },
                    () => this.handleShowSnackbar({
                        variant: 'error',
                        message: 'Transaction not accpeted!',
                    }));
            });
    };

    render() {
        if (this.state.loading) {
            return <Loading />
        }
        const { classes, rejectionReasons } = this.props;
        const { transaction, cancelationModalOpen, rejectionModalOpen, processing, notificationSnackbar } = this.state;
        //console.log(transaction);
        const actionBtns = this.getActionButtons(transaction.action);

        const transactionRejectionReasons = Object.entries(rejectionReasons)
            .filter(([group, reason]) => reason.name.toLowerCase() === "transaction")
            .map(([group, reason]) => {
                return reason;
            })[0];

        const category = transaction.category;
        let pageTitle = "Transaction Details";
        let isDist = false;
        if (category == "Disbursement") {
            isDist = true;
            pageTitle = "Reimbursable Expense";
        }
        return (
            <div className="page--wrapper--no-margin">
                <Paper className={classes.Paper}>
                    {processing && <Processing />}

                    {notificationSnackbar.open &&
                        <NotificationSnackbar
                            snackbarOptions={notificationSnackbar}
                            handleClose={this.handleSnackbarClose}
                        />
                    }

                    <PageHading title={pageTitle} history={this.props.history} />
                    <div className={classes.detailGroup}>
                        <Typography
                            variant="caption"
                            classes={{
                                caption: classes.caption,
                            }}
                        >
                            TITLE:
                    </Typography>
                        <Typography paragraph={true}>
                            {transaction.title_text}
                        </Typography>
                    </div>

                    <div className={classes.detailGroup}>
                        <Typography
                            variant="caption"
                            classes={{
                                caption: classes.caption,
                            }}
                        >
                            WHEN:
                    </Typography>
                        <Typography paragraph={true}>
                            {transaction.time_for_show}
                        </Typography>
                    </div>

                    {!isDist &&

                        <div className={classes.detailGroup}>
                            <Typography
                                variant="caption"
                                classes={{
                                    caption: classes.caption,
                                }}
                            >
                                NOTES:
                    </Typography>
                            <Typography paragraph={true}>
                                {transaction.description}
                            </Typography>
                        </div>
                    }

                    <div className={classes.detailGroup}>
                        <Typography
                            variant="caption"
                            classes={{
                                caption: classes.caption,
                            }}
                        >
                            {isDist ? "EXPENSE BEFORE TAX" : "FEE BEFORE TAX"}
                        </Typography>
                        <Typography paragraph={true}>
                            {transaction.amount}
                        </Typography>
                    </div>

                    {isDist &&
                        <div className={classes.detailGroup}>
                            <Typography
                                variant="caption"
                                classes={{
                                    caption: classes.caption,
                                }}
                            >
                                {"TOTAL EXPENSE"}
                            </Typography>
                            <Typography paragraph={true}>
                                {transaction.full_amount}
                            </Typography>
                        </div>
                    }

                    {isDist &&
                        <div className={classes.detailGroup}>
                            <Typography
                                variant="caption"
                                classes={{
                                    caption: classes.caption,
                                }}
                            >
                                DESCRIPTION:
                            </Typography>
                            <Typography paragraph={true}>
                                {transaction.description}
                            </Typography>
                        </div>
                    }

                    {actionBtns}

                </Paper>

                {rejectionModalOpen &&
                    <CancelationModal
                        open={rejectionModalOpen}
                        handleClose={() => this.handleCancelationModalClose('rejectionModalOpen')}
                        onSubmit={this.handleRejectDisbTransaction}
                        rejectionReasons={transactionRejectionReasons}
                        title={transaction.title_text}
                        data={{
                            comment: '',
                            uuid: transaction.uuid,
                            reason: '',
                        }}
                    />
                }


                {cancelationModalOpen &&
                    <CancelationModal
                        open={cancelationModalOpen}
                        handleClose={() => this.handleCancelationModalClose('cancelationModalOpen')}
                        onSubmit={this.handleCancalDisbTransaction}
                        title={transaction.title_text}
                        rejectionReasons={transactionRejectionReasons}
                        data={{
                            comment: '',
                            uuid: transaction.uuid,
                            reason: '',
                        }}
                    />
                }
            </div>
        )
    };
}

TransactionDetailPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        rejectionReasons: state.utility.rejectionReasons,
        auth: state.auth,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchHeaderData: (data) => { dispatch(fetchHeaderData(data)) },
        logout: () => dispatch({ type: LOGOUT, payload: true }),
    }
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(TransactionDetailPage);