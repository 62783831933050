import React, { Component } from "react";

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	Typography,
	Avatar,
	withStyles,
} from "@material-ui/core";
import { KeyboardArrowRight } from "@material-ui/icons";
import { checkUrl } from "../../utils/helpers";

class ConfirmConsultDialog extends Component {
	render() {
		const { classes, open, handleClose, data } = this.props;
		console.log(data.lawyer.image);
		let isValidUrl = checkUrl(data.lawyer.image);
		return (
			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth
				classes={{
					paper: classes.modalPaper,
				}}
			>
				<DialogTitle style={{ textAlign: "center" }}>
					Confirm Consult
				</DialogTitle>
				<DialogContent>
					<Typography
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: 24,
						}}
					>
						Your meeting with
						<Avatar
							component="span"
							src={
								isValidUrl
									? data.lawyer.image
									: process.env.REACT_APP_DRUPAL_HOST_URL +
									  data.lawyer.image
							}
							style={{ margin: "0px 5px" }}
						/>
						{data.lawyer.name}
					</Typography>

					<Typography gutterBottom>
						<strong>{data.time_show}</strong>
					</Typography>
					<Typography style={{ marginBottom: 24 }}>
						Pacific time | {data.meet_by}
					</Typography>

					<Typography>Legal Issue:</Typography>
					<Typography gutterBottom>
						{data.practice_area_parent} <KeyboardArrowRight />{" "}
						{data.practice_area}
					</Typography>

					<Typography>Case Description:</Typography>
					<Typography>{data.description}</Typography>
				</DialogContent>
				<DialogActions style={{ justifyContent: "center" }}>
					<Button
						onClick={handleClose}
						color="primary"
						variant="contained"
						className={classes.primaryButton}
					>
						BOOK MY FREE CONSULT
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withStyles((theme) => ({
	modalPaper: {
		margin: 10,
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing.unit,
		top: theme.spacing.unit,
		color: theme.palette.grey[500],
		padding: 0,
	},
	titleRoot: {
		borderBottom: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing.unit * 2,
		paddingTop: theme.spacing.unit * 4,
	},
	titleBorderless: {
		borderBottom: "none",
	},
	contentRoot: {
		padding: theme.spacing.unit * 2,
	},
	primaryButton: {
		backgroundColor: "#220C89",
		borderRadius: 8,
	},
}))(ConfirmConsultDialog);
