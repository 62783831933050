import {
    DASHBOARD_FETCH_REQUIRED_ATTENTIONS,
    LOGOUT,
    DASHBOARD_ACCEPT_ATTENTION,
    DASHBOARD_CANCEL_ATTENTION,
    DASHBOARD_SHOW_SNACKBAR,
    DASHBOARD_SHOW_PROCESSING
} from "./actionTypes";

export const fetchRequiredAttentions = (data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            const response = await qaseApi.getRequiredAttentions(data);
            dispatch({
                type: DASHBOARD_FETCH_REQUIRED_ATTENTIONS,
                payload: response.data
            });
        } catch (e) {
            console.error(e);
            if (e.message === 'Session too old.' || e.message === 'No session found.') {
                dispatch({
                    type: LOGOUT,
                    payload: true,
                });
            }
        }
    }
};

export const acceptRequiredAttention = (type, attention_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            dispatch({ type: DASHBOARD_SHOW_PROCESSING });
            const response = await qaseApi.acceptRequiredAttention(type, data);
            console.log(response);
            if (type === 'appointment') {
                dispatch({
                    type: DASHBOARD_ACCEPT_ATTENTION,
                    payload: {
                        attention_uuid: attention_uuid,
                        actions: response.data.actions,
                        status: response.data.status,
                    }
                })
            }
            else {
                if (!!response.data.show_modal) {
                    dispatch({
                        type: DASHBOARD_SHOW_SNACKBAR,
                        payload: {
                            variant: 'warning',
                            message: 'Please complete billing details',
                        }
                    });
                }
                else {
                    dispatch({
                        type: DASHBOARD_CANCEL_ATTENTION,
                        payload: attention_uuid,
                    })
                }
            }
        }
        catch (e) {
            console.error(e);
            if (e.message === 'Session too old.' || e.message === 'No session found.') {
                dispatch({
                    type: LOGOUT,
                    payload: true,
                });
            }
            else {
                dispatch({
                    type: DASHBOARD_SHOW_SNACKBAR,
                    payload: {
                        variant: 'error',
                        message: `${type} did not accepted`,
                    }
                });
            }

        }
    }
};

export const cancelRequiredAttention = (type, attention_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            dispatch({ type: DASHBOARD_SHOW_PROCESSING });
            const response = await qaseApi.cancelRequiredAttention(type, data);

            dispatch({
                type: DASHBOARD_CANCEL_ATTENTION,
                payload: attention_uuid,
            })

        }
        catch (e) {
            console.error(e);
            if (e.message === 'Session too old.' || e.message === 'No session found.') {
                dispatch({
                    type: LOGOUT,
                    payload: true,
                });
            }
            else {
                dispatch({
                    type: DASHBOARD_SHOW_SNACKBAR,
                    payload: {
                        variant: 'error',
                        message: `${type} did not canceled`,
                    }
                });
            }

        }
    }
};

export const rejectRequiredAttention = (type, attention_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            dispatch({ type: DASHBOARD_SHOW_PROCESSING });
            const response = await qaseApi.rejectRequiredAttention(type, data);

            dispatch({
                type: DASHBOARD_CANCEL_ATTENTION,
                payload: attention_uuid,
            })

        }
        catch (e) {
            console.error(e);
            if (e.message === 'Session too old.' || e.message === 'No session found.') {
                dispatch({
                    type: LOGOUT,
                    payload: true,
                });
            }
            else {
                dispatch({
                    type: DASHBOARD_SHOW_SNACKBAR,
                    payload: {
                        variant: 'error',
                        message: `${type} did not rejected`,
                    }
                });
            }

        }
    }
};

export const closeRequiredAttention = (type, attention_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {

            dispatch({ type: DASHBOARD_SHOW_PROCESSING });
            const response = await qaseApi.completeUnattenedAppointment(data);
            console.log(response);
            dispatch({
                type: DASHBOARD_CANCEL_ATTENTION,
                payload: attention_uuid,
            })
        }
        catch (e) {
            console.error(e);
            if (e.message === 'Session too old.' || e.message === 'No session found.') {
                dispatch({
                    type: LOGOUT,
                    payload: true,
                });
            }
            else {
                dispatch({
                    type: DASHBOARD_SHOW_SNACKBAR,
                    payload: {
                        variant: 'error',
                        message: `${type} did not accepted`,
                    }
                });
            }

        }
    }
};