import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { fetchUtilityBillingTypes } from "../redux/actions/utilityActions";
import { Paper, FormControl, Typography, Button } from "@material-ui/core";
import {
	Alert,
	ButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Button as RButton,
} from "reactstrap";
import {
	Phone as PhoneIcon,
	EmailOutlined as EmailIcon,
} from "@material-ui/icons";
import Actions from "../Components/Case/Actions";
import {
	ValidatorForm,
	TextValidator,
	//SelectValidator
} from "react-material-ui-form-validator";
import { Loading, Processing, CancelationModal } from "../Components/Layouts";
import TransactionsStickyHeader from "../Components/Transaction/StickyHeader";
import { LOGOUT } from "../redux/actions/actionTypes";
import { TransferLawyersList } from "../Components/Case";
import { phoneFormatter } from "../utils/helpers";

const edit_fields = [
	"case_uid",
	"case_title",
	"case_body",
	"case_billing_type",
	"case_end_date",
	"case_value",
	"case_contingency_fee",
	"case_opposition",
];

const styles = (theme) => ({
	paper: {
		padding: 20,
		marginBottom: 10,
	},
	label: {
		color: "rgba(0, 0, 0, 0.54)",
		fontSize: "1rem",
	},
	appBar: {
		position: "relative",
	},
	flex: {
		flex: 1,
	},
});

class CaseOverviewPage extends Component {
	state = {
		editMode: false,
		submited: false,
		loading: true,
		error: false,
		processing: false,
		init_billing_type: "",
		Case: {
			case_uid: "",
			no_conflict: "",
			idNumber: "",
			case_title: "",
			case_opposition: "",
			case_body: "",
			jurisdiction: "",
			practice_area: "",
			case_billing_type: "",
			case_end_date: "",
			case_value: "",
			case_contingency_fee: "",
			client: "",
			lawyer: "",
			rate: "",
			is_qase_convert: false,
			declined_message_url: "",
		},
		actionsDropdownOpen: false,
		declineModalOpen: false,
		transferListOpen: false,
		transferLawyers: [],
	};

	componentDidMount() {
		this.props.fetchUtilityBillingTypes();
		this.getCase(this.props.auth.case_uuid);
	}

	getCase(case_uuid) {
		let data = new FormData();

		let userData = JSON.parse(localStorage.getItem("userData"));

		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);

		fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/${case_uuid}`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				//console.log(res);
				if (res.status !== 200 && res.status !== 201) {
					throw new Error("failed");
				}

				return res.json();
			})
			.then((resData) => {
				console.log(resData.data);
				if (resData.success) {
					this.setInitState(resData.data);
				} else {
					this.setState({
						error: true,
					});
				}

				if (
					!resData.success &&
					(resData.message === "Session too old." ||
						resData.message === "No session found.")
				) {
					this.props.logout();
				}
			})
			.catch((err) => {
				//console.log(err);
				this.setState({
					error: true,
				});
			});
	}

	getAvailableLawyersListForTransfer = () => {
		const { Case } = this.state;
		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("case_nid", Case.case_uid);
		data.append("is_transfer", false);

		fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/transfer_lawyers`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				//console.log(res);
				if (res.status !== 200 && res.status !== 201) {
					if (Case.case_uid) {
						throw new Error(
							"Failed to accept case " + Case.case_uid
						);
					}
					throw new Error("General failure: " + res.status);
				}

				return res.json();
			})
			.then((resData) => {
				console.log(resData);
				if (resData.success) {
					this.setState({
						transferListOpen: true,
						transferLawyers: resData.data,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	setInitState = (data) => {
		//console.log(data);
		this.setState({
			...this.state,
			loading: false,
			submited: false,
			init_billing_type: data.field_case_billing_type.name,
			Case: {
				case_uid: data.uuid,
				no_conflict: data.no_conflict,
				idNumber: data.uuid,
				case_title: data.title,
				case_opposition: data.field_opposition,
				case_body: data.body,
				jurisdiction: data.locale.name,
				practice_area: data.practice.name,
				case_billing_type: {
					name: data.field_case_billing_type.name,
					value: data.field_case_billing_type.tid,
				},
				case_end_date: data.field_case_end_date,
				case_value: data.field_case_value,
				case_contingency_fee: data.field_case_contingency_fee,
				client: data.client,
				lawyer: data.lawyer,
				rate: data.rate,
				action: data.action,
				originator: data.originator,
				case_actions: data.case_actions,
				status: data.status,
				ref_source: data.ref_source,
				is_qase_convert: data.is_qase_convert,
				declined_message_url: data.declined_message_url,
			},
		});
	};

	changeEditMode = () => {
		this.setState({
			editMode: !this.state.editMode,
		});
	};

	handleInputChange =
		(name) =>
		({ target: { value } }) => {
			this.setState({
				Case: {
					...this.state.Case,
					[name]: value,
				},
			});
		};

	handleRadioChange =
		(name) =>
		({ target: { value } }) => {
			const { all_billing_types } = this.props;
			let billing_name = all_billing_types[value].name;
			//console.log(billing_name);
			this.setState({
				Case: {
					...this.state.Case,
					[name]: {
						name: billing_name,
						value: value,
					},
				},
			});
		};

	handleRevealCase = () => {
		const {
			Case: { case_uid },
		} = this.state;

		let data = new FormData();
		let userData = JSON.parse(localStorage.getItem("userData"));
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("case_uid", case_uid);

		fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/reveal`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				//console.log(res);
				if (res.status !== 200 && res.status !== 201) {
					throw new Error("failed");
				}

				return res.json();
			})
			.then((resData) => {
				//console.log(resData);
				if (resData.success) {
					this.setState({
						Case: {
							...this.state.Case,
							no_conflict: resData.data.value,
						},
					});
				}
			})
			.catch((err) => {
				//console.log(err);
			});
	};

	handleCloseTransferModal = () => {
		this.setState({ transferListOpen: false });
	};

	handleSubmit = () => {
		//console.log("submit");
		this.setState({
			submited: true,
		});

		const { Case } = this.state;

		let data = new FormData();
		const sessionUserData = JSON.parse(localStorage.getItem("userData"));

		data.append("qase_api_token", sessionUserData.token);
		data.append("sid", sessionUserData.sid);

		Object.keys(Case).forEach((item) => {
			if (edit_fields.includes(item)) {
				if (item == "case_billing_type") {
					data.append(item, Case[item].value);
				} else {
					data.append(item, Case[item]);
				}
			}
		});

		data.forEach(function (value, key) {
			//console.log(value, key);
		});

		fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/edit`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				if (res.status !== 200 && res.status !== 201) {
					throw new Error("failed");
				}
				return res.json();
			})
			.then((resData) => {
				if (resData.success) {
					this.setState({
						editMode: false,
						submited: false,
						init_billing_type: Case.case_billing_type.name,
					});
				}
			})
			.catch((err) => {
				//console.log(err);
			});
	};

	toggleActions = () => {
		this.setState({
			actionsDropdownOpen: !this.state.actionsDropdownOpen,
		});
	};

	handleActionBtnsClick = (type) => {
		console.log(type);
		switch (type) {
			case "case-accept":
				this.handleAcceptCase();
				break;
			case "case-cancel":
				this.handleCancelCase();
				break;
			case "case-decline":
				this.setState({
					declineModalOpen: true,
				});
				break;
			case "case-active":
				this.handleActivateCase();
				break;
			case "case-hold":
				this.handleHoldCase();
				break;
			case "case-transfer-pending":
				this.getAvailableLawyersListForTransfer();
				break;
			case "case-close":
				this.handleCloseCase();
				break;
			case "case-pending":
				this.handlePendingCase();
				break;
			case "case-transfer-accept":
				this.handleAccpetCaseTransfer();
				break;
			case "case-transfer-decline":
				this.handleRejectCaseTransfer();
				break;
		}
	};

	handleFindLawyer = () => {
		const { isLawyer } = this.props;
		if (!isLawyer) {
			this.getAvailableLawyersListForTransfer();
		}
	};

	getActionBtns = () => {
		const {
				Case,
				Case: {
					case_actions,
					originator,
					lawyer,
					ref_source,
					is_qase_convert,
					declined_message_url,
				},
			} = this.state,
			{ auth, isLawyer, classes } = this.props;

		console.log("dasdas", declined_message_url);

		if (
			(ref_source === "apb-lrs" || ref_source === "vfm") &&
			case_actions.name === "Pending" &&
			!lawyer
		) {
			if (ref_source === "vfm") {
				return (
					<div>
						<Typography>
							The referral request was not accepted, visit the{" "}
							<a href={declined_message_url} target="_blank">
								Virtual Family Mediation
							</a>{" "}
							website for more information
						</Typography>
						<RButton
							size="sm"
							color="secondary"
							disabled
							style={{ marginTop: "5px" }}
						>
							Referral Declined
						</RButton>
					</div>
				);
			}
			return (
				<div>
					<Typography>
						Your referral request could not be accepted. Please call
						the Lawyer Referral Service at 604-687-3221 (toll free
						1-800-663-1919) or send them a message:{" "}
						<a href="mailto:lawyerreferral@accessprobono.ca">
							lawyerreferral@accessprobono.ca
						</a>
					</Typography>
					<RButton
						size="sm"
						color="secondary"
						disabled
						style={{ marginTop: "5px" }}
					>
						Referral Declined
					</RButton>
				</div>
			);
		} else {
			if (case_actions.name === "Pending" && !lawyer) {
				if (is_qase_convert) {
					return (
						<RButton
							size="sm"
							color="success"
							className={classes.toggleBtn}
						>
							Request Declined
						</RButton>
					);
				}
				return (
					<RButton
						size="sm"
						color="success"
						className={classes.toggleBtn}
						onClick={this.handleFindLawyer}
					>
						{isLawyer ? "Declined" : "Find Lawyer"}
					</RButton>
				);
			}
		}

		let btnTitle =
			originator === auth.uid
				? isLawyer
					? case_actions.provider_source_text
					: case_actions.client_source_text
				: isLawyer
				? case_actions.provider_target_text
				: case_actions.client_target_text;

		if (
			!(Case.status.field_end_point_function instanceof Array) &&
			((!isLawyer &&
				!!Case.lawyer &&
				Case.status.field_end_point_function.und[0].value ===
					"case-transfer-pending") ||
				(isLawyer &&
					!Case.lawyer &&
					Case.status.field_end_point_function.und[0].value ===
						"case-transfer-pending"))
		) {
			return (
				<RButton
					size="sm"
					color="success"
					className={classes.toggleBtn}
				>
					{btnTitle}
				</RButton>
			);
		}

		let actionsList =
			originator === auth.uid
				? isLawyer
					? case_actions.provider_source_options
					: case_actions.client_source_options
				: isLawyer
				? case_actions.provider_target_options
				: case_actions.client_target_options;
		if (actionsList) {
			return (
				<ButtonDropdown
					isOpen={this.state.actionsDropdownOpen}
					toggle={this.toggleActions}
				>
					<DropdownToggle
						caret
						size="sm"
						color="success"
						className={classes.toggleBtn}
					>
						{btnTitle}
					</DropdownToggle>
					<DropdownMenu>
						{actionsList.map((action) => {
							//console.log(action);
							if (
								action.field_end_point_function.und[0].value !==
								"case-transfer-pending"
							) {
								return (
									<DropdownItem
										key={action.tid}
										onClick={() =>
											this.handleActionBtnsClick(
												action.field_end_point_function
													.und[0].value
											)
										}
									>
										{
											action.field_future_state_label
												.und[0].value
										}
									</DropdownItem>
								);
							}
						})}
					</DropdownMenu>
				</ButtonDropdown>
			);
		} else {
			return (
				<RButton
					size="sm"
					color="success"
					className={classes.toggleBtn}
				>
					{btnTitle}
				</RButton>
			);
		}
	};

	handlePendingCase = () => {
		this.setState({
			processing: true,
		});
		const { Case } = this.state;

		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("case_nid", Case.case_uid);

		fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/pending`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				//console.log(res);
				if (res.status !== 200 && res.status !== 201) {
					if (Case.case_uid) {
						throw new Error(
							"Failed to pending case " + Case.case_uid
						);
					}
					throw new Error("General failure: " + res.status);
				}

				return res.json();
			})
			.then((resData) => {
				console.log(resData);
				if (resData.data.success) {
					this.setState({
						Case: {
							...this.state.Case,
							case_actions: resData.data.case_actions,
						},
						processing: false,
					});
				} else {
					this.setState({
						processing: true,
					});
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					processing: true,
				});
			});
	};

	handleAcceptCase = () => {
		this.setState({
			processing: true,
		});
		const { Case } = this.state;

		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("case_nid", Case.case_uid);

		fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/accept`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				//console.log(res);
				if (res.status !== 200 && res.status !== 201) {
					if (Case.case_uid) {
						throw new Error(
							"Failed to accept case " + Case.case_uid
						);
					}
					throw new Error("General failure: " + res.status);
				}

				return res.json();
			})
			.then((resData) => {
				console.log(resData);
				if (resData.data.success) {
					this.setState({
						Case: {
							...this.state.Case,
							case_actions: resData.data.case_actions,
						},
						processing: false,
					});
				} else {
					this.setState({
						processing: true,
					});
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					processing: true,
				});
			});
	};

	handleActivateCase = () => {
		this.setState({
			processing: true,
		});
		const { Case } = this.state;

		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("case_nid", Case.case_uid);

		fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/activate`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				//console.log(res);
				if (res.status !== 200 && res.status !== 201) {
					if (Case.case_uid) {
						throw new Error(
							"Failed to actviate case " + Case.case_uid
						);
					}
					throw new Error("General failure: " + res.status);
				}

				return res.json();
			})
			.then((resData) => {
				console.log(resData);
				if (resData.data.success) {
					this.setState({
						Case: {
							...this.state.Case,
							case_actions: resData.data.case_actions,
						},
						processing: false,
					});
				} else {
					this.setState({
						processing: true,
					});
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					processing: true,
				});
			});
	};

	handleHoldCase = () => {
		this.setState({
			processing: true,
		});
		const { Case } = this.state;

		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("case_nid", Case.case_uid);

		fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/hold`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				//console.log(res);
				if (res.status !== 200 && res.status !== 201) {
					if (Case.case_uid) {
						throw new Error(
							"Failed to actviate case " + Case.case_uid
						);
					}
					throw new Error("General failure: " + res.status);
				}

				return res.json();
			})
			.then((resData) => {
				console.log(resData);
				if (resData.data.success) {
					this.setState({
						Case: {
							...this.state.Case,
							case_actions: resData.data.case_actions,
						},
						processing: false,
					});
				} else {
					this.setState({
						processing: true,
					});
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					processing: true,
				});
			});
	};

	handleCancelCase = () => {
		this.setState({
			processing: true,
		});
		const { Case } = this.state;

		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("case_nid", Case.case_uid);

		fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/cancel`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				//console.log(res);
				if (res.status !== 200 && res.status !== 201) {
					if (Case.case_uid) {
						throw new Error(
							"Failed to cancel case " + Case.case_uid
						);
					}
					throw new Error("General failure: " + res.status);
				}

				return res.json();
			})
			.then((resData) => {
				console.log(resData);
				if (resData.data.success) {
					this.setState({
						Case: {
							...this.state.Case,
							case_actions: resData.data.case_actions,
						},
						processing: false,
					});
				} else {
					this.setState({
						processing: true,
					});
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					processing: true,
				});
			});
	};

	handleCloseCase = () => {
		this.setState({
			processing: true,
		});
		const { Case } = this.state;

		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("case_nid", Case.case_uid);

		fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/close`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				//console.log(res);
				if (res.status !== 200 && res.status !== 201) {
					if (Case.case_uid) {
						throw new Error(
							"Failed to close case " + Case.case_uid
						);
					}
					throw new Error("General failure: " + res.status);
				}

				return res.json();
			})
			.then((resData) => {
				console.log(resData);
				if (resData.data.success) {
					this.setState({
						Case: {
							...this.state.Case,
							case_actions: resData.data.case_actions,
						},
						processing: false,
					});
				} else {
					this.setState({
						processing: true,
					});
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					processing: true,
				});
			});
	};

	handleDeclineCase = (decline_data) => {
		this.setState({
			processing: true,
			declineModalOpen: false,
		});
		const { Case } = this.state;

		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("case_nid", Case.case_uid);
		data.append("description", decline_data.comment);
		data.append("reason", decline_data.reason);

		fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/decline`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				//console.log(res);
				if (res.status !== 200 && res.status !== 201) {
					if (Case.case_uid) {
						throw new Error(
							"Failed to decline case " + Case.case_uid
						);
					}
					throw new Error("General failure: " + res.status);
				}

				return res.json();
			})
			.then((resData) => {
				console.log(resData);
				if (resData.data.success) {
					this.props.history.push("/cases");
				} else {
					this.setState({
						processing: true,
					});
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					processing: true,
				});
			});
	};

	handleTransferCase = (transfer_data) => {
		this.setState({
			transferListOpen: false,
			processing: true,
		});
		const { Case } = this.state;

		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("case_nid", Case.case_uid);
		data.append("transfer-lawyer", transfer_data.lawyer);

		fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/transfer`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				//console.log(res);
				if (res.status !== 200 && res.status !== 201) {
					if (Case.case_uid) {
						throw new Error(
							"Failed to transfer case " + Case.case_uid
						);
					}
					throw new Error("General failure: " + res.status);
				}

				return res.json();
			})
			.then((resData) => {
				console.log(resData);
				if (resData.data.success) {
					this.setState({
						Case: {
							...this.state.Case,
							case_actions: resData.data.case_actions,
						},
						processing: false,
					});
				} else {
					this.setState({
						processing: true,
					});
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					processing: true,
				});
			});
	};

	handleToggleDeclineCaseModal = () => {
		this.setState({
			declineModalOpen: !this.state.declineModalOpen,
		});
	};

	getCaseRejectionReasons = () => {
		const { rejectionReasons } = this.props;
		return Object.entries(rejectionReasons)
			.filter(([group, reason]) => reason.name.toLowerCase() === "case")
			.map(([group, reason]) => {
				return reason;
			});
	};

	handleCloseTransferList = () => {
		this.setState({
			transferListOpen: false,
		});
	};

	handleAccpetCaseTransfer = () => {
		this.setState({
			processing: true,
		});
		const { Case } = this.state;

		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("case_nid", Case.case_uid);
		data.append("action", "accept");

		fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/transfer/action`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				//console.log(res);
				if (res.status !== 200 && res.status !== 201) {
					if (Case.case_uid) {
						throw new Error(
							"Failed to accept case " + Case.case_uid
						);
					}
					throw new Error("General failure: " + res.status);
				}

				return res.json();
			})
			.then((resData) => {
				if (resData.data.success) {
					this.setState({
						Case: {
							...this.state.Case,
							case_actions: resData.data.case_actions,
							lawyer: resData.data.lawyer,
						},
						processing: false,
					});
				} else {
					this.setState({
						processing: true,
					});
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					processing: true,
				});
			});
	};

	handleRejectCaseTransfer = () => {
		this.setState({
			processing: true,
		});
		const { Case } = this.state;

		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("case_nid", Case.case_uid);
		data.append("action", "reject");

		fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/transfer/action`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				//console.log(res);
				if (res.status !== 200 && res.status !== 201) {
					if (Case.case_uid) {
						throw new Error(
							"Failed to accept case " + Case.case_uid
						);
					}
					throw new Error("General failure: " + res.status);
				}

				return res.json();
			})
			.then((resData) => {
				if (resData.data.success) {
					this.setState({
						Case: {
							...this.state.Case,
							case_actions: resData.data.case_actions,
							lawyer: resData.data.lawyer,
						},
						processing: false,
					});
				} else {
					this.setState({
						processing: true,
					});
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					processing: true,
				});
			});
	};

	getClientEmployment = () => {
		const { classes } = this.props,
			{
				Case: { client },
			} = this.state;
		if (
			!Array.isArray(client.field_employment_employer) &&
			client.field_employment_employer["und"][0]["value"] &&
			!Array.isArray(client.field_employment_occupation) &&
			client.field_employment_occupation["und"][0]["value"] &&
			!Array.isArray(client.field_employment_address) &&
			client.field_employment_address["und"][0]["value"] &&
			!Array.isArray(client.field_employment_phone) &&
			client.field_employment_phone["und"][0]["value"]
		) {
			return (
				<div style={{ marginTop: 16 }}>
					<Typography paragraph={true} className={classes.label}>
						Employment
					</Typography>
					<div style={{ marginBottom: 16 }}>
						<Typography
							style={{ textTransform: "uppercase", fontSize: 12 }}
							className={classes.label}
						>
							Occupation
						</Typography>
						<Typography style={{ marginBottom: 10 }}>
							{
								client.field_employment_occupation["und"][0][
									"value"
								]
							}
						</Typography>
					</div>

					<div style={{ marginBottom: 16 }}>
						<Typography
							style={{ textTransform: "uppercase", fontSize: 12 }}
							className={classes.label}
						>
							Employer
						</Typography>
						<Typography style={{ marginBottom: 10 }}>
							{
								client.field_employment_employer["und"][0][
									"value"
								]
							}
						</Typography>
					</div>

					<div style={{ marginBottom: 16 }}>
						<Typography
							style={{ textTransform: "uppercase", fontSize: 12 }}
							className={classes.label}
						>
							Address
						</Typography>
						<Typography style={{ marginBottom: 10 }}>
							{client.field_employment_address["und"][0]["value"]}
						</Typography>
					</div>

					<div style={{ marginBottom: 16 }}>
						<Typography
							style={{ textTransform: "uppercase", fontSize: 12 }}
							className={classes.label}
						>
							Phone Number
						</Typography>
						<Typography style={{ marginBottom: 10 }}>
							{phoneFormatter(
								client.field_employment_phone["und"][0]["value"]
							)}
						</Typography>
					</div>
				</div>
			);
		} else {
			return null;
		}
	};

	render() {
		if (this.state.loading) {
			return <Loading />;
		}

		if (this.state.error) {
			return <Alert color="danger">Failed load case</Alert>;
		}
		const { classes, isLawyer, all_billing_types } = this.props,
			{
				submited,
				editMode,
				Case,
				declineModalOpen,
				transferListOpen,
				transferLawyers,
			} = this.state;

		let caseActionBtns = this.getActionBtns();
		const caseRejectionReasons = this.getCaseRejectionReasons();

		let _all_billing_types = Object.entries(all_billing_types);
		/*
                const billing_types = (isLawyer && this.state.init_billing_type === "Hourly" && editMode)
                    ? (
                        <FormControl margin="normal" fullWidth>
                            <RadioGroup
                                value={Case.case_billing_type.value}
                                onChange={this.handleRadioChange('case_billing_type')}
                            >
                                {_all_billing_types.map(([group, type]) => {
                                    return (
                                        <FormControlLabel key={group} value={type.value} control={<Radio />} label={type.name} />
                                    )
                                })}
                            </RadioGroup>
                        </FormControl>
                    )
                    : (
                        <FormControl margin="normal" fullWidth>
                            <TextValidator
                                fullWidth
                                value={this.state.init_billing_type}
                                disabled={true}
                            />
                        </FormControl>
                    );
        */
		const billing_types_fields =
			Case.case_billing_type.name === "Contingency" ||
			Case.case_billing_type.name === "Pro Bono" ? (
				<FormControl margin="normal" fullWidth>
					<TextValidator
						fullWidth
						value={Case.field_case_end_date}
						name="field_case_end_date"
						disabled={true}
						type="date"
					/>
				</FormControl>
			) : null;

		return (
			<Fragment>
				<TransactionsStickyHeader
					//onMenuSelect={this.handleMenuSelect}
					activeMenu={2}
				/>
				<div className="page--wrapper">
					{this.state.processing && <Processing />}
					<Paper className={classes.paper}>
						<ValidatorForm
							encType="multipart/form-data"
							ref="form"
							onSubmit={this.handleSubmit}
						>
							<Actions
								changeEditMode={this.changeEditMode}
								submited={submited}
								editMode={editMode}
								canEdit={isLawyer && !!Case.no_conflict}
							/>

							<FormControl margin="normal" fullWidth>
								<TextValidator
									label="CASE ID"
									placeholder="Case ID Number"
									fullWidth
									value={Case.idNumber}
									disabled={true}
								/>
							</FormControl>

							<FormControl margin="normal" fullWidth>
								<TextValidator
									label="CASE TITLE"
									placeholder="Case Name"
									fullWidth
									value={Case.case_title}
									onChange={this.handleInputChange(
										"case_title"
									)}
									name="case_title"
									validators={["required"]}
									errorMessages={["Case Name is required"]}
									disabled={!editMode}
								/>
							</FormControl>

							<FormControl margin="normal" fullWidth>
								<TextValidator
									label="OPPOSING PARTY"
									placeholder="Please identify the opposing party"
									fullWidth
									multiline
									rows="2"
									value={Case.case_opposition}
									onChange={this.handleInputChange(
										"case_opposition"
									)}
									name="case_opposition"
									disabled={!editMode}
								/>
							</FormControl>
							{(!!Case.no_conflict || !isLawyer) && (
								<FormControl
									margin="normal"
									fullWidth
									style={{ marginBottom: 25 }}
								>
									<TextValidator
										label="DESCRIPTION"
										placeholder="Description"
										fullWidth
										multiline
										rows="4"
										value={Case.case_body}
										onChange={this.handleInputChange(
											"case_body"
										)}
										name="case_body"
										validators={["required"]}
										errorMessages={[
											"Case Description is required",
										]}
										disabled={!editMode}
									/>
								</FormControl>
							)}

							{!Case.no_conflict && isLawyer && (
								<FormControl
									margin="normal"
									style={{ marginBottom: 25 }}
								>
									<Typography className={classes.label}>
										Description
									</Typography>
									<Button
										color="primary"
										variant="contained"
										onClick={this.handleRevealCase}
									>
										Reveal
									</Button>
								</FormControl>
							)}

							<FormControl margin="normal" fullWidth>
								<TextValidator
									label="JURISDICTION"
									placeholder="JURISDICTION"
									fullWidth
									value={Case.jurisdiction}
									disabled={true}
								/>
							</FormControl>

							<FormControl
								margin="normal"
								fullWidth
								style={{ marginBottom: 25 }}
							>
								<TextValidator
									label="PRACTICE AREA"
									placeholder="PRACTICE AREA"
									fullWidth
									value={Case.practice_area}
									disabled={true}
								/>
							</FormControl>

							<FormControl margin="normal" fullWidth>
								<TextValidator
									label="CASE BILLING"
									fullWidth
									value={this.state.init_billing_type}
									disabled={true}
								/>
							</FormControl>

							<div style={{ marginTop: 16 }}>
								<Typography
									paragraph={true}
									className={classes.label}
								>
									Case Status
								</Typography>
								{Case.action.status_text.trim() !== "" && (
									<Typography paragraph={true}>
										{Case.action.status_text}
									</Typography>
								)}

								{caseActionBtns}
							</div>

							{billing_types_fields}
							<div style={{ marginTop: 16 }}>
								<Typography
									paragraph={true}
									className={classes.label}
								>
									{isLawyer
										? "Client Details"
										: "Lawyer Details"}{" "}
								</Typography>
								{isLawyer && (
									<Fragment>
										<Typography
											style={{ marginBottom: 10 }}
										>
											{Case.client.field_first_name[
												"und"
											][0]["value"] +
												" " +
												Case.client.field_last_name[
													"und"
												][0]["value"]}
										</Typography>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												marginBottom: 10,
											}}
										>
											<PhoneIcon
												fontSize="small"
												style={{ marginRight: "5px" }}
											/>
											<Typography>
												{
													Case.client.field_telephone[
														"und"
													][0]["value"]
												}
											</Typography>
										</div>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												marginBottom: 10,
											}}
										>
											<EmailIcon
												fontSize="small"
												style={{ marginRight: "5px" }}
											/>
											<Typography>
												{Case.client.mail}
											</Typography>
										</div>
										<Typography
											style={{
												textTransform: "uppercase",
												marginBottom: 10,
												fontSize: 12,
											}}
											className={classes.label}
										>
											Client ADDRESS
										</Typography>
										{Case.client.hasOwnProperty(
											"field_address"
										) &&
											!Array.isArray(
												Case.client.field_address
											) &&
											Case.client.field_address["und"][0][
												"value"
											] && (
												<Typography>
													{
														Case.client
															.field_address[
															"und"
														][0]["value"]
													}
												</Typography>
											)}
										{Case.client.hasOwnProperty(
											"field_address_2"
										) &&
											!Array.isArray(
												Case.client.field_address_2
											) &&
											Case.client.field_address_2[
												"und"
											][0]["value"] && (
												<Typography>
													{
														Case.client
															.field_address_2[
															"und"
														][0]["value"]
													}
												</Typography>
											)}
										{Case.client.hasOwnProperty(
											"field_address_city"
										) &&
											!Array.isArray(
												Case.client.field_address_city
											) &&
											Case.client.field_address_city[
												"und"
											][0]["value"] && (
												<Typography>
													{
														Case.client
															.field_address_city[
															"und"
														][0]["value"]
													}
													, {Case.client.local.name}
												</Typography>
											)}
										{Case.client.hasOwnProperty(
											"field_postal_code"
										) &&
											!Array.isArray(
												Case.client.field_postal_code
											) &&
											Case.client.field_postal_code[
												"und"
											][0]["value"] && (
												<Typography>
													{
														Case.client
															.field_postal_code[
															"und"
														][0]["value"]
													}
												</Typography>
											)}
									</Fragment>
								)}

								{!isLawyer && !!Case.lawyer && (
									<Fragment>
										<Typography
											style={{ marginBottom: 10 }}
										>
											{Case.lawyer.field_first_name[
												"und"
											][0]["value"] +
												" " +
												Case.lawyer.field_last_name[
													"und"
												][0]["value"]}
										</Typography>
										{Case.client.hasOwnProperty(
											"field_telephone"
										) &&
											!Array.isArray(
												Case.client.field_telephone
											) &&
											Case.client.field_telephone[
												"und"
											][0]["value"] && (
												<div
													style={{
														display: "flex",
														alignItems: "center",
														marginBottom: 10,
													}}
												>
													<PhoneIcon
														fontSize="small"
														style={{
															marginRight: "5px",
														}}
													/>
													<Typography>
														{
															Case.lawyer
																.field_telephone[
																"und"
															][0]["value"]
														}
													</Typography>
												</div>
											)}
										<div
											style={{
												display: "flex",
												alignItems: "center",
												marginBottom: 10,
											}}
										>
											<EmailIcon
												fontSize="small"
												style={{ marginRight: "5px" }}
											/>
											<Typography>
												{Case.lawyer.mail}
											</Typography>
										</div>
										{!!Case.rate
											.field_lawyer_practice_area_rate_value && (
											<Typography>
												rate: $
												{
													Case.rate
														.field_lawyer_practice_area_rate_value
												}
											</Typography>
										)}
									</Fragment>
								)}
							</div>
							{isLawyer && this.getClientEmployment()}
						</ValidatorForm>
					</Paper>
				</div>

				<TransferLawyersList
					open={transferListOpen}
					handleClose={this.handleCloseTransferList}
					lawyers={transferLawyers}
					transfer={this.handleTransferCase}
				/>

				{declineModalOpen && (
					<CancelationModal
						open={declineModalOpen}
						handleClose={() => this.handleToggleDeclineCaseModal()}
						onSubmit={this.handleDeclineCase}
						title={Case.case_title}
						rejectionReasons={caseRejectionReasons[0]}
						data={{
							comment: "",
							uuid: Case.case_uid,
							reason: "",
						}}
					/>
				)}
			</Fragment>
		);
	}
}

CaseOverviewPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	return {
		isLawyer: state.headerReducer.user.is_lawyer,
		all_billing_types: state.utility.billing_types,
		auth: state.auth,
		rejectionReasons: state.utility.rejectionReasons,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchUtilityBillingTypes: () => dispatch(fetchUtilityBillingTypes()),
		logout: () => dispatch({ type: LOGOUT, payload: true }),
	};
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps)
)(CaseOverviewPage);
//export default withStyles(styles)(CaseOverviewPage);
