import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Loading, PageHading } from '../Components/Layouts';
import { Paper, Typography, Divider } from '@material-ui/core';

import compose from 'recompose/compose';
import { connect } from "react-redux";
import { fetchHeaderData } from '../redux/actions/headerActions';
import { LOGOUT } from '../redux/actions/actionTypes';

const styles = theme => ({
    Paper: {
        overflow: 'hidden',
        padding: '8px 20px',
    },
    detailGroup: {
        marginBottom: 15,
    },
    caption: {
        fontSize: 10,
        color: '#A0AAB4'
    },
    tableCell: {
        border: 'none',
    }
});

class NoteDetailPage extends Component {


    state = {
        note: {},
        loading: true,
    }

    componentDidMount() {
        const { match: { params: { id, pmid } } } = this.props;

        //console.log(id, pmid);
        this.getNote(id, pmid);
    }

    getNote(uid, pmid) {
        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('uuid', uid);
        
        if(pmid){
            data.append('pmid', pmid);
        }

        fetch(`${process.env.REACT_APP_API_HOST_URL}/note`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('failed');
                }

                return res.json();
            })
            .then(resData => {
                //console.log("note", resData);
                if (resData.success) {
                    this.setState({
                        note: resData.data,
                        loading: false,
                    }, () => {
                        if (pmid) {
                            this.props.fetchHeaderData(data);
                        }
                    })
                }

                if (!resData.success && (resData.message === 'Session too old.' || resData.message === 'No session found.')) {
                    this.props.logout();
                }
            })
            .catch(err => {
                //console.log(err);
            });
    };

    render() {
        if (this.state.loading) {
            return <Loading />
        }

        const { classes } = this.props;
        const { note } = this.state;

        //console.log(note);

        return (
            <div className="page--wrapper--no-margin">
                <Paper className={classes.Paper}>
                    <PageHading title={"Note Details"} history={this.props.history} />

                    <Typography variant="h6">
                        {note.title}
                    </Typography>
                    <Typography variant="subtitle1">
                        Author: {note.author_name}
                    </Typography>
                    <Divider/>
                    <div dangerouslySetInnerHTML={{__html: note.body}}>
                    </div>

                </Paper>
            </div>
        )
    }
}

NoteDetailPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchHeaderData: (data) => { dispatch(fetchHeaderData(data)) },
        logout: () => dispatch({ type: LOGOUT, payload: true }),
    }
};

export default compose(
    withStyles(styles),
    connect(null, mapDispatchToProps),
)(NoteDetailPage);
