import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography,
} from "@material-ui/core";

const styles = (theme) => ({
    label: {
        fontWeight: 500,
        color: "#999999",
        textTransform: "uppercase",
    },
    formControl: {
        margin: theme.spacing.unit * 2,
    },
    formLabel: {
        lineHeight: "27px",
        color: "#333333!important",
    },
});

const ConsultationOptions = (props) => {
    console.log(props);

    const handleChange = (name) => ({ target: { value } }) => {
        props.handleInputChange(name, value);
    };

    return (
        <Fragment>
            <FormControl
                component="fieldset"
                className={props.classes.formControl}
            >
                <FormLabel
                    component="legend"
                    className={props.classes.formLabel}
                >
                    Choose how you will meet with your lawyer for your initial
                    consultation:
                </FormLabel>
                <RadioGroup
                    name="connection_option"
                    className={props.classes.group}
                    //value={this.state.value}
                    onChange={handleChange("connection_option")}
                >
                    {props.connect_options.map((option) => {
                        if (option.is_published) {
                            return (
                                <FormControlLabel
                                    key={option.tid}
                                    value={option.tid}
                                    control={<Radio color="primary" />}
                                    label={option.display_text}
                                />
                            );
                        }
                    })}
                </RadioGroup>
            </FormControl>
            <Typography style={{ paddingLeft: 16, paddingRight: 16 }}>
                *Video calls on mobile device are not available for iPhone users
            </Typography>
        </Fragment>
    );
};

export default withStyles(styles)(ConsultationOptions);
