import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FormControl, Grid } from '@material-ui/core';
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

const createOptions = () => {
    return {
        style: {
            base: {
                fontSize: '16px',
                color: '#424770',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#c23d4b',
            },
        },
    };
};

const styles = theme => ({
    root: {
        paddingRight: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 2,
        paddingTop: 20,
    },
    formGroup: {
        marginBottom: 10,
    },
    label: {
        fontWeight: 500,
        color: '#999999',
        textTransform: 'uppercase'
    },
    CardLabel: {
        color: '#999999',
        fontSize: 10,
        textTransform: 'uppercase'
    },
});

const StripeBlock = (props) => {
    const { classes } = props;
    return (
        <Fragment>
            <FormControl margin="normal" fullWidth>
                <label className={classes.CardLabel}>CARD NUMBER</label>
                <CardNumberElement
                    {...createOptions()}
                />
            </FormControl>
            <Grid container spacing={8}>
                <Grid item xs={6}>
                    <FormControl margin="normal" fullWidth>
                        <label className={classes.CardLabel}>EXPIRATION</label>
                        <CardExpiryElement
                            {...createOptions()}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl margin="normal" fullWidth>
                        <label className={classes.CardLabel}>CVC</label>
                        <CardCvcElement
                            {...createOptions()}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Fragment>
    );
}

StripeBlock.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StripeBlock);