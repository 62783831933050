import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { Link } from "react-router-dom";

import RequiresAttentionItem from "../Components/RequiresAttentionItem";
import {
	ConfirmConsultModal,
	Loading,
	NotificationSnackbar,
	Processing,
} from "../Components/Layouts";
import {
	fetchRequiredAttentions,
	acceptRequiredAttention,
	cancelRequiredAttention,
	rejectRequiredAttention,
	closeRequiredAttention,
} from "../redux/actions/dashboardActions";
import {
	AUTH_REMOVE_CASE_POPUP,
	DASHBOARD_HIDE_SNACKBAR,
} from "../redux/actions/actionTypes";

const styles = (theme) => ({
	Paper: {
		marginBottom: 10,
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		fontSize: 11,
		// paddingBottom: theme.spacing.unit * 2
	},
	top: {
		top: theme.spacing.unit * 10,
	},
	bottom: {
		bottom: theme.spacing.unit * 10,
	},
});

class DashboardPage extends Component {
	state = {
		// attentions: [],
		// loading: true,
		notificationSnackbar: {
			open: false,
			variant: "",
			message: "",
		},
		confirmConsultOpen: false,
	};

	componentDidMount() {
		const { match, auth } = this.props;
		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		if (match.params.id) {
			data.append("case_uuid", match.params.id);
		}

		this.props.fetchRequiredAttentions(data);
		if (!!auth.case_popup) {
			this.setState({ confirmConsultOpen: true });
		}
	}

	handleShowSnackbar = (options) => {
		this.setState({
			notificationSnackbar: {
				open: true,
				variant: options.variant,
				message: options.message,
			},
		});
	};

	handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		this.setState({
			notificationSnackbar: {
				open: false,
				variant: "",
				message: "",
			},
		});
		this.props.hideSnackbar();
	};

	handleReuiresAttentionCancel = (uuid) => {
		this.setState(({ attentions }) => ({
			attentions: attentions.filter((ex) => ex.uuid !== uuid),
		}));
	};

	handleCloseConfirmationModal = () => {
		this.setState({ confirmConsultOpen: false });
		this.props.removeConfirmPopup();
	};

	render() {
		//console.log(this.props.dashboard.loaded);
		if (!this.props.dashboard.loaded) {
			return <Loading />;
		}
		const {
			classes,
			rejectionReasons,
			dashboard: { attentions, processing, notificationSnackbar },
			auth: { is_profile_completed, case_popup },
			cancelRequiredAttention,
			acceptRequiredAttention,
			rejectRequiredAttention,
			closeRequiredAttention,
		} = this.props;
		console.log(attentions);
		//const { notificationSnackbar } = this.state;

		if (attentions.length < 1) {
			return (
				<Fragment>
					{!is_profile_completed && (
						<Paper
							className={classes.Paper}
							style={{
								position: "absolute",
								color: "rgb(63, 81, 181)",
								textAlign: "center",
								left: 20,
								right: 20,
								top: "12%",
							}}
						>
							<h5>
								Go to{" "}
								<Link
									to="/profile"
									style={{ color: "rgb(63, 81, 181)" }}
								>
									'Account Settings’
								</Link>{" "}
								to complete your profile and billing details.
							</h5>
						</Paper>
					)}
					<Paper
						className={classes.Paper}
						style={{
							position: "absolute",
							color: "rgb(63, 81, 181)",
							textAlign: "center",
							left: 20,
							right: 20,
							top: "40%",
							transform: "translate(0, -50%)",
						}}
					>
						<h5>
							Appointments to accept, meetings to join and other
							case activities requiring your attention will appear
							here.
						</h5>
					</Paper>
					<Paper
						className={classes.Paper}
						style={{
							position: "fixed",
							bottom: "32px",
							color: "rgb(63, 81, 181)",
							textAlign: "center",
							left: 20,
							right: 20,
							transform: "translate(0, -50%)",
						}}
					>
						<p>
							Use the menu below to access case details,
							appointments and tasks, files and messages
						</p>
					</Paper>
				</Fragment>
			);
		}

		console.log(attentions);
		return (
			<Fragment>
				<div className="page--wrapper">
					{processing && <Processing />}
					{notificationSnackbar.open && (
						<NotificationSnackbar
							snackbarOptions={notificationSnackbar}
							handleClose={this.handleSnackbarClose}
						/>
					)}
					{!is_profile_completed && (
						<Paper
							className={classes.Paper}
							style={{
								color: "rgb(63, 81, 181)",
								textAlign: "center",
							}}
						>
							<h5>
								Go to{" "}
								<Link
									to="/profile"
									style={{ color: "rgb(63, 81, 181)" }}
								>
									'Account Settings’
								</Link>{" "}
								to complete your profile and billing details.
							</h5>
						</Paper>
					)}
					{attentions.map((attention) => {
						return (
							<RequiresAttentionItem
								key={attention.uuid}
								attention={attention}
								rejectionReasons={rejectionReasons}
								handleShowSnackbar={this.handleShowSnackbar}
								//onAttentionCancel={cancelRequiredAttention}
								acceptRequiredAttention={
									acceptRequiredAttention
								}
								cancelRequiredAttetion={cancelRequiredAttention}
								rejectRequiredAttention={
									rejectRequiredAttention
								}
								closeRequiredAttention={closeRequiredAttention}
								isLawyer={this.props.isLawyer}
								auth={this.props.auth}
								user={this.props.user}
							/>
						);
					})}
					{!!this.state.confirmConsultOpen && (
						<ConfirmConsultModal
							open={this.state.confirmConsultOpen}
							handleClose={this.handleCloseConfirmationModal}
							data={case_popup}
						/>
					)}
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		dashboard: state.dashboard,
		auth: state.auth,
		user: state.headerReducer.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchRequiredAttentions: (data) =>
			dispatch(fetchRequiredAttentions(data)),
		acceptRequiredAttention: (type, attention_uuid, data) =>
			dispatch(acceptRequiredAttention(type, attention_uuid, data)),
		cancelRequiredAttention: (type, attention_uuid, data) =>
			dispatch(cancelRequiredAttention(type, attention_uuid, data)),
		rejectRequiredAttention: (type, attention_uuid, data) =>
			dispatch(rejectRequiredAttention(type, attention_uuid, data)),
		closeRequiredAttention: (type, attention_uuid, data) =>
			dispatch(closeRequiredAttention(type, attention_uuid, data)),
		hideSnackbar: () => dispatch({ type: DASHBOARD_HIDE_SNACKBAR }),
		removeConfirmPopup: () => dispatch({ type: AUTH_REMOVE_CASE_POPUP }),
	};
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps)
)(DashboardPage);
//export default withStyles(styles)(DashboardPage);
