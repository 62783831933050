import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ListItem, ListItemText, ListItemSecondaryAction, IconButton, Typography, Link } from '@material-ui/core';
import {
    Edit as EditIcon,
    ShareOutlined as ShareOutlinedIcon,
    DeleteOutline as DeleteOutlineIcon
} from '@material-ui/icons';
import { Link as RouterLink, withRouter } from 'react-router-dom';

const styles = theme => ({
    avatar: {
        borderRadius: 0,
        height: 16,
        width: 16,
    },
    primary: {
        color: '#220D89',
        fontSize: 13,
    },
    secondary: {
        color: '#A0AAB4',
        fontSize: 10,
    },
    popper: {
        zIndex: 1,
    },
    icon: {
        color: '#220D89'
    }
});

class NoteItem extends Component {

    render() {
        const { classes, note, deleteNote, shareNote } = this.props;
        return (
            <ListItem alignItems="flex-start">
                <ListItemText>
                    <Typography variant="subheading" className={classes.primary}>
                        <Link component={RouterLink} color="primary" to={`/note/${note.uuid}`} className={classes.primary}>
                            {note.title}
                        </Link>

                    </Typography>
                    <Typography variant="body1" className={classes.secondary}>
                        {`Created: ${note.time_spa}`}
                    </Typography>
                </ListItemText>
                {
                    !note.isShared &&
                    <ListItemSecondaryAction>
                        <IconButton onClick={() => this.props.history.push(`/note/edit/${note.uuid}`)}>
                            <EditIcon fontSize="small" color="primary" />
                        </IconButton>
                        <IconButton onClick={() => shareNote(note.uuid)}>
                            <ShareOutlinedIcon fontSize="small" color="primary" />
                        </IconButton>
                        <IconButton onClick={() => deleteNote(note.uuid, note.id)}>
                            <DeleteOutlineIcon fontSize="small" color="primary" />
                        </IconButton>
                    </ListItemSecondaryAction>
                }
            </ListItem>
        )
    }
}

NoteItem.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(NoteItem));
