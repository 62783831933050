import { TRANSACTIONS_FETCH_BEGINING_DATA, TRANSACTIONS_SHOW_PROCESSING, TRANSACTIONS_ACCEPT_TRANSACTION, TRANSACTIONS_CANCEL_TRANSACTION, TRANSACTIONS_REJECT_TRANSACTION, LOGOUT } from "./actionTypes";

export const fetchTransactionsList = (case_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            const response = await qaseApi.getTransactions(case_uuid, data);
            dispatch({
                type: TRANSACTIONS_FETCH_BEGINING_DATA,
                payload: response.data
            });
        } catch (e) {
            console.error(e);
            if (e.message === 'Session too old.' || e.message === 'No session found.') {
                dispatch({
                    type: LOGOUT,
                    payload: true,
                });
            }
        }
    }
};

export const acceptDisbTransaction = (transaction_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            dispatch({ type: TRANSACTIONS_SHOW_PROCESSING })
            const response = await qaseApi.acceptDisbTransaction(data);
            //console.log(response.data);
            if (response.data.success) {
                dispatch({
                    type: TRANSACTIONS_ACCEPT_TRANSACTION,
                    payload: {
                        transaction_uuid: transaction_uuid,
                        data: response.data,
                    }
                });
            }
        }
        catch (e) {
            console.error(e);
        }
    }
};

export const cancelDisbTransaction = (transaction_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            dispatch({ type: TRANSACTIONS_SHOW_PROCESSING })
            const response = await qaseApi.cancelDisbTransaction(data);
            dispatch({
                type: TRANSACTIONS_CANCEL_TRANSACTION,
                payload: {
                    transaction_uuid: transaction_uuid,
                    data: response.data,
                }
            });
        }
        catch (e) {
            console.error(e);
        }
    }
};

export const rejectDisbTransaction = (transaction_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            dispatch({ type: TRANSACTIONS_SHOW_PROCESSING })
            const response = await qaseApi.rejectDisbTransaction(data);
            dispatch({
                type: TRANSACTIONS_REJECT_TRANSACTION,
                payload: {
                    transaction_uuid: transaction_uuid,
                    data: response.data,
                }
            });
        }
        catch (e) {
            console.error(e);
        }
    }
};