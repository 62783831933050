import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListSubheader, ListItemAvatar, Avatar, ListItemText, Radio, Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import LawyerBio from './LawyerBio';

const styles = theme => ({
    label: {
        fontWeight: 500,
        color: '#999999',
        textTransform: 'uppercase'
    }
});

function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

class LawyerList extends Component {


    handleChange = name => ({ target: { value } }) => {
        this.props.handleInputChange(name, value);
    };

    handleShowBio = (lawyer_uuid) => {
        this.props.handleShowLawyerBio(lawyer_uuid);
    };

    getLawyers = () => {
        const { newCase } = this.props;
        let temp_arr = [];
        let date_formated = moment(newCase.date).format('YYYY-MM-DD');
        let available = newCase.calendar_data.available[date_formated];
        let time_start = newCase.start;
        let time_end = moment(time_start, 'HH:mm').add(newCase.default_duration, 'minutes').format('HH:mm')
        if (available) {
            available.forEach((a_item) => {
                if (
                    a_item.start <= time_start
                    && a_item.end >= time_end
                ) {
                    for (var i = 0; i < a_item.uuid.length; i++) {
                        if (!temp_arr.includes(a_item.uuid[i])) {
                            temp_arr.push(a_item.uuid[i]);
                        }
                    }
                }
            });
        }

        if (temp_arr.length > 0) {
            let lawyerList = temp_arr.map(tmp => {
                let lawyer = newCase.calendar_data.lawyer[tmp];
                let isValidUrl = validURL(lawyer.image);
                //console.log(isValidUrl);
                return (
                    <ListItem key={tmp}>
                        <Radio
                            color="primary"
                            checked={newCase.lawyer === tmp}
                            value={tmp}
                            onChange={this.handleChange('lawyer')}
                            style={{
                                paddingLeft: 0,
                            }}
                        />
                        <ListItemAvatar>
                            <Avatar src={isValidUrl ? lawyer.image : process.env.REACT_APP_DRUPAL_HOST_URL + lawyer.image} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={lawyer.name}
                            secondary={
                                <Typography color="primary" style={{ display: 'inline' }} component="span"
                                    onClick={() => this.handleShowBio(tmp)}
                                >
                                    Read Bio
                                </Typography>
                            }
                        />
                    </ListItem>
                )
            });

            return lawyerList;
        }

        return null;
    };

    render() {
        const { classes, newCase } = this.props;

        if (newCase.isBioOpen) {
            return <LawyerBio
                newCase={newCase}
                handleCloseBio={this.props.handleHideLawyerBio}
                handleSelectLawyer={this.props.handleInputChange}
            />
        }

        const lawyersList = this.getLawyers();
        //console.log(newCase);
        return (
            <Fragment>
                <div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 20 }}>
                    <Typography className={classes.label} gutterBottom>
                        date & time
                    </Typography>
                    <Typography>
                        {moment(newCase.date).format('dddd, MMMM D')}
                    </Typography>
                    <Typography>
                        {`${moment(newCase.start, 'HH:mm').format('hh:mm a')} - ${moment(newCase.start, 'HH:mm').add(newCase.default_duration, 'minutes').format('hh:mm a')}`}
                    </Typography>
                </div>
                <List
                    subheader={<ListSubheader className={classes.label} component="div">Choose Lawyer</ListSubheader>}
                >
                    {lawyersList}
                </List>
            </Fragment>
        )
    }
}

LawyerList.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(LawyerList);