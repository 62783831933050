import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, IconButton, ListItemIcon, MenuItem, Popper, Grow, ClickAwayListener, MenuList, Paper } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { RemoveRedEye, PersonAdd, Delete } from '@material-ui/icons'


const styles = theme => ({
    avatar: {
        borderRadius: 0,
        height: 16,
        width: 16,
    },
    primary: {
        color: '#220D89',
        fontSize: 13,
    },
    secondary: {
        color: '#A0AAB4',
        fontSize: 10,
    },
    popper: {
        zIndex: 1,
    },
    icon: {
        color: '#220D89'
    }
});

class FileItem extends Component {

    state = {
        open: false,
    };

    handleToggle = () => {
        this.setState(state => ({ open: !state.open }));
    };

    handleClose = event => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }
        this.setState({ open: false });
    };

    handleDeleteFile = (fid) => {
        this.setState({ open: false });
        this.props.deleteFile(fid);
    };

    handlesShareFile = (fid) => {
        this.setState({ open: false });
        this.props.shareFile(fid);
    };

    // openFancybox = (fid) => {
    //     const { file, case_uuid } = this.props;
    //     this.setState({ open: false });
    //     if (fileTypesForShow.includes(file.filemime)) {
    //         this.props.openFancybox(fid);
    //     }
    //     else {
    //         this.props.showProcessing();
    //         let data = new FormData();
    //         const userData = JSON.parse(localStorage.getItem("userData"));
    //         data.append('qase_api_token', userData.token);
    //         data.append('sid', userData.sid);

    //         fetch(`${process.env.REACT_APP_API_HOST_URL}/file/load/${case_uuid}/${fid}`, {
    //             //credentials: 'include',
    //             method: 'POST',
    //             body: data,
    //         }).then(response => {
    //             if (response.ok && response.status === 200) {
    //                 return response.blob();
    //             }
    //         }).then(blob => {
    //             var a = document.createElement('a');
    //             var url = URL.createObjectURL(blob);
    //             a.href = url;
    //             a.download = file.file_name;
    //             document.body.append(a);
    //             a.click();
    //             a.remove();
    //             window.URL.revokeObjectURL(url);
    //             this.props.hideProcessing();
    //         });
    //     }

    // };

    getFileIcon(type) {
        switch (type) {
            case 'image/png':
                return require('../../images/fileIcons/png-icon.png');
            case 'image/jpeg':
                return require('../../images/fileIcons/jpg-icon.png');
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return require('../../images/fileIcons/word-icon.png');
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return require('../../images/fileIcons/excel-icon.png');
            case 'application/vnd.ms-powerpoint':
                return require('../../images/fileIcons/ppt-icon.png');
            case 'application/pdf':
                return require('../../images/fileIcons/pdf-icon.png');
            case 'video/quicktime':
            case 'video/mp4':
                return require('../../images/fileIcons/video-icon.png');
            default:
                return require('../../images/fileIcons/default-file-icon.png');
        }
    };

    render() {
        const { classes, file } = this.props;
        const { open } = this.state;
        
        return (
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="file" src={this.getFileIcon(file.filemime)} className={classes.avatar} />
                </ListItemAvatar>
                <ListItemText
                    primary={file.file_name}
                    secondary={`Created: ${file.when_spa}`}
                    classes={{
                        primary: classes.primary,
                        secondary: classes.secondary
                    }}
                >

                </ListItemText>
                <Popper open={open} anchorEl={this.anchorEl} transition disablePortal className={classes.popper}>
                    {({ TransitionProps, placement }) => (

                        <Grow
                            {...TransitionProps}
                            id="menu-list-grow"
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom"
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList>
                                        <MenuItem
                                            onClick={() => this.props.openFancybox(file)}
                                        >
                                            <ListItemIcon className={classes.icon}>
                                                <RemoveRedEye style={{ fontSize: 14 }} color="inherit" />
                                            </ListItemIcon>
                                            <ListItemText classes={{ primary: classes.primary }} primary="Preview" />
                                        </MenuItem>
                                        {!Boolean(Number(file.isShared)) &&
                                            <MenuItem onClick={() => this.handlesShareFile(file.fid)}>
                                                <ListItemIcon className={classes.icon}>
                                                    <PersonAdd style={{ fontSize: 14 }} />
                                                </ListItemIcon>
                                                <ListItemText classes={{ primary: classes.primary }} primary="Share" />
                                            </MenuItem>
                                        }
                                        {!Boolean(Number(file.isShared)) &&
                                            <MenuItem onClick={() => this.handleDeleteFile(file.fid)}>
                                                <ListItemIcon className={classes.icon}>
                                                    <Delete style={{ fontSize: 14 }} />
                                                </ListItemIcon>
                                                <ListItemText classes={{ primary: classes.primary }} inset primary="Delete" />
                                            </MenuItem>
                                        }
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
                <ListItemSecondaryAction>
                    <IconButton
                        buttonRef={node => {
                            this.anchorEl = node;
                        }}
                        onClick={this.handleToggle}
                    >
                        <MoreVert />
                    </IconButton>

                </ListItemSecondaryAction>

            </ListItem>
        )
    }
}

FileItem.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FileItem);
