import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography, IconButton, Avatar, Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

const styles = theme => ({
    paper: {
        overflow: 'hidden',
        padding: '8px 20px',
    },
    root: {
        display: 'flex',
        marginBottom: 15,
    },
    avatar: {
        width: 128,
        height: 128,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    formGroup: {
        marginBottom: 10,
    },
    formGroupLabel: {
        fontSize: 10,
        color: '#A0AAB4',
        textTransform: 'uppercase',
    },
    button: {
        marginLeft: theme.spacing.unit,
    }
});

function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

class LawyerBio extends Component {

    handleSelect = () => {
        const { is_case_transfer, newCase, lawyer_show } = this.props;
        //const { newCase: { bio_uuid } } = this.props;
        let bio_uuid = is_case_transfer ? lawyer_show.uid : newCase.bio_uuid;
        this.props.handleSelectLawyer('lawyer', bio_uuid);
        this.props.handleCloseBio();
    };

    render() {
        const { classes, newCase, is_case_transfer = false, lawyer_show } = this.props;
        let lawyer = is_case_transfer ? lawyer_show : newCase.calendar_data.lawyer[newCase.bio_uuid];
        console.log(lawyer);
        return (
            <Paper className={classes.paper}>
                <Typography gutterBottom>
                    <IconButton aria-label="Delete" className={classes.backArrow} onClick={this.props.handleCloseBio}>
                        <ArrowBack fontSize="small" />
                    </IconButton>
                    {lawyer.name}
                </Typography>

                <div className={classes.root}>
                    <div style={{ marginRight: 15 }}>
                        <Avatar className={classes.avatar} src={validURL(lawyer.image) ? lawyer.image : process.env.REACT_APP_DRUPAL_HOST_URL + lawyer.image} />
                    </div>
                    <div style={{ flexGrow: 1 }}>
                        {(lawyer.degree && lawyer.degree.trim()) &&
                            <div className={classes.formGroup}>
                                <Typography
                                    variant="caption"
                                    classes={{
                                        caption: classes.formGroupLabel,
                                    }}
                                >
                                    Degree :
                                </Typography>
                                <Typography>
                                    {lawyer.degree}
                                </Typography>
                            </div>
                        }

                        {lawyer.practice_start_date &&
                            <div className={classes.formGroup}>
                                <Typography
                                    variant="caption"
                                    classes={{
                                        caption: classes.formGroupLabel,
                                    }}
                                >
                                    Practicing Since:
                            </Typography>
                                <Typography>
                                    {lawyer.practice_start_date}
                                </Typography>
                            </div>
                        }

                        {lawyer.rate &&
                            <div className={classes.formGroup}>
                                <Typography
                                    variant="caption"
                                    classes={{
                                        caption: classes.formGroupLabel,
                                    }}
                                >
                                    Rate:
                            </Typography>
                                <Typography>
                                    $ {lawyer.rate}
                            </Typography>
                            </div>
                        }
                    </div>
                </div>

                <Typography gutterBottom>
                    {lawyer.description.text}
                </Typography>

                <div className={classes.root} style={{ justifyContent: 'flex-end' }}>
                    <Button className={classes.button} variant="contained" size="small" onClick={this.props.handleCloseBio}>
                        Back
                    </Button>
                    <Button className={classes.button} variant="contained" size="small" color="primary" onClick={this.handleSelect}>
                        Select
                    </Button>
                </div>
            </Paper>
        )
    }
}

LawyerBio.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LawyerBio);