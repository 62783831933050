import React, { Component, Fragment } from "react";

import {
	Dialog,
	DialogContent,
	DialogTitle,
	withStyles,
	Button,
	Typography,
	IconButton,
	FormControl,
	Select,
	List,
	ListItem,
	Avatar,
	ListItemText,
} from "@material-ui/core";
import classNames from "classnames";
import { checkUrl } from '../../utils/helpers';

import CloseIcon from "@material-ui/icons/Close";
import { AddCircleOutline } from "@material-ui/icons";
import InfoIcon from "../../images/noun_Info.svg";
import SelectLawyerModalLawyerItem from "./SelectLawyerModalLawyerItem";

class SelectLawyerModal extends Component {
	state = {
		practice_area: this.props.defaultSelectedArea,
		showState: 1,
		selectLawyersList: [],
		selectedLawyer: false,
	};

	handleChange = (name) => ({ target: { value } }) => {
		this.setState({
			[name]: value,
		});
	};

	getAvailableLawyersForSelect = () => {
		//const { auth } = this.props;
		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("case_nid", this.props.case_nid);
		data.append("is_transfer", false);
		data.append("practice_area", this.state.practice_area);

		fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/transfer_lawyers`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				//console.log(res);
				if (res.status !== 200 && res.status !== 201) {
					// if (auth.case_uid) {
					// 	throw new Error('Failed to accept case ' + auth.case_uid);
					// }
					throw new Error("General failure: " + res.status);
				}

				return res.json();
			})
			.then((resData) => {
				console.log(resData);
				if (resData.success) {
					this.setState({
						showState: 2,
						selectLawyersList: resData.data,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	handleSelectLawyer = (lawyer_uid) => {
		this.setState({
			selectedLawyer: lawyer_uid,
		})
	}

	handleSelectLawyerRequest = () => {

		if (!!this.state.selectedLawyer) {
			const userData = JSON.parse(localStorage.getItem("userData"));
			let data = new FormData();
			data.append("qase_api_token", userData.token);
			data.append("sid", userData.sid);
			data.append("case_nid", this.props.case_nid);
			data.append("transfer-lawyer", this.state.selectedLawyer);
			data.append("case_practice_area", this.state.practice_area);
			fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/select_lawyer`, {
				method: "POST",
				body: data,
			})
				.then((res) => {
					//console.log(res);
					if (res.status !== 200 && res.status !== 201) {
						// if (auth.case_uid) {
						// 	throw new Error('Failed to accept case ' + auth.case_uid);
						// }
						throw new Error("General failure: " + res.status);
					}
					return res.json();
				})
				.then((resData) => {
					console.log(resData);
					if (resData.success) {
						this.setState({ showState: 3 });
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	render() {
		const {
			classes,
			open,
			handleClose,
			title,
			practice_areas,
		} = this.props;

		const { showState, selectLawyersList, selectedLawyer } = this.state;

		return (
			<Dialog
				onClose={handleClose}
				fullWidth={true}
				open={open}
				classes={{
					paper: classes.modalPaper,
				}}
			>
				<DialogTitle
					disableTypography
					onClose={handleClose}
					className={classNames(classes.titleRoot, { [classes.titleBorderless]: showState === 3 })}
				>
					<Typography
						variant="h6"
						style={{ fontSize: 16, fontWeight: "bold" }}
					>
						{showState === 3 ? "" : title}
					</Typography>
					<IconButton
						aria-label="Close"
						className={classes.closeButton}
						onClick={handleClose}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent className={classes.contentRoot}>
					{showState === 1 && (
						<Fragment>
							<Typography>
								Continue to view other lawyers who can help you
								with this legal issue, or select a different
								legal issue.
							</Typography>
							<FormControl fullWidth margin="normal">
								<Select
									native
									value={this.state.practice_area}
									onChange={this.handleChange(
										"practice_area"
									)}
								>
									{Object.entries(practice_areas).map(
										([group, area]) => {
											return <optgroup label={area.name} key={group}>
												{
													Object.entries(
														area.children
													).map(
														([child_group, child_area]) => {
															return (
																<option value={child_area.value} key={child_group}>{child_area.name}</option>
															);
														})
												}
											);
											</optgroup>
										}
									)}
								</Select>
							</FormControl>

							<div style={{ textAlign: "right" }}>
								<Button
									variant="contained"
									color="primary"
									className={classes.primaryButton}
									onClick={() =>
										this.getAvailableLawyersForSelect()
									}
								>
									Next
								</Button>
							</div>
						</Fragment>
					)}

					{showState === 2 && (
						<Fragment>
							<Typography style={{ marginBottom: 16 }}>
								Continue to view other lawyers who can help you
								with this legal issue, or select a different
								legal issue.
							</Typography>

							{selectLawyersList.length > 0 && (
								<Fragment>
									<Typography variant="title">
										Choose Lawyer
									</Typography>
									{/* {this.showLawyersList()} */}
									<List>
										{
											selectLawyersList.map((lawyer) => {
												return <SelectLawyerModalLawyerItem key={lawyer.uid} lawyer={lawyer} selectedLawyer={selectedLawyer} selectLawyer={this.handleSelectLawyer} />
											})
										}
									</List>
									<div style={{ textAlign: "right" }}>
										<Button
											variant="contained"
											onClick={handleClose}
											style={{ marginRight: 15, borderRadius: 8 }}
										>
											Cancel
										</Button>
										<Button
											variant="contained"
											color="primary"
											className={classes.primaryButton}
											onClick={this.handleSelectLawyerRequest}
										>
											send request
										</Button>
									</div>
								</Fragment>
							)}

							{selectLawyersList.length == 0 && (
								<Fragment>
									<Typography variant="title">
										No available lawyers
									</Typography>
									<div style={{ textAlign: "right" }}>
										<Button
											variant="contained"
											color="primary"
											className={classes.primaryButton}
											onClick={() =>
												this.setState({
													showState: 1,
												})
											}
										>
											Back
										</Button>
									</div>
								</Fragment>
							)}
						</Fragment>
					)}

					{showState === 3 && (
						<Fragment>
							<div
								style={{
									textAlign: "center",
									marginBottom: 16,
								}}
							>
								<img src={InfoIcon} alt="info" />
							</div>
							<Typography style={{ marginBottom: 40 }}>
								Your request is pending until this lawyer
								reviews and accepts it. Send the lawyer a
								message to confirm for your consultation.
							</Typography>
							<div style={{ textAlign: "right" }}>
								<Button
									variant="contained"
									color="primary"
									className={classes.primaryButton}
									onClick={() =>
										window.location.reload(false)
									}
								>
									Ok
								</Button>
							</div>
						</Fragment>
					)}
				</DialogContent>
			</Dialog >
		);
	};
}

export default withStyles((theme) => ({
	modalPaper: {
		margin: 0,
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing.unit,
		top: theme.spacing.unit,
		color: theme.palette.grey[500],
		padding: 0,
	},
	titleRoot: {
		borderBottom: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing.unit * 2,
		paddingTop: theme.spacing.unit * 4,
	},
	titleBorderless: {
		borderBottom: "none"
	},
	contentRoot: {
		padding: theme.spacing.unit * 2,
	},
	primaryButton: {
		backgroundColor: "#220C89",
		borderRadius: 8
	}
}))(SelectLawyerModal);
