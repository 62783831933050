import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { AppBar, Toolbar } from '@material-ui/core';

const styles = {
    logo: {
        maxHeight: '30px',
    }
};

class SignHeader extends Component {
    render() {
        const { classes } = this.props;

        return (
            <AppBar position="static">
                <Toolbar style={{ justifyContent: 'center' }}>
                    <img src={require('../../images/logo.png')} alt="" className={classes.logo} />
                </Toolbar>
            </AppBar>
        )
    }
}

SignHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignHeader);
