import {
	MESSAGES_FETCH_BEGINING_DATA,
	MESSAGES_ADD_NEW_MESSAGE,
	LOGOUT,
	HEADER_FETCH_BEGINING_DATA,
} from "./actionTypes";

export const fetchMessagessList = (case_uuid, data) => {
	return async (dispatch, getState, qaseApi) => {
		try {
			const response = await qaseApi.getMessages(case_uuid, data);
			dispatch({
				type: MESSAGES_FETCH_BEGINING_DATA,
				payload: response.data,
			});

			const headerResponse = await qaseApi.getHeaderData(data);
			dispatch({
				type: HEADER_FETCH_BEGINING_DATA,
				payload: headerResponse.data,
			});
		} catch (e) {
			console.error(e);
			if (
				e.message === "Session too old." ||
				e.message === "No session found."
			) {
				dispatch({
					type: LOGOUT,
					payload: true,
				});
			}
		}
	};
};

export const addMessage = (case_uuid, data) => {
	return async (dispatch, getState, qaseApi) => {
		try {
			const response = await qaseApi.addMessage(case_uuid, data);
			dispatch({
				type: MESSAGES_ADD_NEW_MESSAGE,
				payload: response.data.message,
			});
		} catch (e) {
			console.error(e);
		}
	};
};
