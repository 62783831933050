import {
    FILES_FETCH_BEGINING_DATA,
    FILES_UPLOAD_CASE_FILE,
    FILES_SHOW_PROCESSING,
    FILES_REMOVE_FILE,
    FILES_SHARE_FILE,
    FILES_SHOW_ERROR,
    LOGOUT
} from './actionTypes';

export const fetchFilesList = (case_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            const response = await qaseApi.getFiles(case_uuid, data);
            //console.log(response);
            dispatch({
                type: FILES_FETCH_BEGINING_DATA,
                payload: response.data
            });
        } catch (e) {
            console.error(e);
            if (e.message === 'Session too old.' || e.message === 'No session found.') {
                dispatch({
                    type: LOGOUT,
                    payload: true,
                });
            }
        }
    }
};

export const uploadCaseFile = (case_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            dispatch({ type: FILES_SHOW_PROCESSING });
            const response = await qaseApi.uploadFile(case_uuid, data);
            if(response.data.success){
                dispatch({
                    type: FILES_UPLOAD_CASE_FILE,
                    payload: response.data.file_info
                });
            }
            else{
                dispatch({
                    type: FILES_SHOW_ERROR,
                    payload: {
                        variant: 'error',
                        message: 'file didn`t uploaded',
                    }
                });
            }
            
        } catch (e) {
            console.error(e)
        }
    }
};

export const deleteCaseFile = (fileid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            dispatch({ type: FILES_SHOW_PROCESSING });
            const response = await qaseApi.deleteFile(fileid, data);
            if (response.data.success) {
                dispatch({
                    type: FILES_REMOVE_FILE,
                    payload: {
                        result: response.data,
                        fileid: fileid,
                    }
                });
            }
            else {
                dispatch({
                    type: FILES_SHOW_ERROR,
                    payload: {
                        variant: 'error',
                        message: 'file didn`t deleted',
                    }
                });
            }

        } catch (e) {
            //console.log('error');
            console.error(e.message)
        }
    }
};

export const shareCaseFile = (fileid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            dispatch({ type: FILES_SHOW_PROCESSING });
            const response = await qaseApi.shareFile(fileid, data);
            if (response.data.success) {
                dispatch({
                    type: FILES_SHARE_FILE,
                    payload: {
                        fileid: fileid,
                    }
                });
            }
            else {
                dispatch({
                    type: FILES_SHOW_ERROR,
                    payload: {
                        variant: 'error',
                        message: 'file didn`t shared',
                    }
                });
            }
        } catch (e) {
            //console.log('error');
            console.error(e.message)
        }
    }
};