import React, { Component, Fragment } from 'react';
import { ListItem, ListItemText, ListItemIcon, Collapse, List, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { AddBox, IndeterminateCheckBox } from '@material-ui/icons';

class LegalIssueListItem extends Component {
    state = {
        open: false,
    };

    componentDidMount() {
        const { newCase: { practice_area }, issue } = this.props;
        //console.log(issue);
        if (issue.hasOwnProperty("children")) {
            let children = Object.entries(issue.children);
            children.forEach(([group, child]) => {
                if (child.value === practice_area) {
                    this.setState({
                        open: true,
                    })
                }
            })
        }
        else {
            if (issue.value === practice_area) {
                this.setState({
                    open: true,
                })
            }
        }

    }

    handleClick = () => {
        this.setState(state => ({ open: !state.open }));
    };

    handleChange = name => ({ target: { value } }) => {
        this.props.handleInputChange(name, value);
    };

    getRadioBtnsGroup = (issue) => {
        const { newCase: { practice_area } } = this.props;
        if (issue.hasOwnProperty("children")) {
            let children = Object.entries(issue.children);
            return (
                <ListItem dense button component="div">
                    <RadioGroup
                        aria-label="practice_area"
                        name="practice_area"
                        value={practice_area}
                        onChange={this.handleChange('practice_area')}
                    >
                        {
                            children.map(([group, child]) => {
                                return <FormControlLabel key={group} value={child.value} control={<Radio color="primary" />} label={child.name} />
                            })
                        }
                    </RadioGroup>
                </ListItem>
            )
        }
        else {
            return (
                <ListItem dense button component="div">
                    <RadioGroup
                        aria-label="practice_area"
                        name="practice_area"
                        value={practice_area}
                        onChange={this.handleChange('practice_area')}
                    >
                        <FormControlLabel value={issue.value} control={<Radio color="primary" />} label={issue.name} />
                    </RadioGroup>
                </ListItem>
            )
        }

    };

    render() {
        const { issue } = this.props;
        //console.log(issue);
        let radioBtnsGroup = this.getRadioBtnsGroup(issue);
        return (
            <Fragment>
                <ListItem button onClick={this.handleClick}>
                    <ListItemIcon>
                        {this.state.open ? <IndeterminateCheckBox /> : <AddBox color="primary" />}
                    </ListItemIcon>
                    <ListItemText primary={issue.name} />
                </ListItem>
                <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding style={{ paddingLeft: 32 }}>
                        {radioBtnsGroup}
                    </List>
                </Collapse>
            </Fragment>
        )
    }
}

export default LegalIssueListItem;