import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Paper, IconButton, InputBase } from "@material-ui/core";
import { Send as SendIcon } from "@material-ui/icons";
import { connect } from "react-redux";
import compose from "recompose/compose";
import {
	fetchMessagessList,
	addMessage,
} from "../redux/actions/messageActions";
import MessageItem from "../Components/MessageItem";
import { Loading } from "../Components/Layouts";

const styles = (theme) => ({
	messageInput: {
		position: "fixed",
		bottom: 72,
		width: "100%",
	},
	root: {
		padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		width: "100%",
		borderRadius: 0,
	},
	input: {
		marginLeft: 8,
		flex: 1,
	},
	iconButton: {
		padding: 10,
	},
});

class MessagesPage extends Component {
	state = {
		message: "",
		_case: null,
	};

	fileInput = React.createRef();

	componentDidMount() {
		let data = new FormData();

		let userData = JSON.parse(localStorage.getItem("userData"));
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);

		this.props.fetchMessagessList(this.props.auth.case_uuid, data);

		this.intervalId = setInterval(() => {
			this.props.fetchMessagessList(this.props.auth.case_uuid, data);
		}, 5000);
		this.getCase(this.props.auth.case_uuid);
	}

	componentWillUnmount() {
		clearInterval(this.intervalId);
	}

	getCase(case_uuid) {
		let data = new FormData();

		let { token, sid } = this.props.auth;

		data.append("qase_api_token", token);
		data.append("sid", sid);

		fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/${case_uuid}`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				//console.log(res);
				if (res.status !== 200 && res.status !== 201) {
					throw new Error("failed");
				}

				return res.json();
			})
			.then((resData) => {
				console.log(resData);
				this.setState({
					_case: resData.data,
				});
			})
			.catch((err) => {
				//console.log(err);
				this.setState({
					error: true,
				});
			});
	}

	handleInputChange =
		(name) =>
		({ target: { value } }) => {
			this.setState({
				[name]: value,
			});
		};

	handleAddMessage = () => {
		const { message } = this.state;
		if (message.trim() !== "") {
			const userData = JSON.parse(localStorage.getItem("userData"));
			const data = new FormData();
			data.append("qase_api_token", userData.token);
			data.append("sid", userData.sid);
			data.append("message", message);

			this.props.addMessage(this.props.auth.case_uuid, data);
			this.setState({
				message: "",
			});
		}
	};

	render() {
		const { classes, messagesList } = this.props,
			{ message, _case } = this.state;
		if (!messagesList.loaded || _case == null) {
			return <Loading />;
		}

		const messagesListView =
			messagesList.messages.length > 0 ? (
				messagesList.messages.map((message, index) => (
					<MessageItem
						message={message}
						key={index}
						timezone={messagesList.timezone}
					/>
				))
			) : (
				<MessageItem message={messagesList.defaultMessage} />
			);

		return (
			<Fragment>
				<div className="page--wrapper--messages">
					<div
						style={{
							width: "calc(100% - 30px)",
							margin: "15px auto",
						}}
					>
						<Typography
							variant="subtitle2"
							style={{ fontWeight: "bold" }}
						>
							Messages
						</Typography>
					</div>

					{messagesListView}
				</div>
				<div className={classes.messageInput}>
					<Paper className={classes.root} elevation={1}>
						<InputBase
							className={classes.input}
							multiline={true}
							rowsMax="4"
							placeholder="Type Message"
							value={message}
							onChange={this.handleInputChange("message")}
							disabled={_case.status_name === "Closed"}
						/>
						<IconButton
							className={classes.iconButton}
							aria-label="Search"
							onClick={this.handleAddMessage}
							disabled={_case.status_name === "Closed"}
						>
							<SendIcon />
						</IconButton>
					</Paper>
				</div>
			</Fragment>
		);
	}
}

MessagesPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	return {
		messagesList: state.message,
		auth: state.auth,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchMessagessList: (case_uuid, data) =>
			dispatch(fetchMessagessList(case_uuid, data)),
		addMessage: (case_uuid, data) => dispatch(addMessage(case_uuid, data)),
	};
};

//export default withStyles(styles)(MessagesPage);
export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps)
)(MessagesPage);
