import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import {
    Phone as PhoneIcon,
    EmailOutlined as EmailIcon
} from '@material-ui/icons';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button as RButton } from 'reactstrap';
import { PageHading, Loading, Processing, CancelationModal } from '../Components/Layouts';
import compose from 'recompose/compose';
import { connect } from "react-redux";
import { fetchHeaderData } from '../redux/actions/headerActions';
import { LOGOUT } from '../redux/actions/actionTypes';
import { TransferLawyersList } from '../Components/Case';
import { Redirect } from "react-router-dom";

const styles = theme => ({
    Paper: {
        overflow: 'hidden',
        padding: '8px 20px',
    },
    detailGroup: {
        marginBottom: 15,
    },
    caption: {
        fontSize: 10,
        color: '#A0AAB4',
        textTransform: 'uppercase',
    },
    tableCell: {
        border: 'none',
    },
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
});

class CaseDetailPage extends Component {

    state = {
        cases: {},
        loading: true,
        actionsDropdownOpen: false,
        declineModalOpen: false,
        transferListOpen: false,
        transferLawyers: [],
    };

    componentDidMount() {
        const { match: { params: { id, pmid } } } = this.props;

        this.getCase(id, pmid);
    }

    getCase(uid, pmid) {
        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        if (pmid) {
            data.append('pmid', pmid);
        }

        fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/${uid}`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('failed');
                }

                return res.json();
            })
            .then(resData => {
                //console.log("Case", resData);
                if (resData.success) {
                    this.setState({
                        cases: resData.data,
                        loading: false,
                    }, () => {
                        if (pmid) {
                            this.props.fetchHeaderData(data);
                        }
                    });
                }

                if (!resData.success && (resData.message == 'Session too old.' || resData.message == 'No session found.')) {
                    this.props.logout();
                }
            })
            .catch(err => {
                //console.log(err);
            });
    };

    getAvailableLawyersListForTransfer = () => {
        const { cases } = this.state;
        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('case_nid', cases.case_uid);
        data.append('is_transfer', false);

        fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/transfer_lawyers`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    if (cases.case_uid) {
                        throw new Error('Failed to load case ' + cases.case_uid);
                    }
                    throw new Error('General failure: ' + res.status);
                }

                return res.json();
            })
            .then(resData => {
                //console.log(resData);
                if (resData.success) {
                    this.setState({
                        transferListOpen: true,
                        transferLawyers: resData.data,
                    });
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    toggleActions = () => {
        this.setState({
            actionsDropdownOpen: !this.state.actionsDropdownOpen
        });
    };

    getActionBtns = () => {

        const { cases, cases: { case_actions, originator, lawyer } } = this.state,
            { auth, isLawyer, classes } = this.props;
        console.log(this.state.cases);
        if (case_actions.name === "Pending" && !lawyer) {
            if (isLawyer) {
                return (
                    <RButton size="sm" color="success" className={classes.toggleBtn} onClick={this.handleFindLawyer}>Declined</RButton>
                );    
            } else {
                // return (
                //     <RButton size="sm" color="success" className={classes.toggleBtn}>Sign into your Qase account on desktop and find a new lawyer</RButton>
                // );
                return <Redirect to="/dashboard"></Redirect>;
            }
            
        }
        let btnTitle = originator === auth.uid ? (isLawyer ? case_actions.provider_source_text : case_actions.client_source_text)
            : (isLawyer ? case_actions.provider_target_text : case_actions.client_target_text);

        if (!(cases.status.field_end_point_function instanceof Array) &&
            ((!isLawyer && !!cases.lawyer && cases.status.field_end_point_function.und[0].value === "case-transfer-pending")
                || (isLawyer && !cases.lawyer && cases.status.field_end_point_function.und[0].value === "case-transfer-pending"))
        ) {
            return (
                <RButton size="sm" color="success" className={classes.toggleBtn}>{btnTitle}</RButton>
            );
        }

        let actionsList = originator === auth.uid ? (isLawyer ? case_actions.provider_source_options : case_actions.client_source_options)
            : (isLawyer ? case_actions.provider_target_options : case_actions.client_target_options);

        if (actionsList) {
            return (
                <ButtonDropdown isOpen={this.state.actionsDropdownOpen} toggle={this.toggleActions}>
                    <DropdownToggle caret size="sm" color="success" className={classes.toggleBtn}>
                        {btnTitle}
                    </DropdownToggle>
                    <DropdownMenu>
                        {
                            actionsList.map(action => {
                                if (action.field_end_point_function.und[0].value !== "case-transfer-pending") {
                                    return (
                                        <DropdownItem key={action.tid} onClick={() => this.handleActionBtnsClick(action.field_end_point_function.und[0].value)}>
                                            {action.field_future_state_label.und[0].value}
                                        </DropdownItem>
                                    )
                                }
                            })
                        }
                    </DropdownMenu>
                </ButtonDropdown>
            )
        }
        else {
            return <RButton size="sm" color="success" className={classes.toggleBtn}>{btnTitle}</RButton>
        }
    };

    handleActionBtnsClick = (type) => {
        //console.log(type);
        switch (type) {
            case 'case-accept':
                this.handleAcceptCase();
                break;
            case 'case-cancel':
                this.handleCancelCase();
                break;
            case 'case-decline':
                this.setState({
                    declineModalOpen: true,
                });
                break;
            case 'case-active':
                this.handleActivateCase();
                break;
            case 'case-hold':
                this.handleHoldCase();
                break;
            case 'case-transfer-pending':
                this.getAvailableLawyersListForTransfer();
                break;
            case 'case-close':
                this.handleCloseCase();
                break;
            case 'case-pending':
                this.handlePendingCase();
                break;
            case 'case-transfer-accept':
                this.handleAccpetCaseTransfer();
                break;
            case 'case-transfer-decline':
                this.handleRejectCaseTransfer();
                break;
        }
    };

    handlePendingCase = () => {
        this.setState({
            processing: true,
        });
        const { cases } = this.state;

        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('case_nid', cases.uuid);

        fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/pending`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    if (cases.uuid) {
                        throw new Error('Failed to pending case ' + cases.uuid);
                    }
                    throw new Error('General failure: ' + res.status);
                }

                return res.json();
            })
            .then(resData => {
                //console.log(resData);
                if (resData.data.success) {
                    this.setState({
                        cases: {
                            ...this.state.cases,
                            case_actions: resData.data.case_actions
                        },
                        processing: false,
                    })
                }
                else {
                    this.setState({
                        processing: true,
                    });
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    processing: true,
                });
            });
    };

    handleAcceptCase = () => {
        this.setState({
            processing: true,
        });
        const { cases } = this.state;

        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('case_nid', cases.uuid);

        fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/accept`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    if (cases.uuid) {
                        throw new Error('Failed to accept case ' + cases.uuid);
                    }
                    throw new Error('General failure: ' + res.status);
                }

                return res.json();
            })
            .then(resData => {
                //console.log(resData);
                if (resData.data.success) {
                    this.setState({
                        cases: {
                            ...this.state.cases,
                            case_actions: resData.data.case_actions
                        },
                        processing: false,
                    })
                }
                else {
                    this.setState({
                        processing: true,
                    });
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    processing: true,
                });
            });
    };

    handleActivateCase = () => {
        this.setState({
            processing: true,
        });
        const { cases } = this.state;

        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('case_nid', cases.uuid);

        fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/activate`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    if (cases.uuid) {
                        throw new Error('Failed to actviate case ' + cases.uuid);
                    }
                    throw new Error('General failure: ' + res.status);
                }

                return res.json();
            })
            .then(resData => {
                //console.log(resData);
                if (resData.data.success) {
                    this.setState({
                        cases: {
                            ...this.state.cases,
                            case_actions: resData.data.case_actions
                        },
                        processing: false,
                    })
                }
                else {
                    this.setState({
                        processing: true,
                    });
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    processing: true,
                });
            });
    };

    handleHoldCase = () => {
        this.setState({
            processing: true,
        });
        const { cases } = this.state;

        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('case_nid', cases.uuid);

        fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/hold`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    if (cases.uuid) {
                        throw new Error('Failed to actviate case ' + cases.uuid);
                    }
                    throw new Error('General failure: ' + res.status);
                }

                return res.json();
            })
            .then(resData => {
                //console.log(resData);
                if (resData.data.success) {
                    this.setState({
                        cases: {
                            ...this.state.cases,
                            case_actions: resData.data.case_actions
                        },
                        processing: false,
                    })
                }
                else {
                    this.setState({
                        processing: true,
                    });
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    processing: true,
                });
            });
    };

    handleCancelCase = () => {
        this.setState({
            processing: true,
        });
        const { cases } = this.state;

        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('case_nid', cases.uuid);

        fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/cancel`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    if (cases.uuid) {
                        throw new Error('Failed to cancel case ' + cases.uuid);
                    }
                    throw new Error('General failure: ' + res.status);
                }

                return res.json();
            })
            .then(resData => {
                console.log(resData);
                if (resData.data.success) {
                    this.setState({
                        cases: {
                            ...this.state.cases,
                            case_actions: resData.data.case_actions
                        },
                        processing: false,
                    })
                }
                else {
                    this.setState({
                        processing: true,
                    });
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    processing: true,
                });
            });
    };

    handleCloseCase = () => {
        this.setState({
            processing: true,
        });
        const { cases } = this.state;

        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('case_nid', cases.uuid);

        fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/close`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    if (cases.uuid) {
                        throw new Error('Failed to close case ' + cases.uuid);
                    }
                    throw new Error('General failure: ' + res.status);
                }

                return res.json();
            })
            .then(resData => {
                console.log(resData);
                if (resData.data.success) {
                    this.setState({
                        cases: {
                            ...this.state.cases,
                            case_actions: resData.data.case_actions
                        },
                        processing: false,
                    })
                }
                else {
                    this.setState({
                        processing: true,
                    });
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    processing: true,
                });
            });
    };

    handleDeclineCase = (decline_data) => {
        this.setState({
            processing: true,
            declineModalOpen: false,
        });
        const { cases } = this.state;

        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('case_nid', cases.uuid);
        data.append('description', decline_data.comment);
        data.append('reason', decline_data.reason);

        fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/decline`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    if (cases.uuid) {
                        throw new Error('Failed to decline case ' + cases.uuid);
                    }
                    throw new Error('General failure: ' + res.status);
                }

                return res.json();
            })
            .then(resData => {
                console.log(resData);
                if (resData.data.success) {
                    this.props.history.push("/cases");
                }
                else {
                    this.setState({
                        processing: true,
                    });
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    processing: true,
                });
            });
    };

    handleTransferCase = (transfer_data) => {
        this.setState({
            transferListOpen: false,
            processing: true,
        });
        const { cases } = this.state;

        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('case_nid', cases.uuid);
        data.append('transfer-lawyer', transfer_data.lawyer);

        fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/transfer`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    if (cases.uuid) {
                        throw new Error('Failed to transfer case ' + cases.uuid);
                    }
                    throw new Error('General failure: ' + res.status);
                }

                return res.json();
            })
            .then(resData => {
                console.log(resData);
                if (resData.data.success) {
                    this.setState({
                        cases: {
                            ...this.state.cases,
                            case_actions: resData.data.case_actions
                        },
                        processing: false,
                    })
                }
                else {
                    this.setState({
                        processing: true,
                    });
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    processing: true,
                });
            });

    };

    handleToggleDeclineCaseModal = () => {
        this.setState({
            declineModalOpen: !this.state.declineModalOpen,
        });
    };

    getCaseRejectionReasons = () => {
        const { rejectionReasons } = this.props;
        return Object.entries(rejectionReasons)
            .filter(([group, reason]) => reason.name.toLowerCase() === "case")
            .map(([group, reason]) => {
                return reason;
            });
    };

    handleCloseTransferList = () => {
        this.setState({
            transferListOpen: false,
        })
    };

    handleAccpetCaseTransfer = () => {
        this.setState({
            processing: true,
        });
        const { cases } = this.state;

        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('case_nid', cases.uuid);
        data.append('action', "accept");

        fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/transfer/action`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    if (cases.uuid) {
                        throw new Error('Failed to accept case ' + cases.uuid);
                    }
                    throw new Error('General failure: ' + res.status);
                }

                return res.json();
            })
            .then(resData => {
                if (resData.data.success) {
                    this.setState({
                        cases: {
                            ...this.state.cases,
                            case_actions: resData.data.case_actions,
                            lawyer: resData.data.lawyer,
                        },
                        processing: false,
                    })
                }
                else {
                    this.setState({
                        processing: true,
                    });
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    processing: true,
                });
            });
    };

    handleRejectCaseTransfer = () => {
        this.setState({
            processing: true,
        });
        const { cases } = this.state;

        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('case_nid', cases.uuid);
        data.append('action', "reject");

        fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/transfer/action`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    if (cases.uuid) {
                        throw new Error('Failed to accept case ' + cases.uuid);
                    }
                    throw new Error('General failure: ' + res.status);
                }

                return res.json();
            })
            .then(resData => {
                if (resData.data.success) {
                    this.setState({
                        cases: {
                            ...this.state.cases,
                            case_actions: resData.data.case_actions,
                            lawyer: resData.data.lawyer,
                        },
                        processing: false,
                    })
                }
                else {
                    this.setState({
                        processing: true,
                    });
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    processing: true,
                });
            });
    };

    render() {
        if (this.state.loading) {
            return <Loading />
        }
        const { classes } = this.props;
        const { cases, declineModalOpen, transferListOpen, transferLawyers } = this.state;
        const actionBtns = this.getActionBtns();
        const caseRejectionReasons = this.getCaseRejectionReasons();

        console.log('2222222222222');
        console.log(cases);
        console.log('3333333333333');

        return (
            <div className="page--wrapper--no-margin">
                {this.state.processing &&
                    <Processing />
                }
                <Paper className={classes.Paper}>
                    <PageHading title={"Case Details"} history={this.props.history} />
                    <div className={classes.detailGroup}>
                        <Typography
                            variant="caption"
                            classes={{
                                caption: classes.caption,
                            }}
                        >
                            CASE NUMBER:
                        </Typography>
                        <Typography paragraph={true}>
                            {cases.uuid}

                        </Typography>
                    </div>

                    <div className={classes.detailGroup}>
                        <Typography
                            variant="caption"
                            classes={{
                                caption: classes.caption,
                            }}
                        >
                            CASE NAME:
                        </Typography>
                        <Typography paragraph={true}>
                            {cases.title}
                        </Typography>
                    </div>
                    {!!cases.no_conflict &&
                        <div className={classes.detailGroup}>
                            <Typography
                                variant="caption"
                                classes={{
                                    caption: classes.caption,
                                }}
                            >
                                DESCRIPTION:
                        </Typography>
                            <Typography paragraph={true}>
                                {cases.body}
                            </Typography>
                        </div>
                    }

                    <div className={classes.detailGroup}>
                        <Typography
                            variant="caption"
                            classes={{
                                caption: classes.caption,
                            }}
                        >
                            JURISDICTION:
                        </Typography>
                        <Typography paragraph={true}>
                            {cases.locale.name}
                        </Typography>
                    </div>

                    <div className={classes.detailGroup}>
                        <Typography
                            variant="caption"
                            classes={{
                                caption: classes.caption,
                            }}
                        >
                            PRACTICE AREA:
                        </Typography>
                        <Typography paragraph={true}>
                            {cases.practice.name}
                        </Typography>
                    </div>

                    <div className={classes.detailGroup}>
                        <Typography
                            variant="caption"
                            classes={{
                                caption: classes.caption,
                            }}
                        >
                            Case Status:
                        </Typography>
                        {
                            cases.action.status_text.trim() !== "" &&
                            <Typography paragraph={true}>
                                {cases.action.status_text}
                            </Typography>
                        }

                        {actionBtns}
                    </div>

                    <div className={classes.detailGroup}>
                        <Typography
                            variant="caption"
                            classes={{
                                caption: classes.caption,
                            }}
                        >
                            Client:
                        </Typography>
                        <Typography paragraph={true}>
                            {cases.client.field_first_name["und"][0]["value"] + " " + cases.client.field_last_name["und"][0]["value"]}
                        </Typography>
                        <div style={{ display: 'flex', marginBottom: 10 }}>
                            <PhoneIcon fontSize="small" style={{ marginRight: '5px' }} />
                            {cases.client.field_telephone["und"][0]["value"]}
                        </div>
                        <div style={{ display: 'flex' }}>
                            <EmailIcon fontSize="small" style={{ marginRight: '5px' }} />
                            {cases.client.mail}
                        </div>
                    </div>

                    <div className={classes.detailGroup}>
                        <Typography
                            variant="caption"
                            classes={{
                                caption: classes.caption,
                            }}
                        >
                            Lawyer:
                        </Typography>
                        <Typography paragraph={true}>
                            {cases.laywer ? cases.lawyer.field_first_name["und"][0]["value"] + " " + cases.lawyer.field_last_name["und"][0]["value"] : ""} 
                        </Typography>
                        <div style={{ display: 'flex', marginBottom: 10 }}>
                            <PhoneIcon fontSize="small" style={{ marginRight: '5px' }} />
                            {cases.lawyer ? cases.lawyer.field_telephone["und"][0]["value"] : "" }
                        </div>
                        <div style={{ display: 'flex' }}>
                            <EmailIcon fontSize="small" style={{ marginRight: '5px' }} />
                            {cases.lawyer.mail}
                        </div>
                    </div>
                </Paper>

                <TransferLawyersList
                    open={transferListOpen}
                    handleClose={this.handleCloseTransferList}
                    lawyers={transferLawyers}
                    transfer={this.handleTransferCase}
                />

                {declineModalOpen &&
                    <CancelationModal
                        open={declineModalOpen}
                        handleClose={() => this.handleToggleDeclineCaseModal()}
                        onSubmit={this.handleDeclineCase}
                        title={cases.case_title}
                        rejectionReasons={caseRejectionReasons[0]}
                        data={{
                            comment: '',
                            uuid: cases.case_uid,
                            reason: '',
                        }}
                    />
                }
            </div>
        )
    }
}

CaseDetailPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        isLawyer: state.headerReducer.user.is_lawyer,
        all_billing_types: state.utility.billing_types,
        auth: state.auth,
        rejectionReasons: state.utility.rejectionReasons,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchHeaderData: (data) => { dispatch(fetchHeaderData(data)) },
        logout: () => dispatch({ type: LOGOUT, payload: true }),
    }
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(CaseDetailPage);
