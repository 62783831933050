export class QaseApi {

    constructor() {
        this.baseUrl = process.env.REACT_APP_API_HOST_URL
    }

    async fetchJson(input, init = {}) {
        const headers = new Headers({
            ...init.headers,
            'Accept': 'application/json'
        });
        let response = await
            fetch(`${this.baseUrl}/${input}`, {
                ...init,
                headers: headers,
            });
        const json = await response.json();

        if (response.ok && json.success) {
            return json;
        }
        throw json;
    };

    //Auth
    async login(data) {
        return this.fetchJson('login', {
            method: 'POST',
            body: data,
        })
    };

    async logout(data) {
        return this.fetchJson('logout', {
            method: 'POST',
            body: data,
        })
    };

    //Required Attentions
    async getRequiredAttentions(data) {
        return this.fetchJson('requires_attentions', {
            method: 'POST',
            body: data,
        });
    };

    async acceptRequiredAttention(type, data) {
        switch (type) {
            case 'appointment':
                return this.acceptAppointment(data);
            case 'task':
                return this.acceptTask(data);
            case 'transaction':
                return this.acceptDisbTransaction(data);
            case 'case':
                return this.acceptCase(data);
        }
    };

    async cancelRequiredAttention(type, data) {
        switch (type) {
            case 'appointment':
                return this.cancelAppointment(data);
            case 'task':
                return this.cancelTask(data);
            case 'transaction':
                return this.cancelDisbTransaction(data);
            case 'case':
                return this.cancelCase(data);
        }
    };

    async rejectRequiredAttention(type, data) {
        switch (type) {
            case 'appointment':
                return this.rejectAppointment(data);
            case 'task':
                return this.rejectTask(data);
            case 'transaction':
                return this.rejectDisbTransaction(data);
            case 'case':
                return this.declineCase(data);
        }
    };

    // Files
    async getFiles(case_uuid, data) {
        return this.fetchJson(`files/${case_uuid}`, {
            method: 'POST',
            body: data,
        });
    };

    async uploadFile(case_uuid, data) {
        return this.fetchJson(`files/${case_uuid}/upload`, {
            method: 'POST',
            body: data,
        });
    };

    async deleteFile(fileid, data) {
        return this.fetchJson(`file/${fileid}/delete`, {
            method: 'POST',
            body: data,
        });
    };

    async shareFile(fileid, data) {
        return this.fetchJson(`file/${fileid}/share`, {
            method: 'POST',
            body: data,
        });
    };

    // End Files

    // Tasks
    async getTasks(case_uuid, data) {
        const request_url = case_uuid
            ? `tasks/${case_uuid}`
            : `tasks/`;

        return this.fetchJson(request_url, {
            method: 'POST',
            body: data,
        });
    };

    async createTask(data) {
        return this.fetchJson('task/create', {
            method: 'POST',
            body: data,
        });
    };

    async acceptTask(data) {
        return this.fetchJson('task/accept', {
            method: 'POST',
            body: data,
        });
    };

    async cancelTask(data) {
        return this.fetchJson('task/cancel', {
            method: 'POST',
            body: data,
        });
    };

    async rejectTask(data) {
        return this.fetchJson('task/reject', {
            method: 'POST',
            body: data,
        });
    };

    async completeTask(data) {
        return this.fetchJson('task/complete', {
            method: 'POST',
            body: data,
        });
    };

    //End  Tasks

    // Utilities
    async getRejectionReasons() {
        return this.fetchJson('rejection_reasons', {
            method: 'POST',
        });
    };

    async getLegalIssues(data) {
        return this.fetchJson('legal_issues', {
            method: 'POST',
            body: data,
        });
    };

    async getConnectOptions() {
        return this.fetchJson('connect_options', {
            method: 'POST',
        });
    };

    async getLocals(data) {
        return this.fetchJson('locals', {
            method: 'POST',
            body: data,
        });
    };

    async getBillingTypes() {
        return this.fetchJson('billing_types', {
            method: 'POST',
        });
    };

    //Messages 
    async getMessages(case_uuid, data) {
        return this.fetchJson(`messages/${case_uuid}`, {
            method: 'POST',
            body: data,
        });
    };

    async addMessage(case_uuid, data) {
        return this.fetchJson(`messages/${case_uuid}/add`, {
            method: 'POST',
            body: data,
        });
    };

    //Transactions
    async getTransactions(case_uuid, data) {
        const request_url = case_uuid
            ? `transactions/${case_uuid}`
            : `transactions/`;

        return this.fetchJson(request_url, {
            method: 'POST',
            body: data,
        });
    };

    async acceptDisbTransaction(data) {
        return this.fetchJson('disb_transaction/accept', {
            method: 'POST',
            body: data,
        });
    };

    async cancelDisbTransaction(data) {
        return this.fetchJson('disb_transaction/cancel', {
            method: 'POST',
            body: data,
        });
    };

    async rejectDisbTransaction(data) {
        return this.fetchJson('disb_transaction/reject', {
            method: 'POST',
            body: data,
        });
    };

    //Appointments
    async acceptAppointment(data) {
        return this.fetchJson('appointment/accept', {
            method: 'POST',
            body: data,
        });
    };

    async getAppointments(case_uuid, data) {
        const request_url = case_uuid
            ? `appointments/${case_uuid}`
            : `appointments/`;

        return this.fetchJson(request_url, {
            method: 'POST',
            body: data,
        });
    };

    async cancelAppointment(data) {
        return this.fetchJson('appointment/cancel', {
            method: 'POST',
            body: data,
        });
    };

    async rejectAppointment(data) {
        return this.fetchJson('appointment/reject', {
            method: 'POST',
            body: data,
        });
    };

    async completeUnattenedAppointment(data) {
        return this.fetchJson('appointment/unattended/complete', {
            method: 'POST',
            body: data,
        });
    };

    async completeAppointment(data) {
        return this.fetchJson('appointment/complete', {
            method: 'POST',
            body: data,
        });
    };

    //Header 
    async getHeaderData(data) {
        return this.fetchJson('user/header_data', {
            method: 'POST',
            body: data,
        });
    };


    //Notes
    async getNotes(data) {
        return this.fetchJson('note', {
            method: 'POST',
            body: data,
        });
    };

    async createNote(case_uuid, data) {
        return this.fetchJson(`cases/${case_uuid}/notes/create`, {
            method: 'POST',
            body: data,
        });
    };

    async deleteNote(data) {
        return this.fetchJson(`note/delete`, {
            method: 'POST',
            body: data,
        });
    };

    async shareNote(data) {
        return this.fetchJson(`note/share`, {
            method: 'POST',
            body: data,
        });
    };

    //Cases 
    async acceptCase(data) {
        return this.fetchJson(`cases/accept`, {
            method: 'POST',
            body: data,
        });
    };

    async declineCase(data) {
        return this.fetchJson(`cases/decline`, {
            method: 'POST',
            body: data,
        });
    };

    async cancelCase(data) {
        return this.fetchJson(`cases/cancel`, {
            method: 'POST',
            body: data,
        });
    }
};