import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import {
	Typography,
	Paper,
	Input,
	FormControl,
	Avatar,
	Grid,
	Button,
	Select,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListItemSecondaryAction,
	Dialog,
} from "@material-ui/core";

import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import {
	GetApp as GetAppIcon,
	HelpOutline as HelpOutlineIcon,
	MoreVert as MoreVertIcon,
	Close as CloseIcon,
} from "@material-ui/icons";
import {
	ValidatorForm,
	TextValidator,
	SelectValidator,
} from "react-material-ui-form-validator";
import Actions from "./Actions";
import ImgsViewer from "react-images-viewer";

const DialogTitle = withStyles((theme) => ({
	root: {
		//borderBottom: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing.unit * 2,
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing.unit,
		top: theme.spacing.unit,
		color: theme.palette.grey[500],
	},
}))((props) => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
			<Typography variant="subtitle2" style={{ fontWeight: 700 }}>
				{children}
			</Typography>
			{onClose ? (
				<IconButton
					aria-label="Close"
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing.unit * 2,
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		borderTop: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing.unit,
	},
}))(MuiDialogActions);

const styles = (theme) => ({
	Paper: {
		padding: 20,
		marginBottom: 10,
	},
	formGroup: {
		marginBottom: 15,
	},
	editBtn: {
		padding: 5,
	},
	actionBtn: {
		margin: 3,
	},
	avatar: {
		width: 64,
		height: 64,
		marginTop: 16,
		marginLeft: "auto",
		marginRight: "auto",
	},
	Title: {
		textTransform: "uppercase",
		color: "#999999",
	},
	UploadWrapper: {
		border: "1px solid #8C96A0",
		borderStyle: "dashed",
		textAlign: "center",
		borderRadius: 6,
		padding: 5,
		marginBottom: 10,
	},
	IdentityHelp: {
		padding: 0,
	},
	IdentityListItem: {
		paddingLeft: 0,
	},
	ListItemText: {
		color: "#220D89",
	},
	IdentityPaper: {
		margin: 0,
		borderRadius: 0,
	},
});

class ContactInfo extends Component {
	state = {
		userData: this.getInitState(),
		editMode: false,
		avatarFile: null,
		identityUploadFile: null,
		submited: false,
		imageViewer: {
			open: false,
			src: "",
		},
		idenitytDialogOpen: false,
	};

	avatarFileInput = React.createRef();
	identityFileInput = React.createRef();

	getInitState() {
		const { userData } = this.props;

		return userData
			? userData
			: {
					field_first_name: "",
					field_last_name: "",
					field_telephone: "",
					mail: "",
					field_address: "",
					field_address_2: "",
					field_address_city: "",
					field_locale: "",
					country: "",
					field_postal_code: "",
					field_employment_occupation: "",
					field_employment_employer: "",
					field_employment_address: "",
					field_employment_phone: "",
			  };
	}

	componentDidMount() {
		//console.log(this.state);
		//console.log("mount")
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.userData.field_first_name !==
				this.props.userData.field_first_name ||
			prevProps.userData.field_last_name !==
				this.props.userData.field_last_name ||
			prevProps.userData.field_telephone !==
				this.props.userData.field_telephone ||
			prevProps.userData.mail !== this.props.userData.mail ||
			prevProps.userData.field_address !==
				this.props.userData.field_address ||
			prevProps.userData.field_address_2 !==
				this.props.userData.field_address_2 ||
			prevProps.userData.field_address_city !==
				this.props.userData.field_address_city ||
			prevProps.userData.field_locale !==
				this.props.userData.field_locale ||
			prevProps.userData.country !== this.props.userData.country ||
			prevProps.userData.field_postal_code !==
				this.props.userData.field_postal_code
		) {
			this.setState({
				userData: this.props.userData,
			});
		}
	}

	changeEditMode = () => {
		this.setState({
			editMode: !this.state.editMode,
		});
	};

	handleInputChange =
		(name) =>
		({ target: { value } }) => {
			this.setState({
				userData: {
					...this.state.userData,
					[name]: value,
				},
			});
		};

	handleAvatarChange = (event) => {
		if (event.target.files.length) {
			this.setState({
				avatarFile: URL.createObjectURL(event.target.files[0]),
			});
		}
	};

	handleIdentityChange = (event) => {
		if (event.target.files.length) {
			this.setState({
				identityUploadFile: {
					path: URL.createObjectURL(event.target.files[0]),
					name: event.target.files[0].name,
				},
				//identityFile: event.target.files[0].name,
			});
		}
	};

	handleOpenViewer = (imageSrc) => {
		this.setState({
			imageViewer: {
				open: true,
				src: imageSrc,
			},
		});
	};

	handleCloseViewer = () => {
		this.setState({
			imageViewer: {
				open: false,
				src: "",
			},
		});
	};

	handleIdentityDialogOpen = () => {
		this.setState({
			idenitytDialogOpen: true,
		});
	};

	handleIdentityDialogClose = () => {
		this.setState({
			idenitytDialogOpen: false,
		});
	};

	handleUploadIdentity = () => {
		console.log("upload");
		console.log(this.identityFileInput.current.files.length);
		if (this.identityFileInput.current.files.length) {
			this.props.showProcessing(true);
			let data = new FormData();
			const sessionUserData = JSON.parse(
				localStorage.getItem("userData")
			);

			data.append("qase_api_token", sessionUserData.token);
			data.append("sid", sessionUserData.sid);
			data.append(
				"files[field_identity]",
				this.identityFileInput.current.files[0]
			);

			fetch(
				`${process.env.REACT_APP_API_HOST_URL}/user/digital_identity/upload`,
				{
					method: "POST",
					body: data,
				}
			)
				.then((res) => {
					this.props.showProcessing(false);
					if (res.status !== 200 && res.status !== 201) {
						throw new Error("failed");
					}
					return res.json();
				})
				.then((resData) => {
					console.log(resData);
					if (resData.success) {
						this.identityFileInput.current.value = "";
						let userData = this.state.userData;
						userData["identity_file"] = resData.data.identity;
						this.setState({
							identityUploadFile: null,
							userData: userData,
						});
					}
				})
				.catch((err) => {
					//console.log(err);
					this.props.showProcessing(false);
				});
		}
	};

	handleSubmit = (event) => {
		this.props.showProcessing(true);

		this.setState({
			submited: true,
		});

		const { userData } = this.state;

		var data = new FormData();

		var sessionUserData = JSON.parse(localStorage.getItem("userData"));

		data.append("qase_api_token", sessionUserData.token);
		data.append("sid", sessionUserData.sid);

		Object.keys(userData).forEach((item) => {
			data.append(item, userData[item]);
		});

		//upload profile picture
		if (this.avatarFileInput.current.files.length) {
			data.append(
				"files[field_user_image]",
				this.avatarFileInput.current.files[0]
			);
		}

		// //upload identity
		// if (this.identityFileInput.current.files.length) {
		//     data.append('files[field_identity]', this.identityFileInput.current.files[0]);
		// }

		fetch(`${process.env.REACT_APP_API_HOST_URL}/user/contact/update`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				this.props.showProcessing(false);
				if (res.status !== 200 && res.status !== 201) {
					throw new Error("failed");
				}
				return res.json();
			})
			.then((resData) => {
				//console.log(resData);
				if (resData.success) {
					this.setState({
						editMode: false,
						submited: false,
					});
				}
			})
			.catch((err) => {
				//console.log(err);
				this.props.showProcessing(false);
			});
	};

	render() {
		const { classes } = this.props,
			{
				userData,
				editMode,
				submited,
				avatarFile,
				identityUploadFile,
				imageViewer,
				idenitytDialogOpen,
			} = this.state;

		//console.log(this.identityFileInput);
		return (
			<Fragment>
				{/* Contact Info */}
				<Paper className={classes.Paper}>
					<ValidatorForm
						encType="multipart/form-data"
						ref="form"
						onSubmit={this.handleSubmit}
					>
						<Actions
							changeEditMode={this.changeEditMode}
							submited={submited}
							editMode={editMode}
							title="CONTACT INFO"
						/>

						<div style={{ display: "flex" }}>
							<div style={{ flexGrow: 1 }}>
								<FormControl margin="normal" fullWidth>
									<TextValidator
										placeholder="Given Names"
										fullWidth
										value={userData.field_first_name}
										onChange={this.handleInputChange(
											"field_first_name"
										)}
										name="field_first_name"
										validators={["required"]}
										errorMessages={[
											"first name is required",
										]}
										disabled={!editMode}
									/>
								</FormControl>

								<FormControl margin="normal" fullWidth>
									<TextValidator
										placeholder="Last Name"
										fullWidth
										value={userData.field_last_name}
										onChange={this.handleInputChange(
											"field_last_name"
										)}
										name="field_last_name"
										validators={["required"]}
										errorMessages={[
											"last name is required",
										]}
										disabled={!editMode}
									/>
								</FormControl>
							</div>
							<div style={{ marginLeft: 15 }}>
								<Avatar
									className={classes.avatar}
									src={
										avatarFile
											? avatarFile
											: userData.field_user_image
									}
								/>
								<input
									accept="image/*"
									style={{ display: "none" }}
									id="avatar-button-file"
									type="file"
									onChange={this.handleAvatarChange}
									ref={this.avatarFileInput}
									disabled={!editMode}
								/>
								<label htmlFor="avatar-button-file">
									<Button
										variant="text"
										component="span"
										className={classes.button}
										disabled={!editMode}
									>
										Change
									</Button>
								</label>
							</div>
						</div>

						<FormControl margin="normal" fullWidth>
							<TextValidator
								placeholder="Phone Number"
								fullWidth
								value={userData.field_telephone}
								onChange={this.handleInputChange(
									"field_telephone"
								)}
								name="field_telephone"
								validators={["required"]}
								errorMessages={["phone is required"]}
								disabled={!editMode}
								key={this.props.userData.field_first_name}
							/>
						</FormControl>

						{/* Email Address */}
						<FormControl
							fullWidth
							margin="normal"
							style={{ marginBottom: 25 }}
						>
							<TextValidator
								placeholder="Email Address"
								fullWidth
								value={userData.mail}
								onChange={this.handleInputChange("mail")}
								name="mail"
								validators={["required", "isEmail"]}
								errorMessages={[
									"email is required",
									"email is not valid",
								]}
								disabled={!editMode}
							/>
						</FormControl>

						<Typography>MAILING ADDRESS</Typography>

						<FormControl margin="normal" fullWidth>
							<TextValidator
								placeholder="Street Address"
								fullWidth
								value={userData.field_address}
								onChange={this.handleInputChange(
									"field_address"
								)}
								name="field_address"
								validators={["required"]}
								errorMessages={["street address is required"]}
								disabled={!editMode}
							/>
						</FormControl>

						<FormControl margin="normal" fullWidth>
							<Input
								placeholder="Street Address 2"
								fullWidth
								value={userData.field_address_2}
								onChange={this.handleInputChange(
									"field_address_2"
								)}
								disabled={!editMode}
								name="field_address_2"
							/>
						</FormControl>

						<Grid container spacing={8}>
							<Grid item xs={6}>
								<FormControl margin="normal" fullWidth>
									<TextValidator
										placeholder="City"
										fullWidth
										value={userData.field_address_city}
										onChange={this.handleInputChange(
											"field_address_city"
										)}
										name="field_address_city"
										validators={["required"]}
										errorMessages={["city is required"]}
										disabled={!editMode}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl margin="normal" fullWidth>
									<SelectValidator
										select
										value={userData.field_locale}
										disabled={!editMode}
										onChange={this.handleInputChange(
											"field_locale"
										)}
										inputProps={{
											name: "field_locale",
										}}
										SelectProps={{
											native: true,
											MenuProps: {
												className: classes.menu,
											},
										}}
										validators={["required"]}
										errorMessages={["province is required"]}
									>
										<option value="">
											Choose Province
										</option>
										{Object.entries(userData.locales).map(
											(locale) => {
												return (
													<option
														key={locale[0]}
														value={locale[0]}
													>
														{locale[1]}
													</option>
												);
											}
										)}
									</SelectValidator>
								</FormControl>
							</Grid>
						</Grid>

						<Grid
							container
							spacing={8}
							style={{ marginBottom: 25 }}
						>
							<Grid item xs={6}>
								<FormControl margin="normal" fullWidth>
									<Select
										native
										value={userData.field_locale_country}
										disabled={!editMode}
										//onChange={this.handleChange}
										inputProps={{
											name: "field_locale_country",
										}}
									>
										<option value="">
											{process.env.REACT_APP_COUNTRY ==
											"CA"
												? "Canada"
												: "United States"}
										</option>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl margin="normal" fullWidth>
									<Input
										placeholder="Postal Code"
										fullWidth
										value={userData.field_postal_code}
										onChange={this.handleInputChange(
											"field_postal_code"
										)}
										disabled={!editMode}
										name="field_postal_code"
									/>
								</FormControl>
							</Grid>
						</Grid>

						{userData.role === "client" && (
							<Fragment>
								<Typography>EMPLOYMENT</Typography>
								<FormControl margin="normal" fullWidth>
									<TextValidator
										placeholder="Occupation"
										fullWidth
										value={
											userData.field_employment_occupation
										}
										onChange={this.handleInputChange(
											"field_employment_occupation"
										)}
										name="field_employment_occupation"
										validators={["required"]}
										errorMessages={[
											"Occupation is required",
										]}
										disabled={!editMode}
									/>
								</FormControl>

								<FormControl margin="normal" fullWidth>
									<TextValidator
										placeholder="Employer"
										fullWidth
										value={
											userData.field_employment_employer
										}
										onChange={this.handleInputChange(
											"field_employment_employer"
										)}
										name="field_employment_employer"
										validators={["required"]}
										errorMessages={["Employer is required"]}
										disabled={!editMode}
									/>
								</FormControl>

								<FormControl margin="normal" fullWidth>
									<TextValidator
										placeholder="Address"
										fullWidth
										value={
											userData.field_employment_address
										}
										onChange={this.handleInputChange(
											"field_employment_address"
										)}
										name="field_employment_address"
										validators={["required"]}
										errorMessages={["Address is required"]}
										disabled={!editMode}
									/>
								</FormControl>

								<FormControl margin="normal" fullWidth>
									<TextValidator
										placeholder="Phone Number"
										fullWidth
										value={userData.field_employment_phone}
										onChange={this.handleInputChange(
											"field_employment_phone"
										)}
										name="field_employment_phone"
										validators={["required"]}
										errorMessages={[
											"Phone Number is required",
										]}
										disabled={!editMode}
									/>
								</FormControl>
							</Fragment>
						)}
					</ValidatorForm>
				</Paper>

				<Paper className={classes.Paper} style={{ marginBottom: 60 }}>
					<Typography className={classes.Title} gutterBottom>
						Identification
						<span style={{ color: "#FF5555" }}>*</span>
					</Typography>

					<div className={classes.UploadWrapper}>
						<input
							accept="image/*"
							style={{ display: "none" }}
							id="identity-button-file"
							type="file"
							onChange={this.handleIdentityChange}
							ref={this.identityFileInput}
							//disabled={!editMode}
						/>
						<label htmlFor="identity-button-file">
							<IconButton
								variant="text"
								component="span"
								className={classes.button}
								disabled={!editMode}
							>
								<GetAppIcon fontSize="small" />
							</IconButton>
						</label>
						<Typography variant="caption">
							Tap to choose a file to upload*
							<IconButton
								className={classes.IdentityHelp}
								onClick={this.handleIdentityDialogOpen}
							>
								<HelpOutlineIcon fontSize="small" />
							</IconButton>
						</Typography>
					</div>

					<Button
						variant="contained"
						color={
							this.identityFileInput.current &&
							this.identityFileInput.current.files.length > 0
								? "primary"
								: "default"
						}
						disabled={
							!(
								this.identityFileInput.current &&
								this.identityFileInput.current.files.length > 0
							)
						}
						onClick={this.handleUploadIdentity}
					>
						Upload
					</Button>

					<Dialog
						onClose={this.handleIdentityDialogClose}
						open={idenitytDialogOpen}
						fullWidth={true}
						classes={{
							paper: classes.IdentityPaper,
						}}
					>
						<DialogTitle onClose={this.handleIdentityDialogClose}>
							Why do we need a copy of your ID?
						</DialogTitle>
						<DialogContent>
							<Typography gutterBottom>
								Qase models best practice and compliance with
								regulatory guidelines to ensure security for our
								clients and their legal cases. As part of the
								application process, all prospective clients are
								required to provide proof of identity.
							</Typography>
							<Typography gutterBottom>
								We are required to safeguard your data and keep
								your personal information secure and
								confidential. Visit our{" "}
								<a
									href="https://www.qase.ca/privacy_policy"
									target="_blank"
									style={{
										color: "#220D89",
										fontWeight: 700,
									}}
								>
									Privacy Policy
								</a>{" "}
								to learn more.
							</Typography>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={this.handleIdentityDialogClose}
								variant="contained"
								color="primary"
							>
								Close
							</Button>
						</DialogActions>
					</Dialog>
					{identityUploadFile && (
						<List>
							<ListItem
								alignItems="flex-start"
								className={classes.IdentityListItem}
							>
								<ListItemAvatar>
									<Avatar
										alt=""
										src={identityUploadFile.path}
									/>
								</ListItemAvatar>
								<ListItemText
									onClick={() =>
										this.handleOpenViewer(
											identityUploadFile.path
										)
									}
									primary={identityUploadFile.name}
									classes={{
										primary: classes.ListItemText,
									}}
								/>
								<ListItemSecondaryAction>
									<IconButton>
										<MoreVertIcon />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						</List>
					)}

					{userData.identity_file && (
						<Fragment>
							<Typography style={{ marginTop: 15 }}>
								ID's on File:
							</Typography>
							<List>
								<ListItem
									alignItems="flex-start"
									className={classes.IdentityListItem}
								>
									<ListItemAvatar>
										<Avatar
											alt=""
											src={
												//userData.identity_file.show_url
												`${process.env.REACT_APP_API_HOST_URL}/user/digital_identity/show/${userData.identity_file.fid}`
											}
										/>
									</ListItemAvatar>
									<ListItemText
										onClick={() =>
											this.handleOpenViewer(
												`${process.env.REACT_APP_API_HOST_URL}/user/digital_identity/show/${userData.identity_file.fid}`
											)
										}
										primary={
											userData.identity_file.filename
										}
										classes={{
											primary: classes.ListItemText,
										}}
									/>
									{/* <ListItemSecondaryAction>
                                        <IconButton>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction> */}
								</ListItem>
							</List>
						</Fragment>
					)}
				</Paper>

				{imageViewer.open && (
					<ImgsViewer
						imgs={[
							{
								src: imageViewer.src,
							},
						]}
						closeBtnTitle="close"
						isOpen={imageViewer.open}
						onClose={this.handleCloseViewer}
					/>
				)}
			</Fragment>
		);
	}
}

ContactInfo.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactInfo);
