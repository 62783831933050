import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
    Paper,
    FormControl,
    TextField,
    MenuItem,
    Grid,
    Typography,
    Button,
    FormControlLabel,
    Checkbox,
    MobileStepper,
    FormHelperText,
} from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { Loading, Processing, CalendarNavbar } from "../Components/Layouts";
import {
    ValidatorForm,
    TextValidator,
    SelectValidator,
} from "react-material-ui-form-validator";

// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { Redirect } from "react-router-dom";
import querystring from "query-string";

import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import "../styles/calendar.css";

import compose from "recompose/compose";
import { connect } from "react-redux";

const styles = (theme) => ({
    pageContainer: {
        minHeight: "calc(100vh - 104px)",
    },
    formContainer: {
        // display: 'flex',
        // justifyContent: 'space-between',
        //marginTop: 25,
    },
    textField: {
        fontSize: 12,
        // marginLeft: theme.spacing.unit,
        // marginRight: theme.spacing.unit,
    },
    Paper: {
        paddingRight: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 2,
        borderRadius: 0,
    },
    formLabel: {
        textTransform: "uppercase",
        color: "#999999",
        fontSize: 10,
        fontWeight: 700,
    },
    formGroup: {
        marginBottom: 25,
    },
    resize: {
        fontSize: 13,
    },
    stepperWrapper: {
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        backgroundColor: "#220d89",
    },
    stepperRoot: {
        backgroundColor: "#220d89",
        flexGrow: 1,
    },
    stepperBtn: {
        color: "#fff",
    },
    stepperBtnDisbaled: {
        color: "#767070!important",
    },
    stepperProggress: {
        backgroundColor: "#767070",
    },
    stepperProggressLinear: {
        backgroundColor: "#fff",
    },
});

const durationList = [
    {
        value: 30,
        name: "30 minutes",
    },
    {
        value: 60,
        name: "1 hour",
    },
    {
        value: 90,
        name: "1.5 hours",
    },
    {
        value: 120,
        name: "2 hours",
    },
    {
        value: 180,
        name: "3 hours",
    },
    {
        value: 240,
        name: "4 hours",
    },
];

const currentDate = moment().toDate();

class AppointmentCreatePage extends Component {
    state = {
        monthShow: currentDate,
        startTimes: [],
        endTimes: [],
        calendarData: {},
        loading: false,
        processing: false,
        rescheduleAppointment: {},
        highlighted: [],
        stepDisabled: true,
        validation: {
            stepOneValid: false,
            stepTwoValid: false,
            stepThreeValid: false,
        },
        appointmentData: {
            duration: "",
            startDate: null,
            startTime: "",
            //endTime: '',
            appointmentName: "",
            appointmentDescription: "",
            isBillable: false,
            isReschedule: false,
            billableDissabled: false,
        },
        CaseData: {},
    };

    componentDidMount() {
        const values = querystring.parse(this.props.location.search);
        const isReschedule = Boolean(values.type);
        const appointment_uuid = values.uuid;
        if (isReschedule) {
            this.setState({
                loading: true,
            });
            this.getAvailableHours(null, null, isReschedule, appointment_uuid);
        }

        this.getCase(this.props.auth.case_uuid);
    }

    getCase(case_uuid) {
        let data = new FormData();

        let userData = JSON.parse(localStorage.getItem("userData"));

        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);

        fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/${case_uuid}`, {
            method: "POST",
            body: data,
        })
            .then((res) => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error("failed");
                }

                return res.json();
            })
            .then((resData) => {
                console.log(resData);
                if (resData.success) {
                    this.setState({
                        CaseData: resData.data,
                        appointmentData: {
                            ...this.state.appointmentData,
                            isBillable: !!resData.data.accept_billable,
                            // resData.data.hasOwnProperty(
                            //     "field_case_billing_type"
                            // ) &&
                            // ["Hourly"].includes(
                            //     resData.data.field_case_billing_type.name
                            // ) &&
                            // // !!Object.keys(
                            // //     resData.data.client.field_stripe_account
                            // // ).length &&
                            // !!Object.keys(
                            //     resData.data.lawyer.field_stripe_account
                            // ).length,
                            billableDissabled: !resData.data.accept_billable,
                            // !(
                            //     resData.data.hasOwnProperty(
                            //         "field_case_billing_type"
                            //     ) &&
                            //     ["Hourly"].includes(
                            //         resData.data.field_case_billing_type.name
                            //     ) &&
                            //     // !!Object.keys(
                            //     //     resData.data.client.field_stripe_account
                            //     // ).length &&
                            //     !!Object.keys(
                            //         resData.data.lawyer.field_stripe_account
                            //     ).length
                            // ),
                        },
                    });
                }
            })
            .catch((err) => {
                //console.log(err);
                this.setState({
                    error: true,
                });
            });
    }

    getAvailableHours = (
        startDate,
        duration,
        isReschedule,
        appointment_uuid
    ) => {
        let data = new FormData();

        const userData = JSON.parse(localStorage.getItem("userData"));

        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);
        data.append("initialValue", true);
        data.append("case_uuid", this.props.auth.case_uuid);
        data.append("values[for_month]", true);

        if (duration) {
            data.append("values[duration]", duration);
        }

        if (startDate) {
            data.append("values[startdate]", startDate);
        }

        if (isReschedule && appointment_uuid) {
            data.append("appointment_uuid", appointment_uuid);
        }

        fetch(`${process.env.REACT_APP_API_HOST_URL}/available_hours`, {
            method: "POST",
            body: data,
        })
            .then((res) => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error("failed");
                }
                return res.json();
            })
            .then((resData) => {
                if (resData.success) {
                    this.setInitState(
                        resData.data.response,
                        resData.data.reschedule_appointment
                    );
                }
            })
            .catch((err) => {
                //console.log(err);
            });
    };

    setInitState = (data, rescheduleAppointment) => {
        let startDate = rescheduleAppointment
            ? moment.unix(rescheduleAppointment.from).toDate()
            : this.state.appointmentData.startDate;
        let appointmentName = rescheduleAppointment
            ? rescheduleAppointment.title
            : this.state.appointmentData.appointmentName;
        let appointmentDescription = rescheduleAppointment
            ? rescheduleAppointment.body
            : this.state.appointmentData.appointmentDescription;
        let isReschedule = rescheduleAppointment
            ? true
            : this.state.appointmentData.isReschedule;
        let isBillable = rescheduleAppointment
            ? rescheduleAppointment.billable
            : this.state.appointmentData.isBillable;
        let duration = rescheduleAppointment
            ? rescheduleAppointment.length / 60
            : this.state.appointmentData.duration;
        let highlighted = data.calendar_data[0].calendar.map((schedule) => {
            if (schedule.schedule.length > 0) {
                return moment(schedule.day_iso).tz(data.time_zone).toDate();
            }
        });
        let monthShow = rescheduleAppointment
            ? moment.unix(rescheduleAppointment.from).toDate()
            : this.state.monthShow;

        this.setState(
            {
                calendarData: data,
                loading: false,
                highlighted: highlighted,
                showContent: true,
                processing: false,
                monthShow,
                appointmentData: {
                    ...this.state.appointmentData,
                    startDate,
                    appointmentName,
                    appointmentDescription,
                    isReschedule,
                    isBillable,
                    duration,
                },
                rescheduleAppointment: {
                    ...rescheduleAppointment,
                },
            },
            () => {
                if (rescheduleAppointment) {
                    //this.checkFormValid();
                    this.filterStartHours(
                        moment.unix(rescheduleAppointment.from).toDate(),
                        rescheduleAppointment.from
                    );
                }
            }
        );
    };

    handleNextStep = () => {
        this.form.isFormValid(false).then((isValid) => {
            if (isValid) {
                const {
                    rAppointment: { createAppointmentAcitveStep },
                    handleStepChange,
                } = this.props;
                let step = createAppointmentAcitveStep + 1;
                handleStepChange(step);
                this.setState({
                    stepDisabled: step < 3 ? true : false,
                });
            }
        });
    };

    handlePrevStep = () => {
        const {
            rAppointment: { createAppointmentAcitveStep },
            handleStepChange,
        } = this.props;
        let step = createAppointmentAcitveStep - 1;
        handleStepChange(step);
    };

    isNextDisabled = () => {
        const {
                rAppointment: { createAppointmentAcitveStep },
            } = this.props,
            { appointmentData } = this.state;

        switch (createAppointmentAcitveStep) {
            case 0:
                return !(
                    Boolean(appointmentData.duration) &&
                    Boolean(appointmentData.appointmentName.trim())
                );
            case 1:
                return !Boolean(appointmentData.startDate);
            case 2:
                return !Boolean(appointmentData.startTime);
            case 3:
                return false;
            default:
                return true;
        }
    };

    handleDayClick = (day, { selected }) => {
        const {
            calendarData: { calendar_data },
        } = this.state;
        const currentDate = moment(day).format("DD/MM/YYYY");
        const matchDate = calendar_data[0].calendar.find(
            (cal) => cal.day === currentDate && cal.schedule.length > 0
        );
        if (matchDate) {
            this.setState(
                {
                    appointmentData: {
                        ...this.state.appointmentData,
                        startDate: selected ? undefined : day,
                    },
                },
                () => {
                    this.filterStartHours(day);
                    this.setState({
                        stepDisabled: false,
                    });
                }
            );
        }
    };

    handleDatePickerMonthChange = (data) => {
        const {
            appointmentData: { duration },
        } = this.state;
        // if (moment(data).startOf('month').isAfter(currentDate)) {
        //     var date = moment(data).startOf('month').format('Y-MM-DD');
        // }

        var date = moment(data).startOf("month").isAfter(moment(currentDate))
            ? moment(data).startOf("month").format("Y-MM-DD")
            : null;

        this.setState(
            {
                processing: true,
                monthShow: moment(data).startOf("month").toDate(),
            },
            () => {
                this.getAvailableHours(date, duration);
            }
        );
    };

    handleSelectChange = (name) => ({ target: { value } }) => {
        this.setState(
            {
                appointmentData: {
                    ...this.state.appointmentData,
                    [name]: value,
                },
            },
            () => {
                if (name === "duration") {
                    this.setState(
                        {
                            appointmentData: {
                                ...this.state.appointmentData,
                                startDate: null,
                                startTime: "",
                                endTime: "",
                            },
                        },
                        () => {
                            console.log(0);
                            let startDate = moment(this.state.monthShow).format(
                                "Y-MM-DD"
                            );
                            this.getAvailableHours(startDate, value);
                        }
                    );
                }

                //this.checkFormValid();
            }
        );
    };

    handleInputChange = (name) => ({ target: { value } }) => {
        this.setState({
            appointmentData: {
                ...this.state.appointmentData,
                [name]: value,
            },
        });

        //this.checkFormValid();
    };

    handleCheckboxChange = (name) => ({ target: { checked } }) => {
        this.setState({
            appointmentData: {
                ...this.state.appointmentData,
                [name]: checked,
            },
        });
    };

    filterDate = (data) => {
        const {
            calendarData: { calendar_data },
        } = this.state;
        const currentDate = moment(data).format("DD/MM/YYYY");
        const matchDate = calendar_data[0].calendar.find(
            (cal) => cal.day === currentDate && cal.schedule.length > 0
        );
        return matchDate ? false : true;
    };

    filterStartHours = (date, selectedTime) => {
        const {
            calendarData: { calendar_data, time_zone },
            appointmentData: { duration },
        } = this.state;
        const date_format = moment(date).format("DD/MM/YYYY");
        const available_hours = calendar_data[0].calendar.find(
            (cal) => cal.day === date_format
        );

        const startTimes = selectedTime
            ? [
                  {
                      value: moment
                          .unix(selectedTime)
                          .tz(time_zone)
                          .format("HH:mm"),
                      lastItem: false,
                  },
              ]
            : [];

        let startTime = selectedTime
            ? moment.unix(selectedTime).tz(time_zone).format("HH:mm")
            : "";
        if (available_hours) {
            available_hours.schedule.forEach((item) => {
                let beginningTime = moment(item.starthours, "HH:mm");
                let startMinutes = parseInt(beginningTime.clone().format("m"));

                if (startMinutes < 15 && startMinutes != 0) {
                    let diff = 15 - startMinutes;
                    beginningTime = beginningTime.clone().add(diff, "m");
                } else if (
                    startMinutes > 15 &&
                    startMinutes < 30 &&
                    startMinutes != 0
                ) {
                    let diff = 30 - startMinutes;
                    beginningTime = beginningTime.clone().add(diff, "m");
                } else if (
                    startMinutes > 30 &&
                    startMinutes < 45 &&
                    startMinutes != 0
                ) {
                    let diff = 45 - startMinutes;
                    beginningTime = beginningTime.clone().add(diff, "m");
                } else if (startMinutes > 45) {
                    let diff = 60 - startMinutes;
                    beginningTime = beginningTime.clone().add(diff, "m");
                }

                let endTime = moment(item.endhours, "HH:mm");
                let _duration = moment
                    .duration(endTime.diff(beginningTime))
                    .asMinutes();
                for (var i = 0; i <= _duration; i += duration) {
                    var currTime = beginningTime.clone().add(i, "minutes");
                    var timeForShow = currTime.clone().format("HH:mm");
                    var addValue = {
                        value: timeForShow,
                        lastItem: i === _duration ? true : false,
                    };
                    //console.log();
                    if (
                        moment.duration(endTime.diff(currTime)).asMinutes() >=
                        duration
                    ) {
                        startTimes.push(addValue);
                    }
                }
            });
        }

        this.setState(
            {
                startTimes: startTimes,
                endTimes: [],
                appointmentData: {
                    ...this.state.appointmentData,
                    startDate: date,
                    startTime,
                    endTime: "",
                },
            },
            () => {
                // if (selectedTime) {
                //     this.filterEndHours(startTime, true);
                // }
            }
        );
    };

    checkFormValid = () => {
        this.form
            .isFormValid(true)
            .then((isValid) => {
                this.setState({
                    stepDisabled: !isValid,
                });
            })
            .catch(() => {
                this.setState({
                    stepDisabled: true,
                });
            });
    };

    submit = () => {
        this.form.submit();
    };

    handleSubmit = () => {
        const { appointmentData } = this.state,
            {
                rAppointment: { createAppointmentAcitveStep },
                handleStepChange,
                auth,
            } = this.props;
        if (createAppointmentAcitveStep != 3) {
            let step = createAppointmentAcitveStep + 1;
            handleStepChange(step);
            return;
        }

        this.setState({
            processing: true,
        });

        var data = new FormData();

        var date = moment(appointmentData.startDate).format("YYYY-MM-DD");
        let endTime = moment(appointmentData.startTime, "HH:mm")
            .add(appointmentData.duration, "minutes")
            .format("HH:mm");
        var userData = JSON.parse(localStorage.getItem("userData"));
        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);
        data.append("billable", appointmentData.isBillable ? 1 : 0);
        data.append("title", appointmentData.appointmentName);
        data.append("body", appointmentData.appointmentDescription);
        data.append("start", `${date}T${appointmentData.startTime}`);
        data.append("end", `${date}T${endTime}`);
        data.append("case_uuid", auth.case_uuid);
        if (appointmentData.isReschedule) {
            data.append("action", "edit");
            data.append("oldApptId", this.state.rescheduleAppointment.uuid);
        }

        fetch(`${process.env.REACT_APP_API_HOST_URL}/appointment/create`, {
            method: "POST",
            body: data,
        })
            .then((res) => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error("failed");
                }
                return res.json();
            })
            .then((resData) => {
                if (resData.success) {
                    if (resData.data.success) {
                        this.setState({
                            redirect: true,
                        });
                        this.props.handleStepChange(0);
                    }
                }
            })
            .catch((err) => {
                //console.log(err);
            });
    };

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        if (this.state.redirect) {
            return (
                <Redirect to={`/appointments/${this.props.auth.case_uuid}`} />
            );
        }

        const { classes, rAppointment, user } = this.props,
            {
                startTimes,
                appointmentData,
                processing,
                highlighted,
                monthShow,
                CaseData,
            } = this.state;

        var lawyerEmptyStripeInfo =
            "lawyer has not registered the needed financial information to enable billing.";
        if (user.is_lawyer) {
            lawyerEmptyStripeInfo =
                "Your account is missing business details, enter this information in your account to bill for your services";
        }
        return (
            <Fragment>
                <div
                    className="page--wrapper--no-margin"
                    style={{ paddingBottom: 0 }}
                >
                    <Paper className={classes.Paper}>
                        <div className={classes.pageContainer}>
                            {processing && <Processing />}

                            <ValidatorForm
                                ref={(r) => {
                                    this.form = r;
                                }}
                                onSubmit={this.handleSubmit}
                                onError={(errors) => console.log(errors)}
                                className={classes.formContainer}
                                instantValidate={true}
                            >
                                {rAppointment.createAppointmentAcitveStep ===
                                    0 && (
                                    <div className={classes.formGroup}>
                                        <FormControl fullWidth margin="normal">
                                            <SelectValidator
                                                fullWidth
                                                select
                                                label="Duration*"
                                                className={classes.textField}
                                                value={appointmentData.duration}
                                                onChange={this.handleSelectChange(
                                                    "duration"
                                                )}
                                                SelectProps={{
                                                    MenuProps: {
                                                        className: classes.menu,
                                                    },
                                                }}
                                                margin="normal"
                                                variant="outlined"
                                                validators={["required"]}
                                                errorMessages={[
                                                    "duration is required",
                                                ]}
                                                InputProps={{
                                                    classes: {
                                                        input: classes.resize,
                                                    },
                                                }}
                                            >
                                                <MenuItem value="">
                                                    Choose Duration
                                                </MenuItem>

                                                {durationList.map(
                                                    (option, index) =>
                                                        !option.lastItem ? (
                                                            <MenuItem
                                                                key={index}
                                                                value={
                                                                    option.value
                                                                }
                                                            >
                                                                {option.name}
                                                            </MenuItem>
                                                        ) : null
                                                )}
                                            </SelectValidator>
                                        </FormControl>

                                        <div className={classes.formGroup}>
                                            <Typography
                                                className={classes.formLabel}
                                            >
                                                EVENT DETAILS
                                            </Typography>

                                            <FormControl
                                                fullWidth
                                                margin="normal"
                                            >
                                                <TextValidator
                                                    variant="outlined"
                                                    placeholder="Appointment Name*"
                                                    label="Appointment Name*"
                                                    value={
                                                        appointmentData.appointmentName
                                                    }
                                                    onChange={this.handleInputChange(
                                                        "appointmentName"
                                                    )}
                                                    validators={["required"]}
                                                    errorMessages={[
                                                        "appointment name is required",
                                                    ]}
                                                    InputProps={{
                                                        classes: {
                                                            input:
                                                                classes.resize,
                                                        },
                                                    }}
                                                    fullWidth
                                                />
                                            </FormControl>

                                            <FormControl
                                                fullWidth
                                                margin="normal"
                                            >
                                                <TextField
                                                    variant="outlined"
                                                    multiline
                                                    rows={4}
                                                    placeholder="Appointment Description"
                                                    label="Appointment Description"
                                                    value={
                                                        appointmentData.appointmentDescription
                                                    }
                                                    onChange={this.handleInputChange(
                                                        "appointmentDescription"
                                                    )}
                                                />
                                            </FormControl>

                                            <FormControl>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                appointmentData.isBillable
                                                            }
                                                            onChange={this.handleCheckboxChange(
                                                                "isBillable"
                                                            )}
                                                            value="isBillable"
                                                            color="primary"
                                                            disabled={
                                                                appointmentData.billableDissabled
                                                            }
                                                        />
                                                    }
                                                    label="Billable Appointment"
                                                />
                                                {CaseData.hasOwnProperty(
                                                    "field_case_billing_type"
                                                ) &&
                                                    [
                                                        "Contingency",
                                                        "Pro Bono",
                                                    ].includes(
                                                        CaseData
                                                            .field_case_billing_type
                                                            .name
                                                    ) && (
                                                        <FormHelperText
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            Billing is disabled
                                                            for{" "}
                                                            {
                                                                CaseData
                                                                    .field_case_billing_type
                                                                    .name
                                                            }
                                                        </FormHelperText>
                                                    )}
                                                {/* {
                                                    (CaseData.hasOwnProperty('field_case_billing_type') && ['Hourly'].includes(CaseData.field_case_billing_type.name)) &&
                                                    ((!Object.keys(CaseData.client.field_stripe_account).length)) &&
                                                    <FormHelperText style={{ color: 'red' }}> client has not registered the needed financial information to enable
                                              billing.</FormHelperText>
                                                } */}
                                                {CaseData.hasOwnProperty(
                                                    "field_case_billing_type"
                                                ) &&
                                                    ["Hourly"].includes(
                                                        CaseData
                                                            .field_case_billing_type
                                                            .name
                                                    ) &&
                                                    !Object.keys(
                                                        CaseData.lawyer
                                                            .field_stripe_account
                                                    ).length && (
                                                        <FormHelperText
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            {" "}
                                                            {
                                                                lawyerEmptyStripeInfo
                                                            }
                                                        </FormHelperText>
                                                    )}
                                            </FormControl>
                                        </div>
                                    </div>
                                )}

                                {rAppointment.createAppointmentAcitveStep ===
                                    1 && (
                                    <div className={classes.formGroup}>
                                        <div style={{ paddingTop: 32 }}>
                                            <DayPicker
                                                showOutsideDays
                                                navbarElement={
                                                    <CalendarNavbar />
                                                }
                                                selectedDays={
                                                    appointmentData.startDate
                                                }
                                                modifiers={{
                                                    highlighted: highlighted,
                                                }}
                                                fromMonth={moment().toDate()}
                                                onMonthChange={
                                                    this
                                                        .handleDatePickerMonthChange
                                                }
                                                onDayClick={this.handleDayClick}
                                                disabledDays={this.filterDate}
                                                month={monthShow}
                                            />
                                        </div>
                                    </div>
                                )}

                                {rAppointment.createAppointmentAcitveStep ===
                                    2 && (
                                    <div className={classes.formGroup}>
                                        <FormControl fullWidth margin="normal">
                                            <SelectValidator
                                                fullWidth
                                                select
                                                label="Start*"
                                                className={classes.textField}
                                                value={
                                                    appointmentData.startTime
                                                }
                                                onChange={this.handleSelectChange(
                                                    "startTime"
                                                )}
                                                SelectProps={{
                                                    MenuProps: {
                                                        className: classes.menu,
                                                    },
                                                }}
                                                margin="normal"
                                                variant="outlined"
                                                validators={["required"]}
                                                errorMessages={[
                                                    "start time is required",
                                                ]}
                                                InputProps={{
                                                    classes: {
                                                        input: classes.resize,
                                                    },
                                                }}
                                            >
                                                {!startTimes.length && (
                                                    <MenuItem value="">
                                                        Choose Time
                                                    </MenuItem>
                                                )}
                                                {startTimes.map(
                                                    (option, index) =>
                                                        !option.lastItem ? (
                                                            <MenuItem
                                                                key={index}
                                                                value={
                                                                    option.value
                                                                }
                                                            >
                                                                {moment(
                                                                    option.value,
                                                                    "HH:mm"
                                                                ).format(
                                                                    "h:mm a"
                                                                )}{" "}
                                                                -{" "}
                                                                {moment(
                                                                    option.value,
                                                                    "HH:mm"
                                                                )
                                                                    .add(
                                                                        appointmentData.duration,
                                                                        "minutes"
                                                                    )
                                                                    .format(
                                                                        "h:mm a"
                                                                    )}
                                                            </MenuItem>
                                                        ) : null
                                                )}
                                            </SelectValidator>
                                        </FormControl>
                                    </div>
                                )}

                                {rAppointment.createAppointmentAcitveStep ===
                                    3 && (
                                    <Fragment>
                                        <div className={classes.formGroup}>
                                            <FormControl
                                                fullWidth
                                                margin="normal"
                                            >
                                                <Typography
                                                    className={
                                                        classes.formLabel
                                                    }
                                                >
                                                    When
                                                </Typography>
                                            </FormControl>

                                            <Grid container spacing={8}>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            disabled
                                                            label="Date"
                                                            value={moment(
                                                                appointmentData.startDate
                                                            ).format(
                                                                "MMM D, YYYY"
                                                            )}
                                                            margin="normal"
                                                            variant="outlined"
                                                            InputProps={{
                                                                classes: {
                                                                    input:
                                                                        classes.resize,
                                                                },
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            disabled
                                                            label="Start"
                                                            value={moment(
                                                                appointmentData.startTime,
                                                                "HH:mm"
                                                            ).format("h:mm a")}
                                                            margin="normal"
                                                            variant="outlined"
                                                            InputProps={{
                                                                classes: {
                                                                    input:
                                                                        classes.resize,
                                                                },
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            disabled
                                                            label="End"
                                                            value={moment(
                                                                appointmentData.startTime,
                                                                "HH:mm"
                                                            )
                                                                .add(
                                                                    appointmentData.duration,
                                                                    "minutes"
                                                                )
                                                                .format(
                                                                    "h:mm a"
                                                                )}
                                                            margin="normal"
                                                            variant="outlined"
                                                            InputProps={{
                                                                classes: {
                                                                    input:
                                                                        classes.resize,
                                                                },
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className={classes.formGroup}>
                                            <Typography
                                                className={classes.formLabel}
                                            >
                                                EVENT DETAILS
                                            </Typography>

                                            <FormControl
                                                fullWidth
                                                margin="normal"
                                            >
                                                <TextValidator
                                                    variant="outlined"
                                                    placeholder="Appointment Name*"
                                                    label="Appointment Name*"
                                                    value={
                                                        appointmentData.appointmentName
                                                    }
                                                    disabled
                                                    fullWidth
                                                />
                                            </FormControl>

                                            <FormControl
                                                fullWidth
                                                margin="normal"
                                            >
                                                <TextField
                                                    variant="outlined"
                                                    multiline
                                                    rows={4}
                                                    placeholder="Appointment Description"
                                                    label="Appointment Description"
                                                    value={
                                                        appointmentData.appointmentDescription
                                                    }
                                                    disabled
                                                />
                                            </FormControl>

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            appointmentData.isBillable
                                                        }
                                                        value="isBillable"
                                                        color="primary"
                                                        disabled
                                                    />
                                                }
                                                label="Billable Appointment"
                                            />
                                        </div>
                                    </Fragment>
                                )}
                            </ValidatorForm>
                        </div>
                    </Paper>
                </div>

                <MobileStepper
                    variant="progress"
                    steps={4}
                    position="bottom"
                    activeStep={rAppointment.createAppointmentAcitveStep}
                    className={classes.stepperRoot}
                    LinearProgressProps={{
                        color: "secondary",
                        classes: {
                            barColorSecondary: classes.stepperProggressLinear,
                        },
                    }}
                    classes={{
                        progress: classes.stepperProggress,
                    }}
                    backButton={
                        <Button
                            size="small"
                            className={classes.stepperBtn}
                            onClick={this.handlePrevStep}
                            disabled={
                                rAppointment.createAppointmentAcitveStep === 0
                            }
                            classes={{ disabled: classes.stepperBtnDisbaled }}
                        >
                            <KeyboardArrowLeft />
                            Back
                        </Button>
                    }
                    nextButton={
                        <Button
                            size="small"
                            className={classes.stepperBtn}
                            onClick={
                                rAppointment.createAppointmentAcitveStep < 3
                                    ? this.handleNextStep
                                    : this.submit
                            }
                            //disabled={this.state.stepDisabled}
                            //disabled={this.msg()}
                            disabled={this.isNextDisabled()}
                            classes={{ disabled: classes.stepperBtnDisbaled }}
                        >
                            {rAppointment.createAppointmentAcitveStep < 3 ? (
                                <Fragment>
                                    Next
                                    <KeyboardArrowRight />
                                </Fragment>
                            ) : (
                                "Confirm"
                            )}
                        </Button>
                    }
                />
            </Fragment>
        );
    }
}

AppointmentCreatePage.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        rAppointment: state.appointment,
        auth: state.auth,
        user: state.headerReducer.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleStepChange: (step) => {
            dispatch({
                type: "APPOINTMENTS_CREATE_STEP_CHANGE",
                payload: step,
            });
        },
    };
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(AppointmentCreatePage);
