import React from 'react';

export default React.createContext({
    token: null,
    sessionId: null,
    userEmail: null,
    case_uuid: null,
    login: (token, sessionId, userEmail, case_uuid) => { },
    logout: () => { },
    setCaseUuid: (case_uuid) => { },
});