import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemText, FormControl } from '@material-ui/core';
import moment from 'moment-timezone';
import { ElementsConsumer } from '@stripe/react-stripe-js';

import StripeBlock from './StripeBlock';


const styles = theme => ({
    root: {
        paddingRight: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 2,
        paddingTop: 20,
    },
    formGroup: {
        marginBottom: 10,
    },
    label: {
        fontWeight: 500,
        color: '#999999',
        textTransform: 'uppercase'
    },
});


function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

function findPracticeArea(parent, id) {
    let finded = parent.filter(([group, f]) => f.value === id);
    if (finded.length > 0) {
        return finded[0][1];
    }

    let search = parent.filter(([group, f]) => f.hasOwnProperty("children"));
    let isFinded = false;
    for (let i = 0; i < search.length; i++) {
        let children = Object.entries(search[i][1].children);
        for (let j = 0; j < children.length; j++) {
            if (children[j][1].value === id) {
                finded = children[j][1];
                isFinded = true;
                break;
            }
        }
        if (isFinded) {
            break;
        }
    }
    return finded;
}

class ConfirmBooking extends Component {
    render() {
        const { classes, newCase, legalIssues, locals, auth } = this.props;
        let lawyer = newCase.calendar_data.lawyer[newCase.lawyer];
        let practice = findPracticeArea(Object.entries(legalIssues), newCase.practice_area);
        let local = locals[newCase.practice_jurisdiction];
        return (
            <div className={classes.root}>
                {(auth.referral_source === "acba-lrs" && !auth.is_client_card_exists) &&
                    <Fragment>
                        <Typography gutterBottom>
                            Enter your billing information to confirm your appointment and pay the $40.00 referral fee
                        </Typography>
                        <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                            We accept Visa, Mastercard, American Express
                        </Typography>
                        {/* <StripeBlock /> */}
                        <ElementsConsumer>
                            {({ stripe, elements }) => {
                                return <StripeBlock stripe={stripe} elements={elements} />
                            }}
                        </ElementsConsumer>
                    </Fragment>
                }
                <div className={classes.formGroup}>
                    <Typography
                        variant="caption"
                        classes={{
                            caption: classes.label,
                        }}
                    >
                        Initial Consultation:
                    </Typography>
                    <Typography>
                        {moment(newCase.date).format('dddd, MMMM D, YYYY')}
                    </Typography>
                    <Typography>
                        {`${moment(newCase.start, 'HH:mm').format('hh:mm a')} - ${moment(newCase.start, 'HH:mm').add(newCase.default_duration, 'minutes').format('hh:mm a')}`}
                    </Typography>
                </div>

                <div className={classes.formGroup}>
                    <Typography
                        variant="caption"
                        classes={{
                            caption: classes.label,
                        }}
                    >
                        Who:
                    </Typography>
                    <List>
                        <ListItem alignItems="flex-start" style={{ padding: 0 }}>
                            <ListItemAvatar>
                                <Avatar alt="Remy Sharp" src={validURL(lawyer.image) ? lawyer.image : process.env.REACT_APP_DRUPAL_HOST_URL + lawyer.image} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={lawyer.name}
                            //secondary={lawyer.description.trim}
                            />
                        </ListItem>
                    </List>
                </div>

                <div className={classes.formGroup}>
                    <Typography
                        variant="caption"
                        classes={{
                            caption: classes.label,
                        }}
                    >
                        Legal Issue:
                    </Typography>
                    <Typography>
                        {practice.name}
                    </Typography>
                </div>

                <div className={classes.formGroup}>
                    <Typography
                        variant="caption"
                        classes={{
                            caption: classes.label,
                        }}
                    >
                        Jurisdiction:
                    </Typography>
                    <Typography>
                        {local.name}
                    </Typography>
                </div>

                <div className={classes.formGroup}>
                    <Typography
                        variant="caption"
                        classes={{
                            caption: classes.label,
                        }}
                    >
                        Case Description:
                    </Typography>
                    <Typography>
                        {newCase.description}
                    </Typography>
                </div>

            </div>
        )
    }
}

ConfirmBooking.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ConfirmBooking);