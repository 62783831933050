import React, { Component, Fragment } from "react";
import Video from "twilio-video";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import "./ConferenceRoom.css";
import {
    Grid,
    Fab,
    Typography,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
    ListItemText,
} from "@material-ui/core";
import { connect } from "react-redux";
import compose from "recompose/compose";
import {
    Videocam as VideocamIcon,
    VideocamOff as VideocamOffIcon,
    Mic as MicIcon,
    MicOff as MicOffIcon,
    CallEnd as CallEndIcon,
    MoreVert as MoreVertIcon,
} from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import moment from "moment";
//import Timer from "react-compound-timer";
import CallEndingNotification from "./CallEndingNotification";
import Timer from "../Timer";
import CompleteRoomModal from "./CompleteRoomModal";

const styles = (theme) => ({
    remoteMedia: {
        position: "absolute",
        top: "20px",
        right: "20px",
        zIndex: "1",
        //height: '140px',
        width: "110px",
    },
    localMedia: {
        height: "calc(100vh - 128px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    roomControlsWrapper: {
        position: "fixed",
        bottom: 80,
        left: 0,
        right: 0,
    },

    usedTimerText: {
        fontSize: 12,
        color: "#00CC99",
        fontWeight: 700,
    },

    remainingTimerText: {
        fontSize: 12,
        color: "#FF5555",
        fontWeight: 700,
    },
});

class VideoComponent extends Component {
    state = {
        activeRoom: null,
        videoControls: {
            disabled: false,
            muted: false,
        },
        moreMenuOpen: false,
        now_unix: moment().unix(),
        //timerStart: false,
        isShowEndingNotification: false,
        extendedTime: 0,
        isCompleteModalOpen: false,
    };

    componentDidMount() {
        window.addEventListener("beforeunload", this.leaveRoomIfJoined);
        // this.intervalID = setInterval(
        //     () => this.tick(),
        //     1000
        // );

        var data = new FormData();
        const {
            appointment: { uuid },
        } = this.props;
        const userData = JSON.parse(localStorage.getItem("userData"));

        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);
        data.append("uuid", uuid);

        fetch(`${process.env.REACT_APP_API_HOST_URL}/user/twilio/token`, {
            method: "POST",
            body: data,
        })
            .then((res) => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error("failed");
                }

                return res.json();
            })
            .then((resData) => {
                //console.log(resData);
                if (resData.success) {
                    // Join the Room with the token from the server and the
                    // LocalParticipant's Tracks.

                    Video.connect(resData.data.token)
                        .then((room) => {
                            //console.log(room);
                            this.setState(
                                {
                                    activeRoom: room,
                                },
                                () => {
                                    this.roomJoined(room);
                                }
                            );
                        })
                        .catch((error) => {
                            //console.log("Could not connect to Twilio: " + error.message);
                        });
                }
            })
            .catch((err) => {
                //console.log(err);
            });

        this.props.socket.on("call_extended", (data) => {
            //console.log("Call extended");
            this.setState({
                extendedTime: this.state.extendedTime + Number(data.duration),
            });
        });
    }

    componentWillUnmount() {
        //clearInterval(this.intervalID);
    }

    // tick() {
    //     this.setState({
    //         timerStart: true,
    //     });
    // };

    attachTracks = (tracks, container) => {
        tracks.forEach(function (track) {
            let attachment = track.attach();
            attachment.setAttribute("style", "width: 100%");
            container.appendChild(attachment);
        });
    };

    attachParticipantTracks = (participant, container) => {
        var tracks = this.getTracks(participant);
        //console.log(participant);
        //console.log(tracks);
        this.attachTracks(tracks, container);
    };

    // Get the Participant's Tracks.
    getTracks = (participant) => {
        return Array.from(participant.tracks.values())
            .filter(function (publication) {
                return publication.track;
            })
            .map(function (publication) {
                return publication.track;
            });
    };

    getVideoTracks = (participant) => {
        return Array.from(participant.videoTracks.values())
            .filter(function (publication) {
                return publication.track;
            })
            .map(function (publication) {
                return publication.track;
            });
    };

    getAudioTracks = (participant) => {
        return Array.from(participant.audioTracks.values())
            .filter(function (publication) {
                return publication.track;
            })
            .map(function (publication) {
                return publication.track;
            });
    };

    // Detach the Tracks from the DOM.
    detachTracks = (tracks) => {
        tracks.forEach(function (track) {
            track.detach().forEach(function (detachedElement) {
                detachedElement.remove();
            });
        });
    };

    // Detach the Participant's Tracks from the DOM.
    detachParticipantTracks = (participant) => {
        var tracks = this.getTracks(participant);
        this.detachTracks(tracks);
    };

    disableVideo = (participant) => {
        let tracks = this.getVideoTracks(participant);
        tracks.forEach((track) => {
            track.disable();
        });

        this.setState({
            videoControls: {
                ...this.state.videoControls,
                disabled: true,
            },
        });
    };

    enableVideo = (participant) => {
        let tracks = this.getVideoTracks(participant);
        tracks.forEach((track) => {
            track.enable();
        });

        this.setState({
            videoControls: {
                ...this.state.videoControls,
                disabled: false,
            },
        });
    };

    muteVideo = (participant) => {
        let tracks = this.getAudioTracks(participant);
        //console.log(tracks);

        tracks.forEach((track) => {
            //console.log('mute');
            track.disable();
        });

        this.setState({
            videoControls: {
                ...this.state.videoControls,
                muted: true,
            },
        });
    };

    unmuteVideo = (participant) => {
        let tracks = this.getAudioTracks(participant);
        tracks.forEach((track) => {
            track.enable();
        });

        this.setState({
            videoControls: {
                ...this.state.videoControls,
                muted: false,
            },
        });
    };

    roomJoined = (room) => {
        window.room = room.name;
        const { appointment } = this.props;
        const userData = JSON.parse(localStorage.getItem("userData"));

        this.props.joinConference(appointment.uuid);

        var previewContainer = this.refs.remoteMedia;
        if (!previewContainer.querySelector("video")) {
            this.attachParticipantTracks(
                room.localParticipant,
                previewContainer
            );
        }

        // Attach the Tracks of the Room's Participants.
        room.participants.forEach((participant) => {
            //console.log("Already in Room: ", participant);
            let identity_arr = participant.identity.split("-");
            let uid = identity_arr[0];
            //console.log(uid);
            var previewContainer =
                (uid === appointment.owners.lawyer ||
                    uid === appointment.owners.client) &&
                this.refs.localMedia.childElementCount <= 2
                    ? this.refs.localMedia
                    : this.refs.remoteMedia; //document.getElementById('remote-media');
            this.attachParticipantTracks(participant, previewContainer);
        });

        room.on("trackSubscribed", (track, publication, participant) => {
            //console.log("Subscribed to " + participant.identity + "'s track: " + track.kind);
            //var previewContainer = this.refs.localMedia;//document.getElementById('remote-media');
            let identity_arr = participant.identity.split("-");
            let uid = identity_arr[0];
            //console.log(uid);
            var previewContainer =
                (uid === appointment.owners.lawyer ||
                    uid === appointment.owners.client) &&
                this.refs.localMedia.childElementCount <= 2
                    ? this.refs.localMedia
                    : this.refs.remoteMedia;
            this.attachTracks([track], previewContainer);
        });

        // When a Participant's Track is unsubscribed from, detach it from the DOM.
        room.on("trackUnsubscribed", (track, publication, participant) => {
            //console.log("Unsubscribed from " + participant.identity + "'s track: " + track.kind);
            this.detachTracks([track]);
        });

        // When a Participant leaves the Room, detach its Tracks.
        room.on("participantDisconnected", (participant) => {
            //console.log("RemoteParticipant '" + participant.identity + "' left the room");
            this.detachParticipantTracks(participant);
        });

        // Once the LocalParticipant leaves the room, detach the Tracks
        // of all Participants, including that of the LocalParticipant.
        room.on("disconnected", () => {
            if (this.previewTracks) {
                this.previewTracks.forEach(function (track) {
                    track.stop();
                });
                this.previewTracks = null;
            }
            this.detachParticipantTracks(room.localParticipant);
            room.participants.forEach(this.detachParticipantTracks);
            this.setState(
                {
                    activeRoom: null,
                },
                () => {
                    this.props.leaveConference();
                    this.props.handleRoomDisconected();
                }
            );
        });
    };

    leaveRoomIfJoined = () => {
        if (this.state.activeRoom) {
            this.state.activeRoom.disconnect();
            this.props.leaveConference();
        }
    };

    completeRoom = () => {
        const { appointment } = this.props;
        let data = new FormData();
        const userData = JSON.parse(localStorage.getItem("userData"));

        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);
        data.append("roomName", appointment.uuid);

        fetch(
            `${process.env.REACT_APP_API_HOST_URL}/appointment/complete_conference`,
            {
                method: "POST",
                body: data,
            }
        )
            .then((res) => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error("failed");
                }

                return res.json();
            })
            .then((resData) => {
                //console.log(resData);
                if (resData.success) {
                }
            })
            .catch((err) => {
                //console.log(err);
            });
    };

    handleOpenCompleteRoomModal = () => {
        this.setState({ isCompleteModalOpen: true });
    };

    handleCloseCompleteRoomModal = () => {
        this.setState({ isCompleteModalOpen: false });
    };

    handleCompleteRoom = () => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        const { appointment } = this.props;
        this.setState({
            isCompleteModalOpen: false,
        });
        if (
            userData.uid !== appointment.owners.client &&
            userData.uid !== appointment.owners.lawyer
        ) {
            this.leaveRoomIfJoined();
            this.props.history.push("/dashboard");
        } else {
            //console.log('complete room');
            this.completeRoom();
        }
    };

    pad2 = (number) => {
        return (number < 10 ? "0" : "") + number;
    };

    handleMoreMenuToggle = () => {
        this.setState((state) => ({ moreMenuOpen: !state.moreMenuOpen }));
    };

    handleMoreMenuClose = (event) => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }

        this.setState({ moreMenuOpen: false });
    };

    handleCloseCallEndNotify = () => {
        //console.log("close");
        this.setState({
            isShowEndingNotification: false,
        });
    };

    handleShowCallEndNotify = () => {
        this.setState({
            isShowEndingNotification: true,
        });
    };

    render() {
        const { classes, appointment, extenCallModalOpen } = this.props,
            {
                activeRoom,
                videoControls,
                moreMenuOpen,
                now_unix,
                isShowEndingNotification,
                extendedTime,
                isCompleteModalOpen,
            } = this.state;
        const userData = JSON.parse(localStorage.getItem("userData"));
        let startInterval = now_unix - appointment.from;
        const usedTime = startInterval < 0 ? 0 : now_unix - appointment.from;
        const remainingTime =
            appointment.to - appointment.from - usedTime + Number(extendedTime);
        let timerStart = usedTime >= 0 ? true : false;

        let showExtend =
            userData.uid == appointment.owners.client && appointment.billable;
        return (
            <Fragment>
                {isShowEndingNotification && (
                    <div
                        style={{
                            position: "fixed",
                            width: "100%",
                            zIndex: 1000,
                        }}
                    >
                        <CallEndingNotification
                            showExtend={showExtend}
                            extendCall={extenCallModalOpen}
                            closeNotify={this.handleCloseCallEndNotify}
                        />
                    </div>
                )}
                <div style={{ position: "relative" }}>
                    <div
                        id="remote-media"
                        ref="remoteMedia"
                        className={classes.remoteMedia}
                    ></div>
                    <div className={classes.localMedia}>
                        <div id="preview">
                            <div ref="localMedia" id="local-media"></div>
                        </div>
                    </div>
                    {activeRoom && (
                        <div
                            ref="roomControls"
                            className={classes.roomControlsWrapper}
                        >
                            <Grid container style={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Grid
                                        container
                                        alignItems="center"
                                        justify="space-between"
                                    >
                                        <Grid item>
                                            <div
                                                style={{
                                                    padding: 8,
                                                    textAlign: "left",
                                                }}
                                            >
                                                <Timer
                                                    variant="green"
                                                    startValues={usedTime}
                                                    start={true}
                                                    startFrom={
                                                        startInterval > 0
                                                            ? 0
                                                            : startInterval *
                                                              1000 *
                                                              -1
                                                    }
                                                />
                                                {/* <Timer initialTime={usedTime * 1000} startImmediately={timerStart} lastUnit="h"
                                                >
                                                    {({ start, timerState }) => {
                                                        return (
                                                            <React.Fragment>
                                                                <Typography className={classes.usedTimerText}>
                                                                    <Timer.Hours
                                                                        formatValue={value => {
                                                                            if (value > 0) {
                                                                                return `${this.pad2(value)}:`
                                                                            }
                                                                            else {
                                                                                return ''
                                                                            }
                                                                        }}
                                                                    />
                                                                    <Timer.Minutes formatValue={value => `${this.pad2(value)}:`} />
                                                                    <Timer.Seconds formatValue={value => this.pad2(value)} />
                                                                </Typography>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                    }
                                                </Timer> */}
                                                <Typography
                                                    className={
                                                        classes.usedTimerText
                                                    }
                                                >
                                                    Used
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div style={{ padding: 4 }}>
                                                {!videoControls.disabled && (
                                                    <Fab
                                                        size="small"
                                                        color="primary"
                                                        onClick={() =>
                                                            this.disableVideo(
                                                                activeRoom.localParticipant
                                                            )
                                                        }
                                                    >
                                                        <VideocamIcon />
                                                    </Fab>
                                                )}
                                                {videoControls.disabled && (
                                                    <Fab
                                                        size="small"
                                                        color="primary"
                                                        onClick={() =>
                                                            this.enableVideo(
                                                                activeRoom.localParticipant
                                                            )
                                                        }
                                                    >
                                                        <VideocamOffIcon />
                                                    </Fab>
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div style={{ padding: 4 }}>
                                                {!videoControls.muted && (
                                                    <Fab
                                                        size="small"
                                                        color="primary"
                                                        onClick={() =>
                                                            this.muteVideo(
                                                                activeRoom.localParticipant
                                                            )
                                                        }
                                                    >
                                                        <MicIcon />
                                                    </Fab>
                                                )}

                                                {videoControls.muted && (
                                                    <Fab
                                                        size="small"
                                                        color="primary"
                                                        onClick={() =>
                                                            this.unmuteVideo(
                                                                activeRoom.localParticipant
                                                            )
                                                        }
                                                    >
                                                        <MicOffIcon />
                                                    </Fab>
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div style={{ padding: 4 }}>
                                                <Fab
                                                    size="small"
                                                    color="secondary"
                                                    //onClick={this.handleCompleteRoom}
                                                    onClick={() =>
                                                        this.handleOpenCompleteRoomModal()
                                                    }
                                                >
                                                    <CallEndIcon />
                                                </Fab>
                                            </div>
                                        </Grid>
                                        {/* <Grid item>
                                            <div style={{ padding: 4 }}>
                                                <Fab size="small" color="primary"
                                                    buttonRef={node => {
                                                        this.anchorEl = node;
                                                    }}
                                                    aria-owns={moreMenuOpen ? 'menu-list-grow' : undefined}
                                                    aria-haspopup="true"
                                                    onClick={this.handleMoreMenuToggle}
                                                >
                                                    <MoreVertIcon />
                                                </Fab>

                                                <Popper
                                                    open={moreMenuOpen}
                                                    anchorEl={this.anchorEl}
                                                    transition
                                                    disablePortal
                                                    placement="top-end"
                                                >
                                                    {({ TransitionProps, placement }) => (
                                                        <Grow
                                                            {...TransitionProps}
                                                            id="menu-list-grow"

                                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                                        >
                                                            <Paper>
                                                                <ClickAwayListener onClickAway={this.handleMoreMenuClose}>
                                                                    <MenuList>
                                                                        {((userData.uid == appointment.owners.client) && (appointment.billable && timerStart)) &&
                                                                            <MenuItem onClick={extenCallModalOpen}>
                                                                                Extend call
                                                                        </MenuItem>
                                                                        }
                                                                        <MenuItem>
                                                                            Screenshare
                                                                    </MenuItem>
                                                                    </MenuList>
                                                                </ClickAwayListener>
                                                            </Paper>
                                                        </Grow>
                                                    )}
                                                </Popper>
                                            </div>
                                        </Grid> */}
                                        <Grid item>
                                            <div
                                                style={{
                                                    padding: 8,
                                                    textAlign: "right",
                                                }}
                                            >
                                                <Timer
                                                    variant="red"
                                                    startValues={remainingTime}
                                                    countDown={true}
                                                    startFrom={
                                                        startInterval > 0
                                                            ? 0
                                                            : startInterval *
                                                              1000 *
                                                              -1
                                                    }
                                                    showNotification={
                                                        this
                                                            .handleShowCallEndNotify
                                                    }
                                                />
                                                {/* <Timer
                                                    initialTime={remainingTime * 1000}
                                                    direction="backward"
                                                    startImmediately={timerStart}
                                                    lastUnit="h"
                                                    checkpoints={[
                                                        {
                                                            time: 5 * 60 * 1000,
                                                            callback: () => this.handleShowCallEndNotify(),
                                                        },
                                                    ]}
                                                >
                                                    {({ start, timerState }) => {
                                                        return (
                                                            <React.Fragment>
                                                                <Typography className={classes.remainingTimerText}>
                                                                    <Timer.Hours
                                                                        formatValue={value => {
                                                                            if (value > 0) {
                                                                                return `${this.pad2(value)}:`
                                                                            }
                                                                            else {
                                                                                return ''
                                                                            }
                                                                        }}
                                                                    />
                                                                    <Timer.Minutes formatValue={value => `${this.pad2(value)}:`} />
                                                                    <Timer.Seconds formatValue={value => this.pad2(value)} />
                                                                </Typography>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                    }
                                                </Timer> */}
                                                <Typography
                                                    className={
                                                        classes.remainingTimerText
                                                    }
                                                >
                                                    REMAINING
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </div>

                <CompleteRoomModal
                    open={isCompleteModalOpen}
                    handleClose={this.handleCloseCompleteRoomModal}
                    handleCompleteRoom={this.handleCompleteRoom}
                />
            </Fragment>
        );
    }
}

VideoComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return {
        joinConference: (uuid) =>
            dispatch({ type: "UTILITY_JOIN_CONFERENCE", payload: uuid }),
        leaveConference: () => dispatch({ type: "UTILITY_LEAVE_CONFERENCE" }),
    };
};

//export default withRouter(withStyles(styles)(VideoComponent));

export default compose(
    withRouter,
    withStyles(styles),
    connect(null, mapDispatchToProps)
)(VideoComponent);
