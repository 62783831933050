import {
    UTILITY_FETCH_REJECTION_REASONS,
    UTILITY_JOIN_CONFERENCE,
    UTILITY_LEAVE_CONFERENCE,
    UTILITY_FETCH_LEGAL_ISSUES,
    UTILITY_FETCH_LOCALS,
    UTILITY_FETCH_CASE_BILLING_TYPES,
    UTILITY_FETCH_CONNECT_OPTIONS
} from "../actions/actionTypes";

const initState = {
    rejectionReasons: [],
    isActiveCall: false,
    conference_uuid: null,
    legalIssues: [],
    locals: [],
    billing_types: [],
    connect_options: [],
    //legalIssuesLoaded: false,
};

const utilityReducer = (state = initState, action) => {
    switch (action.type) {
        case UTILITY_FETCH_REJECTION_REASONS:
            return {
                ...state,
                rejectionReasons: action.payload,
            };
        case UTILITY_JOIN_CONFERENCE:
            return {
                ...state,
                isActiveCall: true,
                conference_uuid: action.payload,
            };
        case UTILITY_LEAVE_CONFERENCE:
            return {
                ...state,
                isActiveCall: false,
                conference_uuid: null,
            };
        case UTILITY_FETCH_LEGAL_ISSUES:
            return {
                ...state,
                legalIssues: action.payload,
            };
        case UTILITY_FETCH_LOCALS:
            return {
                ...state,
                locals: action.payload,
            };
        case UTILITY_FETCH_CASE_BILLING_TYPES:
            return {
                ...state,
                billing_types: action.payload,
            };
        case UTILITY_FETCH_CONNECT_OPTIONS:
            return {
                ...state,
                connect_options: action.payload,
            }
        default:
            return state;
    }
};

export default utilityReducer;