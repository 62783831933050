import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper, List, ListItem, ListItemText } from '@material-ui/core';
import { Alert } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { PageHading, Loading, NotificationSnackbar } from '../Components/Layouts';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { AUTH_SET_CASE_UUID, LOGOUT } from '../redux/actions/actionTypes';

const styles = theme => ({
    Paper: {
        overflow: 'hidden',
        padding: '8px 20px',
    },
});

class CaseListPage extends Component {
    state = {
        cases: [],
        loading: true,
        redirect: false,
        notificationSnackbar: {
            open: false,
            variant: '',
            message: '',
        },
    }

    componentDidMount() {
        this.getCasesList();
    }

    getCasesList() {
        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('case', "all");

        fetch(`${process.env.REACT_APP_API_HOST_URL}/cases`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('failed');
                }

                return res.json();
            })
            .then(resData => {
                console.log("cases", resData);
                if (resData.success) {
                    this.setState({
                        cases: resData.data,
                        loading: false,
                    })
                } else {
                    this.setState({
                        loading: false,
                    })
                }

                if (!resData.success && (resData.message == 'Session too old.' || resData.message == 'No session found.')) {
                    this.props.logout();
                }
            })
            .catch(err => {
                //console.log(err);
            });
    }

    goToCase = (case_uuid) => {
        var data = new FormData();

        var userData = JSON.parse(localStorage.getItem("userData"));
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('case_uuid', case_uuid);

        fetch(`${process.env.REACT_APP_API_HOST_URL}/user/last_case/update`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('failed');
                }

                return res.json();
            })
            .then(resData => {
                if (resData.success) {
                    this.props.setCaseUuid(case_uuid);
                    this.setState({
                        redirect: true,
                        path: `/dashboard/${case_uuid}`,
                    })
                }
                else {
                    this.setState({
                        notificationSnackbar: {
                            open: true,
                            variant: 'error',
                            message: resData.message,
                        }
                    });
                }
            })
            .catch(err => {
                //console.log(err);
            });


    };

    handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({
            notificationSnackbar: {
                open: false,
                variant: '',
                message: '',
            }
        });
    };

    render() {
        if (this.state.loading) {
            return <Loading />
        }

        if (this.state.redirect) {
            return <Redirect to={this.state.path} />
        }

        const { classes, auth } = this.props;
        const { cases, notificationSnackbar } = this.state;

        const caseList = cases.length ? cases.map((caseitem) => {
            return (
                <ListItem button
                    key={caseitem.id}
                    disableGutters={true}
                    //component={NavLink}
                    onClick={() => this.goToCase(caseitem.id)}
                >
                    <ListItemText
                        primary={caseitem.title}
                        secondary={caseitem.id}
                    />
                </ListItem>
            )
        }) :
            (
                <Alert color="primary">
                    No Cases
                </Alert>
            );

        //console.log(auth);
        return (
            <div className="page--wrapper--no-margin">
                <Paper className={classes.Paper}>
                    <PageHading title={"Cases"} history={this.props.history} />
                    <List>
                        {!auth.case_uuid &&
                            <Alert color="info">
                                Please {cases.length ? "select" : "create"} a case for continue
                            </Alert>
                        }
                        {caseList}
                    </List>
                    {notificationSnackbar.open &&
                        <NotificationSnackbar
                            snackbarOptions={notificationSnackbar}
                            handleClose={this.handleSnackbarClose}
                        />
                    }
                </Paper>
            </div>
        )
    }
}

CaseListPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCaseUuid: (case_uuid) => { dispatch({ type: AUTH_SET_CASE_UUID, payload: case_uuid }) },
        logout: () => dispatch({ type: LOGOUT, payload: true }),
    }
};

export default compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
)(CaseListPage);

//export default withStyles(styles)(CaseListPage);
