import {
    FILES_FETCH_BEGINING_DATA,
    FILES_UPLOAD_CASE_FILE,
    FILES_SHOW_PROCESSING,
    FILES_REMOVE_FILE,
    FILES_SHOW_ERROR,
    FILES_HIDE_ERROR,
    FILES_SHARE_FILE,
    FILES_HIDE_PROCESSING
} from '../actions/actionTypes';
const initState = {
    loaded: false,
    processing: false,
    files: [],
    notificationSnackbar: {
        open: false,
        variant: '',
        message: '',
    },
};

const fileReducer = (state = initState, action) => {
    switch (action.type) {
        case FILES_FETCH_BEGINING_DATA:
            return {
                ...state,
                loaded: true,
                files: action.payload,
            };
        case FILES_UPLOAD_CASE_FILE:
            state.files.unshift(action.payload);
            return {
                ...state,
                processing: false,
            };
        case FILES_SHOW_PROCESSING:
            return {
                ...state,
                processing: true,
            };
        case FILES_HIDE_PROCESSING:
            return {
                ...state,
                processing: false,
            };
        case FILES_REMOVE_FILE:
            let newFiles = state.files.filter(file => file.fid !== action.payload.fileid);
            return {
                ...state,
                processing: false,
                files: newFiles,
            }
        case FILES_SHARE_FILE:
            let sharedFile = state.files.find(file => file.fid === action.payload.fileid);
            sharedFile.isShared = 1;
            return {
                ...state,
                processing: false,
                //files: newFiles,
            };
        case FILES_SHOW_ERROR:
            return {
                ...state,
                processing: false,
                notificationSnackbar: {
                    open: true,
                    variant: action.payload.variant,
                    message: action.payload.message,
                },
            };
        case FILES_HIDE_ERROR:
            return {
                ...state,
                processing: false,
                notificationSnackbar: initState.notificationSnackbar,
            };
        default:
            return state;
    }
};

export default fileReducer;