import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
    Divider,
    Fab,
    Popper,
    Grow,
    Paper,
    MenuItem,
    MenuList,
    ListItemIcon,
    ListItemText,
    ClickAwayListener,
    Typography,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import {
    Loading,
    CalendarNavbar,
    Processing,
    NotificationSnackbar,
} from "../Components/Layouts";
import {
    DateRangeIcon,
    ListIcon,
    AppointmentIcon,
    TaskIcon,
} from "../Components/Icons";
import { AppointmentItems } from "../Components/Appointement";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import "../styles/calendar.css";
import moment from "moment-timezone";
import { connect } from "react-redux";
import compose from "recompose/compose";
import {
    fetchAppointmentsList,
    cancelAppointment,
    rejectAppointment,
    acceptAppointment,
    completeUnattendedAppointment,
    completeAppointment,
} from "../redux/actions/appointmentActions";
import TaskItem from "../Components/Task/TaskItem";
import { APPOINTMENTS_HIDE_ERROR } from "../redux/actions/actionTypes";

const styles = (theme) => ({
    apptTitle: {
        fontWeight: "700",
        fontStyle: "normal",
        fontSize: "15px",
        color: "#220D89",
        //marginLeft: '15px',
    },

    apptSubTitle: {
        color: "#999999",
        fontSize: "15px",
    },

    dividerStyle: {
        marginTop: "15px",
    },
    toolbarStyle: {
        marginTop: "10px",
        marginBottom: "10px",
    },
    bookedItem: {
        width: "16px",
        height: "16px",
        backgroundColor: "#220D89",
        float: "left",
    },

    pendingItem: {
        width: "16px",
        height: "16px",
        backgroundColor: "white",
        float: "left",
        borderColor: "#220D89",
        borderStyle: "solid",
        borderWidth: "thin",
        marginLeft: "15px",
    },

    toolbarLabel: {
        fontSize: "10px",
        fontWeight: "700",
        color: "#999999",
        marginLeft: "10px",
    },

    toolBarIcon: {
        width: "16px",
        height: "16px",
        marginLeft: "10px",
        color: "#999999",
    },

    fab: {
        position: "fixed",
        bottom: 100,
        right: theme.spacing.unit * 2,
        zIndex: 1001,
    },

    menuPaper: {
        backgroundColor: "#220d89",
        padding: theme.spacing.unit,
    },
    menuIcon: {
        color: "#fff",
    },
    menuPrimary: {
        color: "#fff",
        fontSize: 13,
    },
    menuHeader: {
        textTransform: "uppercase",
        fontSize: 10,
        color: "#CCCCCC",
    },
    verDivider: {
        width: 2,
        height: 16,
        margin: "4px 8px",
    },
});

const taskCreateAvailableStatusArray = [
    "accepted",
    "reviewed",
    "active",
    "hold",
];

class AppointmentsPage extends Component {
    state = {
        appointments: [],
        highlighted: [],
        timeZone: null,
        loading: true,
        apointmentViewMode: 1,
        viewMode: 1, // 1: appointments, 2: task
        isOpen: false,
        _case: null,
    };

    componentDidMount() {
        //const { match: { params: { id } } } = this.props;
        var userData = JSON.parse(localStorage.getItem("userData"));
        var data = new FormData();
        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);
        this.getCase(this.props.auth.case_uuid);
        this.props.fetchAppointmentsList(this.props.auth.case_uuid, data);
        //this.props.fetchTasksList(this.props.auth.case_uuid, data);
    }

    getCase(case_uuid) {
        let data = new FormData();

        let userData = JSON.parse(localStorage.getItem("userData"));

        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);

        fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/${case_uuid}`, {
            method: "POST",
            body: data,
        })
            .then((res) => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error("failed");
                }

                return res.json();
            })
            .then((resData) => {
                console.log(resData);
                this.setState({
                    _case: resData.data,
                });
            })
            .catch((err) => {
                //console.log(err);
                this.setState({
                    error: true,
                });
            });
    }

    groupAppointmentsByDate = (appointments, timeZone) => {
        const groups = appointments.reduce((groups, appointment) => {
            const date = moment
                .unix(appointment.from)
                .tz(timeZone)
                .startOf("month")
                .unix(); //.format("MM/YYYY");
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(appointment);
            return groups;
        }, {});

        const groupArrays = Object.keys(groups).map((date) => {
            return {
                date,
                appointments: groups[date],
            };
        });

        return groupArrays;
    };

    changeAppointmentViewMode = (mode) => {
        this.setState({
            apointmentViewMode: mode,
        });
    };

    changeViewMode = (mode) => {
        this.setState({
            viewMode: mode,
        });
    };

    setInitState(data) {
        const hilighted = data.appointments.map((appointment) => {
            return moment.unix(appointment.from).toDate();
        });

        this.setState({
            appointments: data.appointments,
            highlighted: hilighted,
            timeZone: data.time_zone,
            loading: false,
        });
    }

    handleToggle = () => {
        this.setState((state) => ({ isOpen: !state.open }));
    };

    handleClose = (event) => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }

        this.setState({ isOpen: false });
    };

    getStyle = (index) => {
        if (this.state.viewMode === index) {
            return {
                color: "#220D89",
                fontWeight: 700,
            };
        }
    };

    /* APPOINTMENT ACTIONS */
    handleAcceptAppointment = (appointment_uuid) => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);
        data.append("uuid", appointment_uuid);

        this.props.acceptAppointment(appointment_uuid, data);
    };

    handleCancelAppointment = (cancel_data) => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        const { isLawyer } = this.props;
        let data = new FormData();
        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);
        data.append("uuid", cancel_data.uuid);
        data.append("comment", cancel_data.comment);
        data.append("reason", cancel_data.reason);
        //console.log(cancel_data);
        this.props.cancelAppointment(cancel_data.uuid, isLawyer, data);
    };

    handleRejectAppointment = (reject_data) => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);
        data.append("uuid", reject_data.uuid);
        data.append("comment", reject_data.comment);
        data.append("reason", reject_data.reason);
        this.props.rejectAppointment(reject_data.uuid, data);
    };

    handleUnattendedAppointmentComplete = (appointment_uuid) => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);
        data.append("uuid", appointment_uuid);

        this.props.completeUnattendedAppointment(appointment_uuid, data);
    };

    handleCompleteAppointment = (appointment_uuid) => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);
        data.append("uuid", appointment_uuid);

        this.props.completeAppointment(appointment_uuid, data);
    };

    render() {
        const {
                classes,
                history,
                rejectionReasons,
                isLawyer,
                appointmentList,
                auth,
            } = this.props,
            {
                highlighted,
                apointmentViewMode,
                viewMode,
                isOpen,
                _case,
            } = this.state;

        if (!appointmentList.loaded || _case === null) {
            return <Loading />;
        }

        const appointmentsGroup = this.groupAppointmentsByDate(
            appointmentList.appointments,
            appointmentList.timeZone
        );
        //console.log(appointmentsGroup);
        return (
            <Fragment>
                <div className="page--wrapper">
                    <div style={{ marginBottom: 10 }}>
                        <span
                            className={classes.apptSubTitle}
                            style={this.getStyle(1)}
                        >
                            Appointments
                        </span>
                    </div>
                    {/* {viewMode === 1 && ( */}
                    <Fragment>
                        {appointmentList.processing && <Processing />}
                        <Divider
                            variant="fullWidth"
                            className={classes.dividerStyle}
                        />
                        <div className={classes.toolbarStyle}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {/* todo: decommissioned until later time.
                                    <div className={classes.bookedItem}></div> <span className={classes.toolbarLabel}>BOOKED</span>
                                    <div className={classes.pendingItem}></div> <span className={classes.toolbarLabel}>PENDING</span>
                                    */}
                                <div style={{ flexGrow: "1" }}></div>
                                {/* todo: decommissioned until later time.
                                    <span className={classes.toolbarLabel}>VIEW</span>
                                    <DateRangeIcon
                                        className={classes.toolBarIcon}
                                        onClick={() => this.changeAppointmentViewMode(0)}
                                    />
                                    <ListIcon
                                        className={classes.toolBarIcon}
                                        onClick={() => this.changeAppointmentViewMode(1)}
                                    />
                                    */}
                            </div>
                        </div>
                        {apointmentViewMode === 0 && (
                            <DayPicker
                                showOutsideDays
                                navbarElement={<CalendarNavbar />}
                                modifiers={{ highlighted: highlighted }}
                            />
                        )}
                        <AppointmentItems
                            appointments={appointmentsGroup}
                            rejectionReasons={rejectionReasons}
                            timeZone={appointmentList.timeZone}
                            acceptAppointment={this.handleAcceptAppointment}
                            cancelAppointment={this.handleCancelAppointment}
                            rejectAppointment={this.handleRejectAppointment}
                            unattendedAppointmentComplete={
                                this.handleUnattendedAppointmentComplete
                            }
                            completeAppointment={this.handleCompleteAppointment}
                            isLawyer={isLawyer}
                            auth={auth}
                        />
                        {appointmentList.notificationSnackbar.open && (
                            <NotificationSnackbar
                                snackbarOptions={
                                    appointmentList.notificationSnackbar
                                }
                                handleClose={this.props.hideAppointmentError}
                            />
                        )}
                    </Fragment>

                    {taskCreateAvailableStatusArray.includes(
                        _case.status.name.toLowerCase()
                    ) && (
                        <Fragment>
                            <Fab
                                className={classes.fab}
                                color="primary"
                                buttonRef={(node) => {
                                    this.anchorEl = node;
                                }}
                                aria-owns={
                                    isOpen ? "menu-list-grow" : undefined
                                }
                                aria-haspopup="true"
                                onClick={this.handleToggle}
                            >
                                <AddIcon />
                            </Fab>
                            <Popper
                                open={isOpen}
                                anchorEl={this.anchorEl}
                                transition
                                disablePortal
                                placement="top-start"
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        id="menu-list-grow"
                                        style={{
                                            transformOrigin:
                                                placement === "bottom"
                                                    ? "center top"
                                                    : "center bottom",
                                        }}
                                        //style={{ transformOrigin: 'center top' }}
                                    >
                                        <Paper className={classes.menuPaper}>
                                            <ClickAwayListener
                                                onClickAway={this.handleClose}
                                            >
                                                <Fragment>
                                                    <Typography
                                                        className={
                                                            classes.menuHeader
                                                        }
                                                    >
                                                        Create
                                                    </Typography>
                                                    <MenuList>
                                                        <MenuItem
                                                            disableGutters
                                                            onClick={() =>
                                                                history.push(
                                                                    "/appointment/create"
                                                                )
                                                            }
                                                        >
                                                            <ListItemIcon
                                                                className={
                                                                    classes.menuIcon
                                                                }
                                                            >
                                                                <AppointmentIcon
                                                                    style={{
                                                                        fontSize: 18,
                                                                    }}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                classes={{
                                                                    primary:
                                                                        classes.menuPrimary,
                                                                }}
                                                                primary="New Appointment"
                                                            />
                                                        </MenuItem>
                                                    </MenuList>
                                                </Fragment>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </Fragment>
                    )}
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        //taskList: state.task,
        rejectionReasons: state.utility.rejectionReasons,
        isLawyer: state.headerReducer.user.is_lawyer,
        appointmentList: state.appointment,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAppointmentsList: (case_uuid, data) =>
            dispatch(fetchAppointmentsList(case_uuid, data)),
        acceptAppointment: (appointment_uuid, data) =>
            dispatch(acceptAppointment(appointment_uuid, data)),
        cancelAppointment: (appointment_uuid, isLawyer, data) =>
            dispatch(cancelAppointment(appointment_uuid, isLawyer, data)),
        rejectAppointment: (appointment_uuid, data) =>
            dispatch(rejectAppointment(appointment_uuid, data)),
        hideAppointmentError: () => dispatch({ type: APPOINTMENTS_HIDE_ERROR }),
        completeUnattendedAppointment: (appointment_uuid, data) =>
            dispatch(completeUnattendedAppointment(appointment_uuid, data)),
        completeAppointment: (appointment_uuid, data) =>
            dispatch(completeAppointment(appointment_uuid, data)),
    };
};

AppointmentsPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(AppointmentsPage);
