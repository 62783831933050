import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Paper, List, ListItem } from "@material-ui/core";
import { PageHading, Loading } from "../Components/Layouts";
import AccountActivityItem from "../Components/ActivityItem";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { fetchHeaderData } from "../redux/actions/headerActions";
import { AUTH_SET_CASE_UUID, LOGOUT } from "../redux/actions/actionTypes";

const styles = (theme) => ({
    Paper: {
        padding: "8px 20px",
    },
});

class AccountMessagesPage extends Component {
    state = {
        activity: {},
        loading: true,
    };

    componentDidMount() {
        this.getActivities();
    }

    getActivities() {
        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);

        fetch(`${process.env.REACT_APP_API_HOST_URL}/activities`, {
            method: "POST",
            body: data,
        })
            .then((res) => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error("failed");
                }

                return res.json();
            })
            .then((resData) => {
                console.log("Activities", resData);
                if (resData.success) {
                    this.setState({
                        activity: resData.data,
                        loading: false,
                    });
                }

                if (
                    !resData.success &&
                    (resData.message == "Session too old." ||
                        resData.message == "No session found.")
                ) {
                    this.props.logout();
                }
            })
            .catch((err) => {
                //console.log(err);
            });
    }

    handleClearNotifications = () => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append("qase_api_token", userData.token);
        data.append("sid", userData.sid);

        fetch(`${process.env.REACT_APP_API_HOST_URL}/chat_activities/clear`, {
            method: "POST",
            body: data,
        })
            .then((res) => {
                //console.log(res);
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error("failed");
                }

                return res.json();
            })
            .then((resData) => {
                console.log(resData);
                if (resData.data.success) {
                    this.getActivities();
                    this.props.fetchHeaderData(data);
                    // this.setState(
                    //     {
                    //         activity: {
                    //             messages_count: 0,
                    //             chat_messages: [],
                    //         },
                    //     },
                    //     () => {
                    //         this.props.fetchHeaderData(data);
                    //     }
                    // );
                }

                if (
                    !resData.success &&
                    (resData.message == "Session too old." ||
                        resData.message == "No session found.")
                ) {
                    this.props.logout();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        const { classes } = this.props;
        const { messages_count, chat_messages } = this.state.activity;
        console.log(this.state.activity);
        const ActivityList = chat_messages.map((activity) => {
            return (
                <AccountActivityItem
                    activity={activity}
                    key={activity.mid}
                    setCaseUuid={this.props.setCaseUuid}
                />
            );
        });
        return (
            <Fragment>
                <div className="page--wrapper">
                    <Paper className={classes.Paper}>
                        <List>
                            <ListItem dense>
                                <PageHading
                                    title={""}
                                    //count={Number(count)}
                                    history={this.props.history}
                                    clearNotification={!!Number(messages_count)}
                                    onClearNotification={
                                        this.handleClearNotifications
                                    }
                                />
                            </ListItem>
                            {ActivityList}
                        </List>
                    </Paper>
                </div>
            </Fragment>
        );
    }
}

AccountMessagesPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchHeaderData: (data) => {
            dispatch(fetchHeaderData(data));
        },
        setCaseUuid: (case_uuid) => {
            dispatch({ type: AUTH_SET_CASE_UUID, payload: case_uuid });
        },
        logout: () => dispatch({ type: LOGOUT, payload: true }),
    };
};

export default compose(
    withStyles(styles),
    connect(null, mapDispatchToProps)
)(AccountMessagesPage);
