import {
    TASKS_FETCH_BEGINING_DATA,
    TASKS_CREATE_TASK,
    TASKS_SHOW_PROCESSING,
    TASKS_ACCEPT_TASK,
    TASKS_SHOW_ERROR,
    TASKS_HIDE_ERROR,
    TASKS_CANCEL_TASK,
    TASKS_REJECT_TASK,
    TASKS_COMPLETE_TASK,
    TASKS_RESET_REDIRECT
} from "../actions/actionTypes";

const initState = {
    loaded: false,
    isRedirect: false,
    processing: false,
    tasks: [],
    notificationSnackbar: {
        open: false,
        variant: '',
        message: '',
    },
};

const taskReducer = (state = initState, action) => {
    switch (action.type) {
        case TASKS_FETCH_BEGINING_DATA:
            return {
                ...state,
                loaded: true,
                tasks: action.payload,
            }
        case TASKS_CREATE_TASK:
            return {
                ...state,
                processing: false,
                isRedirect: true,
            };
        case TASKS_RESET_REDIRECT:
            return {
                ...state,
                isRedirect: false,
            }
        case TASKS_ACCEPT_TASK:
            let accpetedTask = state.tasks.find(task => task.uuid === action.payload.task_uuid);
            accpetedTask.actions = action.payload.data.actions;
            return {
                ...state,
                processing: false,
            };
        case TASKS_SHOW_PROCESSING:
            return {
                ...state,
                processing: true,
            };
        case TASKS_SHOW_ERROR:
            return {
                ...state,
                processing: false,
                notificationSnackbar: {
                    open: true,
                    variant: action.payload.variant,
                    message: action.payload.message,
                },
            };
        case TASKS_HIDE_ERROR:
            return {
                ...state,
                processing: false,
                notificationSnackbar: initState.notificationSnackbar,
            };
        case TASKS_CANCEL_TASK:
            let newTasks = state.tasks.filter(task => task.uuid !== action.payload.task_uuid);
            return {
                ...state,
                processing: false,
                tasks: newTasks
            };
        case TASKS_REJECT_TASK:
            let tasks = state.tasks.filter(task => task.uuid !== action.payload.task_uuid);
            return {
                ...state,
                processing: false,
                tasks: tasks
            };
        case TASKS_COMPLETE_TASK:
            let completedTask = state.tasks.find(task => task.uuid === action.payload.task_uuid);
            completedTask.actions = action.payload.data.actions;
            return {
                ...state,
                processing: false,
            };
        default:
            return state;
    }
};

export default taskReducer;