import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Link } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import './Home.css';

const styles = {
    whiteText: {
        color: '#fff',
    },
    welcomeText: {
        fontSize: '13px',
        color: '#fff',
        marginBottom: '100px',
    },
    signInBtn: {
        display: 'block',
        color: '#fff',
        backgroundColor: '#ed5452',
        padding: '15px',
        marginBottom: '15px',
        textDecoration: 'none',
        borderRadius: '6px',
        '&:hover': {
            textDecoration: 'none',
            color: '#fff'
        }
    },
    signUpBtn: {
        display: 'block',
        color: '#fff',
        backgroundColor: 'transparent',
        border: '1px solid #fff',
        padding: '15px',
        marginBottom: '15px',
        textDecoration: 'none',
        borderRadius: '6px',
        '&:hover': {
            textDecoration: 'none',
            color: '#fff'
        }
    },
    learnMoreLink: {
        color: '#fff',
        fontSize: '13px',
    }
};

class HomePage extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className="home-page__wrapper">
                <div className="home-page__content">
                    <img src={require('../images/homeimage.png')} alt="" style={{
                        maxWidth: '241px',
                        maxHeight: '206px',
                        margin: '0 auto'
                    }} />

                    <Typography variant="headline" align="center" className={classes.whiteText}>
                        Welcome to Qase
                    </Typography>

                    <Typography variant="subtitle1" align="center" className={classes.welcomeText}>
                        Qase helps Canadians connect with experienced lawyers and provides everything needed to get legal services online.
                    </Typography>

                    <NavLink to="/login" className={classes.signInBtn}>
                        Sign In
                    </NavLink>

                    <Typography variant="subtitle1" align="center" className={classes.welcomeText} style={{ marginBottom: 0 }}>
                        Want to learn more about Qase?
                    </Typography>
                    <a href={process.env.REACT_APP_MARKETING_URL} className={classes.learnMoreLink}>Click here for the website.</a>
                </div>
            </div>
        )
    }
}

HomePage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomePage);