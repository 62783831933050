import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
    Paper,
    Typography,
    Toolbar,
    Chip,
    Button,
    Link,
} from "@material-ui/core";
import {
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button as BtButton,
} from "reactstrap";
import { NavLink, withRouter } from "react-router-dom";
import { Processing, CancelationModal } from "../Layouts";

const styles = (theme) => ({
    grow: {
        flexGrow: 1,
    },
    Paper: {
        marginBottom: 10,
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    requiresSubHeading: {
        fontSize: "13px",
        fontWeight: 700,
        marginBottom: 10,
    },
    qaseChip: {
        height: "auto",
        borderColor: "#cccccc",
        color: "#cccccc",
        borderRadius: "3px",
        marginRight: 2,
    },
    qaseChipLabel: {
        color: "#999",
        fontSize: "9px",
        fontWeight: 700,
        padding: 4,
        textTransform: "uppercase",
    },
    toggleBtn: {
        backgroundColor: "#220d89!important",
        borderColor: "#220d89!important",
        boxShadow: "none!important",
        "&:hover": {
            backgroundColor: "#220d89",
            borderColor: "#220d89",
        },
        "&:active": {
            backgroundColor: "#220d89",
            borderColor: "#220d89",
        },
    },
});

class TaskItem extends Component {
    state = {
        dropdownOpen: false,
        cancelationModalOpen: false,
        rejectionModalOpen: false,
    };

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    };

    handleActionClick = (type, action) => {
        const { acceptTask, task, completeTask, isLawyer } = this.props;
        //console.log(action);
        switch (type) {
            case "review_task":
                switch (action.toLowerCase()) {
                    case "task-accept":
                        console.log(task);
                        if (task.billable && !isLawyer) {
                            this.props.history.push(`/task/${task.uuid}`);
                        } else {
                            acceptTask(task.uuid);
                        }

                        break;
                    case "task-cancel":
                        this.setState({
                            cancelationModalOpen: true,
                        });
                        break;
                    case "task-decline":
                        //console.log('reject task');
                        this.setState({
                            rejectionModalOpen: true,
                        });
                        break;
                    case "task-complete":
                        completeTask(task.uuid);
                        break;
                }
                break;
        }
    };

    handleCancelationModalClose = (type) => {
        this.setState({
            [type]: false,
        });
    };

    getTaskActions = () => {
        const { dropdownOpen } = this.state,
            { task, auth, isLawyer, classes } = this.props;
        const task_actions = task.actions;

        let btnTitle =
            task.user_uuid === auth.uid
                ? isLawyer
                    ? task_actions.provider_source_text
                    : task_actions.client_source_text
                : isLawyer
                ? task_actions.provider_target_text
                : task_actions.client_target_text;

        let actionsList =
            task.user_uuid === auth.uid
                ? isLawyer
                    ? task_actions.provider_source_options
                    : task_actions.client_source_options
                : isLawyer
                ? task_actions.provider_target_options
                : task_actions.client_target_options;

        if (actionsList) {
            return (
                <ButtonDropdown isOpen={dropdownOpen} toggle={this.toggle}>
                    <DropdownToggle
                        caret
                        size="sm"
                        color="primary"
                        className={classes.toggleBtn}
                    >
                        {btnTitle}
                    </DropdownToggle>
                    <DropdownMenu right={true}>
                        {actionsList.map((action) => {
                            if (
                                !task.billable ||
                                action.field_end_point_function.und[0].value !==
                                    "task-invoice"
                            ) {
                                let endpoint =
                                    action.field_end_point_function.und[0]
                                        .value === "task-invoice"
                                        ? task.billable
                                            ? action.field_end_point_function
                                                  .und[0].value
                                            : "task-complete"
                                        : action.field_end_point_function.und[0]
                                              .value;
                                let title =
                                    action.field_end_point_function.und[0]
                                        .value === "task-invoice"
                                        ? task.billable
                                            ? action.field_future_state_label
                                                  .und[0].value
                                            : "Complete"
                                        : action.field_future_state_label.und[0]
                                              .value;
                                return (
                                    <DropdownItem
                                        key={action.tid}
                                        onClick={() =>
                                            this.handleActionClick(
                                                "review_task",
                                                endpoint
                                            )
                                        }
                                    >
                                        {title}
                                    </DropdownItem>
                                );
                            }
                        })}
                    </DropdownMenu>
                </ButtonDropdown>
            );
        } else {
            return (
                <BtButton
                    size="sm"
                    color="success"
                    className={classes.toggleBtn}
                >
                    {btnTitle}
                </BtButton>
            );
        }
    };

    getRejectionReasons = () => {
        const { rejectionReasons } = this.props;
        return Object.entries(rejectionReasons)
            .filter(([group, reason]) => reason.name.toLowerCase() === "task")
            .map(([group, reason]) => {
                return reason;
            });
    };

    render() {
        const { classes, task, cancelTask, rejectTask } = this.props,
            { cancelationModalOpen, rejectionModalOpen } = this.state;

        const taskAction = this.getTaskActions();
        const taskRejectionReasons = this.getRejectionReasons();
        return (
            <Fragment>
                <Paper className={classes.Paper}>
                    <Typography
                        variant="subheading"
                        classes={{
                            subheading: classes.requiresSubHeading,
                        }}
                    >
                        <Link
                            component={NavLink}
                            to={`/${task.type}/${task.uuid}`}
                        >
                            {task.title}
                        </Link>
                    </Typography>
                    {task.time_show && (
                        <Typography>{task.time_show}</Typography>
                    )}
                    <Typography>Amount: ${task.amount}</Typography>

                    <Toolbar disableGutters={true}>
                        <Chip
                            label={task.type}
                            className={classes.qaseChip}
                            classes={{
                                label: classes.qaseChipLabel,
                            }}
                            variant="outlined"
                        />
                        <Chip
                            label={task.billable ? "$" : "Non-Billable"}
                            className={classes.qaseChip}
                            classes={{
                                label: classes.qaseChipLabel,
                            }}
                            variant="outlined"
                        />
                        <div className={classes.grow} />
                        {taskAction}
                    </Toolbar>
                </Paper>

                {cancelationModalOpen && (
                    <CancelationModal
                        open={cancelationModalOpen}
                        handleClose={() =>
                            this.handleCancelationModalClose(
                                "cancelationModalOpen"
                            )
                        }
                        onSubmit={cancelTask}
                        title={task.title}
                        rejectionReasons={taskRejectionReasons[0]}
                        data={{
                            comment: "",
                            uuid: task.uuid,
                            reason: "",
                        }}
                    />
                )}

                {rejectionModalOpen && (
                    <CancelationModal
                        open={rejectionModalOpen}
                        handleClose={() =>
                            this.handleCancelationModalClose(
                                "rejectionModalOpen"
                            )
                        }
                        onSubmit={rejectTask}
                        title={task.title}
                        rejectionReasons={taskRejectionReasons[0]}
                        data={{
                            comment: "",
                            uuid: task.uuid,
                            reason: "",
                        }}
                    />
                )}
            </Fragment>
        );
    }
}

TaskItem.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(TaskItem));
