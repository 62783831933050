import {
    APPOINTMENTS_FETCH_BEGINING_DATA,
    APPOINTMENTS_SHOW_PROCESSING,
    APPOINTMENTS_CANCEL_APPOINTMENT,
    APPOINTMENTS_ACCEPT_APPOINTMENT,
    APPOINTMENTS_SHOW_ERROR,
    APPOINTMENTS_COMPLETE_UNATTENDED,
    LOGOUT,
    APPOINTMENTS_COMPLETE_APPOINTMENT
} from "./actionTypes";

export const fetchAppointmentsList = (case_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            const response = await qaseApi.getAppointments(case_uuid, data);

            dispatch({
                type: APPOINTMENTS_FETCH_BEGINING_DATA,
                payload: response.data
            });
        } catch (e) {
            console.error(e);
            if (e.message === 'Session too old.' || e.message === 'No session found.') {
                dispatch({
                    type: LOGOUT,
                    payload: true,
                });
            }
        }
    }
};

export const acceptAppointment = (appointment_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            dispatch({ type: APPOINTMENTS_SHOW_PROCESSING })
            const response = await qaseApi.acceptAppointment(data);
            if (response.data.success) {
                dispatch({
                    type: APPOINTMENTS_ACCEPT_APPOINTMENT,
                    payload: {
                        appointment_uuid: appointment_uuid,
                        data: response.data,
                    }
                });
            }
            else {
                dispatch({
                    type: APPOINTMENTS_SHOW_ERROR,
                    payload: {
                        variant: 'error',
                        message: 'appointment didn`t accepted',
                    }
                });
            }
        }
        catch (e) {
            console.error(e);
        }
    }
};

export const cancelAppointment = (appointment_uuid, isLawyer, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            dispatch({ type: APPOINTMENTS_SHOW_PROCESSING })
            const response = await qaseApi.cancelAppointment(data);
            dispatch({
                type: APPOINTMENTS_CANCEL_APPOINTMENT,
                payload: {
                    appointment_uuid: appointment_uuid,
                    data: response.data,
                    isLawyer: isLawyer,
                }
            });
        }
        catch (e) {
            console.error(e);
        }
    }
};

export const rejectAppointment = (appointment_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            dispatch({ type: APPOINTMENTS_SHOW_PROCESSING })
            const response = await qaseApi.rejectAppointment(data);
            dispatch({
                type: APPOINTMENTS_CANCEL_APPOINTMENT,
                payload: {
                    appointment_uuid: appointment_uuid,
                    data: response.data,
                }
            });
        }
        catch (e) {
            console.error(e);
        }
    }
};

export const completeUnattendedAppointment = (appointment_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            dispatch({ type: APPOINTMENTS_SHOW_PROCESSING })
            const response = await qaseApi.completeUnattenedAppointment(data);
            //console.log(response);
            if (response.data.success) {
                dispatch({
                    type: APPOINTMENTS_COMPLETE_UNATTENDED,
                    payload: {
                        appointment_uuid: appointment_uuid,
                        data: response.data,
                    }
                });
            }
            else {
                dispatch({
                    type: APPOINTMENTS_SHOW_ERROR,
                    payload: {
                        variant: 'error',
                        message: 'appointment didn`t accepted',
                    }
                });
            }
        }
        catch (e) {
            console.error(e);
        }
    }
};

export const completeAppointment = (appointment_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            dispatch({ type: APPOINTMENTS_SHOW_PROCESSING })
            const response = await qaseApi.completeAppointment(data);
            //console.log(response);
            if (response.data.success) {
                dispatch({
                    type: APPOINTMENTS_COMPLETE_APPOINTMENT,
                    payload: {
                        appointment_uuid: appointment_uuid,
                        data: response.data,
                    }
                });
            }
            else {
                dispatch({
                    type: APPOINTMENTS_SHOW_ERROR,
                    payload: {
                        variant: 'error',
                        message: 'appointment didn`t accepted',
                    }
                });
            }
        }
        catch (e) {
            console.error(e);
        }
    }
};
