import React from 'react';
import {IconButton} from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
const CalendarNavbar = ({
    nextMonth,
    previousMonth,
    onPreviousClick,
    onNextClick,
    className,
    localeUtils,
}) => {
    //const months = localeUtils.getMonths();
    // const prev = months[previousMonth.getMonth()];
    // const next = months[nextMonth.getMonth()];
    const styleLeft = {
        position: 'absolute',
        left: 0,
    };
    const styleRight = {
        position: 'absolute',
        right: 0,
    };
    return (
        <div className={className}>
            <IconButton style={styleLeft} onClick={() => onPreviousClick()}>
                <ChevronLeft color="primary" />
            </IconButton>
            <IconButton style={styleRight} onClick={() => onNextClick()}>
                <ChevronRight color="primary" />
            </IconButton>
        </div>
    );
}

export default CalendarNavbar;