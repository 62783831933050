import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FormControl, TextField, MenuItem } from '@material-ui/core';

const styles = theme => ({
    root: {
        paddingRight: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 2,
    }
});

function findPracticeArea(parent, id) {
    let finded = parent.filter(([group, f]) => f.value === id);
    if (finded.length > 0) {
        return finded[0][1];
    }

    let search = parent.filter(([group, f]) => f.hasOwnProperty("children"));
    let isFinded = false;
    for (let i = 0; i < search.length; i++) {
        let children = Object.entries(search[i][1].children);
        for (let j = 0; j < children.length; j++) {
            if (children[j][1].value === id) {
                finded = children[j][1];
                isFinded = true;
                break;
            }
        }
        if (isFinded) {
            break;
        }
    }
    return finded;
}

class DescribeCase extends Component {

    handleChange = name => ({ target: { value } }) => {
        this.props.handleInputChange(name, value);
    };

    render() {
        const { classes, newCase, legalIssues, locals, lawyers } = this.props;
        let practice = findPracticeArea(Object.entries(legalIssues), newCase.practice_area);
        let localsArray = Object.entries(locals);

        return (
            <div className={classes.root}>
                <FormControl margin="normal" fullWidth>
                    <TextField
                        InputProps={{
                            readOnly: true
                        }}
                        label="Legal Issue*"
                        defaultValue={practice.name}
                        variant="outlined"
                    />
                </FormControl>
                <FormControl margin="normal" fullWidth>
                    <TextField
                        select
                        label="Location*"
                        value={newCase.practice_jurisdiction}
                        onChange={this.handleChange('practice_jurisdiction')}
                        SelectProps={{
                            MenuProps: {
                                className: classes.menu,
                            },
                        }}
                        helperText="Please select locale"
                        variant="outlined"
                    >
                        {localsArray.map(([group, local]) => (
                            <MenuItem key={group} value={local.value}>
                                {local.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>

                {lawyers &&
                    <FormControl margin="normal" fullWidth>
                        <TextField
                            select
                            label="Select Lawyer"
                            value={newCase.lawyer}
                            onChange={this.handleChange('lawyer')}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            helperText="Please select lawyer (leave blank to discover a new lawyer)"
                            variant="outlined"
                        >
                            <MenuItem value="">
                                Select Lawyer
                            </MenuItem>
                            {lawyers.map(lawyer => (
                                <MenuItem key={lawyer.uid} value={lawyer.uuid}>
                                    {`${lawyer.fname} ${lawyer.lname} (${lawyer.name})`}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                }

                <FormControl margin="normal" fullWidth>
                    <TextField
                        label="Enter opposing party name or N/A*"
                        value={newCase.opposition_field}
                        onChange={this.handleChange("opposition_field")}
                        variant="outlined"
                    />
                </FormControl>

                <FormControl margin="normal" fullWidth>
                    <TextField
                        label="Description*"
                        multiline
                        rows="4"
                        value={newCase.description}
                        onChange={this.handleChange("description")}
                        variant="outlined"
                    />
                </FormControl>
            </div>
        )
    }
}

DescribeCase.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(DescribeCase);