import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField, FormControl, FormHelperText, Typography, OutlinedInput, InputAdornment, IconButton, InputLabel } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import TextMaskCustom from '../TextMaskCustom';

const styles = theme => ({
    root: {
        paddingRight: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 2,
    },
    errorLabel: {
        color: "#f44336",
    },
    marginBottom15: {
        marginBottom: 15,
    },
    resendLink: {
        color: '#220d89',
    },
    confirmTitle: {
        fontSize: "24px",
        fontWeight: "bold",
        marginTop: "16px",
        marginBottom: "16px",
    }
});

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validPhoneRegex = /^(\+?1-?)?(\([2-9]([02-9]\d|1[02-9])\)|[2-9]([02-9]\d|1[02-9]))-?[2-9]([02-9]\d|1[02-9])-?\d{4}$/;
const validPasswordRegex = /.*^(?=.{8,16})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;
class CreateClientAccount extends Component {
    state = {
        errors: {
            field_first_name: "",
            field_last_name: "",
            email: "",
            field_telephone: "",
            password: "",
            password_confirm: "",
        },
        showPassword: false,
        showConfirmPassword: false,
    };

    handleChange = name => ({ target: { value } }) => {
        let errors = this.state.errors;
        let shouldUpdate = true;
        this.props.handleInputChange(name, value);
        switch (name) {
            case 'field_first_name':
                errors.field_first_name =
                    value.length > 0
                        ? ''
                        : 'First Name is required';
                break;
            case 'field_last_name':
                errors.field_first_name =
                    value.length > 0
                        ? ''
                        : 'Last Name is required';
                break;
            case 'email':
                if (!validEmailRegex.test(value)) {
                    errors.email = 'Email is not valid!'
                }
                else {
                    shouldUpdate = false;
                    let data = new FormData();
                    data.append('email', value);
                    fetch(`${process.env.REACT_APP_API_HOST_URL}/user/check_email`, {
                        method: 'POST',
                        body: data,
                    })
                        .then(res => {
                            if (res.status !== 200 && res.status !== 201) {
                                throw new Error('failed');
                            }
                            return res.json();
                        })
                        .then(resData => {
                            errors.email = resData ? '' : 'This email is already in use or invalid, please login or enter another email address';
                            this.setState({ errors, [name]: value }, () => {
                                let isValid = this.validateForm(errors, this.props.newUser);
                                this.props.enableCreateUser(!isValid);
                            })
                        })
                        .catch(err => {
                            console.log(err);
                        });
                }
                break;
            case 'field_telephone':
                let phone_number = value.replace(/\D+/g, '');
                errors.field_telephone =
                    phone_number.match(validPhoneRegex)
                        ? ''
                        : 'Phone is not valid!';
                break;
            case 'password':
                errors.password =
                    value.match(validPasswordRegex)
                        ? ''
                        : 'Password doesn’t meet the requirements';
                if (value === this.props.newUser.password_confirm) {
                    errors.password_confirm = "";
                }
                break;
            case 'password_confirm':
                errors.password_confirm =
                    value === this.props.newUser.password
                        ? ''
                        : 'Passwords must match';
                break;
            default:
                break;
        }
        if (shouldUpdate) {
            this.setState({ errors, [name]: value }, () => {
                let isValid = this.validateForm(errors, this.props.newUser);
                this.props.enableCreateUser(!isValid);
            })
        }
    };

    validateForm = (errors, values) => {
        let valid = true;
        Object.keys(errors).forEach(key => {
            if (errors[key].length > 0 || values[key].length == 0) {
                valid = false;
            }
        });
        return valid;
    };

    togglePasswordView = type => {
        this.setState({
            [type]: !this.state[type]
        })
    };

    render() {
        const { classes, newUser, userSubmited, resendEmail } = this.props;
        const { errors } = this.state;
        //console.log(newUser);
        return (
            <div className={classes.root}>
                {!userSubmited &&
                    <Fragment>
                        <FormControl margin="normal" fullWidth>
                            <TextField
                                label="Given Names*"
                                value={newUser.field_first_name}
                                variant="outlined"
                                onChange={this.handleChange("field_first_name")}
                            />
                            {errors.field_first_name && <FormHelperText className={classes.errorLabel}>{errors.field_first_name}</FormHelperText>}
                        </FormControl>

                        <FormControl margin="normal" fullWidth>
                            <TextField
                                label="Last Name*"
                                value={newUser.field_last_name}
                                variant="outlined"
                                onChange={this.handleChange("field_last_name")}
                            />
                            {errors.field_last_name && <FormHelperText className={classes.errorLabel}>{errors.field_last_name}</FormHelperText>}
                        </FormControl>

                        <FormControl margin="normal" fullWidth>
                            <TextField
                                label="Email Address*"
                                type="email"
                                value={newUser.email}
                                variant="outlined"
                                onChange={this.handleChange("email")}
                            />
                            {errors.email && <FormHelperText className={classes.errorLabel}>{errors.email}</FormHelperText>}
                        </FormControl>

                        <FormControl margin="normal" fullWidth>
                            <TextField
                                label="Phone Number*"
                                value={newUser.field_telephone}
                                variant="outlined"
                                InputProps={{
                                    inputComponent: TextMaskCustom,
                                }}
                                onChange={this.handleChange("field_telephone")}
                            />
                            {errors.field_telephone && <FormHelperText className={classes.errorLabel}>{errors.field_telephone}</FormHelperText>}
                        </FormControl>

                        <FormControl margin="normal" fullWidth>
                            <TextField
                                label="Password*"
                                type={this.state.showPassword ? 'text' : 'password'}
                                value={newUser.password}
                                autoComplete="current-password"
                                variant="outlined"
                                onChange={this.handleChange("password")}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={() => this.togglePasswordView('showPassword')}
                                            >
                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <FormHelperText style={{ marginBottom: 15 }}>Password must be 8-16 characters and must contain at least 1 capital letter, 1 number and 1 symbol</FormHelperText>
                            {errors.password && <FormHelperText className={classes.errorLabel}>{errors.password}</FormHelperText>}
                        </FormControl>

                        <FormControl margin="normal" fullWidth>
                            <TextField
                                label="Confirm Password*"
                                type={this.state.showConfirmPassword ? 'text' : 'password'}
                                value={newUser.password_confirm}
                                variant="outlined"
                                onChange={this.handleChange("password_confirm")}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={() => this.togglePasswordView('showConfirmPassword')}
                                            >
                                                {this.state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {errors.password_confirm && <FormHelperText className={classes.errorLabel}>{errors.password_confirm}</FormHelperText>}
                        </FormControl>
                    </Fragment>
                }

                {userSubmited &&
                    <Fragment>
                        <Typography variant="h6" className={classes.confirmTitle}>Confirm your email</Typography>
                        <Typography className={classes.marginBottom15}>Check the inbox for email address {newUser.email}</Typography>
                        <Typography className={classes.marginBottom15}>
                            Look for a message with the subject line "Verify your email".
                        </Typography>
                        <Typography className={classes.marginBottom15}>
                            Open the email, click on the link in the message and verify.
                        </Typography>
                        <Typography className={classes.marginBottom15}>
                            Before you can confirm your consultation appointment, email verification must be completed in the next 15 minutes.
                        </Typography>
                        <Typography className={classes.marginBottom15}>
                            Didn’t get an email from us?
                        </Typography>
                        <Typography className={classes.resendLink} onClick={resendEmail}>Try again</Typography>
                    </Fragment>
                }
            </div>
        )
    }
}

CreateClientAccount.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateClientAccount);