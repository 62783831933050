import {
    UTILITY_FETCH_REJECTION_REASONS,
    UTILITY_FETCH_LEGAL_ISSUES,
    UTILITY_FETCH_LOCALS,
    UTILITY_FETCH_CASE_BILLING_TYPES,
    UTILITY_FETCH_CONNECT_OPTIONS
} from "./actionTypes";

export const fetchUtilityRejectionReasons = () => {
    return async (dispatch, getState, qaseApi) => {
        try {
            const response = await qaseApi.getRejectionReasons();
            dispatch({
                type: UTILITY_FETCH_REJECTION_REASONS,
                payload: response.data,
            });
        }
        catch (e) {
            console.error(e);
        }
    }
};

export const fetchUtilityLegalIssues = (data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            const response = await qaseApi.getLegalIssues(data);
            dispatch({
                type: UTILITY_FETCH_LEGAL_ISSUES,
                payload: response.data,
            });
        }
        catch (e) {
            console.error(e);
        }
    }
};

export const fetchUtilityConnectOptions = (data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            const response = await qaseApi.getConnectOptions(data);
            //console.log(response);
            dispatch({
                type: UTILITY_FETCH_CONNECT_OPTIONS,
                payload: response.data,
            });
        }
        catch (e) {
            console.error(e);
        }
    }
};

export const fetchUtilityLocals = (data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            const response = await qaseApi.getLocals(data);
            dispatch({
                type: UTILITY_FETCH_LOCALS,
                payload: response.data,
            });
        }
        catch (e) {
            console.error(e);
        }
    }
};

export const fetchUtilityBillingTypes = () => {
    return async (dispatch, getState, qaseApi) => {
        try {
            const response = await qaseApi.getBillingTypes();
            dispatch({
                type: UTILITY_FETCH_CASE_BILLING_TYPES,
                payload: response.data,
            });
        }
        catch (e) {
            console.error(e);
        }
    }
};

