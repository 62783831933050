import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
    Typography,
    TextField,
    FormControl,
    Input,
    InputAdornment,
    IconButton,
    Button,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormLabel
} from '@material-ui/core';
import { Visibility, VisibilityOff, Done as DoneIcon, ArrowBack } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';

import { SignHeader } from '../Components/Layouts';

const styles = {
    registerText: {
        textDecoration: 'none',
        color: '#220D89',
        marginLeft: '5px',
    },
    formGroup: {
        width: '100%',
        marginBottom: '25px',
    },
    singBtn: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        borderRadius: 0,
        color: '#fff!important',
    },
    disabledBtn: {
        backgroundColor: '#999999',
    },
    activeBtn: {
        backgroundColor: '#220d89',
    }
};


class SingupPage extends Component {

    state = {
        showPassword: false,
        step: 'first',
        validForm: false,
        formValidation: {
            field_first_name: {
                value: '',
                valid: false,
            },
            field_last_name: {
                value: '',
                valid: false,
            },
            mail: {
                value: '',
                valid: false,
            },
            field_telephone: {
                value: '',
                valid: false,
            },
            password: {
                value: '',
                valid: false,
            },
            field_user_type: {
                value: '',
                valid: false,
            }
        }
    };

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    }

    handleInputChange = name => ({ target: { value } }) => {
        this.setState({
            formValidation: {
                ...this.state.formValidation,
                [name]: {
                    value: value,
                    valid: value.trim().length !== 0,
                }
            }
        }, () => this.activateNextBtn());
    }

    goToStep = (step) => {
        this.setState({
            step
        })
    };

    activateNextBtn = () => {
        const { formValidation } = this.state;

        const invalidFields = Object.keys(formValidation).filter((key) => {
            return formValidation[key].valid === false;
        });

        this.setState((prevState) => ({
            validForm: !invalidFields.length,
        }));
    }

    render() {

        const { classes } = this.props;
        const { step, formValidation, validForm } = this.state;
        return (
            <div className="page-login__wrapper">
                <SignHeader />
                <div className="page_login__content">
                    {step === 'first' &&
                        <Fragment>
                            <Typography component="h6" style={{ fontWeight: 700 }}>
                                Create an Account
                            </Typography>
                            <Typography component="h6" variant="caption">
                                ALREADY HAVE ACCOUNT?
                                <NavLink to="/login" className={classes.registerText}>SIGN IN</NavLink>
                            </Typography>

                            <form style={{
                                marginTop: '40px',
                            }} >
                                {/* First Name */}
                                <FormControl className={classes.formGroup} margin="normal">
                                    <Input
                                        placeholder="First Name"
                                        fullWidth
                                        value={formValidation.field_first_name.value}
                                        onChange={this.handleInputChange('field_first_name')}
                                        name="field_first_name"
                                        endAdornment={
                                            formValidation.field_first_name.valid
                                                ?
                                                <InputAdornment position="end">
                                                    <DoneIcon style={{ color: '#00CC99' }} fontSize="small" />
                                                </InputAdornment>
                                                : null
                                        }
                                    />
                                </FormControl>

                                {/* Last Name */}
                                <FormControl className={classes.formGroup} margin="normal">
                                    <Input
                                        placeholder="Last Name"
                                        fullWidth
                                        value={formValidation.field_last_name.value}
                                        onChange={this.handleInputChange('field_last_name')}
                                        name="field_last_name"
                                        endAdornment={
                                            formValidation.field_last_name.valid
                                                ?
                                                <InputAdornment position="end">
                                                    <DoneIcon style={{ color: '#00CC99' }} fontSize="small" />
                                                </InputAdornment>
                                                : null
                                        }
                                    />
                                </FormControl>

                                {/* Email Address */}
                                <FormControl className={classes.formGroup} margin="normal">
                                    <Input
                                        placeholder="Email Address"
                                        fullWidth
                                        value={formValidation.mail.value}
                                        onChange={this.handleInputChange('mail')}
                                        name="mail"
                                        endAdornment={
                                            formValidation.mail.valid
                                                ?
                                                <InputAdornment position="end">
                                                    <DoneIcon style={{ color: '#00CC99' }} fontSize="small" />
                                                </InputAdornment>
                                                : null
                                        }
                                    />
                                </FormControl>

                                {/* Phone Number */}
                                <FormControl className={classes.formGroup} margin="normal">
                                    <Input
                                        placeholder="Phone Number"
                                        fullWidth
                                        value={formValidation.field_telephone.value}
                                        onChange={this.handleInputChange('field_telephone')}
                                        name="field_telephone"
                                        endAdornment={
                                            formValidation.field_telephone.valid
                                                ?
                                                <InputAdornment position="end">
                                                    <DoneIcon style={{ color: '#00CC99' }} fontSize="small" />
                                                </InputAdornment>
                                                : null
                                        }
                                    />
                                </FormControl>

                                {/* Password */}
                                <FormControl className={classes.formGroup} margin="normal">
                                    <Input
                                        fullWidth
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        value={formValidation.password.value}
                                        onChange={this.handleInputChange('password')}
                                        placeholder="Password"
                                        name="password"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                >
                                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                                {
                                                    formValidation.password.valid &&
                                                    <DoneIcon style={{ color: '#00CC99' }} fontSize="small" />
                                                }

                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>

                                <FormControl component="fieldset">
                                    <FormLabel component="legend">I am a:</FormLabel>
                                    <RadioGroup
                                        aria-label="position"
                                        name="field_user_type"
                                        value={formValidation.field_user_type.value}
                                        onChange={this.handleInputChange('field_user_type')}
                                        row
                                    >
                                        <FormControlLabel
                                            value="client"
                                            control={<Radio color="primary" />}
                                            label="Client"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="lawyer"
                                            control={<Radio color="primary" />}
                                            label="Lawyer"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </FormControl>

                                <Button variant="contained" fullWidth size="large" disabled={!validForm} onClick={() => this.goToStep('second')} className={classNames(classes.singBtn, !this.state.validForm ? classes.disabledBtn : classes.activeBtn)}>
                                    Next
                            </Button>
                            </form>
                        </Fragment>
                    }
                    {step === 'second' &&
                        <Fragment>
                            <Typography>
                                <IconButton aria-label="Go to prev" className={classes.backArrow} onClick={() => this.goToStep('first')}>
                                    <ArrowBack fontSize="small" />
                                </IconButton>
                                Back to Create Account
                            </Typography>

                            <Typography component="h6" style={{ fontWeight: 700, marginBottom: 10 }}>
                                Quick verification check
                            </Typography>

                            <Typography paragraph={true}>
                                We need to confirm it's you:
                            </Typography>

                        </Fragment>
                    }
                </div>
            </div>
        )
    }
}

SingupPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SingupPage);