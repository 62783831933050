import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { List, Fab } from '@material-ui/core';
import { Backup as BackupIcon } from '@material-ui/icons';
import { FileItem, DeleteConfirmationModal } from '../Components/File';
import { StickySubHeader, Loading, Processing, NotificationSnackbar } from '../Components/Layouts';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { fetchFilesList, uploadCaseFile, deleteCaseFile, shareCaseFile } from '../redux/actions/fileActions';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import PDFViewer from "mgr-pdf-viewer-react";
import { FILES_SHOW_PROCESSING, FILES_HIDE_PROCESSING } from '../redux/actions/actionTypes';

const fileTypesForShow = ["image/png", "image/jpeg", "application/pdf"];

const styles = theme => ({
    fab: {
        position: 'fixed',
        bottom: 100,
        right: theme.spacing.unit * 2,
    },
    pdfWrapper: {
        left: 0,
        right: 0,
        position: 'absolute',
        top: 55,
        background: 'transparent',
        border: 0,
        display: 'block',
        height: '100%',
        margin: '0 auto',
        overflow: 'hidden',
        padding: 0,
        width: 1,
        minWidth: '100%',
    }
});

function isMobile() {
    var isMobile = false; //initiate as false
    // device detection
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))
        || !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
    ) {
        isMobile = true;
    }
    return isMobile;
}

const MAX_FILE_SIZE = 8388608;//kbyte

class FilesPage extends Component {
    constructor(props) {
        super(props);
        this.handleOpenFancybox = this.handleOpenFancybox.bind(this);
        this.handleLoadFile = this.handleLoadFile.bind(this);
    };

    state = {
        viewMode: 1, // 1: files, 2: shared files
        loading: true,
        isOpenFancybox: false,
        photoIndex: 0,
        deletionModal: {
            open: false,
            fid: null,
        },
        imageForShow: '',
    };

    fileInput = React.createRef();

    componentDidMount() {
        var data = new FormData();

        var userData = JSON.parse(localStorage.getItem("userData"));
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);

        this.props.fetchFilesList(this.props.auth.case_uuid, data);
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        //console.log(0);
        this.setState({ numPages });
    }

    changeMode = (mode) => {
        this.setState({
            viewMode: mode,
        });
    };

    fileInputChange = (event) => {
        if (event.target.files.length) {
            var data = new FormData();
            let uploadFile = this.fileInput.current.files[0];

            console.log(uploadFile.size);
            if (uploadFile.size > MAX_FILE_SIZE) {
                this.props.showError({
                    variant: 'error',
                    message: 'Maximum supported file size is 8MB',
                });
            }
            else {
                var userData = JSON.parse(localStorage.getItem("userData"));
                data.append('qase_api_token', userData.token);
                data.append('sid', userData.sid);
                data.append('files[case_file]', this.fileInput.current.files[0]);
                this.props.uploadCaseFile(this.props.auth.case_uuid, data);
            }
        }
    };

    handleOpenDeteleConfirmation = (fid) => {
        this.setState({
            deletionModal: {
                open: true,
                fid: fid,
            }
        })
    };

    handleCloseDeleteConfirmation = () => {
        this.setState({
            deletionModal: {
                open: false,
                fid: null,
            }
        });
    };

    handleDeleteFile = (fid) => {
        this.handleCloseDeleteConfirmation();
        var userData = JSON.parse(localStorage.getItem("userData"));
        var data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        this.props.deleteCaseFile(fid, data)
    };

    handleShareFile = (fid) => {
        var userData = JSON.parse(localStorage.getItem("userData"));
        var data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        this.props.shareCaseFile(fid, data)
    };

    handleSnackbarClose = () => {
        this.props.hideError();
    };

    async handleOpenFancybox(file) {
        const { classes, auth: { case_uuid } } = this.props;

        const blob = await this.handleLoadFile(file.fid);

        if (blob) {
            if (fileTypesForShow.includes(file.filemime)) {
                let fileUrl = URL.createObjectURL(blob);
                let file;
                if (blob.type.search('image') >= 0) {
                    file = fileUrl;
                }
                else {
                    file = <PDFViewer document={{
                        url: fileUrl
                    }}
                        scale={0.5}
                        css={classes.pdfWrapper}
                    />
                }

                this.setState({
                    isOpenFancybox: true,
                    imageForShow: file,
                });
            }
            else {

                var reader = new FileReader();
                reader.onload = function (e) {
                    //window.open(reader.result);
                    if (isMobile()) {
                        window.location.href = reader.result;
                    }
                    else {
                        var a = document.createElement('a');
                        var url = URL.createObjectURL(blob);
                        a.href = url;
                        a.download = file.file_name;
                        document.body.append(a);
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    }
                }
                reader.readAsDataURL(blob);
            }
        }
        else {
            this.props.showError({
                variant: 'error',
                message: 'access denied',
            })
        }

        this.props.hideProcessing();
    };

    async handleLoadFile(fid) {
        this.props.showProcessing();
        const { case_uuid } = this.props.auth;
        let data = new FormData();

        const userData = JSON.parse(localStorage.getItem("userData"));
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);

        let response = await fetch(`${process.env.REACT_APP_API_HOST_URL}/file/load/${case_uuid}/${fid}`, {
            //credentials: 'include',
            method: 'POST',
            body: data,
        });

        if (response.ok && response.status === 200) {
            const blob = await response.blob();
            //console.log(blob);
            return blob;
        }
        else {
            return null;
        }
    };

    render() {

        const { classes, filesList } = this.props,
            { viewMode, isOpenFancybox, deletionModal } = this.state;
        if (!filesList.loaded) {
            return <Loading />
        }

        const filesListForShow = filesList.files
            .filter(file => Boolean(Number(file.isShared)) === (viewMode !== 1));

        return (
            <Fragment>
                {(filesList.processing) &&
                    <Processing />
                }
                <StickySubHeader title1="Documents" title2="Shared" active={this.state.viewMode} changeMode={this.changeMode} />
                <div className="page--wrapper">
                    <List>
                        {
                            filesListForShow
                                .map((file, index) => {
                                    return <FileItem
                                        key={file.fid}
                                        index={index}
                                        file={file}
                                        deleteFile={this.handleOpenDeteleConfirmation}
                                        shareFile={this.handleShareFile}
                                        openFancybox={this.handleOpenFancybox}
                                        case_uuid={this.props.auth.case_uuid}
                                        showProcessing={this.props.showProcessing}
                                        hideProcessing={this.props.hideProcessing}
                                    />
                                })
                        }
                    </List>
                    <div className={classes.fab}>
                        <input
                            accept=".jpg, .jpeg, .gif, .png, .txt, .doc, .docx, .xls, .xlsx, .pdf, .ppt, .pptx, .pps, .odt, .ods, .odp, .mov, .mp4, .mp3"
                            style={{ display: 'none' }}
                            id="files-button-file"
                            type="file"
                            ref={this.fileInput}
                            //multiple
                            onChange={this.fileInputChange}
                        />
                        <label htmlFor="files-button-file">
                            <Fab component="span" color="primary">
                                <BackupIcon />
                            </Fab>
                        </label>
                    </div>

                    {isOpenFancybox && (
                        <Lightbox
                            mainSrc={this.state.imageForShow}
                            onCloseRequest={() => this.setState({ isOpenFancybox: false })}
                            reactModalStyle={{
                                overlay: {
                                    top: 56,
                                    zIndex: 1301,
                                }
                            }}
                        />
                    )}
                </div>
                {filesList.notificationSnackbar.open &&
                    <NotificationSnackbar
                        snackbarOptions={filesList.notificationSnackbar}
                        handleClose={this.handleSnackbarClose}
                    />
                }
                {deletionModal.open &&
                    <DeleteConfirmationModal
                        open={deletionModal.open}
                        handleClose={() => this.handleCloseDeleteConfirmation()}
                        handleAccept={this.handleDeleteFile}
                        fid={deletionModal.fid}
                    />
                }
            </Fragment>
        )
    };
}

const mapStateToProps = (state) => {
    return {
        filesList: state.file,
        auth: state.auth,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchFilesList: (case_uuid, data) => dispatch(fetchFilesList(case_uuid, data)),
        uploadCaseFile: (case_uuid, data) => dispatch(uploadCaseFile(case_uuid, data)),
        deleteCaseFile: (fileid, data) => dispatch(deleteCaseFile(fileid, data)),
        shareCaseFile: (fileid, data) => dispatch(shareCaseFile(fileid, data)),
        hideError: () => dispatch({ type: 'FILES_HIDE_ERROR' }),
        showError: (data) => dispatch({ type: 'FILES_SHOW_ERROR', payload: data }),
        showProcessing: () => dispatch({ type: FILES_SHOW_PROCESSING }),
        hideProcessing: () => dispatch({ type: FILES_HIDE_PROCESSING }),
    };
};

FilesPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(FilesPage);

