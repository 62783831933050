import React, { Component, Fragment } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
	fetchUtilityLegalIssues,
	fetchUtilityLocals,
	fetchUtilityConnectOptions,
} from "../redux/actions/utilityActions";
import {
	LegalIssues,
	DescribeCase,
	ScheduleConsult,
	LawyerList,
	ConfirmBooking,
	CreateClientAccount,
} from "../Components/Case";
import {
	CASE_NEW_CASE_FILL_DATA,
	CASE_NEW_SHOW_LAWYER_BIO,
	CASE_NEW_HIDE_LAWYER_BIO,
	CASE_NEW_RESET_AFTER_CREATE,
	CASE_NEW_CASE_STEP_CHANGE,
	HEADER_ADD_NEW_CASE,
	AUTH_SET_CASE_UUID,
	LOGOUT,
	CASE_NEW_CASE_INIT_STEPS,
	CASE_NEW_CASE_FILL_USER_DATA,
	CASE_NEW_CASE_FILL_INIT_DATA,
	AUTH_SET_CARD_ASSINGNED,
} from "../redux/actions/actionTypes";
import { MobileStepper, Button } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import {
	Processing,
	Loading,
	NotificationSnackbar,
} from "../Components/Layouts";
import moment from "moment-timezone";
import querystring from "query-string";

import {
	Elements,
	ElementsConsumer,
	CardNumberElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ConsultationOptions from "../Components/Case/ConsultationOptions";

const styles = (theme) => ({
	stepperRoot: {
		backgroundColor: "#220d89",
		flexGrow: 1,
	},
	stepperBtn: {
		color: "#fff",
	},
	stepperBtnDisbaled: {
		color: "#767070!important",
	},
	stepperProggress: {
		backgroundColor: "#767070",
	},
	stepperProggressLinear: {
		backgroundColor: "#fff",
	},
});

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

class CreateCasePage extends Component {
	stripeContextRef = React.createRef();

	state = {
		activeStep: 0,
		processing: false,
		lawyers: false,
		loading: this.props.auth.token ? true : false,
		is_logged_user: this.props.auth.token ? true : false,
		disableCreateUser: true,
		//userSubmited: false,
		user_uuid: false,
		notificationSnackbar: {
			open: false,
			variant: "",
			message: "",
		},
	};

	componentDidMount() {
		const values = querystring.parse(this.props.location.search);
		this.props.fillNewCaseInitData(values);
		const {
			auth: { token },
			newCase: { referral_source },
		} = this.props;

		this.props.initCaseSteps({
			is_logged: token ? true : false,
		});
		let data = new FormData();
		if (token) {
			const userData = JSON.parse(localStorage.getItem("userData"));
			data.append("qase_api_token", userData.token);
			data.append("sid", userData.sid);
			this.getUserLawyers();
		}
		if (!!values["referral_source"]) {
			data.append("referral_source", values["referral_source"]);
		}

		if (!!values["practice_area_p"]) {
			data.append("practice_area_p", values["practice_area_p"]);
		}

		this.props.fetchUtilityLegalIssues(data);
		this.props.fetchUtilityLocals(data);
		this.props.fetchUtilityConnectOptions();
	}

	getUserLawyers = () => {
		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);

		fetch(`${process.env.REACT_APP_API_HOST_URL}/user/lawyers`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				if (res.status !== 200 && res.status !== 201) {
					throw new Error("General failure: " + res.status);
				}
				return res.json();
			})
			.then((resData) => {
				//console.log(resData);
				if (resData.success) {
					this.setState({
						lawyers: resData.data,
						loading: false,
					});
				}
				if (
					!resData.success &&
					(resData.message == "Session too old." ||
						resData.message == "No session found.")
				) {
					this.props.logout();
				}
			})
			.catch((err) => {
				//console.log(err, 'Error');
			});
	};

	handleNext = () => {
		this.setState(
			(state) => ({
				activeStep: state.activeStep + 1,
			}),
			() => {
				this.props.handleStepChange(this.state.activeStep);
			}
		);
	};

	handleBack = () => {
		this.setState(
			(state) => ({
				activeStep: state.activeStep - 1,
			}),
			() => {
				this.props.handleStepChange(this.state.activeStep);
			}
		);
	};

	isNextDisabled = () => {
		const { activeStep, is_logged_user, disableCreateUser } = this.state,
			{ newCase } = this.props;
		switch (activeStep) {
			case 0:
				return !Boolean(newCase.practice_area.trim());
			case 1:
				return !(
					Boolean(newCase.description.trim()) &&
					Boolean(newCase.practice_jurisdiction) &&
					Boolean(newCase.opposition_field.trim())
				);
			case 2:
				return !(Boolean(newCase.date) && Boolean(newCase.start));
			case 3:
				return !Boolean(newCase.lawyer);
			case 4:
				return !Boolean(newCase.connection_option);
			case 5:
				if (is_logged_user) {
					return false;
				} else {
					return disableCreateUser;
				}
			default:
				return true;
		}
	};

	enableCreateUser = (disbale) => {
		this.setState({
			disableCreateUser: disbale,
		});
	};

	async handleCreateCase() {
		const { newCase, newUser, auth } = this.props;
		const { is_logged_user } = this.state;
		let data = new FormData();
		if (
			auth.referral_source === "acba-lrs" &&
			!auth.is_client_card_exists
		) {
			let stripe = this.stripeContextRef.current.stripe;
			let elements = this.stripeContextRef.current.elements;
			let card = elements.getElement(CardNumberElement);

			const { error, token } = await stripe.createToken(card);
			if (error) {
				this.handleShowSnackbar({
					variant: "error",
					message: error.message,
				});
				return;
			}
			this.props.setCardAssigned(true);
			data.append("stripeToken", token.id);
		}

		if (auth.referral_source === "acba-lrs" && auth.is_client_card_exists) {
			data.append("card_assigned", true);
		}

		this.setState({
			processing: true,
		});
		const request_url = is_logged_user
			? `${process.env.REACT_APP_API_HOST_URL}/cases/create`
			: `${process.env.REACT_APP_API_HOST_URL}/cases/create/user`;

		let date = moment(newCase.date).format("YYYY-MM-DD");
		let endTime = moment(newCase.start, "HH:mm")
			.add(newCase.default_duration, "minutes")
			.format("HH:mm");
		if (is_logged_user) {
			const userData = JSON.parse(localStorage.getItem("userData"));
			data.append("qase_api_token", userData.token);
			data.append("sid", userData.sid);

			if (
				auth.is_client_card_exists &&
				auth.referral_source === "acba-lrs"
			) {
				data.append("card_assigned", true);
			}
		}
		data.append("appt-startdate", `${date}T${newCase.start}`);
		data.append("appt-enddate", `${date}T${endTime}`);
		data.append("lawyer", newCase.lawyer);
		data.append("local", newCase.practice_jurisdiction);
		data.append("practice_area", newCase.practice_area);
		data.append("description", newCase.description);
		data.append("opposition_field", newCase.opposition_field);
		data.append("referral_source", newCase.referral_source);
		data.append("connection_option", newCase.connection_option);

		if (!is_logged_user) {
			Object.keys(newUser).forEach((key) => {
				data.append(key, newUser[key]);
			});
		}

		fetch(request_url, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				if (res.status !== 200 && res.status !== 201) {
					throw new Error("failed");
				}
				return res.json();
			})
			.then((resData) => {
				if (resData.success) {
					if (is_logged_user) {
						let case_uuid = resData.data.uuid;
						this.props.setCaseUuid(case_uuid);
						this.props.resetAfterCreate();
						this.props.addNewCase({
							title: resData.data.title,
							id: case_uuid,
						});
						this.props.history.push(`/dashboard/${case_uuid}`);
					} else {
						this.setState({
							//userSubmited: true,
							processing: false,
							user_uuid: resData.uuid,
						});
						this.props.fillNewCaseData("userSubmited", true);
					}
				} else {
					this.setState(
						{
							processing: false,
						},
						() => {
							this.handleShowSnackbar({
								variant: "error",
								message: "Case not created!",
							});
						}
					);
				}
			})
			.catch((err) => {
				//console.log(err);
				this.setState({
					processing: false,
				});
			});
	}

	resendEmail = () => {
		const { user_uuid } = this.state;
		if (!user_uuid) {
			return;
		}

		this.setState({
			processing: true,
		});

		let data = new FormData();
		data.append("values[resend]", true);
		data.append("values[uuid]", user_uuid);

		fetch(`${process.env.REACT_APP_API_HOST_URL}/cases/create/user`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				if (res.status !== 200 && res.status !== 201) {
					throw new Error("failed");
				}
				return res.json();
			})
			.then((resData) => {
				console.log(resData);
				this.setState({
					processing: false,
				});
			})
			.catch((err) => {
				//console.log(err);
				this.setState({
					processing: false,
				});
			});
	};

	handleShowSnackbar = (options) => {
		this.setState({
			notificationSnackbar: {
				open: true,
				variant: options.variant,
				message: options.message,
			},
		});
	};

	handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		this.setState({
			notificationSnackbar: {
				open: false,
				variant: "",
				message: "",
			},
		});
	};

	render() {
		const {
				classes,
				legalIssues,
				locals,
				newCase,
				newUser,
				auth,
				connect_options,
			} = this.props,
			{
				activeStep,
				lawyers,
				is_logged_user,
				notificationSnackbar,
			} = this.state;

		if (this.state.loading) {
			return <Loading />;
		}

		if (this.state.processing) {
			return <Processing />;
		}

		return (
			<Fragment>
				<div className="page--wrapper--no-margin">
					{notificationSnackbar.open && (
						<NotificationSnackbar
							snackbarOptions={notificationSnackbar}
							handleClose={this.handleSnackbarClose}
						/>
					)}
					{activeStep === 0 && (
						<LegalIssues
							legalIssues={legalIssues}
							newCase={newCase}
							handleInputChange={this.props.fillNewCaseData}
						/>
					)}
					{activeStep === 1 && (
						<DescribeCase
							newCase={newCase}
							legalIssues={legalIssues}
							locals={locals}
							lawyers={lawyers}
							handleInputChange={this.props.fillNewCaseData}
						/>
					)}
					{activeStep === 2 && (
						<ScheduleConsult
							newCase={newCase}
							handleInputChange={this.props.fillNewCaseData}
							is_logged_user={this.state.is_logged_user}
						/>
					)}
					{activeStep === 3 && (
						<LawyerList
							newCase={newCase}
							handleInputChange={this.props.fillNewCaseData}
							handleShowLawyerBio={this.props.showLawyerBio}
							handleHideLawyerBio={this.props.hideLawyerBio}
						/>
					)}

					{activeStep === 4 && (
						<ConsultationOptions
							newCase={newCase}
							handleInputChange={this.props.fillNewCaseData}
							connect_options={connect_options}
						/>
					)}

					{activeStep === 5 && is_logged_user && (
						<Elements stripe={stripePromise}>
							<ElementsConsumer>
								{(context) => {
									this.stripeContextRef.current = context;
									return null;
								}}
							</ElementsConsumer>
							<ConfirmBooking
								newCase={newCase}
								legalIssues={legalIssues}
								locals={locals}
								auth={auth}
							/>
						</Elements>
					)}
					{activeStep === 5 && !is_logged_user && (
						<CreateClientAccount
							newUser={newUser}
							handleInputChange={this.props.fillNewCaseUserData}
							enableCreateUser={this.enableCreateUser}
							userSubmited={newCase.userSubmited}
							resendEmail={this.resendEmail}
						/>
					)}
				</div>

				{
					<MobileStepper
						variant="progress"
						steps={6}
						position="bottom"
						activeStep={activeStep}
						className={classes.stepperRoot}
						LinearProgressProps={{
							color: "secondary",
							classes: {
								barColorSecondary:
									classes.stepperProggressLinear,
							},
						}}
						classes={{
							progress: classes.stepperProggress,
						}}
						backButton={
							<Button
								size="small"
								className={classes.stepperBtn}
								onClick={this.handleBack}
								disabled={
									activeStep === 0 ||
									(activeStep === 5 && newCase.userSubmited)
								}
								classes={{
									disabled: classes.stepperBtnDisbaled,
								}}
							>
								<KeyboardArrowLeft />
								Back
							</Button>
						}
						nextButton={
							<Button
								size="small"
								className={classes.stepperBtn}
								//onClick={this.handleCreateCaseWithStripe.bind(this)}
								onClick={
									activeStep === 5
										? this.handleCreateCase.bind(this)
										: this.handleNext
								}
								disabled={
									this.isNextDisabled() ||
									(activeStep === 5 && newCase.userSubmited)
								}
								classes={{
									disabled: classes.stepperBtnDisbaled,
								}}
							>
								{activeStep === 5 ? (
									"Confirm"
								) : (
									<Fragment>
										Next
										<KeyboardArrowRight />
									</Fragment>
								)}
							</Button>
						}
					/>
				}
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		legalIssues: state.utility.legalIssues,
		newCase: state.case.newCase,
		newUser: state.case.newUser,
		locals: state.utility.locals,
		auth: state.auth,
		connect_options: state.utility.connect_options,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchUtilityLegalIssues: (data) =>
			dispatch(fetchUtilityLegalIssues(data)),
		fetchUtilityLocals: (data) => dispatch(fetchUtilityLocals(data)),
		fetchUtilityConnectOptions: (data) =>
			dispatch(fetchUtilityConnectOptions(data)),
		fillNewCaseData: (key, value) => {
			dispatch({
				type: CASE_NEW_CASE_FILL_DATA,
				payload: {
					key: key,
					value: value,
				},
			});
		},
		fillNewCaseUserData: (key, value) => {
			dispatch({
				type: CASE_NEW_CASE_FILL_USER_DATA,
				payload: {
					key: key,
					value: value,
				},
			});
		},
		handleStepChange: (step) => {
			dispatch({ type: CASE_NEW_CASE_STEP_CHANGE, payload: step });
		},
		showLawyerBio: (lawyer_uuid) =>
			dispatch({ type: CASE_NEW_SHOW_LAWYER_BIO, payload: lawyer_uuid }),
		hideLawyerBio: () => dispatch({ type: CASE_NEW_HIDE_LAWYER_BIO }),
		resetAfterCreate: () => dispatch({ type: CASE_NEW_RESET_AFTER_CREATE }),
		addNewCase: (data) =>
			dispatch({ type: HEADER_ADD_NEW_CASE, payload: data }),
		setCaseUuid: (case_uuid) => {
			dispatch({ type: AUTH_SET_CASE_UUID, payload: case_uuid });
		},
		logout: () => dispatch({ type: LOGOUT, payload: true }),
		initCaseSteps: (data) =>
			dispatch({ type: CASE_NEW_CASE_INIT_STEPS, payload: data }),
		fillNewCaseInitData: (data) =>
			dispatch({ type: CASE_NEW_CASE_FILL_INIT_DATA, payload: data }),
		setCardAssigned: (data) =>
			dispatch({ type: AUTH_SET_CARD_ASSINGNED, payload: data }),
	};
};

// CreateCasePage.propTypes = {
//     classes: PropTypes.object.isRequired,
// };

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps)
)(CreateCasePage);
