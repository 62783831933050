import React, { Component } from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { AppointmentCreateExitConfirm } from '../Appointement';
class AppointmentCreateHeader extends Component {
    state = {
        isExitConfirmationOpen: false,
    };

    handleShowExitConfiramtion = () => {
        this.setState({
            isExitConfirmationOpen: true,
        });
    };

    handleCloseExitConfiramtion = () => {
        this.setState({
            isExitConfirmationOpen: false,
        });
    };

    handleQuitFormAppointmentCreation = () => {
        this.setState({
            isExitConfirmationOpen: false,
        }, () => {
            this.props.history.push("/appointments");
            this.props.appointmentStepChangeReset();
        });
    }

    render() {
        const { classes, appointment: { createAppointmentSteps, createAppointmentAcitveStep } } = this.props;

        const activeStep = createAppointmentSteps[createAppointmentAcitveStep];
        return (
            <AppBar position="sticky" className={classes.root}>
                <Toolbar>
                    <img src={require('../../images/Qlogo.png')} alt="logo" className={classes.qaseLogo} />

                    <Typography className={classes.title} variant="h6" color="inherit" noWrap={true}>
                        {activeStep}
                    </Typography>
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        <IconButton className={classes.menuButton} color="inherit" aria-label="Open drawer" onClick={this.handleShowExitConfiramtion}>
                            <CloseIcon />
                        </IconButton>
                        <AppointmentCreateExitConfirm
                            open={this.state.isExitConfirmationOpen}
                            handleClose={this.handleCloseExitConfiramtion}
                            handleQuit={this.handleQuitFormAppointmentCreation}
                        />
                    </div>
                </Toolbar>
            </AppBar>
        );
    }
};

export default AppointmentCreateHeader;



