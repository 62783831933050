import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Typography, Button, IconButton } from '@material-ui/core';
import { Create as CreateIcon } from '@material-ui/icons';
const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 15,
    },
    Title: {
        textTransform: 'uppercase',
        color: '#999999',
    },
    editBtn: {
        padding: 5,
    },
    actionBtn: {
        margin: 3,
    },
});


class Actions extends Component {

    render() {

        const { classes, editMode, changeEditMode, title, submited } = this.props;

        return (
            <div className={classes.root}>
                <Typography className={classes.Title}>
                    {title}
                </Typography>
                {editMode &&
                    <div>
                        <Button variant="contained" size="small" className={classes.actionBtn} onClick={() => changeEditMode()}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" size="small" disabled={submited} className={classes.actionBtn} type="submit">
                            Save
                        </Button>
                    </div>
                }
                {!editMode &&
                    < IconButton aria-label="Delete" className={classes.editBtn} onClick={() => changeEditMode()}>
                        <CreateIcon fontSize="small" />
                    </IconButton>
                }
            </div>
        )
    }
}

Actions.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Actions);