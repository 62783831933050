import { NOTES_FETCH_BEGINING_DATA, NOTES_DELETE_NOTE, NOTES_SHOW_PROCESSING, NOTES_SHOW_ERROR, NOTES_HIDE_ERROR, NOTES_SHARE_NOTE } from "../actions/actionTypes";

const initState = {
    notes: [],
    loaded: false,
    processing: false,
    notificationSnackbar: {
        open: false,
        variant: '',
        message: '',
    },
};

const noteReducer = (state = initState, action) => {
    switch (action.type) {
        case NOTES_FETCH_BEGINING_DATA:
            return {
                ...state,
                loaded: true,
                notes: action.payload,
            };
        case NOTES_DELETE_NOTE:
            let newNotes = state.notes.filter(note => note.uuid !== action.payload);
            return {
                ...state,
                notes: newNotes,
                processing: false,
            };
        case NOTES_SHARE_NOTE:
            let sharedNote = state.notes.find(note => note.uuid === action.payload);
            sharedNote.isShared = true;
            return {
                ...state,
                processing: false,
            }
        case NOTES_SHOW_PROCESSING:
            return {
                ...state,
                processing: true,
            };
        case NOTES_SHOW_ERROR:
            return {
                ...state,
                processing: false,
                notificationSnackbar: {
                    open: true,
                    variant: action.payload.variant,
                    message: action.payload.message,
                },
            };
        case NOTES_HIDE_ERROR:
            return {
                ...state,
                processing: false,
                notificationSnackbar: initState.notificationSnackbar,
            };
        default:
            return state;
    }
};

export default noteReducer;
