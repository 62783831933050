import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { List } from '@material-ui/core';
import LegalIssueItem from './LegalIsuesListItem';

const styles = theme => ({

});

class CaseLegalIssues extends Component {

    getIssuesAsArray = (legalIssues) => {
        return Object.entries(legalIssues);
    }

    render() {
        const { legalIssues, newCase, handleInputChange } = this.props;
        const Issues = this.getIssuesAsArray(legalIssues);
        return (
            <List component="nav">
                {
                    Issues.map(([group, issue]) => {
                        return (
                            <LegalIssueItem
                                key={group}
                                issue={issue}
                                newCase={newCase}
                                handleInputChange={handleInputChange}
                            />
                        )
                    })
                }
            </List>
        )
    }
}

CaseLegalIssues.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CaseLegalIssues);