import {
    TRANSACTIONS_FETCH_BEGINING_DATA,
    TRANSACTIONS_ACCEPT_TRANSACTION,
    TRANSACTIONS_SHOW_PROCESSING,
    TRANSACTIONS_CANCEL_TRANSACTION,
    TRANSACTIONS_REJECT_TRANSACTION
} from "../actions/actionTypes";

const initState = {
    loaded: false,
    transactions: [],
    proccessing: false,
};

const transactionReducer = (state = initState, action) => {
    switch (action.type) {
        case TRANSACTIONS_FETCH_BEGINING_DATA:
            return {
                ...state,
                loaded: true,
                transactions: action.payload,
            };
        case TRANSACTIONS_ACCEPT_TRANSACTION:
            let accpetedTransaction = state.transactions.find(tr => tr.uuid === action.payload.transaction_uuid);
            accpetedTransaction.actions = action.payload.data.actions;
            return {
                ...state,
                proccessing: false,
            };
        case TRANSACTIONS_CANCEL_TRANSACTION:
        case TRANSACTIONS_REJECT_TRANSACTION:
            let canceledTransaction = state.transactions.find(tr => tr.uuid === action.payload.transaction_uuid);
            canceledTransaction.actions = action.payload.data.actions;
            return {
                ...state,
                proccessing: false,
            };
        case TRANSACTIONS_SHOW_PROCESSING:
            return {
                ...state,
                proccessing: true,
            };
        default:
            return state;
    }
}

export default transactionReducer;