import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import '../../styles/calendar.css';
import { CalendarNavbar, Loading, Processing } from '../Layouts';
import moment from 'moment-timezone';
import { FormControl, MenuItem, TextField, Typography } from '@material-ui/core';
import { Alert } from 'reactstrap';
import { TimeScheduler } from './index';
const styles = theme => ({
    root: {
        paddingRight: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 2,
    }
});

const duration = 30;
const currentDate = moment().toDate();

function sortAvailable(a, b) {
    var first = moment(a.start, 'HH:mm');
    var second = moment(b.start, 'HH:mm');
    if (second.diff(first) > 0)
        return -1;
    if (second.diff(first) < 0)
        return 1;
    return 0;
}

class ScheduleConsult extends Component {

    state = {
        calendar: {},
        loaded: false,
        processing: false,
        availableHours: [],
    };

    componentDidMount() {
        this.getAvailableHours();
    }

    getAvailableHours = (startDate, duration) => {
        const { newCase, is_logged_user } = this.props;

        const data = new FormData();
        if (is_logged_user) {
            const userData = JSON.parse(localStorage.getItem("userData"));
            data.append('qase_api_token', userData.token);
            data.append('sid', userData.sid);
        }
        //data.append('values[for_month]', true);
        data.append('values[local]', newCase.practice_jurisdiction); //12
        data.append('values[practice_area]', newCase.practice_area); //20
        if (!!newCase.lawyer) {
            data.append('values[lawyer]', newCase.lawyer);
        }

        if(!!newCase.referral_source){
            data.append('values[referral_source]', newCase.referral_source);
        }

        if (duration) {
            data.append('values[duration]', duration);
        }

        if (startDate) {
            data.append('values[startdate]', startDate);
        }

        fetch(`${process.env.REACT_APP_API_HOST_URL}/available_hours`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('failed');
                }
                return res.json();
            })
            .then(resData => {
                //console.log("resData", resData);
                if (resData.success) {
                    this.setState({
                        calendar: resData.data,
                        loaded: true,
                        processing: false,
                    }, () => {
                        this.props.handleInputChange('calendar_data', resData.data.response);
                    })
                }
            })
            .catch(err => {
                //console.log(err);
            });
    };

    filterDate = (data) => {
        const { calendar: { response: { calendar_data } } } = this.state;
        const currentDate = moment(data).format('DD/MM/YYYY');
        const matchDate = calendar_data[0].calendar.find(cal => cal.day === currentDate && cal.schedule.length > 0);
        return matchDate ? false : true;
    };

    filterHighlighted = () => {
        //const { calendar: { calendar_data } } = this.state;
        const { calendar: { response: { calendar_data } } } = this.state;

        let hightlighted = [];

        for (let i = 0; i < calendar_data.length; i++) {
            let calendar = calendar_data[i].calendar;
            for (let j = 0; j < calendar.length; j++) {
                let schedule = calendar[j];
                if (schedule.schedule.length > 0) {
                    let date = moment(schedule.day_iso).tz(calendar_data[i].timeZone).toDate();
                    if (hightlighted.indexOf(date) < 0) {
                        hightlighted.push(date);
                    }
                }

            }
        }
        return hightlighted;
    };

    handleDayClick = (day, { selected }) => {
        const { calendar } = this.state;
        //console.log(calendar);
        //console.log(day);
        const currentDate = moment(day).format('YYYY-MM-DD');
        if (calendar.response.available[currentDate]) {
            this.props.handleInputChange('date', day);
            let availableHours = calendar.response.available[currentDate].sort(sortAvailable);
            this.filterHours(availableHours);
        }
    };

    filterHours = (avaliableHours) => {
        const startTimes = [];
        avaliableHours.forEach((item, index) => {
            var beginningTime = moment(item.start, 'HH:mm');
            var startMinutes = parseInt(beginningTime.clone().format("m"));

            if (startMinutes < 15 && startMinutes != 0) {
                var diff = 15 - startMinutes;
                beginningTime = beginningTime.clone().add(diff, 'm');
            } else if (startMinutes > 15 && startMinutes < 30 && startMinutes != 0) {
                var diff = 30 - startMinutes;
                beginningTime = beginningTime.clone().add(diff, 'm');
            } else if (startMinutes > 30 && startMinutes < 45 && startMinutes != 0) {
                var diff = 45 - startMinutes;
                beginningTime = beginningTime.clone().add(diff, 'm');
            } else if (startMinutes > 45) {
                let diff = 60 - startMinutes;
                beginningTime = beginningTime.clone().add(diff, 'm');
            }


            var endTime = moment(item.end, 'HH:mm');
            let _duration = moment.duration(endTime.diff(beginningTime)).asMinutes();
            for (var i = 0; i <= _duration; i += duration) {
                var currTime = beginningTime.clone().add(i, 'minutes');
                var timeForShow = currTime.clone().format('HH:mm');
                var addValue = {
                    value: timeForShow,
                    lastItem: (i === _duration) ? true : false,
                };
                if (moment.duration(endTime.diff(currTime)).asMinutes() >= duration) {
                    startTimes.push(addValue);
                }
            }
        })

        this.setState({
            availableHours: startTimes,
        }, () => {
            this.props.handleInputChange('start', '');
        })
    };

    handleDatePickerMonthChange = (data) => {
        if (moment(data).startOf('month').isAfter(currentDate)) {
            var date = moment(data).startOf('month').format('Y-MM-DD');
        }

        var date = moment(data).startOf('month').isAfter(moment(currentDate))
            ? moment(data).startOf('month').format('Y-MM-DD')
            : null;

        this.setState({
            processing: true,
        }, () => {
            this.getAvailableHours(date, duration);
        });
    };

    handleSchedulerDateChange = (date) => {
        this.setState({
            processing: true,
        }, () => {
            this.getAvailableHours(date.format('Y-MM-DD'));
        });
    };

    handleSchedulerTimeSelect = (date) => {
        //console.log(date);
        this.props.handleInputChange('date', date.date);
        this.props.handleInputChange('start', date.time);
    };

    handleChange = name => ({ target: { value } }) => {
        //console.log(value);
        this.props.handleInputChange(name, value);
    };

    render() {
        if (!this.state.loaded) {
            return <Loading />
        }

        const { availableHours, processing, calendar, error } = this.state,
            { classes, newCase } = this.props;
        if (!calendar.success) {

            return (
                <div className={classes.root} style={{ paddingTop: 20 }}>
                    <Alert color="info">
                        {calendar.error.map(err => {
                            return err
                        })}
                    </Alert>
                </div>
            );
        }

        let highlighted = this.filterHighlighted();

        return (
            <div className={classes.root} style={{ paddingTop: 20 }}>
                {processing && <Processing />}

                <Typography style={{ marginBottom: '10px' }}>
                    Choose a date and time for your free consultation.
                    Next you'll select a lawyer who specializes in cases like yours.
                </Typography>

                <TimeScheduler
                    calendarData={calendar}
                    onDateChange={this.handleSchedulerDateChange}
                    onDayClick={this.handleSchedulerTimeSelect} />

                {/* <DayPicker
                    showOutsideDays
                    selectedDays={newCase.date}
                    navbarElement={<CalendarNavbar />}
                    modifiers={{ highlighted: highlighted }}
                    fromMonth={moment().toDate()}
                    onMonthChange={this.handleDatePickerMonthChange}
                    onDayClick={this.handleDayClick}
                    disabledDays={this.filterDate}
                />

                <FormControl margin="normal" fullWidth>
                    <TextField
                        select
                        label="AvailableHours"
                        value={newCase.start}
                        onChange={this.handleChange('start')}
                        helperText="Please select hours"
                        variant="outlined"
                    >
                        {!availableHours.length &&
                            < MenuItem value="">
                                Choose Time
                            </MenuItem>
                        }
                        {
                            availableHours.map((option, index) =>
                                !option.lastItem
                                    ?
                                    <MenuItem key={index} value={option.value}>
                                        {moment(option.value, 'HH:mm').format('h:mm a')} - {moment(option.value, 'HH:mm').add(duration, 'minutes').format('h:mm a')}
                                    </MenuItem>
                                    : null
                            )
                        }
                    </TextField>
                </FormControl> */}
            </div>

        )
    }
}

ScheduleConsult.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(ScheduleConsult);