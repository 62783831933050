import React, { Component } from "react";
import { List, ListSubheader } from "@material-ui/core";
import AppointmentListItem from "./AppointmentListItem";
import moment from "moment-timezone";

class AppointmentsItems extends Component {
	render() {
		const {
			appointments,
			timeZone,
			rejectionReasons,
			cancelAppointment,
			rejectAppointment,
			acceptAppointment,
			unattendedAppointmentComplete,
			completeAppointment,
			isLawyer,
			auth,
		} = this.props;

		return appointments.map((appt, index) => {
			return (
				<List
					key={index}
					style={{ marginBottom: 20 }}
					subheader={
						<ListSubheader
							style={{ textTransform: "uppercase" }}
							disableGutters
						>
							{moment
								.unix(appt.date)
								.tz(timeZone)
								.format("MMMM YYYY")}
						</ListSubheader>
					}
				>
					{appt.appointments.map((appointment, index) => {
						return (
							<AppointmentListItem
								appointment={appointment}
								timeZone={timeZone}
								key={index}
								rejectionReasons={rejectionReasons}
								cancelAppointment={cancelAppointment}
								rejectAppointment={rejectAppointment}
								acceptAppointment={acceptAppointment}
								unattendedAppointmentComplete={
									unattendedAppointmentComplete
								}
								completeAppointment={completeAppointment}
								isLawyer={isLawyer}
								auth={auth}
							/>
						);
					})}
				</List>
			);
		});
	}
}

export default AppointmentsItems;
