import React, { Component } from 'react';
import EasyTimer from "easytimer";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";

const styles = theme => ({
    green: {
        color: '#00CC99',
    },
    red: {
        color: '#FF5555'
    },
    label: {
        fontSize: 12,
        fontWeight: 700,
    }
});

class Timer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            timer: new EasyTimer(),
            startValues: props.startValues,
            timeValues: "00:00",
        };

        this.tick = this.tick.bind(this);
    };

    componentDidMount() {
        let { timer } = this.state,
            { showNotification } = this.props;
        timer.addEventListener("secondsUpdated", this.tick);
        const { startValues, countDown, startFrom } = this.props;
        //console.log(startValues);
        let config = {
            startValues: { seconds: startValues },
            countdown: countDown,
        };

        if (startFrom >= 0) {
            setTimeout(function () {
                timer.start(config);

                if (startValues <= 300 && countDown) {
                    showNotification();
                }
            }, startFrom);
        }
    };

    componentWillUnmount() {
        let { timer } = this.state;
        timer.stop();
    }

    static getDerivedStateFromProps = (props, state) => {
        if (props.startValues != state.startValues) {
            let { timer } = state;
            timer.stop();
            timer.start({
                startValues: { seconds: props.startValues },
                countdown: true
            });
            return {
                startValues: props.startValues,
            }
        }
        return null;
    };

    tick(e) {
        let { timer } = this.state,
            { countDown } = this.props;
        const hours = Number(timer.getTotalTimeValues().toString(['hours']));
        let seconds = timer.getTotalTimeValues().seconds;
        if (seconds == 300 && countDown) {
            this.props.showNotification();
        }
        let timeValues = "00:00";
        if (hours > 0) {
            timeValues = timer.getTimeValues().toString();
        } else {
            timeValues = timer.getTimeValues().toString(['minutes', 'seconds']);
        }
        //const timeValues = timer.getTimeValues().toString();
        this.setState({ timeValues: timeValues });
    }

    render() {
        const { classes, variant } = this.props;
        return <div className={classNames(classes[variant], classes.label)}>{this.state.timeValues}</div>;
    }
}

Timer.propTypes = {
    variant: PropTypes.oneOf(["green", "red"]).isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Timer);