import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import {
	AppBar,
	Toolbar,
	Typography,
	IconButton,
	Drawer,
	Badge,
	Card,
	CardHeader,
	Avatar,
	List,
	ListItem,
	ListItemText,
	Link,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MailIcon from "@material-ui/icons/Mail";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { fetchHeaderData } from "../../redux/actions/headerActions";

import AppointmentCreateHeader from "./AppoinmentCreateHeader";
import CaseCreateHeader from "../Case/CaseCreateHeader";
import {
	LOGOUT,
	APPOINTMENTS_CREATE_STEP_CHANGE,
	CASE_NEW_RESET_AFTER_CREATE,
} from "../../redux/actions/actionTypes";

const styles = (theme) => ({
	root: {
		width: "100%",
		backgroundColor: "#220d89",
	},
	grow: {
		flexGrow: 1,
	},
	title: {
		fontSize: "15px",
		fontWeight: 700,
	},
	menuButton: {
		marginLeft: 10,
		//marginRight: 20,
	},
	sectionDesktop: {
		display: "flex",
	},
	qaseLogo: {
		maxHeight: "36px",
		marginRight: "5px",
	},
	//Right Menu
	qaseMenu: {
		// '&:afet': {
		//     content: "",
		//     position: 'absolute',
		//     width: '100%',
		//     height: '100%',
		//     top: 0,
		//     left: 0,
		//     zIndex: -1,
		//     opacity: 0.02,
		//     backgroundImage: '../images/logo-blue.png',
		//     backgroundPosition: 'center bottom',
		//     backgroundSize: 'contain',
		//     backgroundRepeat: 'no-repeat',
		// }
	},
	qaseMenuBackdrop: {
		top: "57px",
	},
	qaseMenuPaper: {
		width: "290px",
		top: "57px",
	},
	card: {
		boxShadow: "none",
	},
	avatar: {
		height: "56px",
		width: "56px",
	},
	qaseMenuLogo: {
		position: "absolute",
		bottom: "56px",
		opacity: 0.03,
		maxWidth: "100%",
		zIndex: -1,
	},
	caseLink: {
		color: "#fff",
		textDecoration: "none",
	},
});

class Header extends Component {
	state = {
		qaseMenu: false,
		// user: {},
	};

	componentDidMount() {
		if (this.props.auth.token) {
			const userData = JSON.parse(localStorage.getItem("userData"));
			let data = new FormData();
			data.append("qase_api_token", userData.token);
			data.append("sid", userData.sid);
			this.props.fetchHeaderData(data);
		}
	}

	toggleDrawer = (menu, open) => () => {
		this.setState({
			[menu]: open,
		});
	};

	handleMorMenuItemClick = (path) => {
		this.props.history.push(path);
	};

	logout = () => {
		this.props.logout();
	};

	getSelecedCase = (cases) => {
		return cases.find((cs) => cs.id === this.props.auth.case_uuid);
	};

	render() {
		const { classes, user, auth } = this.props;
		if (this.props.location.pathname === "/appointment/create") {
			return <AppointmentCreateHeader {...this.props} />;
		} else if (this.props.location.pathname === "/case/create") {
			return (
				<CaseCreateHeader
					{...this.props}
					isLogged={!!this.props.auth.token}
				/>
			);
		}

		if (user.loading) {
			return null;
		}
		const selectedCase = this.getSelecedCase(user.cases);
		return (
			<Fragment>
				<AppBar position="sticky" className={classes.root}>
					<Toolbar>
						<img
							src={require("../../images/Qlogo.png")}
							alt="logo"
							className={classes.qaseLogo}
						/>

						<Typography
							className={classes.title}
							variant="h6"
							color="inherit"
							noWrap={true}
						>
							<NavLink
								to={
									selectedCase || !!user.cases.length
										? "/cases"
										: user.is_lawyer
											? "/cases"
											: "/case/create"
								}
								className={classes.caseLink}
							>
								{selectedCase
									? selectedCase.title
									: user.is_lawyer || !!user.cases.length
										? "Select a Case"
										: "Create a Case"}
							</NavLink>
						</Typography>

						<div className={classes.grow} />
						<div className={classes.sectionDesktop}>
							<IconButton
								color="inherit"
								onClick={() =>
									this.handleMorMenuItemClick(
										"/account_messages"
									)
								}
							>
								<Badge
									badgeContent={user.messages_count}
									color="secondary"
								>
									<MailIcon />
								</Badge>
							</IconButton>
							<IconButton
								color="inherit"
								onClick={() =>
									this.handleMorMenuItemClick("/activities")
								}
							>
								<Badge
									badgeContent={user.notification_count}
									color="secondary"
								>
									<NotificationsIcon />
								</Badge>
							</IconButton>
							<IconButton
								className={classes.menuButton}
								color="inherit"
								aria-label="Open drawer"
								onClick={this.toggleDrawer(
									"qaseMenu",
									!this.state.qaseMenu
								)}
							>
								<MenuIcon />
							</IconButton>
						</div>
					</Toolbar>
				</AppBar>
				<Drawer
					anchor="right"
					open={this.state.qaseMenu}
					onClose={this.toggleDrawer("qaseMenu", false)}
					classes={{
						paper: classes.qaseMenuPaper,
						modal: classes.qaseMenuBackdrop,
					}}
					ModalProps={{
						BackdropProps: {
							style: {
								top: 56,
								bottom: 72,
							},
						},
					}}
				>
					<div
						tabIndex={0}
						role="button"
						onClick={this.toggleDrawer("qaseMenu", false)}
					// onKeyDown={this.toggleDrawer('qaseMenu', false)}
					>
						<Card className={classes.card}>
							<CardHeader
								avatar={
									<Avatar
										alt="User"
										src={user.pic}
										className={classes.avatar}
									/>
								}
								title={`${user.first_name} ${user.last_name}`}
							/>
						</Card>
						<List component="nav">
							<ListItem button component={NavLink} to="/cases">
								<ListItemText primary="My Cases" />
							</ListItem>
							{!user.is_lawyer &&
								auth.referral_source != "acba-lrs" && (
									<ListItem
										button
										component={NavLink}
										to="/case/create"
									>
										<ListItemText primary="Create a New Case" />
									</ListItem>
								)}

							<ListItem button component={NavLink} to="/profile">
								<ListItemText primary="Account Settings" />
							</ListItem>

							<ListItem
								button
								component={Link}
								href={process.env.REACT_APP_HELP_URL}
								target="_blank"
							>
								<ListItemText primary="Help" />
							</ListItem>

							<ListItem
								button
								component={Link}
								href={`${process.env.REACT_APP_CONTACT_URL}?type=mobile&returnUrl=${window.location.href}`}
								target="_blank"
							>
								<ListItemText primary="Contact" />
							</ListItem>
							<ListItem
								button
								component={Link}
								href={process.env.REACT_APP_PRIVACY_URL}
							>
								<ListItemText primary="Privacy & Terms" />
							</ListItem>
							<ListItem button>
								<ListItemText
									primary="Sign out"
									onClick={() => this.logout()}
								/>
							</ListItem>
						</List>
						<img
							src={require("../../images/logo-blue.png")}
							alt=""
							className={classes.qaseMenuLogo}
						/>
					</div>
				</Drawer>
			</Fragment>
		);
	}
}

Header.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	return {
		user: state.headerReducer.user,
		appointment: state.appointment,
		newCase: state.case.newCase,
		auth: state.auth,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		//addNewCase: data => dispatch({ type: HEADER_ADD_NEW_CASE, payload: data }),
		fetchHeaderData: (data) => {
			dispatch(fetchHeaderData(data));
		},
		logout: () => dispatch({ type: LOGOUT, payload: false }),
		appointmentStepChangeReset: () =>
			dispatch({ type: APPOINTMENTS_CREATE_STEP_CHANGE, payload: 0 }),
		caseStepChangeReset: () =>
			dispatch({ type: CASE_NEW_RESET_AFTER_CREATE, payload: 0 }),
	};
};
//export default withStyles(styles)(Header);

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps)
)(Header);
