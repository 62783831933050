import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Paper, Grid, Typography, IconButton } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import './TimeScheduler.css'
const SCHEDULE_DURATION = 30;
const SHOW_DAYS = 3;

function sortAvailable(a, b) {
    var first = moment(a.start, 'HH:mm');
    var second = moment(b.start, 'HH:mm');
    if (second.diff(first) > 0)
        return -1;
    if (second.diff(first) < 0)
        return 1;
    return 0;
}


class TimeScheduler extends Component {
    weekdayshort = moment.weekdaysShort();
    startHours = moment("00:00", "HH:mm");
    endHours = moment("23:30", "HH:mm");

    state = {
        todayObject: moment(),
        dateObject: moment(),
        selectedDay: {},
    };

    headerWeekDays = () => {
        let retValue = [];
        const { dateObject } = this.state;

        for (let i = 0; i < SHOW_DAYS; i++) {
            let date = dateObject.clone().add("day", i);
            let row = (
                <Grid item xs={4} key={i}>
                    <Typography align="center" style={{ fontSize: '10px' }}>
                        {date.format("ddd")}
                    </Typography>
                    <Typography align="center" style={{ fontSize: '11px' }}>
                        {date.format("MMM D").toUpperCase()}
                    </Typography>
                </Grid>
            );
            retValue.push(row);
        }

        return retValue;
    };

    availableTimes = () => {
        let times = {};
        let startTimes = [];
        let timesForShow = {};
        let allAvailableTimes = [];
        let weekNames = [];
        const { dateObject, selectedDay } = this.state,
            { calendarData } = this.props;
        if (calendarData != null) {

            let _duration = moment.duration(this.endHours.diff(this.startHours)).asMinutes();
            for (let i = 0; i <= _duration; i += SCHEDULE_DURATION) {
                let currTime = this.startHours.clone().add(i, 'minutes');
                var timeForShow = currTime.clone().format('HH:mm');
                startTimes.push(timeForShow);
            }

            for (let i = 0; i < SHOW_DAYS; i++) {
                let date = dateObject.clone().add("day", i);
                let currentDate = date.format('YYYY-MM-DD');
                weekNames.push(date.clone().format("ddd"));
                if (calendarData.response.available.hasOwnProperty(currentDate)) {
                    let availableHours = calendarData.response.available[currentDate].sort(sortAvailable);
                    let timeArray = this.filterHours(availableHours)
                    let temp = {
                        values: timeArray,
                        date: date
                    }
                    times[date.clone().format("ddd")] = temp;
                    allAvailableTimes = [...new Set([...allAvailableTimes, ...timeArray])].sort(function (a, b) {
                        var first = moment(a, 'HH:mm');
                        var second = moment(b, 'HH:mm');
                        if (second.diff(first) > 0)
                            return -1;
                        if (second.diff(first) < 0)
                            return 1;
                        return 0;
                    });
                }

            }
        }

        return allAvailableTimes.map((time) => {
            let time1 = times.hasOwnProperty(weekNames[0]) && times[weekNames[0]]["values"].includes(time) ? time : "";
            let selected1 = (!!selectedDay && !!time1 && times[weekNames[0]]["date"].isSame(selectedDay.date) && selectedDay.time === time1) ? true : false;
            let time2 = times.hasOwnProperty(weekNames[1]) && times[weekNames[1]]["values"].includes(time) ? time : "";
            let selected2 = (!!selectedDay && !!time2 && times[weekNames[1]]["date"].isSame(selectedDay.date) && selectedDay.time === time2) ? true : false;
            let time3 = times.hasOwnProperty(weekNames[2]) && times[weekNames[2]]["values"].includes(time) ? time : "";
            let selected3 = (!!selectedDay && !!time3 && times[weekNames[2]]["date"].isSame(selectedDay.date) && selectedDay.time === time3) ? true : false;
            return (
                <Grid container key={time} className="calendar-row">
                    <Grid xs={4} item>
                        <Typography align="center">
                            {!!time1 &&
                                <span
                                    className={selected1 ? "selected-time" : ""}
                                    onClick={e => {
                                        this.onDayClick(e, time1, times[weekNames[0]]["date"]);
                                    }}>
                                    {time1}
                                </span>
                            }
                        </Typography>
                    </Grid>
                    <Grid xs={4} item>
                        <Typography align="center">
                            {!!time2 &&
                                <span
                                    className={selected2 ? "selected-time" : ""}
                                    onClick={e => {
                                        this.onDayClick(e, time2, times[weekNames[1]]["date"]);
                                    }}>
                                    {time2}
                                </span>
                            }
                        </Typography>
                    </Grid>
                    <Grid xs={4} item>
                        <Typography align="center">
                            {!!time3 &&
                                <span
                                    className={selected3 ? "selected-time" : ""}
                                    onClick={e => {
                                        this.onDayClick(e, time3, times[weekNames[2]]["date"]);
                                    }}>
                                    {time3}
                                </span>
                            }
                        </Typography>
                    </Grid>
                </Grid>
            )
        })
    };

    filterHours = (avaliableHours) => {
        const startTimes = [];
        avaliableHours.forEach((item, index) => {
            var beginningTime = moment(item.start, 'HH:mm');
            var startMinutes = parseInt(beginningTime.clone().format("m"));

            if (startMinutes > 0 && startMinutes < 30 && startMinutes != 0) {
                var diff = 30 - startMinutes;
                beginningTime = beginningTime.clone().add(diff, 'm');
            }
            else if (startMinutes > 30) {
                let diff = 60 - startMinutes;
                beginningTime = beginningTime.clone().add(diff, 'm');
            }

            var endTime = moment(item.end, 'HH:mm');
            let _duration = moment.duration(endTime.diff(beginningTime)).asMinutes();
            for (var i = 0; i <= _duration; i += SCHEDULE_DURATION) {
                var currTime = beginningTime.clone().add(i, 'minutes');
                var timeForShow = currTime.clone().format('HH:mm');
                var addValue = {
                    value: timeForShow,
                    lastItem: (i === _duration) ? true : false,
                };
                if (moment.duration(endTime.diff(currTime)).asMinutes() >= SCHEDULE_DURATION) {
                    startTimes.push(timeForShow);
                }
            }
        })

        return startTimes;
    };

    onPrev = () => {
        const { dateObject } = this.state,
            { onDateChange } = this.props;
        let nextMoment = dateObject.clone().subtract("days", SHOW_DAYS);
        this.setState({
            dateObject: nextMoment,
        }, () => {
            if (onDateChange) {
                onDateChange(nextMoment);
            }
        })
    };

    onNext = () => {
        const { dateObject } = this.state,
            { onDateChange } = this.props;
        let nextMoment = dateObject.clone().add("days", SHOW_DAYS);
        this.setState({
            dateObject: nextMoment,
        }, () => {
            if (onDateChange) {
                onDateChange(nextMoment);
            }
        })
    };

    onDayClick = (e, time, date) => {
        const { onDayClick } = this.props;
        this.setState(
            {
                selectedDay: {
                    date: date,
                    time: time,
                }
            },
            () => {
                if (onDayClick) {
                    onDayClick(this.state.selectedDay);
                }
            }
        );
    };

    render() {
        const { todayObject, dateObject } = this.state;
        let weekdayshortname = this.headerWeekDays();
        let availableHours = this.availableTimes();
        let isBackDisabled = dateObject.isSameOrBefore(todayObject);
        return (
            <div className="calendar-date">
                <Paper>
                    <div className="calendar-header">
                        <div className="calendar-navigation">
                            <div className="calendar-button-wrapper button-prev">
                                <IconButton onClick={this.onPrev} disabled={isBackDisabled}>
                                    <KeyboardArrowLeft fontSize="small" />
                                </IconButton>
                            </div>
                            <div className="calendar-button-wrapper button-next">
                                <IconButton onClick={this.onNext}>
                                    <KeyboardArrowRight fontSize="small" />
                                </IconButton>
                            </div>
                        </div>
                        <div className="calendar-weeks">
                            <Grid container>
                                {weekdayshortname}
                            </Grid>
                        </div>
                    </div>

                    <div style={{ height: 400, width: '100%', overflowY: 'scroll' }}>
                        {availableHours}
                    </div>
                </Paper>
            </div>
        )
    }
}

export default TimeScheduler;