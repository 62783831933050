import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Typography, TextField, Button } from '@material-ui/core';
import { SignHeader } from '../Components/Layouts';

import PhoneIcon from '../Components/Icons/PhoneIcon';
import MailIcon from '../Components/Icons/MailIcon';

import './Login.css'

const styles = {
    formGroup: {
        width: '100%',
        marginBottom: '25px',
    },
    singBtn: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        borderRadius: 0,
        color: '#fff!important',
    },
    disabledBtn: {
        backgroundColor: '#999999',
    },
    activeBtn: {
        backgroundColor: '#220d89',
    },
    lblIcon: {
        width: '16px',
        height: '16px',
        marginTop: '15px;'
    }
};

class Contact extends Component {

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <div className="page-login__wrapper">
                    <SignHeader />
                    <div className="page_login__content">

                        <Typography component="h6" style={{ marginTop: '30px' }}>
                            Contact Qase
                        </Typography>
                        
                        <br />
                        
                        <div>

                            <Typography component="h6" inline={true} >
                                {<PhoneIcon className={classes.lblIcon} />} (844) 435-7658
                            </Typography>
                            
                            <Typography component="h6" inline={true} >
                                {<MailIcon className={classes.lblIcon} />} support@qase.ca
                            </Typography>

                        </div>

                        <form style={{
                            marginTop: '40px',
                        }} onSubmit={this.handleFormSubmit}>
                            <TextField
                                className={classes.formGroup}
                                placeholder="First Name"
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                className={classes.formGroup}
                                placeholder="Last Name"
                                fullWidth
                                margin="normal"
                            />

                            <TextField
                                className={classes.formGroup}
                                placeholder="Phone Number"
                                fullWidth
                                margin="normal"
                            />

                            <TextField
                                className={classes.formGroup}
                                placeholder="Email Address"
                                fullWidth
                                margin="normal"
                            />

                            <TextField
                                className={classes.formGroup}
                                placeholder="Message"
                                fullWidth
                                margin="normal"
                            />
                            
                            <Button variant="contained" fullWidth size="large"  type="submit" className={classNames.formGroup}>
                                SUBMIT
                            </Button>


                         </form>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

Contact.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contact);
