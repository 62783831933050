import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import './index.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';

// const reduxStore = 

// ReactDOM.render(
//     <Provider store={reduxStore}>
//         <App />
//     </Provider>,
//     document.getElementById('root')
// );


async function main() {
    const store = configureStore();
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById('root')
    );
}

main().catch(console.error)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
