import {
    HEADER_FETCH_BEGINING_DATA,
    HEADER_FETCH_HEADER_FAILURE,
    HEADER_ADD_NEW_CASE
} from '../actions/actionTypes';

const initialState = {
    qaseMenu: false,
    user: {
        activities_count: '',
        case_name: '',
        first_name: '',
        last_name: '',
        pic: '',
        cases: [],
        loading: true,
        is_lawyer: null,
    }
};

export default function headerReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case HEADER_FETCH_BEGINING_DATA:
            // All done: set loading "false".
            // Also, replace the items with the ones from the server
            return {
                ...state,
                user: {
                    ...action.payload,
                    loading: false
                }
            };

        case HEADER_FETCH_HEADER_FAILURE:
            // The request failed, but it did stop, so set loading to "false".
            // Save the error, and we can display it somewhere
            // Since it failed, we don't have items to display anymore, so set it empty.
            // This is up to you and your app though: maybe you want to keep the items
            // around! Do whatever seems right.
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case HEADER_ADD_NEW_CASE:
            state.user.cases.push(action.payload);
            return {
                ...state,
            };

        default:
            return state;
    }
}