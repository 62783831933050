import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  FormControl,
  Input,
  InputAdornment,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { SignHeader, NotificationSnackbar } from "../Components/Layouts";
import { Link, NavLink, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../redux/actions/authActions";
import "./Login.css";
import {
  AUTH_RESET_SESSION_EXPIRED_NOTIFY,
  CASE_STORE_CASE_DATA,
  HEADER_ADD_NEW_CASE,
  AUTH_SET_CASE_UUID,
} from "../redux/actions/actionTypes";
import querystring from "query-string";

const styles = {
  registerText: {
    textDecoration: "none",
    color: "#220D89",
    marginLeft: "5px",
  },
  formGroup: {
    width: "100%",
    marginBottom: "25px",
  },
  singBtn: {
    position: "fixed",
    bottom: 0,
    left: 0,
    borderRadius: 0,
    color: "#fff!important",
  },
  disabledBtn: {
    backgroundColor: "#999999",
  },
  activeBtn: {
    backgroundColor: "#220d89",
  },
};

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      showPassword: false,
      validForm: false,
      errorText: false,
      notifyModal: false,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    console.log(this.props.location);
    var queryParams = querystring.parse(this.props.location.search);

    //console.log(queryParams.create_case);
    //if need store qase data for create qase on login
    if (queryParams.create_case === "true") {
      this.props.storeCaseData(queryParams);
    }

    if (
      queryParams.register === "true" &&
      !localStorage.getItem("newRegiser")
    ) {
      localStorage.setItem(
        "newRegiser",
        JSON.stringify({
          register_reroute: 1,
          firsAccess: true,
        })
      );
      this.setState({
        notifyModal: true,
      });
    } else if (!!localStorage.getItem("newRegiser")) {
      var registerNotify = JSON.parse(localStorage.getItem("newRegiser"));
      registerNotify.firsAccess = false;
      localStorage.setItem("newRegiser", JSON.stringify(registerNotify));
      this.setState({
        notifyModal: true,
      });
    }
  }

  handleInputChange = (prop) => (event) => {
    this.setState({
      [prop]: event.target.value,
      validForm: !!(!!this.state.username && !!this.state.password),
      errorText: false,
    });
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  handleFormSubmit = (event) => {
    event.preventDefault();
    const { caseStore } = this.props;

    let data = new FormData(event.target);

    let data_object = {};
    data.forEach(function (value, key) {
      data_object[key] = value;
      //console.log(value, key);
    });

    var username = this.state.username;
    var password = this.state.password;

    if (username.trim().length === 0 || password.trim().length === 0) {
      return;
    }

    if (!!caseStore) {
      for (const [key, value] of Object.entries(caseStore)) {
        data.append(key, value);
      }
    }

    this.props.login(data);
  };

  isFormValid = () => {
    //const { username, password } = this.state;
    return !!(!!this.state.username && !!this.state.password);
  };

  getRegisterSuccessModal = () => {
    const regInfo = JSON.parse(localStorage.getItem("newRegiser"));
    if (regInfo === null) {
      return null;
    } else {
      return (
        <Dialog
          open={this.state.notifyModal}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {regInfo.firsAccess
                ? "Welcome to Qase.  You have successfully registered!"
                : "To access your account, please login"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ notifyModal: false })}
              color="primary"
              autoFocus
            >
              Login
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  };

  render() {
    const {
      classes,
      auth: { errorMessage, sessionExpired, token, case_uuid },
    } = this.props;
    let values = querystring.parse(this.props.location.search);
    if (token) {
      if (!!values["newcase"]) {
        return (
          <Redirect
            to={{
              pathname: "/case/create",
              search: this.props.location.search,
            }}
          />
        );
      } else {
        return (
          <Redirect
            to={!!case_uuid ? `/dashboard/${case_uuid}` : "dashboard"}
          />
        );
      }
    }

    const regInfo = JSON.parse(localStorage.getItem("newRegiser"));
    var registerLink =
      regInfo === null
        ? `${process.env.REACT_APP_HOST_URL}/user/register`
        : "/login";
    var notifyModal = this.getRegisterSuccessModal();
    return (
      <div className="page-login__wrapper">
        <SignHeader />
        {notifyModal}
        <div className="page_login__content">
          <Typography
            component="h6"
            style={{ marginTop: "25x", fontWeight: 700 }}
          >
            Sign In
          </Typography>
          <Typography component="h6" variant="caption">
            NO ACCOUNT?
            <a href={registerLink}> REGISTER</a>
          </Typography>
          <form
            style={{
              marginTop: "40px",
            }}
            onSubmit={this.handleFormSubmit}
          >
            {errorMessage && (
              <Typography align="center" color="secondary">
                {errorMessage}
              </Typography>
            )}
            <TextField
              className={classes.formGroup}
              placeholder="Email Address"
              fullWidth
              value={this.state.username}
              onChange={this.handleInputChange("username")}
              margin="normal"
              name="name"
            />

            <FormControl className={classes.formGroup}>
              <Input
                fullWidth
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.password}
                onChange={this.handleInputChange("password")}
                placeholder="Password"
                name="pass"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                    >
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Typography
                component="h6"
                variant="caption"
                align="right"
                style={{ marginTop: 10 }}
              >
                <NavLink
                  to="/login/recover/password"
                  className={classes.registerText}
                >
                  FORGOT?
                </NavLink>
              </Typography>
            </FormControl>
            <Button
              variant="contained"
              fullWidth
              size="large"
              disabled={!this.isFormValid()}
              type="submit"
              className={classNames(
                classes.singBtn,
                !this.isFormValid() ? classes.disabledBtn : classes.activeBtn
              )}
            >
              Sign In
            </Button>

            <Button
              variant="contained"
              color="secondary"
              fullWidth
              component="a"
              href={process.env.REACT_APP_ELC_WEBSITE_URL}
            >
              Everyone Legal Clinic Client: CLICK HERE to login
            </Button>
          </form>
        </div>
        {sessionExpired && (
          <NotificationSnackbar
            snackbarOptions={{
              open: sessionExpired,
              variant: "info",
              message: "Your session is expired",
            }}
            handleClose={this.props.resetSessionExpiration}
          />
        )}
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    caseStore: state.case.caseStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => dispatch(login(data)),
    resetSessionExpiration: () =>
      dispatch({ type: AUTH_RESET_SESSION_EXPIRED_NOTIFY }),
    storeCaseData: (data) =>
      dispatch({ type: CASE_STORE_CASE_DATA, payload: data }),
    // setCaseUuid: (case_uuid) => {
    // 	dispatch({ type: AUTH_SET_CASE_UUID, payload: case_uuid });
    // },
    // addNewCase: (data) =>
    // 	dispatch({ type: HEADER_ADD_NEW_CASE, payload: data }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LoginPage));
