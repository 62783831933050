import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { connect } from "react-redux";
import {
    Tabs,
    Tab,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    //Typography
} from "@material-ui/core";

import HomeIcon from "@material-ui/icons/Home";

import {
    MoreHoriz,
    Folder as FolderIcon,
    Gavel as GavelIcon,
    CreditCard as CreditCardIcon,
    KeyboardArrowDown,
} from "@material-ui/icons";

import { AppointmentIcon, MessagesIcon, NotesIcon, TaskIcon } from "../Icons";
import NotificationSnackbar from "./NotificationSnackbar";

const menuList = ["/appointments", "/tasks", "/messages", "/files"];

const submenuList = ["/dashboard", "/notes", "/case", "/transactions"];

const styles = (theme) => ({
    bottomNavigation: {
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        backgroundColor: "#220d89",
        zIndex: 100,
    },
    selectedTab: {
        opacity: 1,
        color: "#fff!important",
    },
    labelIcon: {
        color: "#fff",
        opacity: 0.5,
    },
    tabLabelContainer: {
        padding: 0,
    },
    tabLabel: {
        fontSize: "9px",
        textTransform: "none",
    },
    bottomMenuPaper: {
        bottom: 72,
    },
    bottomMenuBackdrop: {
        bottom: 72,
    },
    bottomMenuWrapper: {
        backgroundColor: "#180a61",
    },
    closeMenu: {
        color: "#fff",
        textAlign: "center",
    },
});

class BottomNavigation extends Component {
    state = {
        bottom: false,
        notificationSnackbar: {
            open: false,
            variant: "",
            message: "",
        },
    };

    handleTabChange = (event, value) => {
        if (!this.props.auth.case_uuid) {
            this.handleShowSnackbar({
                variant: "info",
                message:
                    "These features are available after you have selected or created a case",
            });
        } else {
            if (value === "/more") {
                this.setState({
                    bottom: true,
                });
            } else {
                this.props.history.push(value);
            }
        }
    };

    handleMenuToggle = (side, open) => {
        this.setState({
            [side]: open,
        });
    };

    handleMorMenuItemClick = (path) => {
        this.setState({
            bottom: false,
        });
        this.props.history.push(path);
    };

    getActiveMenuPath = () => {
        let returnPath = "/more";
        const {
            location: { pathname },
        } = this.props;
        const slash_index = pathname.indexOf("/", 1);
        let activeMenu =
            slash_index > 0 ? pathname.substring(0, slash_index) : pathname;

        if (menuList.includes(activeMenu)) {
            returnPath = activeMenu;
        } else if (submenuList.includes(activeMenu)) {
            returnPath = "/more";
        }

        return returnPath;
    };

    handleShowSnackbar = (options) => {
        this.setState({
            notificationSnackbar: {
                open: true,
                variant: options.variant,
                message: options.message,
            },
        });
    };

    handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({
            notificationSnackbar: {
                open: false,
                variant: "",
                message: "",
            },
        });
    };

    render() {
        if (
            this.props.location.pathname === "/appointment/create" ||
            this.props.location.pathname === "/case/create"
        ) {
            return null;
        }
        let pathname = this.props.location.pathname
            ? this.props.location.pathname
            : "/dashboard";
        let value = this.getActiveMenuPath();
        //console.log(pathname);
        const { notificationSnackbar } = this.state;
        const { classes, auth } = this.props;

        return (
            <Fragment>
                <div className={classes.bottomNavigation}>
                    <Tabs
                        value={value}
                        onChange={this.handleTabChange}
                        variant="fullWidth"
                        indicatorColor="primary"
                        centered
                    >
                        <Tab
                            label="Appointments"
                            icon={<AppointmentIcon />}
                            classes={{
                                labelIcon: classes.labelIcon,
                                label: classes.tabLabel,
                                labelContainer: classes.tabLabelContainer,
                                wrapper: classes.tabItemWrapper,
                            }}
                            value="/appointments"
                            //disabled={!auth.case_uuid}
                        />

                        <Tab
                            label="Tasks"
                            icon={<TaskIcon />}
                            classes={{
                                labelIcon: classes.labelIcon,
                                label: classes.tabLabel,
                                labelContainer: classes.tabLabelContainer,
                                wrapper: classes.tabItemWrapper,
                            }}
                            value="/tasks"
                            //disabled={!auth.case_uuid}
                        />

                        {/* <Tab label="Overview" icon={<HomeIcon />}
                            classes={{
                                labelIcon: classes.labelIcon,
                                label: classes.tabLabel,
                                labelContainer: classes.tabLabelContainer,
                                wrapper: classes.tabItemWrapper,
                                selected: classes.selectedTab,
                            }}
                            value="/dashboard"
                        //disabled={!auth.case_uuid}
                        /> */}

                        <Tab
                            label="Messages"
                            icon={<MessagesIcon />}
                            classes={{
                                labelIcon: classes.labelIcon,
                                label: classes.tabLabel,
                                labelContainer: classes.tabLabelContainer,
                                wrapper: classes.tabItemWrapper,
                            }}
                            value="/messages"
                            //disabled={!auth.case_uuid}
                        />

                        {/* <Tab label="Notes" icon={<NotesIcon />}
                            classes={{
                                labelIcon: classes.labelIcon,
                                label: classes.tabLabel,
                                labelContainer: classes.tabLabelContainer,
                                wrapper: classes.tabItemWrapper,
                            }}
                            value="/notes"
                        //disabled={!auth.case_uuid}
                        /> */}

                        <Tab
                            label="Documents"
                            icon={<FolderIcon />}
                            classes={{
                                labelIcon: classes.labelIcon,
                                label: classes.tabLabel,
                                labelContainer: classes.tabLabelContainer,
                                wrapper: classes.tabItemWrapper,
                            }}
                            value="/files"
                            //disabled={!auth.case_uuid}
                        />
                        <Tab
                            label="More"
                            icon={<MoreHoriz />}
                            classes={{
                                labelIcon: classes.labelIcon,
                                label: classes.tabLabel,
                                labelContainer: classes.tabLabelContainer,
                                wrapper: classes.tabItemWrapper,
                            }}
                            value="/more"
                            disabled={!auth.case_uuid}
                        />
                    </Tabs>
                </div>
                <Drawer
                    anchor="bottom"
                    open={this.state.bottom}
                    onClose={() => this.handleMenuToggle("bottom", false)}
                    classes={{
                        paper: classes.bottomMenuPaper,
                        modal: classes.bottomMenuBackdrop,
                    }}
                    ModalProps={{
                        BackdropProps: {
                            style: {
                                top: "56px",
                            },
                        },
                    }}
                >
                    <div tabIndex={0} role="button">
                        <div className={classes.bottomMenuWrapper}>
                            <List component="nav">
                                <ListItem
                                    button
                                    onClick={() =>
                                        this.handleMenuToggle("bottom", false)
                                    }
                                >
                                    <ListItemText
                                        primary="Close"
                                        disableTypography={true}
                                        style={{
                                            color: "#fff",
                                            textAlign: "center",
                                        }}
                                    />
                                    <ListItemIcon
                                        style={{ color: "#fff", margin: 0 }}
                                    >
                                        <KeyboardArrowDown />
                                    </ListItemIcon>
                                </ListItem>

                                <ListItem
                                    button
                                    onClick={() =>
                                        this.handleMorMenuItemClick(
                                            "/dashboard"
                                        )
                                    }
                                    selected={pathname === "/dashboard"}
                                    disabled={!auth.case_uuid}
                                >
                                    <ListItemIcon style={{ color: "#fff" }}>
                                        <HomeIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Overview"
                                        disableTypography={true}
                                        style={{ color: "#fff" }}
                                    />
                                </ListItem>

                                <ListItem
                                    button
                                    onClick={() =>
                                        this.handleMorMenuItemClick("/notes")
                                    }
                                    selected={pathname === "/notes"}
                                    disabled={!auth.case_uuid}
                                >
                                    <ListItemIcon style={{ color: "#fff" }}>
                                        <NotesIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Notes"
                                        disableTypography={true}
                                        style={{ color: "#fff" }}
                                    />
                                </ListItem>

                                <ListItem
                                    button
                                    onClick={() =>
                                        this.handleMorMenuItemClick(
                                            "/case/overview"
                                        )
                                    }
                                    selected={pathname === "/case/overview"}
                                    disabled={!auth.case_uuid}
                                >
                                    <ListItemIcon style={{ color: "#fff" }}>
                                        <GavelIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Case Details"
                                        disableTypography={true}
                                        style={{ color: "#fff" }}
                                    />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() =>
                                        this.handleMorMenuItemClick(
                                            "/transactions"
                                        )
                                    }
                                    selected={pathname === "/transactions"}
                                    disabled={!auth.case_uuid}
                                >
                                    <ListItemIcon style={{ color: "#fff" }}>
                                        <CreditCardIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Transactions"
                                        disableTypography={true}
                                        style={{ color: "#fff" }}
                                    />
                                </ListItem>
                            </List>
                        </div>
                    </div>
                </Drawer>
                {notificationSnackbar.open && (
                    <NotificationSnackbar
                        snackbarOptions={notificationSnackbar}
                        handleClose={this.handleSnackbarClose}
                    />
                )}
            </Fragment>
        );
    }
}

BottomNavigation.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, null)
)(BottomNavigation);

//export default withStyles(styles)(BottomNavigation);
