import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Typography, FormControl, Button } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Alert } from 'reactstrap';
import { SignHeader, PageHading, Processing } from '../Components/Layouts';


const styles = theme => ({
    singBtn: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        borderRadius: 0,
        color: '#fff!important',
    },
    disabledBtn: {
        backgroundColor: '#999999',
    },
    activeBtn: {
        backgroundColor: '#220d89',
    }
});

class PasswordResetPage extends Component {
    state = {
        username: '',
        emailSent: false,
        errorMessage: false,
        submited: false,
    };

    handleInputChange = prop => event => {
        this.setState({
            [prop]: event.target.value,
        });
    };

    handleFormSubmit = () => {

        this.setState({
            submited: true,
        })
        var data = new FormData();
        data.append("name", this.state.username);
        data.append("is_spa", true);
        data.append("returnUrl", `${window.location.origin}/login?register=true` );

        fetch(`${process.env.REACT_APP_API_HOST_URL}/user/password/reset`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('failed');
                }
                return res.json();
            })
            .then(resData => {
                //console.log(resData);
                if (resData.success) {
                    this.setState({
                        errorMessage: false,
                        emailSent: true,
                        submited: false,
                    });
                }
                else {
                    this.setState({
                        errorMessage: resData.msg,
                        submited: false,
                    });
                }
            })
            .catch(err => {
                //console.log(err);
            });

    };

    render() {
        const { classes } = this.props,
            { errorMessage, emailSent, username, submited } = this.state;
        return (
            <div className="page-login__wrapper">
                <SignHeader />
                <div className="page_login__content">
                    {submited &&
                        (<Processing />)
                    }
                    {!submited &&
                        <PageHading title="Back to Sign In" history={this.props.history} />
                    }

                    {(!emailSent && !submited) &&
                        < Fragment >
                            <Typography component="h6" style={{ marginTop: '25px', fontWeight: 700 }}>
                                Need help with your password?
                        </Typography>
                            <Typography>
                                Enter the email you use for Qase and we'll help you create a new password:
                        </Typography>
                            <ValidatorForm
                                ref="form"
                                onSubmit={this.handleFormSubmit}
                                style={{
                                    marginTop: '40px',
                                }}>
                                <FormControl margin="normal" fullWidth>
                                    <TextValidator
                                        className={classes.formGroup}
                                        placeholder="Email Address"
                                        fullWidth
                                        value={this.state.username}
                                        onChange={this.handleInputChange('username')}
                                        name="name"
                                        validators={['required', 'isEmail']}
                                        errorMessages={['email is required', 'email is not valid']}
                                    />
                                </FormControl>
                                {errorMessage &&
                                    <Alert color="danger">
                                        {errorMessage}
                                    </Alert>
                                }



                                <Button variant="contained" fullWidth size="large" type="submit" className={classNames(classes.singBtn, !this.state.username ? classes.disabledBtn : classes.activeBtn)}>
                                    E-mail new password
                            </Button>
                            </ValidatorForm>
                        </Fragment>
                    }

                    {emailSent &&
                        <Alert color="success">
                            Further instructions have been sent to {username} e-mail address.
                        </Alert>
                    }
                </div>
            </div>
        )
    }
}

PasswordResetPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PasswordResetPage);