import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { List, Fab, Typography } from '@material-ui/core';
import { Edit as EditIcon, ArrowForward } from '@material-ui/icons';
import NoteItem from '../Components/NoteItem';
import { StickySubHeader, Loading, Processing, NotificationSnackbar } from '../Components/Layouts';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { fetchNotesList, deleteNote, shareNote } from '../redux/actions/noteActions';
import { NOTES_HIDE_ERROR } from '../redux/actions/actionTypes';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    fab: {
        position: 'fixed',
        bottom: 100,
        right: theme.spacing.unit * 2,
    },
    createFirstText: {
        position: 'fixed',
        bottom: 115,
        left: 0,
        right: 0,

    }
});

class NotesPage extends Component {

    state = {
        viewMode: 1, // 1: notes, 2: shared notes
    };

    componentDidMount() {
        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('case_uuid', this.props.auth.case_uuid);
        this.props.fetchNotesList(data);
    };

    changeMode = (mode) => {
        this.setState({
            viewMode: mode,
        })
    };

    handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.props.hideError();
    };

    handleDeleteNote = (uuid, id) => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('nid', id);
        this.props.deleteNote(uuid, data);
    };

    handleShareNote = (uid) => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        let data = new FormData();
        data.append('qase_api_token', userData.token);
        data.append('sid', userData.sid);
        data.append('uuid', uid);
        this.props.shareNote(uid, data);
    };

    render() {
        const { classes, noteList } = this.props,
            { viewMode } = this.state;

        if (!noteList.loaded) {
            return <Loading />
        }

        //console.log(noteList.notes);

        const notesList = noteList.notes.length
            ?
            <List>
                {
                    noteList.notes.filter(note => note.isShared === (viewMode !== 1))
                        .map(note => {
                            return (
                                <NoteItem
                                    key={note.id}
                                    note={note}
                                    deleteNote={this.handleDeleteNote}
                                    shareNote={this.handleShareNote}
                                />
                            );
                        })
                }
            </List>
            :
            (
                <Typography className={classes.createFirstText} align="center">
                    Create your first note!
                    {/* <IconButton> */}
                    <ArrowForward />
                    {/* </IconButton> */}
                </Typography>
            );

        return (

            <Fragment>
                <StickySubHeader title1="My Notes" title2="Shared" active={this.state.viewMode} changeMode={this.changeMode} />
                <div className="page--wrapper" onScroll={this.handleScroll}>
                    {noteList.processing &&
                        <Processing />}
                    {notesList}
                    <Fab className={classes.fab} color="primary"
                        onClick={() => { this.props.history.push('/note/create') }}
                    >
                        <EditIcon />
                    </Fab>

                    {noteList.notificationSnackbar.open &&
                        <NotificationSnackbar
                            snackbarOptions={noteList.notificationSnackbar}
                            handleClose={this.handleSnackbarClose}
                        />
                    }
                </div>

            </Fragment>


        )
    };
}

NotesPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        noteList: state.note,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchNotesList: (data) => dispatch(fetchNotesList(data)),
        deleteNote: (note_uuid, data) => dispatch(deleteNote(note_uuid, data)),
        shareNote: (note_uuid, data) => dispatch(shareNote(note_uuid, data)),
        hideError: () => dispatch({ type: NOTES_HIDE_ERROR }),
    }
};

export default compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
)(NotesPage);

