import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
	Paper,
	Typography,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableFooter,
	Button,
	Chip,
	DialogTitle,
	DialogContent,
	Dialog,
	DialogContentText,
	DialogActions,
} from "@material-ui/core";
import { ReportProblem } from "@material-ui/icons";
// import { Document, Page } from 'react-pdf';
//import PDF from "react-pdf-js";
// import { Document, Page } from "react-pdf/dist/entry.webpack";
import {
	PageHading,
	Loading,
	Processing,
	NotificationSnackbar,
	CancelationModal,
} from "../Components/Layouts";

import compose from "recompose/compose";
import { connect } from "react-redux";
import { fetchHeaderData } from "../redux/actions/headerActions";
import { LOGOUT } from "../redux/actions/actionTypes";
import { currency } from "../utils/helpers/";
import { loadStripe } from "@stripe/stripe-js";
import {
	Elements,
	ElementsConsumer,
	CardNumberElement,
} from "@stripe/react-stripe-js";

import StripeSplitForm from "../Components/Layouts/StripeSplitForm";

const styles = (theme) => ({
	Paper: {
		overflow: "hidden",
		padding: "8px 20px",
	},
	detailGroup: {
		marginBottom: 15,
	},
	caption: {
		fontSize: 10,
		color: "#A0AAB4",
		textTransform: "uppercase",
	},
	tableCell: {
		border: "none",
	},
	margin: {
		marginRight: theme.spacing.unit,
	},
	joinCallBtn: {
		backgroundColor: "#00cc99",
		"&:hover": {
			backgroundColor: "#00cc99",
		},
	},
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

class AppointmentDetailPage extends Component {
	state = {
		appointment: {},
		loading: true,
		submited: false,
		numPages: null,
		pageNumber: 1,
		notificationSnackbar: {
			open: false,
			variant: "",
			message: "",
		},
		rejectionModalOpen: false,
		cancelationModalOpen: false,
		acceptBtnColor: "default",
		acceptedModalOpen: false,
	};

	stripeContextRef = React.createRef();

	componentDidMount() {
		const {
			match: {
				params: { id, pmid },
			},
		} = this.props;
		this.getAppointment(id, pmid);
	}

	getAppointment(uid, pmid) {
		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("uuid", uid);

		if (pmid) {
			data.append("pmid", pmid);
		}

		fetch(`${process.env.REACT_APP_API_HOST_URL}/appointment`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				//console.log(res);
				if (res.status !== 200 && res.status !== 201) {
					throw new Error("failed");
				}

				return res.json();
			})
			.then((resData) => {
				console.log(resData);
				if (resData.data.success) {
					this.setState(
						{
							appointment: resData.data.result,
							loading: false,
						},
						() => {
							if (pmid) {
								this.props.fetchHeaderData(data);
							}
						}
					);
				}
				if (
					!resData.success &&
					(resData.message == "Session too old." ||
						resData.message == "No session found.")
				) {
					this.props.logout();
				}
			})
			.catch((err) => {
				//console.log(err);
			});
	}

	handleStripeElementChange = (change) => {
		//console.log("[change]", change);
		//console.log(this.stripeContextRef);
		if (change.error || change.empty) {
			this.setState({
				acceptBtnColor: "default",
			});
		} else {
			this.setState({
				acceptBtnColor: "primary",
			});
		}
	};

	getActionButtons = () => {
		const { appointment } = this.state;
		const { classes, isLawyer, auth } = this.props;
		const appointment_actions = appointment.appointment_actions;

		if (
			appointment.status === "accepted" &&
			appointment.when <= appointment.now + 600 &&
			appointment.to > appointment.now - 3600
		) {
			return (
				<Button
					color="primary"
					variant="contained"
					size="small"
					className={classes.joinCallBtn}
					onClick={() =>
						this.props.history.push(
							`/conference/${appointment.uuid}`
						)
					}
				>
					Join Call
				</Button>
			);
		}

		if (appointment.status === "closed" && isLawyer) {
			if (appointment.billable === "0") {
				return (
					<Button
						variant="contained"
						size="small"
						color="primary"
						className={classes.margin}
						onClick={() =>
							this.handleActionButtonsClick(
								"appointment-complete"
							)
						}
					>
						Complete
					</Button>
				);
			} else {
				return null;
			}
		}

		if (appointment.status === "completed") {
			return (
				<Chip
					label={
						appointment.billable === "0" ? "Completed" : "Invoiced"
					}
					color="primary"
					variant="outlined"
				/>
			);
		}
		let actionsList =
			appointment.user_uuid === auth.uid
				? isLawyer
					? appointment_actions.provider_source_options
					: appointment_actions.client_source_options
				: isLawyer
				? appointment_actions.provider_target_options
				: appointment_actions.client_target_options;

		if (
			appointment.user_role == "client" &&
			!appointment.accept_billable &&
			appointment.status == "pending" &&
			appointment.billable === "1" &&
			appointment.appointment_type != "Initial consultation"
		) {
			return (
				<Fragment>
					<Typography>
						<ReportProblem
							style={{ fontSize: 16, marginRight: 4 }}
						/>
						Please complete billing details.
					</Typography>
					<Elements stripe={stripePromise}>
						<ElementsConsumer>
							{(context) => {
								this.stripeContextRef.current = context;
								return (
									<StripeSplitForm
										stripe={context.stripe}
										elements={context.elements}
										onChange={
											this.handleStripeElementChange
										}
									/>
								);
							}}
						</ElementsConsumer>
					</Elements>
					{actionsList.map((action) => {
						//console.log(action);
						if (
							action.field_end_point_function.und[0].value !=
							"appointment-reschedule"
						) {
							return (
								<Button
									variant="contained"
									size="small"
									color={
										action.field_end_point_function.und[0]
											.value == "appointment-accept"
											? this.state.acceptBtnColor
											: "primary"
									}
									className={classes.margin}
									key={action.tid}
									onClick={() =>
										this.handleActionButtonsClick(
											action.field_end_point_function
												.und[0].value
										)
									}
								>
									{
										action.field_future_state_label.und[0]
											.value
									}
								</Button>
							);
						}
					})}
				</Fragment>
			);
		}

		if (actionsList) {
			return (
				<Fragment>
					{actionsList.map((action) => {
						return (
							<Button
								variant="contained"
								size="small"
								color="primary"
								className={classes.margin}
								key={action.tid}
								onClick={() =>
									this.handleActionButtonsClick(
										action.field_end_point_function.und[0]
											.value
									)
								}
							>
								{action.field_future_state_label.und[0].value}
							</Button>
						);
					})}
				</Fragment>
			);
		}
	};

	handleActionButtonsClick = (type) => {
		switch (type.toLowerCase()) {
			case "appointment-accept":
				this.handleAppointmentAccept();
				break;
			case "appointment-cancel":
				this.handleAppointmentCancel();
				break;
			case "appointment-decline":
				this.handleAppointmentReject();
				break;
			case "appointment-reschedule":
				this.props.history.push(
					`/appointment/create?type=reschdule&uuid=${this.state.appointment.uuid}`
				);
				break;
			case "appointment-complete":
				this.handleAppointmentComplete();
				break;
			case "appointment-close":
				this.handleCloseAppointment();
				break;
		}
	};

	handleAppointmentAccept = async () => {
		this.setState({
			submited: true,
		});

		let endpoint_url = `${process.env.REACT_APP_API_HOST_URL}/appointment/accept`;
		const { appointment } = this.state;
		let data = new FormData();
		const userData = JSON.parse(localStorage.getItem("userData"));

		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("uuid", appointment.uuid);

		if (
			appointment.user_role == "client" &&
			!appointment.accept_billable &&
			appointment.status == "pending"
		) {
			endpoint_url = `${process.env.REACT_APP_API_HOST_URL}/appointment/accept_with_token`;
			let stripe = this.stripeContextRef.current.stripe;
			let elements = this.stripeContextRef.current.elements;
			let card = elements.getElement(CardNumberElement);
			const { error, token } = await stripe.createToken(card);
			console.log(error);
			if (error) {
				this.handleShowSnackbar({
					variant: "error",
					message: error.message,
				});
				this.setState({
					submited: false,
				});
				return;
			}
			data.append("stripeToken", token.id);
		}

		fetch(endpoint_url, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				if (res.status !== 200 && res.status !== 201) {
					if (appointment.uuid) {
						throw new Error(
							"Failed to accept appointment " + appointment.uuid
						);
					}
					throw new Error("General failure: " + res.status);
				}
				return res.json();
			})
			.then((resData) => {
				console.log(resData);
				if (resData.success) {
					if (resData.data.success) {
						this.setState({
							submited: false,
							appointment: {
								...this.state.appointment,
								status: resData.data.status.toLowerCase(),
								appointment_actions: resData.data.actions,
							},
							acceptedModalOpen:
								resData.data.billable && resData.data.is_client,
						});
					} else {
						this.setState(
							{
								submited: false,
							},
							() =>
								this.handleShowSnackbar({
									variant: "error",
									message: !!resData.data.error
										? !!resData.data.error
										: "Appointment not accepted!",
								})
						);
					}
				} else {
					this.setState({
						submited: false,
					});
				}
			})
			.catch((err) => {
				//console.log(err);
			});
	};

	handleAppointmentCancel = () => {
		this.setState({
			cancelationModalOpen: true,
		});
	};

	handleAppointmentCancelSubmit = (appointment) => {
		this.setState({
			submited: true,
			cancelationModalOpen: false,
		});
		const { isLawyer } = this.props;
		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("uuid", appointment.uuid);
		data.append("comment", appointment.comment);
		data.append("reason", appointment.reason);
		fetch(`${process.env.REACT_APP_API_HOST_URL}/appointment/cancel`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				//console.log(res);
				if (res.status !== 200 && res.status !== 201) {
					if (appointment.uuid) {
						throw new Error(
							"Failed to cancel appointment " + appointment.uuid
						);
					}
					throw new Error("General failure: " + res.status);
				}

				return res.json();
			})
			.then((resData) => {
				//console.log(resData);
				if (resData.success) {
					if (resData.data.success) {
						this.setState({
							submited: false,
							appointment: {
								...this.state.appointment,
								status: resData.data.status.toLowerCase(),
								appointment_actions: isLawyer
									? resData.data.actions
									: [],
							},
						});
					} else {
						this.setState(
							{
								submited: false,
							},
							() =>
								this.handleShowSnackbar({
									variant: "error",
									message: "Appointment not canceled!",
								})
						);
					}
				} else {
					this.setState(
						{
							submited: false,
						},
						() =>
							this.handleShowSnackbar({
								variant: "error",
								message: "Appointment not canceled!",
							})
					);
				}
			})
			.catch((err) => {
				//console.log(err);
				this.setState(
					{
						submited: false,
					},
					() =>
						this.handleShowSnackbar({
							variant: "error",
							message: "Appointment not canceled!",
						})
				);
			});
	};

	handleAppointmentReject = () => {
		this.setState({
			rejectionModalOpen: true,
		});
	};

	handleAppointmentRejectSubmit = (appointment) => {
		this.setState({
			submited: true,
			rejectionModalOpen: false,
		});
		const userData = JSON.parse(localStorage.getItem("userData"));

		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("uuid", appointment.uuid);
		data.append("comment", appointment.comment);
		data.append("reason", appointment.reason);

		fetch(`${process.env.REACT_APP_API_HOST_URL}/appointment/reject`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				//console.log(res);
				if (res.status !== 200 && res.status !== 201) {
					if (appointment.uuid) {
						throw new Error(
							"Failed to reject appointment " + appointment.uuid
						);
					}
					throw new Error("General failure: " + res.status);
				}

				return res.json();
			})
			.then((resData) => {
				//console.log(resData);
				if (resData.success) {
					if (resData.data.success) {
						this.setState({
							submited: false,
							appointment: {
								...this.state.appointment,
								status: resData.data.status.toLowerCase(),
								appointment_actions: [],
							},
						});
					} else {
						this.setState(
							{
								submited: false,
							},
							() =>
								this.handleShowSnackbar({
									variant: "error",
									message: "Appointment not rejected!",
								})
						);
					}
				} else {
					this.setState(
						{
							submited: false,
						},
						() =>
							this.handleShowSnackbar({
								variant: "error",
								message: "Appointment not rejected!",
							})
					);
				}
			})
			.catch((err) => {
				//console.log(err);
				this.setState(
					{
						submited: false,
					},
					() =>
						this.handleShowSnackbar({
							variant: "error",
							message: "Appointment not rejected!",
						})
				);
			});
	};

	handleAppointmentComplete = () => {
		this.setState({
			submited: true,
		});

		const { appointment } = this.state;
		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("uuid", appointment.uuid);

		fetch(`${process.env.REACT_APP_API_HOST_URL}/appointment/complete`, {
			method: "POST",
			body: data,
		})
			.then((res) => {
				if (res.status !== 200 && res.status !== 201) {
					if (appointment.uuid) {
						throw new Error(
							"Failed to complete appointment " + appointment.uuid
						);
					}
					throw new Error("General failure: " + res.status);
				}

				return res.json();
			})
			.then((resData) => {
				if (resData.success) {
					if (resData.data.success) {
						//console.log(resData.data);
						this.setState({
							submited: false,
							appointment: {
								...this.state.appointment,
								status: resData.data.status.toLowerCase(),
								appointment_actions: [],
							},
						});
					} else {
						this.setState(
							{
								submited: false,
							},
							() =>
								this.handleShowSnackbar({
									variant: "error",
									message: "Appointment not completed!",
								})
						);
					}
				} else {
					this.setState({
						submited: false,
					});
				}
			})
			.catch((err) => {
				this.setState(
					{
						submited: false,
					},
					() =>
						this.handleShowSnackbar({
							variant: "error",
							message: "Appointment not completed!",
						})
				);
			});
	};

	handleCloseAppointment = () => {
		this.setState({
			submited: true,
		});

		const { appointment } = this.state;
		const userData = JSON.parse(localStorage.getItem("userData"));
		let data = new FormData();
		data.append("qase_api_token", userData.token);
		data.append("sid", userData.sid);
		data.append("uuid", appointment.uuid);

		fetch(
			`${process.env.REACT_APP_API_HOST_URL}/appointment/unattended/complete`,
			{
				method: "POST",
				body: data,
			}
		)
			.then((res) => {
				if (res.status !== 200 && res.status !== 201) {
					if (appointment.uuid) {
						throw new Error(
							"Failed to complete appointment " + appointment.uuid
						);
					}
					throw new Error("General failure: " + res.status);
				}

				return res.json();
			})
			.then((resData) => {
				if (resData.success) {
					if (resData.data.success) {
						//console.log(resData.data);
						this.setState({
							submited: false,
							appointment: {
								...this.state.appointment,
								status: resData.data.status.toLowerCase(),
								appointment_actions: resData.data.actions,
							},
						});
					} else {
						this.setState(
							{
								submited: false,
							},
							() =>
								this.handleShowSnackbar({
									variant: "error",
									message: "Appointment not closed!",
								})
						);
					}
				} else {
					this.setState({
						submited: false,
					});
				}
			})
			.catch((err) => {
				this.setState(
					{
						submited: false,
					},
					() =>
						this.handleShowSnackbar({
							variant: "error",
							message: "Appointment not closed!",
						})
				);
			});
	};

	onDocumentLoadSuccess = ({ numPages }) => {
		this.setState({ numPages });
	};

	handleShowSnackbar = (options) => {
		this.setState({
			notificationSnackbar: {
				open: true,
				variant: options.variant,
				message: options.message,
			},
		});
	};

	handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		this.setState({
			notificationSnackbar: {
				open: false,
				variant: "",
				message: "",
			},
		});
	};

	handleCancelationModalClose = (type) => {
		this.setState({
			[type]: false,
		});
	};

	getAppointmentTaxPercentage = () => {
		const { appointment } = this.state;
		let tax_percentage = 0;
		if (appointment.pst_tax_percentage) {
			tax_percentage += Number(appointment.pst_tax_percentage.toFixed(0));
		}

		if (appointment.gst_tax_percentage) {
			tax_percentage += Number(appointment.gst_tax_percentage.toFixed(0));
		}

		if (appointment.hst_tax_percentage) {
			tax_percentage += Number(appointment.hst_tax_percentage.toFixed(0));
		}

		return tax_percentage;
	};

	getRejectionReasons = () => {
		const { rejectionReasons } = this.props;
		return Object.entries(rejectionReasons)
			.filter(
				([group, reason]) => reason.name.toLowerCase() === "appointment"
			)
			.map(([group, reason]) => {
				return reason;
			});
	};

	render() {
		if (this.state.loading) {
			return <Loading />;
		}

		const { classes } = this.props;
		const {
			appointment,
			submited,
			notificationSnackbar,
			rejectionModalOpen,
			cancelationModalOpen,
			acceptedModalOpen,
		} = this.state;
		const actionBtns = this.getActionButtons();
		const appointmentRejectionReasons = this.getRejectionReasons();
		//console.log(appointment);
		return (
			<div className="page--wrapper--no-margin">
				{submited && <Processing />}
				{notificationSnackbar.open && (
					<NotificationSnackbar
						snackbarOptions={notificationSnackbar}
						handleClose={this.handleSnackbarClose}
					/>
				)}
				<Paper className={classes.Paper}>
					<PageHading title="" history={this.props.history} />

					<div className={classes.detailGroup}>
						<Typography
							variant="caption"
							classes={{
								caption: classes.caption,
							}}
						>
							MEETING:
						</Typography>
						<Typography paragraph={true}>
							{appointment.title_text}
						</Typography>
					</div>
					<div className={classes.detailGroup}>
						<Typography
							variant="caption"
							classes={{
								caption: classes.caption,
							}}
						>
							WHEN:
						</Typography>
						<Typography paragraph={true}>
							{appointment.time_for_show}
						</Typography>
					</div>
					<div className={classes.detailGroup}>
						<Typography
							variant="caption"
							classes={{
								caption: classes.caption,
							}}
						>
							DURATION:
						</Typography>
						<Typography paragraph={true}>
							{appointment.duration}
						</Typography>
					</div>
					{/* <div className={classes.detailGroup}>
                        <Typography
                            variant="caption"
                            classes={{
                                caption: classes.caption,
                            }}
                        >
                            REQUESTED BY:
                        </Typography>
                        <Typography paragraph={true}>
                            {appointment.client.field_first_name.und[0].value} {appointment.client.field_last_name.und[0].value}
                        </Typography>
                    </div> */}
					{appointment.show_breakdown && (
						<div className={classes.detailGroup}>
							<Typography
								variant="caption"
								classes={{
									caption: classes.caption,
								}}
							>
								RATE:
							</Typography>
							<Typography paragraph={true}>
								{appointment.amount_hour} {currency()}
							</Typography>
						</div>
					)}
					<div className={classes.detailGroup}>
						<Typography
							variant="caption"
							classes={{
								caption: classes.caption,
							}}
						>
							NOTES:
						</Typography>
						<Typography paragraph={true}>
							{appointment.description}
						</Typography>
					</div>

					{appointment.show_breakdown && (
						<Fragment>
							<Divider></Divider>
							<Table>
								<TableBody>
									<TableRow>
										<TableCell
											align="left"
											padding="none"
											classes={{
												body: classes.tableCell,
											}}
										>
											<Typography>
												Estimated Total:
											</Typography>
										</TableCell>
										<TableCell
											align="right"
											padding="none"
											classes={{
												body: classes.tableCell,
											}}
										>
											<Typography>
												{appointment.base_amount}
											</Typography>
										</TableCell>
									</TableRow>
									{appointment.pst_tax && (
										<TableRow>
											<TableCell
												align="left"
												padding="none"
											>
												<Typography>
													PST (
													{appointment.pst_tax_percentage.toFixed(
														0
													)}
													%):
												</Typography>
											</TableCell>
											<TableCell
												align="right"
												padding="none"
											>
												<Typography>
													+ ${appointment.pst_tax}
												</Typography>
											</TableCell>
										</TableRow>
									)}

									{appointment.gst_tax && (
										<TableRow>
											<TableCell
												align="left"
												padding="none"
											>
												<Typography>
													PST (
													{appointment.gst_tax_percentage.toFixed(
														0
													)}
													%):
												</Typography>
											</TableCell>
											<TableCell
												align="right"
												padding="none"
											>
												<Typography>
													+ ${appointment.gst_tax}
												</Typography>
											</TableCell>
										</TableRow>
									)}

									{!!appointment.hst_tax && (
										<TableRow>
											<TableCell
												align="left"
												padding="none"
											>
												<Typography>
													PST (
													{appointment.hst_tax_percentage.toFixed(
														0
													)}
													%):
												</Typography>
											</TableCell>
											<TableCell
												align="right"
												padding="none"
											>
												<Typography>
													+ ${appointment.hst_tax}
												</Typography>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
								<TableFooter>
									<TableRow>
										<TableCell
											align="left"
											padding="none"
											classes={{
												body: classes.tableCell,
											}}
										>
											<Typography
												style={{ fontWeight: 700 }}
											>
												TOTAL
											</Typography>
										</TableCell>
										<TableCell
											align="right"
											padding="none"
											classes={{
												body: classes.tableCell,
											}}
										>
											<Typography
												style={{ fontWeight: 700 }}
											>
												{currency()}{" "}
												{appointment.total_amount}
											</Typography>
										</TableCell>
									</TableRow>
								</TableFooter>
							</Table>
						</Fragment>
					)}
					{actionBtns}
				</Paper>

				{rejectionModalOpen && (
					<CancelationModal
						open={rejectionModalOpen}
						handleClose={() =>
							this.handleCancelationModalClose(
								"rejectionModalOpen"
							)
						}
						onSubmit={this.handleAppointmentRejectSubmit}
						title={appointment.title_text}
						rejectionReasons={appointmentRejectionReasons[0]}
						data={{
							comment: "",
							uuid: appointment.uuid,
							reason: "",
						}}
					/>
				)}

				{cancelationModalOpen && (
					<CancelationModal
						open={cancelationModalOpen}
						handleClose={() =>
							this.handleCancelationModalClose(
								"cancelationModalOpen"
							)
						}
						onSubmit={this.handleAppointmentCancelSubmit}
						rejectionReasons={appointmentRejectionReasons[0]}
						title={appointment.title_text}
						data={{
							comment: "",
							uuid: appointment.uuid,
							reason: "",
						}}
					/>
				)}

				{acceptedModalOpen && (
					<Dialog
						open={acceptedModalOpen}
						onClose={() =>
							this.handleCancelationModalClose(
								"acceptedModalOpen"
							)
						}
					>
						<DialogTitle>
							Pre-authorization charge accepted
						</DialogTitle>
						<DialogContent>
							<DialogContentText>
								The pre-authorization amount is reserved on your
								credit card. You may see the amount being
								reflected on your credit card statement as In
								Process or Pending, but this isn’t an actual
								charge.
								<br />
								<br />
								The pre-authorization amount held on your credit
								card will not expire. When your lawyer completes
								their services and sends you an invoice, their
								fee is automatically captured from the reserved
								pre-authorization amount.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								color="primary"
								variant="contained"
								onClick={() =>
									this.handleCancelationModalClose(
										"acceptedModalOpen"
									)
								}
							>
								ok
							</Button>
						</DialogActions>
					</Dialog>
				)}
			</div>
		);
	}
}

AppointmentDetailPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	return {
		isLawyer: state.headerReducer.user.is_lawyer,
		auth: state.auth,
		rejectionReasons: state.utility.rejectionReasons,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchHeaderData: (data) => {
			dispatch(fetchHeaderData(data));
		},
		logout: () => dispatch({ type: LOGOUT, payload: true }),
	};
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps)
)(AppointmentDetailPage);
