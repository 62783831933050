import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchUtilityRejectionReasons } from "./redux/actions/utilityActions";
import { LOGOUT } from "./redux/actions/actionTypes";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

//import cookie from 'react-cookies';
// import { Elements, useElements, useStripe, } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';

import {
	Header,
	QaseBottomNavigation,
	Loading,
	BackToConference,
} from "./Components/Layouts";

import {
	HomePage,
	LoginPage,
	DashboardPage,
	MessagesPage,
	AppointmentsPage,
	FilesPage,
	NotesPage,
	AccountActivityPage,
	AppointmentDetailPage,
	TransactionsPage,
	TaskDetailPage,
	TransactionDetailPage,
	CaseDetailPage,
	CaseListPage,
	Contact as ContactPage,
	SignupPage,
	ProfilePage,
	NoteDetailPage,
	AppointmentCreatePage,
	NoteCreatePage,
	TaskCreatePage,
	ConferencePage,
	PasswordReset,
	CreateCasePage,
	CaseOverviewPage,
	TasksPage,
	AccountMessagesPage,
} from "./Pages";

//import { FullStoryClient } from 'react-fullstory-component';

//import AuthContext from './Context/auth-context';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

// const settings = {
//   debug: false,
//   host: 'www.fullstory.com',
//   orgKey: 'MVNEX'
// };

// const data = {
//   key: 'value'
// };

// const sessionId = '123456789';

// const client = new FullStoryClient({
//   host: 'www.fullstory.com',
//   orgKey: 'MVNEX',
//   iframe: true,
//   debug: false,
// });

// client.render();
// client.setSession(new Date().getTime());

class App extends Component {
	state = {
		token: null,
		sessionId: null,
		userEmail: null,
		case_uuid: null,
		loading: true,
	};

	componentDidMount() {
		this.props.fetchRejectionReasons();

		this.setState({
			loading: false,
		});
	}

	render() {
		if (this.state.loading) {
			return <Loading />;
		}

		const {
			auth: { token, case_uuid },
			isActiveCall,
		} = this.props;
		return (
			<BrowserRouter>
				<React.Fragment>
					{(token || window.location.pathname === "/case/create") && (
						<Route component={Header} />
					)}
					{isActiveCall && token && (
						<Route
							render={(props) => <BackToConference {...props} />}
						/>
					)}
					<main>
						<Switch>
							{/* {token && (<Redirect from="/login" to={!!case_uuid ? `/dashboard/${case_uuid}` : 'dashboard'} exact />)}*/}

							<Route path="/" component={HomePage} exact />
							<Route
								path="/login/recover/password"
								component={PasswordReset}
							/>
							<Route
								path="/login"
								render={(props) => <LoginPage {...props} />}
							/>
							<Route path="/signup" component={SignupPage} />
							<Route
								path="/case/create"
								render={(props) => (
									<CreateCasePage {...props} />
								)}
							/>
							{!token && <Redirect from="/" to="/login" />}

							<Route path="/cases" component={CaseListPage} />
							<Route path="/profile">
								<ProfilePage />
							</Route>
							{/* {token && !case_uuid &&
                <Redirect to="/cases" />
              } */}

							<Route
								path="/conference/:uuid"
								component={ConferencePage}
								exact
							/>
							<Route
								path="/dashboard/:id?"
								render={(props) => (
									<DashboardPage
										{...props}
										{...this.props}
										rejectionReasons={
											this.props.rejectionReasons
										}
									/>
								)}
							/>
							<Route path="/messages" component={MessagesPage} />
							<Route
								path="/appointments/:id?"
								component={AppointmentsPage}
							/>
							<Route path="/tasks/:id?" component={TasksPage} />
							<Route path="/files" component={FilesPage} />
							<Route path="/notes" component={NotesPage} />
							<Route
								path="/note/create"
								component={NoteCreatePage}
							/>
							<Route
								path="/note/edit/:note_uuid"
								component={(props) => (
									<NoteCreatePage {...props} isEdit={true} />
								)}
							/>
							<Route
								path="/note/:id/:pmid?"
								component={NoteDetailPage}
							/>
							<Route
								path="/activities"
								component={AccountActivityPage}
							/>

							<Route
								path="/account_messages"
								component={AccountMessagesPage}
							/>
							<Route
								path="/appointment/create"
								component={AppointmentCreatePage}
							/>
							<Route
								path="/appointment/:id/:pmid?"
								component={AppointmentDetailPage}
								exact
							/>
							<Route
								path="/task/create"
								component={TaskCreatePage}
							/>
							<Route
								path="/task/:id/:pmid?"
								component={TaskDetailPage}
							/>
							<Route
								path="/transaction/:id/:pmid?"
								component={TransactionDetailPage}
							/>
							<Route
								path="/transactions"
								component={TransactionsPage}
							/>
							<Route
								path="/case/overview"
								component={CaseOverviewPage}
							/>
							<Route
								path="/case/:id/:pmid?"
								component={CaseDetailPage}
							/>

							<Route path="/contactus" component={ContactPage} />
						</Switch>
					</main>
					{token && <Route component={QaseBottomNavigation} />}
				</React.Fragment>
			</BrowserRouter>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isActiveCall: state.utility.isActiveCall,
		rejectionReasons: state.utility.rejectionReasons,
		auth: state.auth,
		isLawyer: state.headerReducer.user.is_lawyer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchRejectionReasons: () => {
			dispatch(fetchUtilityRejectionReasons());
		},
		sessionEnd: () => dispatch({ type: LOGOUT, payload: true }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
