import {
    HEADER_FETCH_BEGINING_DATA,
} from '../actions/actionTypes';

export const fetchHeaderData = (data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            const response = await qaseApi.getHeaderData(data);
            dispatch({
                type: HEADER_FETCH_BEGINING_DATA,
                payload: response.data
            });
        } catch (e) {
            //console.log(e);
        }
    }
}

