import { combineReducers } from 'redux';

import headerReducer from './headerReducer';
import fileReducer from './fileReducer';
import taskReducer from './taskReducer';
import utilityReducer from './utilityReducer';
import messageReducer from './messageReducer';
import appointmentReducer from './appointmentReducer';
import transactionReducer from './transactionReducer';
import caseReducer from './caseReducer';
import authReducer from './authReducer';
import noteReducer from './noteReducer';
import dashboardReducer from './dashboardReducer';

export default combineReducers({
    headerReducer,
    file: fileReducer,
    task: taskReducer,
    utility: utilityReducer,
    message: messageReducer,
    appointment: appointmentReducer,
    transaction: transactionReducer,
    case: caseReducer,
    auth: authReducer,
    note: noteReducer,
    dashboard: dashboardReducer,
});