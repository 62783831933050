import { NOTES_FETCH_BEGINING_DATA, NOTES_CREATE_NOTE, NOTES_DELETE_NOTE, NOTES_SHOW_PROCESSING, NOTES_SHOW_ERROR, NOTES_SHARE_NOTE, LOGOUT } from "./actionTypes";

export const fetchNotesList = (data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            const response = await qaseApi.getNotes(data);
            dispatch({
                type: NOTES_FETCH_BEGINING_DATA,
                payload: response.data
            });
        } catch (e) {
            console.error(e);
            if (e.message === 'Session too old.' || e.message === 'No session found.') {
                dispatch({
                    type: LOGOUT,
                    payload: true,
                });
            }
        }
    }
};

export const createNote = (case_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            const response = await qaseApi.createNote(case_uuid, data);
            dispatch({
                type: NOTES_CREATE_NOTE,
                payload: response.data,
            });
        } catch (e) {
            console.error(e)
        }
    }
};

export const deleteNote = (note_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            dispatch({ type: NOTES_SHOW_PROCESSING });
            const response = await qaseApi.deleteNote(data);
            if (response.success) {
                dispatch({
                    type: NOTES_DELETE_NOTE,
                    payload: note_uuid,
                });
            }
            else {
                dispatch({
                    type: NOTES_SHOW_ERROR,
                    payload: {
                        variant: 'error',
                        message: response.message,
                    }
                });
            }

        } catch (e) {
            dispatch({
                type: NOTES_SHOW_ERROR,
                payload: {
                    variant: 'error',
                    message: e.message,
                }
            });
        }
    }
};

export const shareNote = (note_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            dispatch({ type: NOTES_SHOW_PROCESSING });
            const response = await qaseApi.shareNote(data);
            if (response.success) {
                dispatch({
                    type: NOTES_SHARE_NOTE,
                    payload: note_uuid,
                });
            }
            else {
                dispatch({
                    type: NOTES_SHOW_ERROR,
                    payload: {
                        variant: 'error',
                        message: response.message,
                    }
                });
            }

        } catch (e) {
            dispatch({
                type: NOTES_SHOW_ERROR,
                payload: {
                    variant: 'error',
                    message: e.message,
                }
            });
        }
    }
};