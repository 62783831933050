import {
    TASKS_FETCH_BEGINING_DATA,
    TASKS_CREATE_TASK,
    TASKS_ACCEPT_TASK,
    TASKS_SHOW_PROCESSING,
    TASKS_SHOW_ERROR,
    TASKS_CANCEL_TASK,
    TASKS_REJECT_TASK,
    TASKS_COMPLETE_TASK,
    LOGOUT,
} from "./actionTypes";

export const fetchTasksList = (case_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            const response = await qaseApi.getTasks(case_uuid, data);
            dispatch({
                type: TASKS_FETCH_BEGINING_DATA,
                payload: response.data,
            });
        } catch (e) {
            console.error(e);
            if (
                e.message === "Session too old." ||
                e.message === "No session found."
            ) {
                dispatch({
                    type: LOGOUT,
                    payload: true,
                });
            }
        }
    };
};

export const createTask = (data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            dispatch({ type: TASKS_SHOW_PROCESSING });
            const response = await qaseApi.createTask(data);
            dispatch({
                type: TASKS_CREATE_TASK,
                payload: response.data,
            });
        } catch (e) {
            console.error(e);
        }
    };
};

export const acceptTask = (task_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            dispatch({ type: TASKS_SHOW_PROCESSING });
            const response = await qaseApi.acceptTask(data);
            console.log(response);
            if (response.data.success) {
                if (!!response.data.show_modal) {
                    dispatch({
                        type: TASKS_SHOW_ERROR,
                        payload: {
                            variant: "warning",
                            message: "Please complete billing details",
                        },
                    });
                } else {
                    dispatch({
                        type: TASKS_ACCEPT_TASK,
                        payload: {
                            task_uuid: task_uuid,
                            data: response.data,
                        },
                    });
                }
            } else {
                dispatch({
                    type: TASKS_SHOW_ERROR,
                    payload: {
                        variant: "error",
                        message: "task didn`t accepted",
                    },
                });
            }
        } catch (e) {
            console.error(e);
        }
    };
};

export const cancelTask = (task_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            dispatch({ type: TASKS_SHOW_PROCESSING });
            const response = await qaseApi.cancelTask(data);
            dispatch({
                type: TASKS_CANCEL_TASK,
                payload: {
                    task_uuid: task_uuid,
                    data: response.data,
                },
            });
        } catch (e) {
            console.error(e);
        }
    };
};

export const rejectTask = (task_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            dispatch({ type: TASKS_SHOW_PROCESSING });
            const response = await qaseApi.rejectTask(data);
            dispatch({
                type: TASKS_REJECT_TASK,
                payload: {
                    task_uuid: task_uuid,
                    data: response.data,
                },
            });
        } catch (e) {
            console.error(e);
        }
    };
};

export const completeTask = (task_uuid, data) => {
    return async (dispatch, getState, qaseApi) => {
        try {
            dispatch({ type: TASKS_SHOW_PROCESSING });
            const response = await qaseApi.completeTask(data);
            if (response.data.success) {
                dispatch({
                    type: TASKS_COMPLETE_TASK,
                    payload: {
                        task_uuid: task_uuid,
                        data: response.data,
                    },
                });
            } else {
                dispatch({
                    type: TASKS_SHOW_ERROR,
                    payload: {
                        variant: "error",
                        message: "task didn`t completed",
                    },
                });
            }
        } catch (e) {
            console.error(e);
        }
    };
};
