import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import {
    Typography,
    Button,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import {
    KeyboardArrowDown,
    Person as PersonIcon,
    CreditCard as CreditCardIcon,
    Lock as LockIcon,
    Info as InfoIcon,
} from '@material-ui/icons';
import Sticky from 'react-stickynode';
import '../Layouts/StickySubHeader.css'
const styles = theme => ({
    subHeader: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '80%',
        minWidth: 340,
        margin: '0 auto',
        padding: 5,
    },
    subHeaderTitle: {
        fontSize: 15,
        color: '#1E1E1E',
        fontWeight: 700,
    },
    subHeaderBtn: {
        textTransform: 'capitalize',
    },
    accountMenuBackdrop: {
        top: '102px',
    },
    accountMenuPaper: {
        top: '102px',
    },
});


class TransactionsStickyHeader extends Component {

    state = {
        top: false,
        status: 0,
    };

    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        });
    };

    getMenuItems = () => {
        return ['Case Transactions', 'Case Details', 'Case Activities'];
    }

    handleStateChange = (status) => {
        //console.log(Sticky.STATUS_RELEASED);

        this.setState({
            status: status.status,
        });
    }

    render() {
        const { classes, activeMenu } = this.props,
            { status } = this.state;
        const MenuItems = this.getMenuItems();

        const fullList = (
            <div className={classes.fullList}>
                <List>
                    {MenuItems.map((text, index) => (
                        <ListItem button key={text}
                            selected={index == (activeMenu - 1)}
                        //onClick={() => onMenuSelect(index + 1)}
                        >
                            <ListItemText primary={text} />
                        </ListItem>
                    ))}
                </List>
            </div>
        );

        return (
            <Fragment>
                <Sticky enabled={true} top={56} innerZ={1299} onStateChange={this.handleStateChange}>
                    <div className={classes.subHeader}>
                        <Button color="primary"
                            //onClick={this.toggleDrawer('top', true)}
                            className={classes.subHeaderTitle}
                        >
                            {activeMenu ? MenuItems[activeMenu - 1] : MenuItems[0]}
                            {/* {status == 2 &&
                                <KeyboardArrowDown />
                            } */}
                        </Button>
                    </div>
                </Sticky>

                {/* <Drawer
                    anchor="top"
                    open={this.state.top}
                    onClose={this.toggleDrawer('top', false)}
                    classes={{
                        paper: classes.accountMenuPaper,
                        modal: classes.accountMenuBackdrop
                    }}
                    ModalProps={{
                        BackdropProps: {
                            style: {
                                top: 102,
                                bottom: 72
                            }
                        }
                    }}
                >
                    <div
                        tabIndex={0}
                        role="button"
                        onClick={this.toggleDrawer('top', false)}
                        onKeyDown={this.toggleDrawer('top', false)}
                    >
                        {fullList}
                    </div>
                </Drawer> */}
            </Fragment>
        )
    }
}

TransactionsStickyHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TransactionsStickyHeader);