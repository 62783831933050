import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { ArrowBack } from "@material-ui/icons";
import { IconButton, Typography, Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
    root: {},
    grow: {
        flexGrow: 1,
    },
    backArrow: {
        padding: 5,
        paddingLeft: 0,
    },
    clearBtn: {
        float: "right",
        backgroundColor: "#E8E6F3",
        color: "#220D89",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#E8E6F3",
            color: "#220D89",
        },
    },
});

class PageHeading extends Component {
    render() {
        const {
            classes,
            title,
            count,
            history,
            clearNotification,
            onClearNotification,
        } = this.props;
        return (
            <Typography className={classes.grow}>
                <IconButton
                    aria-label="Delete"
                    className={classes.backArrow}
                    onClick={() => history.goBack()}
                >
                    <ArrowBack fontSize="small" />
                </IconButton>
                {title} {!!count && `(${count})`}
                {clearNotification && (
                    <Button
                        variant="contained"
                        size="small"
                        onClick={onClearNotification}
                        className={classes.clearBtn}
                    >
                        Clear Notifications
                    </Button>
                )}
            </Typography>
        );
    }
}

PageHeading.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(PageHeading));
