import React, { Component } from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import CaseCreateExitConfirmation from './CaseCreateExitConfirmation';
import { CASE_NEW_RESET_AFTER_CREATE } from '../../redux/actions/actionTypes';
class CaseCreateHeader extends Component {
    state = {
        isExitConfirmationOpen: false,
    };

    handleShowExitConfiramtion = () => {
        this.setState({
            isExitConfirmationOpen: true,
        });
    };

    handleCloseExitConfiramtion = () => {
        this.setState({
            isExitConfirmationOpen: false,
        });
    };

    handleQuitFormAppointmentCreation = () => {
        this.setState({
            isExitConfirmationOpen: false,
        }, () => {
            this.props.caseStepChangeReset();
            if (this.props.isLogged) {
                this.props.history.push("/dashboard");
            }
            else {
                const { newCase: { referral_source } } = this.props;
                console.log(referral_source);
                switch (referral_source) {
                    case 'acba-lrs':
                        window.location = 'http://www.acbanet.org/request-a-lawyer/';
                        break;
                    default:
                        window.location = process.env.REACT_APP_MARKETING_URL;
                        break;
                };
            }
        });
    }

    render() {
        //console.log(this.props);
        const { classes, newCase: { createCaseSteps, activeStep, userSubmited } } = this.props;

        const _activeStep = createCaseSteps[activeStep];
        return (
            <AppBar position="sticky" className={classes.root}>
                <Toolbar>
                    <img src={require('../../images/Qlogo.png')} alt="logo" className={classes.qaseLogo} style={{ marginRight: 15 }} />

                    <Typography className={classes.title} variant="h6" color="inherit" noWrap={true}>
                        {_activeStep}
                    </Typography>
                    <div className={classes.grow} />
                    {!userSubmited &&
                        <div className={classes.sectionDesktop}>
                            <IconButton className={classes.menuButton} color="inherit" aria-label="Open drawer" onClick={this.handleShowExitConfiramtion}>
                                <CloseIcon />
                            </IconButton>
                            <CaseCreateExitConfirmation
                                open={this.state.isExitConfirmationOpen}
                                handleClose={this.handleCloseExitConfiramtion}
                                handleQuit={this.handleQuitFormAppointmentCreation}
                            />
                        </div>
                    }

                </Toolbar>
            </AppBar>
        );
    }
};

export default CaseCreateHeader;
