import {
	CASE_NEW_CASE_INIT_STEPS,
	CASE_NEW_CASE_FILL_DATA,
	CASE_NEW_CASE_FILL_USER_DATA,
	CASE_NEW_SHOW_LAWYER_BIO,
	CASE_NEW_HIDE_LAWYER_BIO,
	CASE_NEW_RESET_AFTER_CREATE,
	CASE_NEW_CASE_STEP_CHANGE,
	CASE_NEW_CASE_FILL_INIT_DATA,
	CASE_STORE_CASE_DATA,
} from "../actions/actionTypes";

const clientSteps = [
	"1: Choose Legal Issue",
	"2: Describe Case",
	"3: Schedule Consult",
	"4: Select Lawyer",
	"5: Consultation Options",
	"6: Confirm Consult",
];
const anonymousSteps = [
	"1: Choose Legal Issue",
	"2: Describe Case",
	"3: Schedule Consult",
	"4: Select Lawyer",
	"5: Consultation Options",
	"6: Create Account",
];

const initState = {
	newCase: {
		default_duration: 30,
		practice_area: "",
		practice_jurisdiction: process.env.REACT_APP_DEFAULT_JURISDICTION,
		description: "",
		opposition_field: "",
		start: "",
		date: null,
		lawyer: "",
		calendar_data: {},
		isBioOpen: false,
		bio_uuid: "",
		createCaseSteps: [], //["1: Choose Legal Issue", "2: Describe Case", "3: Schedule Consult", "4: Select Lawyer", "5: Confirm Consult"],
		activeStep: 0,
		referral_source: "",
		userSubmited: false,
		connection_option: "",
	},
	newUser: {
		field_first_name: "",
		field_last_name: "",
		email: "",
		field_telephone: "",
		password: "",
		password_confirm: "",
	},

	caseStore: false,
};

const caseReducer = (state = initState, action) => {
	switch (action.type) {
		case CASE_NEW_CASE_FILL_INIT_DATA:
			let newData = Object.assign(state.newCase, action.payload);
			return {
				...state,
				newCase: newData,
			};
		case CASE_NEW_CASE_INIT_STEPS:
			let caseSteps = action.payload.is_logged
				? clientSteps
				: anonymousSteps;
			return {
				...state,
				newCase: {
					...state.newCase,
					createCaseSteps: caseSteps,
				},
			};
		case CASE_NEW_CASE_FILL_DATA:
			return {
				...state,
				newCase: {
					...state.newCase,
					[action.payload.key]: action.payload.value,
				},
			};
		case CASE_NEW_CASE_FILL_USER_DATA:
			return {
				...state,
				newUser: {
					...state.newUser,
					[action.payload.key]: action.payload.value,
				},
			};
		case CASE_NEW_SHOW_LAWYER_BIO:
			return {
				...state,
				newCase: {
					...state.newCase,
					isBioOpen: true,
					bio_uuid: action.payload,
				},
			};
		case CASE_NEW_HIDE_LAWYER_BIO:
			return {
				...state,
				newCase: {
					...state.newCase,
					isBioOpen: false,
					bio_uuid: "",
				},
			};
		case CASE_NEW_RESET_AFTER_CREATE:
			return {
				...state,
				newCase: {
					...initState.newCase,
				},
				newUser: {
					...initState.newUser,
				},
			};
		case CASE_NEW_CASE_STEP_CHANGE:
			return {
				...state,
				newCase: {
					...state.newCase,
					activeStep: action.payload,
				},
			};
		case CASE_STORE_CASE_DATA:
			return {
				...state,
				caseStore: action.payload,
			};

		default:
			return state;
	}
};

export default caseReducer;
