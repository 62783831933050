import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Typography, IconButton, Paper, Input, FormControl, InputAdornment, Grid } from '@material-ui/core';
import { Visibility, VisibilityOff, Create as CreateIcon } from '@material-ui/icons';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Alert } from 'reactstrap';
import Actions from './Actions';

const styles = theme => ({
    Paper: {
        padding: 20,
        marginBottom: 10,
    },
    formGroup: {
        marginBottom: 15,
    },
    editBtn: {
        padding: 5,
    },
    avatar: {
        width: 64,
        height: 64,
        marginTop: 16,
    }
});

class PasswordInfo extends Component {

    state = {
        new_password: {
            value: '',
            error: false,
        },
        confirm_password: {
            value: '',
            error: false,
        },
        old_password: {
            value: '',
            error: false,
        },
        showCurrentPassword: false,
        showNewPassword: false,
        showConfirmPassword: false,
        editMode: false,
        submited: false,
        errorMessage: false,
    };

    handleClickShowPassword = (name) => {
        this.setState(state =>
            ({
                [name]: !state[name]
            })
        );
    };

    handleInputChange = name => event => {
        this.setState({
            [name]: {
                value: event.target.value,
            }
        });
    };

    changeEditMode = () => {
        this.setState({
            editMode: !this.state.editMode,
        })
    };

    handleSubmit = (event) => {
        this.setState({
            submited: true,
        });

        const { new_password, confirm_password, old_password } = this.state;

        var data = new FormData();

        var sessionUserData = JSON.parse(localStorage.getItem("userData"));

        data.append('qase_api_token', sessionUserData.token);
        data.append('sid', sessionUserData.sid);
        data.append('new_password', new_password.value);
        data.append('confirm_password', confirm_password.value);
        data.append('old_password', old_password.value);



        fetch(`${process.env.REACT_APP_API_HOST_URL}/user/password/update`, {
            method: 'POST',
            body: data,
        })
            .then(res => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('failed');
                }
                return res.json();
            })
            .then(resData => {
                //console.log(resData);
                if (resData.success) {
                    this.setState({
                        editMode: false,
                        submited: false,
                        errorMessage: false,
                    });

                    if (!resData.data.success) {
                        this.setState({
                            errorMessage: true,
                        });
                    }
                }
            })
            .catch(err => {
                //console.log(err);
            });
    };

    render() {
        const { classes } = this.props,
            { editMode, submited, errorMessage } = this.state;
        return (
            <Paper className={classes.Paper}>
                <ValidatorForm
                    encType="multipart/form-data"
                    ref="form"
                    onSubmit={this.handleSubmit}>

                    <Actions
                        title="PASSWORD"
                        editMode={editMode}
                        submited={submited}
                        changeEditMode={this.changeEditMode}
                    />

                    <FormControl margin="normal" fullWidth>
                        <TextValidator
                            type={this.state.showNewPassword ? 'text' : 'password'}
                            value={this.state.new_password.value}
                            onChange={this.handleInputChange('new_password')}
                            placeholder="New Password"
                            label="New Password"
                            name="new_password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Toggle password visibility"
                                            onClick={() => this.handleClickShowPassword('showNewPassword')}
                                            disabled={!editMode}
                                        >
                                            {this.state.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            validators={['required']}
                            errorMessages={['new password is required']}
                            disabled={!editMode}
                        />
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                        <TextValidator
                            type={this.state.showConfirmPassword ? 'text' : 'password'}
                            value={this.state.confirm_password.value}
                            onChange={this.handleInputChange('confirm_password')}
                            placeholder="Confirm New Password"
                            label="Confirm New Password"
                            name="confirm_password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Toggle password visibility"
                                            onClick={() => this.handleClickShowPassword('showConfirmPassword')}
                                            disabled={!editMode}
                                        >
                                            {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            validators={['required']}
                            errorMessages={['confirm password is required']}
                            disabled={!editMode}
                        />
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                        <TextValidator
                            type={this.state.showCurrentPassword ? 'text' : 'password'}
                            value={this.state.old_password.value}
                            onChange={this.handleInputChange('old_password')}
                            placeholder="Current Password"
                            label="Current Password"
                            name="old_password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Toggle password visibility"
                                            onClick={() => this.handleClickShowPassword('showCurrentPassword')}
                                            disabled={!editMode}
                                        >
                                            {this.state.showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            validators={['required']}
                            errorMessages={['current password is required']}
                            disabled={!editMode}
                        />
                    </FormControl>

                    {errorMessage &&
                        <Alert color="danger">
                            Password Not Changed!
                        </Alert>
                    }
                </ValidatorForm>
            </Paper>
        )
    }
}

PasswordInfo.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PasswordInfo);